import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISessionCancelModel from 'stores/domains/Session/SessionCancel/interface'

const SessionCancelModel: ISessionCancelModel = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  errorMessage: '',

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setSuccess: action((state, payload) => {
    state.isSuccess = payload
  }),
  setError: action((state, payload) => {
    state.isSuccess = false
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  cancelSession: thunk(async (actions, data, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      await apiClient({
        url: `/session/v1/live/cancel`,
        method: 'PUT',
        token: getCookie(authConfig.idTokenName),
        data,
      })

      actions.setLoading(false)
      actions.setSuccess(true)
    } catch (error) {
      if (get(error, 'response.data.code') === 'FAIL') {
        actions.setError(
          get(error, 'response.data.errors', ['unknown error']).join(
            ',',
          ),
        )
      } else {
        actions.setError(get(error, 'response.data.message'))
      }
    }
  }),
}

export default SessionCancelModel
