import { action } from 'easy-peasy'
import ISignUpBonusDrawerModel from 'stores/domains/SignUpBonus/Drawer/interface'

const SignUpBonusDrawerModel: ISignUpBonusDrawerModel = {
  // Whether the user earn the bonus or not, default `false`
  earned: false,

  // Whether the user already received the information and closes it, default `false`
  closed: false,

  setEarned: action((state, payload) => {
    state.earned = payload
  }),

  setClosed: action((state, payload) => {
    state.closed = payload
  }),
}

export default SignUpBonusDrawerModel
