import { useRouter } from 'next/router'
import {
  CssBaseline,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import ButtonIcon from 'components/commons/ButtonIcon'

import SearchPage from 'containers/commons/SearchPage/v2'
import SearchComponent from 'containers/commons/Search/v2'
import { HeaderMenu } from 'containers/commons/HeaderMenu'

import { useNotificationStore } from 'stores/commons'

import authConfig from 'configs/auth'
import appConfig from 'configs/app'

import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import afterLogout from 'helpers/auth/after-logout'
import { checkQuery } from 'helpers/browse'
import { getIdToken } from 'helpers/auth'
import { getLocalStorage } from 'helpers/local-storage'
import { setUserDevice } from 'helpers/user-device'
import { getLastAuthUser } from 'helpers/cognito/token'
import {
  openInNewTab,
  redirectWithReferrer,
} from 'helpers/redirector'

import { useStoreActions, useStoreState } from 'stores/hooks'
import trackEvent from 'src/trackers'

import SignUpBonusBanner from 'components/commons/Banner/v2'
import { ModalBecomeCreator } from 'components/domains/Home/ModalBecomeCreator'

import {
  useCategoryStore,
  useMessageStore,
  useProfileUserStore,
} from 'stores/domains/User'

import {
  useCartTotalItems,
  IUseCartTotalItems,
} from 'src/hooks/domains/Cart/useCartTotaltems'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import MailRoundedIcon from '@mui/icons-material/MailRounded'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import MainLogo from 'assets/logos/logo.svg'
import MobileMainLogo from 'assets/logos/mini-logo.svg'

import {
  HeaderContainer,
  StyleAppBar,
  StyledContainer,
  StyledHeaderTitle,
  StyledNavBar,
} from './styled'

import CategoriesHeader from './CategoriesHeader'
import CreateMenuHeader from './CreateMenuHeader'
import NotificationHeader from './NotificationHeader'
import ProfileHeader from './ProfileHeader'
import AppBanner from 'components/commons/AppBanner'
import { isGuestMode } from 'helpers/cookie'
import { useCartTotalItemsStore } from 'stores/domains/Cart'
import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import Stack from 'components/commons/Stack'
import Button from 'components/commons/Button'
import MobileSignIn from 'components/commons/Header/BottomBar/MobileSignIn'
import MultipleLanguageMenu from './MultipleLanguageMenu'
import { useTranslation } from 'react-i18next'
import Divider from 'components/commons/Divider'
import LocationHeader from './LocationHeader'

type IHeaderProps = {
  renderCart?: boolean
  showAppBanner?: boolean
  setShowAppBanner?: Function
  isShowLogoOnly?: boolean
  isHome?: boolean
}

function Header({
  showAppBanner,
  setShowAppBanner,
  renderCart = true,
  isShowLogoOnly = false,
  isHome = false,
}: IHeaderProps): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const [scroll, setScroll] = useState(false)

  const isMobileResolution = useMediaQuery(
    theme.breakpoints.down('sm'),
  )
  // Begin of Sign Up Bonus
  const { pathname, isReady, query } = useRouter()

  const trackerData = { pathname, query }
  const whitelistedPathsNames = useMemo(
    () => [
      '/',
      '/browse',
      '/session/[session_id]',
      '/p/[username]',
      '/sign-up',
      '/login',
    ],
    [],
  )

  const idToken = getIdToken()
  const lastAuthUser = getLastAuthUser()

  // Path for new user to show the banner sign up coin bonus
  const isNewUser = ['/user/post-registration'].includes(pathname)
  // End of path for new user

  const isGuest = whitelistedPathsNames.includes(pathname) //  on the whitelisted page

  const elBonusBanner = <SignUpBonusBanner />

  const [showSearch, setShowSearch] = useState(false)
  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const { categories, selectedCategories } = useCategoryStore(
    (state) => ({
      categories: state.categories,
      selectedCategories: state.selectedCategories,
    }),
  )
  const { searchHistory } = useStoreState((state) => state.search)
  const {
    renderHeaderMenu,
    headerTitle,
    headerType,
    onClickBack,
    renderBackButton,
  } = useStoreState((state) => state.screenLayout)
  const { sessionData } = useStoreState(
    (state) => state.browseSession,
  )
  const { creatorData } = useStoreState(
    (state) => state.browseCreator,
  )
  const { searchData } = useStoreState((state) => state.search)

  const { setSearchHistory, checkKeywordsToLocalStorage } =
    useStoreActions((action) => action.search)
  const { isBadge } = useMessageStore((state) => ({
    isBadge: state.isUnreadMessage,
  }))

  const { isBadgeCart } = useCartTotalItemsStore((state) => ({
    isBadgeCart: state.isBadgeCart,
  }))

  const { refetch: fetchCartTotalItems }: IUseCartTotalItems =
    useCartTotalItems()

  const {
    totalUnreadInformationNotification,
    totalUnreadTransactionNotification,
  } = useNotificationStore((state) => ({
    totalUnreadInformationNotification:
      state.totalUnreadInformationNotification,
    totalUnreadTransactionNotification:
      state.totalUnreadTransactionNotification,
  }))

  const [openModalBecomeCreator, setOpenModalBecomeCreator] =
    useState(false)

  const redirectURL = (url: string, isNewTab = false) => {
    setUserDevice()
    if (isNewTab) {
      openInNewTab(url)
    } else {
      redirect(url)
    }
  }

  const handleRedirectLogin = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_sign_in', trackerData)
    redirectWithReferrer(authConfig.loginPath)
  }

  const handleRedirectSignUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_sign_up', trackerData)
    redirectWithReferrer('/sign-up')
  }

  const redirectSearchPage = (key: string) => {
    const keyword = key !== '' ? `&q=${key}` : ''
    const url = `/browse?source=${pathname}&source_action=click_search_button&status=${
      query?.status || 'all'
    }${keyword}`
    redirect(url)
  }

  const getPropertiesClickSearchButton = (keyword) => {
    return {
      search_box: keyword,
      search_history: searchHistory,
      selected_browse_and_search_menu: `${
        query?.status || 'all'
      }_tab`,
      list_selected_category_id: isEmpty(query.category_id)
        ? []
        : checkQuery(query.category_id),
      list_selected_category_name: isEmpty(query.category_id)
        ? []
        : categories
            .filter((cat) => selectedCategories?.includes(cat.id))
            .map((cat) => cat.name),
      browse_session_list:
        query.status === 'session' && sessionData.sessionList.length
          ? sessionData.sessionList.map((session) => session.id)
          : [],
      browse_creator_list:
        query.status === 'creator'
          ? creatorData.creatorList.map(
              (creator) => creator.creator_id,
            )
          : [],
      browse_creator_user_id_list:
        query.status === 'creator'
          ? creatorData.creatorList.map(
              (creator) => creator.creator_user_id,
            )
          : [],
      browse_creator_name_list:
        query.status === 'creator'
          ? creatorData.creatorList.map((creator) => creator.name)
          : [],
      browse_community_list:
        query?.status === 'community'
          ? searchData.community.map((community) => community.mask_id)
          : [],
      browse_community_num:
        query?.status === 'community'
          ? searchData.community.length
          : 0,
    }
  }

  const handleEnterPress = (keyword) => {
    const getSearchProperties =
      getPropertiesClickSearchButton(keyword)

    trackEvent.home('click_search_button', trackerData, {
      ...getSearchProperties,
    })
    if (keyword !== '') checkKeywordsToLocalStorage(keyword)
    redirectSearchPage(keyword)
  }

  const handleClickSearchIcon = (keyword) => {
    setShowSearch(true)
    const getSearchProperties =
      getPropertiesClickSearchButton(keyword)
    trackEvent.home('click_search_button', trackerData, {
      ...getSearchProperties,
    })
  }

  useEffect(() => {
    let el = getLocalStorage('search')
    if (isReady && !isEmpty(el)) {
      setSearchHistory(el.reverse())
    } else {
      setSearchHistory([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, searchHistory.length])

  useEffect(() => {
    if (!isEmpty(idToken) && isEmpty(lastAuthUser)) {
      //handle special case for oauth cognito signout
      afterLogout(() => {
        redirect('/')
      })
    }
  }, [idToken, lastAuthUser])

  useEffect(() => {
    if (!isGuestMode()) fetchCartTotalItems()
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      let bannerHeight = isMobileResolution ? 313 : 400
      if (window.scrollY > bannerHeight) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isCreator = useMemo(() => {
    return (
      !isEmpty(idToken) &&
      !isEmpty(profileData.username) &&
      profileData.isCreator
    )
  }, [profileData, idToken])

  const isHasUnreadNotification = useMemo(() => {
    if (
      totalUnreadInformationNotification === 0 &&
      totalUnreadTransactionNotification === 0
    )
      return false
    return (
      totalUnreadInformationNotification > 0 ||
      totalUnreadTransactionNotification > 0
    )
  }, [
    totalUnreadInformationNotification,
    totalUnreadTransactionNotification,
  ])

  const setBackgroundAppBar = () => {
    if (isHome) {
      if (!scroll) return 'transparent'
      return theme.palette.background.primary
    } else {
      return theme.palette.background.primary
    }
  }

  const setColorBtn = () => {
    if (isHome) {
      if (scroll) return theme.palette.text.secondary
      return theme.palette.background.primary
    } else {
      return theme.palette.text.secondary
    }
  }

  return (
    <Fragment>
      <CssBaseline />
      <HeaderContainer>
        <StyleAppBar
          sx={{
            background: isMobileResolution && setBackgroundAppBar(),
          }}
        >
          <>
            {/* Begin of Sign Up Bonus */}
            {(isGuest || isNewUser) && elBonusBanner}
            {showAppBanner && isMobileResolution && (
              <AppBanner
                onClose={() => {
                  setShowAppBanner(false)
                }}
              />
            )}
            <StyledContainer
              showAppBanner={showAppBanner && isMobileResolution}
            >
              <Toolbar
                sx={{
                  justifyContent: {
                    xs: 'space-between',
                  },
                  alignItems: 'center',
                  padding: { xs: '10px 0 10px 10px', md: 0 },
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    mr: 2,
                    p: 0,
                    display: { xs: 'none', md: 'inline-flex' },
                    ':hover': {
                      background: 'transparent',
                    },
                  }}
                  onClick={() => {
                    trackEvent.home('click_home', trackerData)
                    redirect('/')
                  }}
                >
                  <MainLogo />
                </IconButton>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    mr: 2,
                    p: 0,
                    display: { xs: 'inline-flex', md: 'none' },
                    pl: renderBackButton ? '23px' : 0,
                  }}
                  onClick={
                    renderBackButton
                      ? () => onClickBack()
                      : () => redirect('/')
                  }
                >
                  {renderBackButton ? (
                    <ArrowBackIcon
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  ) : (
                    <MobileMainLogo />
                  )}
                </IconButton>
                {!isEmpty(headerTitle) && (
                  <StyledHeaderTitle>{headerTitle}</StyledHeaderTitle>
                )}
                {!isShowLogoOnly && (
                  <Stack
                    flex={1}
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      flex={1}
                      spacing={2}
                      alignItems={'center'}
                    >
                      <SearchComponent
                        searchId="i-common-search-header"
                        handleOnFocus={handleClickSearchIcon}
                        handleEnterPress={handleEnterPress}
                      />
                      {!idToken && isMobileResolution && (
                        <MobileSignIn />
                      )}
                    </Stack>
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      display={{ xs: 'none', md: 'flex' }}
                    >
                      {isEmpty(idToken) && (
                        <>
                          <Button
                            buttonVariant="outlined"
                            sx={{ marginRight: '12px' }}
                            onClick={(e) => handleRedirectLogin(e)}
                          >
                            Sign In
                          </Button>
                          <Button
                            onClick={(e) => handleRedirectSignUp(e)}
                          >
                            Sign Up
                          </Button>
                        </>
                      )}
                      {!isEmpty(idToken) && (
                        <>
                          <StyledNavBar
                            onClick={() => {
                              trackEvent.home(
                                'click_library',
                                trackerData,
                              )
                              redirectURL(
                                `/library?source=${pathname}&source_action=trigger_my_library_tab`,
                              )
                            }}
                          >
                            {t('purchase')}
                          </StyledNavBar>
                          {isCreator && <CreateMenuHeader />}
                          <Link
                            href={`/message?source=${pathname}&source_action=click_message`}
                            onClick={() =>
                              trackEvent.user(
                                'click_message',
                                trackerData,
                              )
                            }
                          >
                            <ButtonIcon
                              id="b-users-message-entrypoint"
                              badge={{ invisible: !isBadge }}
                              icon={MailRoundedIcon}
                              sx={(theme) => ({
                                color: theme.palette.text.secondary,
                                '&:hover': {
                                  color: theme.palette.tiptip[500],
                                },
                              })}
                            />
                          </Link>
                          <NotificationHeader
                            showBadge={isHasUnreadNotification}
                          />
                          {!isGuestMode() && renderCart && (
                            <Link
                              href={`/cart?source=${pathname}&source_action=click_cart`}
                              onClick={() => {
                                trackEvent.cart(
                                  'click_cart',
                                  trackerData,
                                )
                              }}
                            >
                              <ButtonIcon
                                id="b-users-cart-entrypoint"
                                badge={{ invisible: !isBadgeCart }}
                                icon={ShoppingCartIcon}
                                sx={(theme) => ({
                                  color:
                                    theme.palette.background
                                      .secondary,
                                  '&:hover': {
                                    color: theme.palette.tiptip[500],
                                  },
                                })}
                              />
                            </Link>
                          )}

                          <ProfileHeader profileData={profileData} />
                        </>
                      )}
                    </Stack>
                    {!isEmpty(idToken) && (
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        display={{ xs: 'flex', md: 'none' }}
                      >
                        {headerType === 'base' && (
                          <>
                            <Link
                              href={`/message?source=${pathname}&source_action=click_message`}
                              onClick={() =>
                                trackEvent.user(
                                  'click_message',
                                  trackerData,
                                )
                              }
                              sx={{
                                display: 'block',
                              }}
                            >
                              <ButtonIcon
                                id="b-users-message-entrypoint"
                                badge={{ invisible: !isBadge }}
                                icon={MailRoundedIcon}
                                colorBtn={setColorBtn()}
                              />
                            </Link>
                            <Link
                              href={'/notification'}
                              sx={{
                                display: 'block',
                                cursor: 'pointer',
                              }}
                            >
                              <ButtonIcon
                                id="b-users-header-notification"
                                badge={{
                                  invisible: !isHasUnreadNotification,
                                }}
                                icon={NotificationsIcon}
                                colorBtn={setColorBtn()}
                              />
                            </Link>
                            {!isGuestMode() && renderCart && (
                              <Link
                                href={`/cart?source=${pathname}&source_action=click_cart`}
                                onClick={() =>
                                  trackEvent.cart(
                                    'click_cart',
                                    trackerData,
                                  )
                                }
                                sx={{
                                  display: {
                                    xs: 'block',
                                    md: 'none',
                                  },
                                }}
                              >
                                <ButtonIcon
                                  id="b-users-cart-entrypoint"
                                  badge={{ invisible: !isBadgeCart }}
                                  icon={ShoppingCartIcon}
                                  colorBtn={setColorBtn()}
                                />
                              </Link>
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
              </Toolbar>
            </StyledContainer>
          </>
          {!isMobileResolution && (
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                background: 'white',
                height: '36px',
                margin: `auto`,
                width: `100%`,
                maxWidth: `1440px`,
              }}
            >
              <Stack
                direction={'row'}
                gap={2}
                sx={{
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <LocationHeader />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    margin: '6px 0px',
                    width: '2px',
                    borderColor: theme.palette.grey[200],
                  }}
                />
                <CategoriesHeader />
              </Stack>

              <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <MultipleLanguageMenu />
                <StyledNavBar
                  onClick={() =>
                    redirectURL(appConfig.aboutTiptipUrl, true)
                  }
                >
                  {t('aboutTiptip')}
                </StyledNavBar>
              </Stack>
            </Stack>
          )}
        </StyleAppBar>

        {renderHeaderMenu && <HeaderMenu />}
      </HeaderContainer>
      <ModalBecomeCreator
        open={openModalBecomeCreator}
        onClose={() => setOpenModalBecomeCreator(false)}
      />
      <SearchPage
        showPage={showSearch}
        onCloseAction={() => setShowSearch(false)}
        pathname={pathname}
      />
    </Fragment>
  )
}

export default memo(Header)
