import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

interface SvgComponentProps extends React.SVGProps<SVGSVGElement> {
  active?: boolean
}

const SvgComponent = ({ active, ...rest }: SvgComponentProps) => {
  if (active) {
    return (
      <SVGUniqueID>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          {...rest}
        >
          <path
            d="M27.7726 22.1055L15.9349 29.0639C15.5603 29.2841 15.1623 29.428 14.7588 29.5001L14.7837 21.3979L15.2477 21.1251L11.0434 13.8632C10.0483 12.1443 10.6242 9.93824 12.3297 8.93572C14.0352 7.93319 16.2245 8.51388 17.2196 10.2327L21.4238 17.4947L24.1689 15.8811C25.8744 14.8785 28.0637 15.4592 29.0589 17.1781C30.054 18.8969 29.4781 21.103 27.7726 22.1055Z"
            fill="#CB1C4F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.929 8.93349L27.777 15.8746C29.4839 16.8747 30.0631 19.0799 29.0705 20.8002C28.0779 22.5205 25.8894 23.1044 24.1824 22.1043L21.435 20.4947L17.2415 27.7628C16.2489 29.4831 14.0604 30.067 12.3535 29.0669C10.6465 28.0669 10.0674 25.8616 11.06 24.1413L15.2535 16.8733L12.3345 15.1632C10.6275 14.1632 10.0484 11.9579 11.041 10.2376C12.0336 8.51732 14.222 7.93345 15.929 8.93349Z"
            fill="#F87500"
          />
        </svg>
      </SVGUniqueID>
    )
  }

  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6208 9.02339C13.3439 8.59406 14.1449 8.41036 14.9262 8.44961C15.4712 8.47608 16.0179 8.61195 16.5334 8.86482C16.6476 8.92065 16.7595 8.98188 16.8687 9.04839L27.4849 15.3308C29.4748 16.5083 30.1443 19.0983 28.9917 21.1161C28.5995 21.8027 28.0441 22.338 27.4032 22.6973L17.0711 28.8321C15.7893 29.709 14.0723 29.8252 12.6434 28.9796C10.6535 27.8021 9.98403 25.212 11.1366 23.1943L13.528 19.0079L11.1692 14.8925C11.0502 14.6947 10.9479 14.4881 10.8628 14.2752C10.6275 13.6888 10.5307 13.0715 10.5609 12.4672C10.5792 12.0952 10.646 11.7221 10.7641 11.358C11.0697 10.4116 11.7054 9.56688 12.6208 9.02339ZM21.457 20.0828C21.3843 20.0397 21.2975 20.0278 21.2159 20.0496C21.1344 20.0714 21.0648 20.1251 21.0227 20.1989L17.2485 26.8063C16.4424 28.2174 14.6673 28.6945 13.2837 27.8757C11.8986 27.056 11.4268 25.2463 12.2337 23.8337L16.008 17.2265C16.0949 17.0742 16.0439 16.8795 15.8937 16.7906L13.2666 15.236C11.8815 14.4163 11.4098 12.6067 12.2167 11.194C13.0228 9.78285 14.7979 9.30581 16.1815 10.1246L26.8447 16.4347C28.2297 17.2544 28.7015 19.064 27.8946 20.4767C27.0885 21.8878 25.3134 22.3649 23.9297 21.5461L21.457 20.0828Z"
          fill="#919EAB"
        />
      </svg>
    </SVGUniqueID>
  )
}

export default SvgComponent
