import { createStore } from 'easy-peasy'
import StoreConfig from 'stores/config'
import StoreModel from 'stores/models'
import IStoreModel from 'stores/models/interface'
import {STORE_NAME} from 'constants/index'

const store = createStore<IStoreModel>(
  StoreModel,
  StoreConfig({ name: STORE_NAME }),
)

export default store
