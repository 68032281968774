import { action, thunk } from 'easy-peasy'
import { IMoreContent } from 'stores/domains/Content/models/MoreContent/interface'

const AnotherContentModel: IMoreContent = {
  isError: false,
  errorMessage: '',
  isLoading: false,
  data: null,

  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),

  getData: thunk(async (actions, payload, { injections }) => {
    if (!payload.categoryId || !payload.excludeId) {
      actions.setIsError(true)
      return
    }

    try {
      actions.setIsLoading(true)

      const { apiClient } = injections

      const res = await apiClient({
        url: `/content/guest/v1/product/search`,
        method: 'GET',
        params: {
          page: 1,
          limit: 10,
          category_id: payload.categoryId,
          exclude_id: payload.excludeId,
          order_by: 'created_at',
          sort: 'ASC',
          is_published: true,
        },
      })

      actions.setData(res.data.data)
    } catch (err) {
      actions.setIsError(true)
      actions.setErrorMessage(err.message)
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default AnotherContentModel
