import { action, computed } from 'easy-peasy'
import { original } from 'immer'
import remove from 'lodash/remove'
import filter from 'lodash/filter'
import map from 'lodash/map'
import ICreatorNotification from 'stores/domains/Creator/CreatorNotification/interface'

const CreatorNotificationModel: ICreatorNotification = {
  raisehandParticipants: [],
  rejectRaisehand: false,
  approveRaisehand: false,
  tippingCoins: [],
  joinedUserQueue: [],
  joinedGuestQueue: [],

  clearTippingCoin: action((state) => {
    state.tippingCoins = []
  }),
  clearRaisehandParticipant: action((state) => {
    state.raisehandParticipants = []
  }),
  setRejectRaisehand: action((state, payload) => {
    state.approveRaisehand = false
    state.rejectRaisehand = payload
  }),
  setApproveRaisehand: action((state, payload) => {
    state.rejectRaisehand = false
    state.approveRaisehand = payload
  }),
  addRaisehandParticipant: action((state, payload) => {
    const newRaisehandParticipants =
      state.raisehandParticipants.filter(
        (item) => item.uid !== payload.uid,
      )

    state.raisehandParticipants = [
      payload,
      ...newRaisehandParticipants,
    ]
  }),
  removeRaisehandParticipant: action((state, uid) => {
    const newRaisehandParticipants =
      state.raisehandParticipants.filter((item) => item.uid !== uid)

    state.raisehandParticipants = newRaisehandParticipants
  }),
  getTippingCoins: computed((state) =>
    filter(state.tippingCoins, (item) => item.isShow && item),
  ),
  addTippingCoins: action((state, payload) => {
    state.tippingCoins = [...state.tippingCoins, payload]
  }),
  updateTippingCoins: action((state) => {
    const oldTippingCoins = original(state.tippingCoins)
    const newTippingCoins = map(oldTippingCoins, (item, index) => {
      if (index === 0 && item.isShow) {
        return {
          ...item,
          isShow: false,
        }
      }
      return item
    })
    state.tippingCoins = newTippingCoins
  }),
  removeTippingCoins: action((state) => {
    const newTippingCoins = original(state.tippingCoins)
    remove(newTippingCoins, (item) => !item.isShow)
    state.tippingCoins = newTippingCoins
  }),

  addToJoinedUserQueue: action((state, payload) => {
    state.joinedUserQueue = [...state.joinedUserQueue, payload]
  }),
  addToJoinedGuestQueue: action((state, payload) => {
    state.joinedGuestQueue = [...state.joinedGuestQueue, payload]
  }),

  clearJoinedUserQueue: action((state) => {
    state.joinedUserQueue = []
  }),
  clearJoinedGuestQueue: action((state) => {
    state.joinedGuestQueue = []
  }),
}

export default CreatorNotificationModel
