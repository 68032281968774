import { action } from 'easy-peasy'
import IScreenLayoutModel from './interface'

const ScreenLayout: IScreenLayoutModel = {
  headerType: 'base',
  isLoading: false,
  renderHeaderMenu: false,
  headerTitle: '',
  renderBackButton: false,
  onClickBack: () => {},

  setHeaderType: action((state, type) => {
    state.headerType = type
  }),
  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading
  }),
  setRenderHeaderMenu: action((state, payload) => {
    state.renderHeaderMenu = payload
  }),
  setHeaderTitle: action((state, payload) => {
    state.headerTitle = payload
  }),
  setRenderBackButton: action((state, payload) => {
    state.renderBackButton = payload
  }),
  setOnClickBack: action((state, onClick) => {
    state.onClickBack = onClick
  }),
}

export default ScreenLayout
