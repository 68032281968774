/**
 * Returns true is an object or string is empty
 *
 * @param value undefined | null | object | string
 */
const isEmpty = (
  value: undefined | null | object | string,
): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export default isEmpty
