import { StateCreator } from 'zustand'
import get from 'lodash/get'
import { IBrowseRecommendationSession } from './interface'

export const createBrowseRecommendationSessionStore: StateCreator<
  IBrowseRecommendationSession
> = (set) => ({
  recommendationSession: {
    sessionList: [],
    loadMore: false,
    currentPage: 1,
  },
  isError: false,
  errorMessage: '',
  isLoading: false,

  setRecommendationSession: (response) => {
    set((state) => ({
      recommendationSession: {
        sessionList:
          get(response, 'data._metadata.page', 1) > 1
            ? [
                ...state.recommendationSession.sessionList,
                ...get(response, 'data.data', []),
              ]
            : get(response, 'data.data', []),
        loadMore: get(response, 'data._metadata.load_more', false),
        currentPage: get(response, 'data._metadata.page', 1),
      },
    }))
  },
  setError: (message) => {
    set({
      isError: true,
      errorMessage: message,
    })
  },
  clearError: () => {
    set({
      isError: false,
      errorMessage: '',
    })
  },
  setIsLoading: (isLoading) => {
    set({
      isLoading,
    })
  },
})
