/**
 * @summary Reference for this component: https://www.figma.com/file/qMQ4vfwcS4Qo5aqfSCEuKN/LIGH-THEME-Components?node-id=3425%3A19873&mode=dev
 *
 *
 */

import { Chip as CommonChip, styled } from '@mui/material'

const Chip = styled(CommonChip)({})

export default Chip
