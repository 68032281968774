import Stack from 'components/commons/Stack'

import StateImage from 'components/commons/StateImage'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import Typography from 'components/commons/Typography'
import Button from 'components/commons/Button'

interface BlockDialogProps {
  open: boolean
  onClose: () => void
}

export default function CommunityMissionCompleteDialog({
  open,
  onClose,
}: BlockDialogProps) {
  return (
    <ModalDrawer
      hideCloseButton
      title=""
      isOpen={open}
      onClose={onClose}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
        padding: '24px',
      }}
      sxDrawerContainer={{
        padding: '16px 0px',
      }}
    >
      <Stack
        textAlign="center"
        alignItems="center"
        spacing={3}
        width="100%"
      >
        <StateImage type="create-creator-success" />
        <Stack spacing={1}>
          <Typography variant="h4" color="text.primary">
            Selamat, kamu berhasil menyelesaikan semua mission 🎉
          </Typography>
          <Typography variant="normalRegular" color="text.secondary">
            Berikutnya, TipTip akan mencoba menghubungkan komunitas
            kamu dengan brand partners kita. Tunggu info selanjutnya
            ya!
          </Typography>
        </Stack>
        <Stack width="100%">
          <Button
            buttonVariant="filled"
            buttonType="primary"
            size="large"
            fullWidth
            onClick={onClose}
          >
            Tutup
          </Button>
        </Stack>
      </Stack>
    </ModalDrawer>
  )
}
