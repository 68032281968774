import { AppBar, Container, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'

import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

export const HeaderContainer = styled(Box)({
  '& .MuiIconButton-root': {
    marginRight: '0',
  },
  '& .MuiAppBar-root': {
    right: 'inherit',
  },
})

export const StyleAppBar = styled(AppBar)(({ theme }) => ({
  padding: '0px 128px',
  maxWidth: '100%',
  background: theme.palette.background.primary,
  boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
  [theme.breakpoints.up('md')]: {
    paddingTop: '0px',
    // height: '80px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px 16px',
  },
}))

export const StyledNavBar = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.tiptip[500],
    background: 'transparent',
  },
}))

type IStyledContainer = {
  showAppBanner?: boolean
}
export const StyledContainer = styled(Container)<IStyledContainer>(
  ({ theme, showAppBanner }) => ({
    padding: '0px 16px',
    height: '60px',
    marginTop: showAppBanner ? 72 : 0,
    [theme.breakpoints.down('md')]: {
      padding: '0px !important',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1440px',
    },
  }),
)

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    background: theme.palette.background.primary,
    boxShadow: '0px 8px 8px rgba(161, 160, 166, 0.12)',
    borderRadius: 12,
    color: theme.palette.text.primary,
    marginTop: 20,
    padding: 8,
    overflowY: 'auto',
  },
  height: 'auto',
  maxHeight: '80vh',
  zIndex: 1,
}))

export const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.text.primary,
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
