import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={8} width={48} height={28} rx={6} fill="url(#a)" />
      <rect
        x={8}
        y={32}
        width={22}
        height={22}
        rx={4}
        fill="url(#b)"
      />
      <rect
        x={34}
        y={42}
        width={22}
        height={22}
        rx={4}
        fill="url(#c)"
      />
      <path
        d="M18.994 37.167A5.83 5.83 0 0 0 13.167 43a5.83 5.83 0 0 0 5.827 5.833A5.837 5.837 0 0 0 24.834 43a5.837 5.837 0 0 0-5.84-5.833Zm-2.036 3.5a.874.874 0 1 1 0 1.75.874.874 0 1 1 0-1.75Zm4.813 3.937A3.202 3.202 0 0 1 19 46.208a3.202 3.202 0 0 1-2.772-1.604.295.295 0 0 1 .257-.437h5.028c.228 0 .368.245.257.437Zm-.73-2.187a.874.874 0 1 1 0-1.75.874.874 0 1 1 0 1.75Z"
        fill="#9EC5FF"
      />
      <path
        d="M47.042 52.417a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75ZM42.958 52.417a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z"
        fill="#E1E0E2"
      />
      <path
        d="M44.994 47.167A5.83 5.83 0 0 0 39.167 53a5.83 5.83 0 0 0 5.827 5.833A5.837 5.837 0 0 0 50.834 53a5.837 5.837 0 0 0-5.84-5.833Zm.006 10.5A4.665 4.665 0 0 1 40.333 53 4.665 4.665 0 0 1 45 48.333 4.665 4.665 0 0 1 49.667 53 4.665 4.665 0 0 1 45 57.667Zm2.573-3.565a.435.435 0 0 0-.601.14 2.321 2.321 0 0 1-3.944-.005.436.436 0 1 0-.74.46A3.177 3.177 0 0 0 45 56.209a3.198 3.198 0 0 0 2.713-1.505.436.436 0 0 0-.14-.6Z"
        fill="#E1E0E2"
      />
      <path
        d="M32 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM32 16c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4Z"
        fill="#FFD0A4"
      />
      <path
        d="M39.106 9.26a3.175 3.175 0 0 1 0 2.48c-.2.473-.116 1.007.262 1.37l.031.03c.609.584 1.647.463 2.046-.272.797-1.461.797-3.174-.021-4.695-.399-.746-1.448-.887-2.067-.292l-.01.01c-.357.352-.44.896-.241 1.37Zm3.891-4.916a1.117 1.117 0 0 0-.136 1.491c2.066 2.76 2.056 6.458-.032 9.32-.335.453-.262 1.078.147 1.47l.032.03c.514.494 1.384.454 1.825-.1 2.884-3.566 2.895-8.433 0-12.11-.44-.554-1.322-.594-1.836-.1ZM24.894 9.26a3.175 3.175 0 0 0 0 2.48c.2.473.116 1.007-.262 1.37l-.031.03c-.609.584-1.647.463-2.046-.272-.797-1.461-.797-3.174.021-4.695.399-.746 1.448-.887 2.067-.292l.01.01c.357.352.44.896.241 1.37Zm-3.891-4.916c.42.403.482 1.028.136 1.491-2.066 2.76-2.056 6.458.032 9.32.335.453.262 1.078-.147 1.47l-.032.03c-.514.494-1.384.454-1.825-.1-2.884-3.566-2.895-8.433 0-12.11.44-.554 1.322-.594 1.836-.1Z"
        fill="#E1E0E2"
      />
      <circle cx={19} cy={58} r={6} fill="#F87500" />
      <path
        d="M21 57.4V56c0-.22-.18-.4-.4-.4h-4.8c-.22 0-.4.18-.4.4v4c0 .22.18.4.4.4h4.8c.22 0 .4-.18.4-.4v-1.4l.916.916a.401.401 0 0 0 .684-.284v-2.468a.401.401 0 0 0-.684-.284L21 57.4Z"
        fill="#E1E0E2"
      />
      <circle cx={45} cy={38} r={6} fill="#CB1C4F" />
      <path
        d="M45 39c.664 0 1.2-.536 1.2-1.2v-2.4c0-.664-.536-1.2-1.2-1.2-.664 0-1.2.536-1.2 1.2v2.4c0 .664.536 1.2 1.2 1.2Zm2.364-1.2a.4.4 0 0 0-.392.34A2.006 2.006 0 0 1 45 39.8c-.988 0-1.808-.72-1.972-1.66a.4.4 0 0 0-.392-.34.402.402 0 0 0-.4.456 2.797 2.797 0 0 0 2.364 2.312v.832c0 .22.18.4.4.4.22 0 .4-.18.4-.4v-.832a2.797 2.797 0 0 0 2.364-2.312.4.4 0 0 0-.4-.456Z"
        fill="#E1E0E2"
      />
      <defs>
        <linearGradient
          id="a"
          x1={19.143}
          y1={22}
          x2={47.868}
          y2={-27.244}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1C4F" />
          <stop offset={1} stopColor="#F87500" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={11.929}
          y1={49.679}
          x2={7.607}
          y2={31.607}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#484FF9" />
          <stop offset={1} stopColor="#3B95FF" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={56}
          y1={53}
          x2={34}
          y2={53}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F87500" />
          <stop offset={1} stopColor="#FFA235" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
