import { action, thunk } from 'easy-peasy'
import { getCookie, isGuestMode } from 'helpers/cookie'
import authConfig from 'configs/auth'
import ISessionLeaveModel from 'stores/commons/models/SessionLeave/interface'

const SessionLeaveModel: ISessionLeaveModel = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setSuccess: action((state, payload) => {
    state.isError = false
    state.errorMessage = ''
    state.isSuccess = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = payload
  }),
  setSessionLeave: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        // skip hit endpoint leave when user login as a guest
        if (!isGuestMode()) {
          const { apiClient } = injections
          await apiClient({
            url: `/session/v1/live/${sessionID}/leave`,
            method: 'POST',
            token: getCookie(authConfig.idTokenName),
          })
        }
        actions.setLoading(false)
        actions.setSuccess(true)
      } catch (error) {
        actions.setError(error.message)
      }
    },
  ),
}

export default SessionLeaveModel
