import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'

import { useCommunityStore } from 'stores/domains/Social'
import { getCommunityContent } from 'services/content'

export const useGetTotalContent = (id: string) => {
  const { setTotalContent } = useCommunityStore((state) => ({
    setTotalContent: state.setTotalContent,
  }))

  return useQuery({
    queryKey: ['total-community-content', id],
    queryFn: async () => {
      try {
        const response = await getCommunityContent(id, {
          lc: 1,
          pc: 1,
          pe: 1,
          le: 1,
          tab: 'all',
        })
        return {
          data: get(response, 'data.data.content', []),
          metadata: get(response, 'data._metadata', {}),
        }
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onSuccess: ({ metadata }) =>
      setTotalContent(metadata?.total || 0),
  })
}
