import { Divider, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Coin from 'svgs/icons/Coin'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'

export const StyledTitleDetailPayment = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    marginTop: 24,
    marginBottom: 18,
    ...theme.typography.mediumBold,
  }),
)
export const StyledContainer = styled(Box)({
  width: '100%',
  marginBottom: '24px',
})

export const CoinIconContainer = styled(Stack)({
  width: '50%',
})

export const PromoLabel = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px',
  lineHeight: '18px',
  fontWeight: 'bold',
}))
export const PromoDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.smallRegular,
}))
type InputProps = {
  border?: any
  disabled?: any
  isError?: boolean
}
type StyleColor = {
  styleColor?: boolean
}

export const CoinLabelDiscount = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: theme.palette.text.tertiery,
  textDecoration: 'line-through',
  fontWeight: theme.typography.smallRegular.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: theme.typography.mediumRegular.lineHeight,
}))

export const StyledCheck = styled(CheckCircleIcon)(({ theme }) => ({
  fill: `${theme.palette.success.main} !important`,
  marginRight: '5px',
}))

export const CoinInfoContainer = styled(Stack)({
  width: '100%',
})
export const CoinPriceContainer = styled(Stack)({
  width: '50%',
})

export const CoinPriceLabel = styled(Typography)<StyleColor>(
  ({ theme, styleColor }) => ({
    color: styleColor
      ? theme.palette.success.main
      : theme.palette.text.primary,
    ...theme.typography.normalRegular,
    display: 'flex',
    alignItems: 'center',
  }),
)
export const CoinQuantityLabel = styled(Typography)<StyleColor>(
  ({ theme, styleColor }) => ({
    color: styleColor
      ? theme.palette.success.main
      : theme.palette.text.primary,
    ...theme.typography.normalRegular,
  }),
)

export const TotalPaymentLabel = styled(Typography)(({ theme }) => ({
  letterSpacing: '0em',
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
}))

export const CoinValueLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: theme.palette.text.primary,
  ...theme.typography.mediumBold,
}))

export const StyledStack = styled(Stack)({
  width: '100%',
})

export const PriceCurrency = styled('span')<StyleColor>(
  ({ theme, styleColor }) => ({
    display: 'flex',
    alignItems: 'center',
    color: styleColor
      ? theme.palette.success.main
      : theme.palette.text.primary,
  }),
)
export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette.border.bold,
}))

export const StyledTags = styled(LocalOfferIcon)(({ theme }) => ({
  fill: `${theme.palette.info.main} !important`,
  marginRight: '10px',
}))

export const StyledArrow = styled(ArrowForwardIcon)<InputProps>(
  ({ disabled, theme }) => ({
    fill: disabled
      ? `${theme.palette.text.tertiery} !important`
      : `${theme.palette.text.primary} !important`,
    marginRight: '10px',
  }),
)

export const StyledContainerVoucher = styled(Box)<InputProps>(
  ({ border, disabled, theme, isError = false }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 8,
    marginRight: 16,
    marginLeft: 16,
    marginBottom: 16,
    svg: {
      marginRight: 8,
    },
    cursor: disabled ? 'not-allowed' : border ? '' : 'pointer',
    border: isError
      ? `1px solid ${theme.palette.error.main}`
      : border
      ? `1px solid ${theme.palette.success.main}`
      : `1px solid ${theme.palette.info.main}`,
    height: '44px',
    backgroundColor: isError
      ? theme.palette.red['200']
      : theme.palette.background.primary,
    borderRadius: '6px',
    marginTop: '0px',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiOutlinedInput-input': {
      color: theme.palette.text.primary,
      paddingRight: '57px',
      '&::placeholder': {
        color: theme.palette.text.tertiery,
      },
    },
  }),
)

export const StyledDesc = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.main,
  p: {
    fontSize: theme.typography.normalBold.fontSize,
  },
}))

export const StyledArrowRightContainer = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  svg: {
    color: `${theme.palette.text.primary} !important`,
    marginRight: 8,
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  svg: {
    color: `${theme.palette.text.tertiery} !important`,
  },
}))
export const StyledNote = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  marginBottom: 24,
  fontSize: theme.typography.smallRegular.fontSize,
  color: theme.palette.text.primary,
}))

export const StyledCoin = styled(Coin)({
  width: '16px',
  height: '16px',
  margin: '0px 4px',
})
