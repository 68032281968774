import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import _flattenDeep from 'lodash/flattenDeep'
import _get from 'lodash/get'
import _union from 'lodash/union'

import { getCommunityEventSchedule } from 'services/social'
import { getErrorMessage } from 'helpers/error'
import { getDaysBetween } from 'helpers/date-time'

interface Payload {
  started_date: string
  ended_date: string
}

export const useCommunityEventSchedule = (
  id: string,
  payload: Payload,
) => {
  return useQuery({
    queryKey: ['community-event-schedule', id, payload],
    queryFn: async () => {
      const response = await getCommunityEventSchedule(id, payload)
      const responseData = _get(response, 'data.data.schedules', {})
      const eventSchedules = responseData.map((data) => {
        const dateRanges = getDaysBetween(
          dayjs(data.event_started_at),
          dayjs(data.event_ended_at),
        )
        return dateRanges.map((date) =>
          dayjs(date).format('YYYY-MM-DD'),
        )
      })
      // flatten nested array and return as unique dates
      const uniqueDates = _union(_flattenDeep(eventSchedules))
      return uniqueDates
    },
    enabled: false,
    retry: false,
    onError: (err) => Error(getErrorMessage(err as any)),
  })
}
