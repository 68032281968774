import { StateCreator } from 'zustand'
import {
  ICommunity,
  ICommunityStateDefault,
  ICommunityStore,
} from './interface'
import { sendLog } from 'helpers/log'
import { getErrorCode, getErrorMessage } from 'helpers/error'
import { renderHtmlDescription } from 'helpers/renderHtmlDescription'

const resetters: (() => void)[] = []

const DEFAULT_COMMUNITY_DETAIL: ICommunity = {
  mask_id: '',
  name: '',
  category: {
    id: 0,
    name: '',
  },
  city_id: null,
  description: '',
  delta_description: null,
  is_owner: false,
  is_member: false,
  thumbnail_image: '',
  banner_image: '',
  link_fb: '',
  link_twitter: '',
  link_instagram: '',
  link_youtube: '',
  link_linkedin: '',
  link_tiktok: '',
  link_whatsapp: '',
  link_telegram: '',
  community_tagging: '',
  community_pic: '',
  verified: false,
  status: 0,
  total_content: 0,
  total_member: 0,
  owner_id: '',
  is_private: false,
  status_join: 1,
  pending_request: 0,
  has_screening_questions: false,
  verification_status: 0,
  html_descripton: '',
}

const INITIAL_DATA: ICommunityStateDefault = {
  metadata: {
    limit_my_community: 1,
    limit_followed_community: 1,
    load_more_my_community: false,
    load_more_followed_community: true,
    page_my_community: 1,
    page_followed_community: 1,
  },
  myCommunity: [],
  followedCommunity: [],
  community: { ...DEFAULT_COMMUNITY_DETAIL },
  communityInfo: {
    has_community: false,
    is_allowed: false,
    community_id: '',
    verification_status: 0,
  },
  isError: false,
  errorMessage: undefined,
  errorCode: undefined,
  alertCommunity: {
    isOpen: false,
    message: '',
    severity: 'success',
  },
  openEditProfile: false,
  communityMemberHasLoadMore: false,
  selectedCommunityMainTab: 0,
  selectedCommunityProductTab: 0,
  wizard: {
    activeStep: 0,
    completed: {},
    totalSteps: 0,
  },
  formDetail: {
    name: '',
    category_id: null,
    is_private: false,
  },
  formQuestion: {
    city_id: null,
    num_members_code: null,
    member_ages_code: null,
    member_gender_code: null,
    member_province_codes: [],
  },
  formDetailCompleted: false,
  isLoading: false,
  communityProduct: {
    mask_id: '',
    name: '',
    thumbnail_image: '',
    description: '',
    category: {
      id: 0,
      name: '',
    },
    total_member: 0,
    is_private: false,
    status_join: 1,
    has_screening_questions: false,
  },
  isOpenPrivateCommunityContentModal: false,
  isOpenScreeningQuestionDrawer: false,
  isOpenVerificationModal: false,
  screeningAnswerInput: {
    userId: '',
    communityId: '',
    answer: {},
  },
  temporary_description: null,
}

export const createCommunityStore: StateCreator<ICommunityStore> = (
  set,
  get,
) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    isOpenMainGuide: true,
    isOpenEditorGuide: true,
    setCommunityData(data) {
      set(() => {
        let followedCommunity = [...get().followedCommunity]
        return {
          myCommunity: data?.data?.my_community || [],
          followedCommunity:
            data?.metadata?.page_followed_community > 1
              ? followedCommunity.concat(
                  data?.data?.followed_community || [],
                )
              : data?.data?.followed_community || [],
          metadata: data?.metadata,
        }
      })
    },
    setCommunityDetail(data) {
      set((state) => {
        return {
          community: {
            ...state.community,
            ...data.data,
            html_descripton:
              renderHtmlDescription(data?.data?.delta_description) ||
              data?.data?.description,
          },
          selectedCommunityMainTab:
            !state.isOpenMainGuide &&
            (data?.data.is_owner || data?.data.is_member)
              ? 1
              : 0,
        }
      })
    },
    setTotalContent(data) {
      set(() => {
        return {
          community: { ...get().community, total_content: data },
        }
      })
    },
    setTotalMember(data) {
      set(() => {
        return {
          community: { ...get().community, total_member: data },
        }
      })
    },
    setCommunityInfo(data) {
      set(() => {
        return {
          communityInfo: data,
        }
      })
    },
    setAlertOpen(isOpen, message, severity) {
      set(() => {
        return {
          alertCommunity: {
            isOpen: isOpen,
            message: !isOpen ? '' : message,
            severity,
          },
        }
      })
    },
    setOpenEditProfile(isOpen) {
      set(() => {
        return {
          openEditProfile: isOpen,
        }
      })
    },
    setJoinLeaveCommunity(isJoin) {
      set(() => {
        return {
          community: { ...get().community, is_member: isJoin },
        }
      })
    },
    setCommunityMemberLoadMore(hasLoadMore) {
      set(() => {
        return {
          communityMemberHasLoadMore: hasLoadMore,
        }
      })
    },
    setSelectedCommunityMainTab(tab) {
      set(() => {
        return {
          selectedCommunityMainTab: tab,
        }
      })
    },
    setSelectedCommunityProductTab(tab) {
      set(() => {
        return {
          selectedCommunityProductTab: tab,
        }
      })
    },
    setError(error) {
      const message = getErrorMessage(error)
      sendLog(message)

      const errorCode = getErrorCode(error)
      set({
        errorCode,
        isError: true,
        errorMessage: message,
      })
    },
    setWizardTempDetail(data) {
      set((state) => ({
        formDetail: { ...state.formDetail, ...data },
        formDetailCompleted: true,
      }))
    },
    setWizardTempQuestion(data) {
      set((state) => ({
        formQuestion: { ...state.formQuestion, ...data },
      }))
    },
    setWizardActive(step) {
      if (step < 0 || step > 1) return
      set((state) => {
        return {
          wizard: {
            ...state.wizard,
            activeStep: step,
          },
        }
      })
    },
    resetWizard() {
      set(() => ({
        wizard: INITIAL_DATA.wizard,
        formDetail: INITIAL_DATA.formDetail,
        formQuestion: INITIAL_DATA.formQuestion,
        formDetailCompleted: INITIAL_DATA.formDetailCompleted,
      }))
    },
    setLoading(loading) {
      set(() => ({
        isLoading: loading,
      }))
    },
    setStatusJoin(status) {
      set(() => {
        return {
          community: { ...get().community, status_join: status },
        }
      })
    },
    setTotalPendingRequest(data) {
      set(() => {
        return {
          community: { ...get().community, pending_request: data },
        }
      })
    },
    setCommunityProduct(data) {
      set(() => {
        return {
          communityProduct: data,
        }
      })
    },
    setIsOpenPrivateCommunityContentModal(data) {
      set(() => {
        return {
          isOpenPrivateCommunityContentModal: data,
        }
      })
    },
    resetCommunityDrawer() {
      set(() => {
        return {
          isOpenPrivateCommunityContentModal: false,
          isOpenScreeningQuestionDrawer: false,
        }
      })
    },
    setIsOpenScreeningQuestionDrawer(data) {
      set(() => {
        return {
          isOpenScreeningQuestionDrawer: data,
        }
      })
    },
    setScreeningAnswerInput(data) {
      set(() => {
        return {
          screeningAnswerInput: data,
        }
      })
    },
    setOpenVerificationModal(open) {
      set(() => {
        return {
          isOpenVerificationModal: open,
        }
      })
    },
    resetScreeningAnswer() {
      set(() => {
        return {
          screeningAnswerInput: {
            userId: '',
            communityId: '',
            answer: {},
          },
        }
      })
    },
    setTemporaryDescription(description) {
      set(() => ({
        temporary_description: description,
      }))
    },
    closeMainGuide() {
      set(() => ({
        isOpenMainGuide: false,
      }))
    },
    closeMainGuestGuide() {
      set(() => ({
        isOpenMainGuestGuide: false,
      }))
    },
    closeEditorGuide() {
      set(() => ({
        isOpenEditorGuide: false,
      }))
    },
  }
}

export const resetCommunityStore = () =>
  resetters.forEach((resetter) => resetter())
