import { styled, useTheme } from '@mui/material'

import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'

import StateImage from 'components/commons/StateImage'
import ActionButton from 'components/commons/Button/Action'
import ModalDrawer from '../ModalDrawer'

interface BlockDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  peerName: string
  unblock?: boolean
}

export default function BlockDialog({
  unblock = false,
  ...props
}: BlockDialogProps) {
  const theme = useTheme()
  return (
    <ModalDrawer
      hideCloseButton={!unblock}
      title={unblock ? `Unblock ${props.peerName}?` : ''}
      isOpen={props.open}
      onClose={props.onClose}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
        padding: '24px',
      }}
      sxDrawerContainer={{
        padding: '16px 0px',
      }}
    >
      <Stack spacing={3} width="100%">
        <Stack
          textAlign={unblock ? 'left' : 'center'}
          alignItems={unblock ? 'flex-start' : 'center'}
          spacing={2}
        >
          {!unblock && <StateImage type="user-alert" />}
          {!unblock && (
            <Typography
              sx={{
                typography: { xs: 'h4', md: 'h4Desk' },
                color: theme.palette.text.primary,
              }}
            >
              {!unblock ? 'Block' : 'Unblock'} {props.peerName}?
            </Typography>
          )}
          <Typography
            sx={{
              typography: 'normalRegular',
              color: theme.palette.text.secondary,
            }}
          >
            {!unblock
              ? `Kamu tidak akan menerima pesan dari ${props.peerName}`
              : `Kamu akan menerima pesan dari ${props.peerName} lagi`}
            .
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          {unblock && (
            <StyledButton
              buttonVariant={'outlined'}
              onClick={() => props.onClose()}
              fullWidth
            >
              Batal
            </StyledButton>
          )}
          <StyledButton
            buttonVariant={!unblock ? 'outlined' : 'filled'}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              props.onConfirm()
            }}
            fullWidth
          >
            {!unblock ? 'Block' : 'Unblock'}
          </StyledButton>
          {!unblock && (
            <StyledButton
              buttonVariant={'filled'}
              onClick={() => props.onClose()}
              fullWidth
            >
              Batal
            </StyledButton>
          )}
        </Stack>
      </Stack>
    </ModalDrawer>
  )
}

const StyledButton = styled(ActionButton)({
  borderRadius: '8px',
})
