import CreatorProfileModel from 'stores/domains/Creator/Profile/index'
import CreatorFeedsModel from 'stores/domains/Creator/Feeds/index'
import CreatorSessionModel from 'stores/domains/Creator/Session/index'
import CreatorRatingModel from 'stores/domains/Creator/Rating/index'
import StreamRTCModel from 'stores/commons/models/StreamRTC/index'
import StreamRTMModel from 'stores/commons/models/StreamRTM/index'
import SessionDetailModel from 'stores/domains/Session/SessionDetail/index'
import SessionCancelModel from 'stores/domains/Session/SessionCancel/index'
import SessionBookingModel from 'stores/domains/Session/SessionBooking/index'
import SessionLeaveModel from 'stores/commons/models/SessionLeave/index'
import CreateSessionModel from 'stores/domains/Creator/CreateSession/index'
import SufficientBalanceModel from 'stores/domains/Session/SufficientBalance/index'
import CoinBalanceModel from 'stores/domains/Wallet/models/CoinBalance/index'
import TippingCoinModel from 'stores/domains/Wallet/models/TippingCoin/index'
import CreatorNotificationModel from 'stores/domains/Creator/CreatorNotification/index'
import SendTippingCoinModel from 'stores/domains/Wallet/models/SendTippingCoin/index'
import SessionExtendModel from 'stores/domains/Creator/SessionExtend/index'
import SessionLivestreamModel from 'stores/commons/models/SessionLivestream/index'
import TippingAccumulationModel from 'stores/domains/Session/TippingAccumulation/index'
import LocalTrackModel from 'stores/commons/models/LocalTrack/index'
import ParticipantListModel from 'stores/domains/Session/ParticipantList/index'
import BookingDetailModel from 'stores/domains/Booking/Detail/index'
import IncomeBalanceModel from 'stores/domains/Creator/Income'
import BankAccountModel from 'stores/domains/Creator/BankAccount'
import TopUpModel from 'stores/domains/Wallet/models/TopUp/index'
import SessionListModel from 'stores/domains/Session/SessionList/index'
import RemoteUser from 'stores/commons/models/RemoteUsers/index'
import JoinSession from 'stores/commons/models/JoinSession/index'
import VoiceDetected from 'stores/commons/models/VoiceDetected/index'
import NetworkQuality from 'stores/commons/models/NetworkQuality'
import BrowseContentModel from 'stores/domains/Content/models/content-list'
import BrowseCreatorModel from 'stores/domains/Browse/Creator/index'
import ShareScreen from 'stores/domains/Creator/ShareScreen'
import CreatorUser from 'stores/commons/models/CreatorUser'
import SessionReview from 'stores/domains/Session/SessionReview'
import SignUpBonusBanner from 'stores/domains/SignUpBonus/Banner'
import SignUpBonusDrawer from 'stores/domains/SignUpBonus/Drawer'
import PreviewSession from 'stores/commons/models/PreviewSession'
import PhoneNumber from 'stores/domains/User/models/PhoneNumber/index'
import MoreContent from 'stores/domains/Content/models/MoreContent'
import ContentDelivery from 'stores/domains/Content/models/ContentDelivery'
import ContentDetailModel from 'stores/domains/Content/models/Detail'
import PurchasedList from 'stores/domains/Library/PurchasedList/index'
import ProductType from 'stores/domains/Library/ProductType/index'
import FilterCreator from 'stores/domains/Library/FilterCreator/index'
import ScreenLayout from 'stores/commons/models/ScreenLayout'
import Search from 'stores/commons/models/Search'
import ReferralCodeModel from 'stores/domains/User/models/ReferralCode'
import VoucherModel from 'stores/domains/User/models/Voucher'
import BankListModel from 'stores/domains/Creator/BankList'
import AgreementModel from 'stores/domains/Creator/AgreementPromoter'
import EventPersonalQuestionsModel from 'stores/domains/Event/models/EventPersonalQuestions'
import selectedProductsModel from 'stores/domains/Payment/SelectedProducts'
import BrowseSessionModel from 'stores/domains/Browse/Session'

const StoreModel = {
  creatorProfile: CreatorProfileModel,
  creatorFeeds: CreatorFeedsModel,
  creatorSession: CreatorSessionModel,
  creatorRating: CreatorRatingModel,
  streamRTC: StreamRTCModel,
  streamRTM: StreamRTMModel,
  sessionDetail: SessionDetailModel,
  sessionCancel: SessionCancelModel,
  sessionBooking: SessionBookingModel,
  sessionLeave: SessionLeaveModel,
  createSession: CreateSessionModel,
  sufficientBalance: SufficientBalanceModel,
  coinBalance: CoinBalanceModel,
  tippingCoin: TippingCoinModel,
  creatorNotification: CreatorNotificationModel,
  sendTippingCoin: SendTippingCoinModel,
  sessionExtend: SessionExtendModel,
  sessionLivestream: SessionLivestreamModel,
  tippingAccumulation: TippingAccumulationModel,
  localTrack: LocalTrackModel,
  participantList: ParticipantListModel,
  bookingDetail: BookingDetailModel,
  incomeBalance: IncomeBalanceModel,
  bankAccount: BankAccountModel,
  topUp: TopUpModel,
  sessionList: SessionListModel,
  remoteUser: RemoteUser,
  joinSession: JoinSession,
  voiceDetected: VoiceDetected,
  networkQuality: NetworkQuality,
  browseSession: BrowseSessionModel,
  browseContent: BrowseContentModel,
  browseCreator: BrowseCreatorModel,
  shareScreen: ShareScreen,
  creatorUser: CreatorUser,
  sessionReview: SessionReview,
  signUpBonusBanner: SignUpBonusBanner,
  signUpBonusDrawer: SignUpBonusDrawer,
  previewSession: PreviewSession,
  phoneNumber: PhoneNumber,
  moreContent: MoreContent,
  contentDelivery: ContentDelivery,
  contentDetail: ContentDetailModel,
  purchasedList: PurchasedList,
  productType: ProductType,
  filterCreator: FilterCreator,
  screenLayout: ScreenLayout,
  search: Search,
  referralCode: ReferralCodeModel,
  voucherModel: VoucherModel,
  bankList: BankListModel,
  agreement: AgreementModel,
  eventPersonalQuestions: EventPersonalQuestionsModel,
  selectedProducts: selectedProductsModel,
}

export default StoreModel
