import {
  IRequestOTPPayload,
  IVerifyOTPPayload,
} from 'stores/domains/User/Auth/interface'

import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { getSessionStorage } from 'helpers/session-storage'

import ampConfig from 'configs/amplify'

export const requestOtp = async (
  payload: IRequestOTPPayload,
  isGuest: boolean = false,
) => {
  return await apiClient({
    url: isGuest ? '/user/guest/v1/otp/send' : '/user/v2/otp/send',
    method: 'POST',
    data: payload,
    token: getIdToken(),
  })
}

export const verifyOtp = async (
  payload: IVerifyOTPPayload,
  isGuest: boolean = false,
) => {
  return await apiClient({
    url: isGuest
      ? '/user/guest/v1/otp/verify'
      : `/user/v2/otp/verify`,
    method: 'POST',
    data: {
      ...payload,
      otp: Number(payload.otp),
      ip: getSessionStorage('ip'),
      client_id: ampConfig.aws_user_pools_web_client_id,
    },
    token: getIdToken(),
  })
}

export const requestEmailOtp = async (email: string) => {
  return await apiClient({
    url: '/user/v1/otp',
    method: 'POST',
    data: { email, ip: getSessionStorage('ip') },
    token: getIdToken(),
  })
}
