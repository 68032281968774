import { useQuery } from '@tanstack/react-query'
import * as serviceSocial from 'services/social'
import * as serviceCreator from 'services/creator'
import { useFollowStore } from 'stores/domains/Social'
import { INITIAL_CREATOR_FOLLOWER } from 'stores/domains/Social/Follow'
import { useStoreActions } from 'stores/hooks'

export const useCreatorFollowers = (username: string) => {
  const { setCreatorFollower } = useFollowStore((state) => ({
    setCreatorFollower: state.setCreatorFollower,
  }))
  return useQuery({
    queryKey: ['creator-followers', username],
    queryFn: async () => {
      try {
        const response = await serviceSocial.getFollowers(username)
        setCreatorFollower(response?.data || INITIAL_CREATOR_FOLLOWER)
        return response?.data
      } catch (error) {
        throw error
      }
    },
    enabled: !!username,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 1 * 60 * 1000, //1 minute
  })
}

export const useCreatorProfile = (
  username: string,
  token?: string,
) => {
  const { setCreatorProfile } = useStoreActions(
    (action) => action.creatorProfile,
  )
  return useQuery({
    queryKey: ['creator-profile', username, token],
    queryFn: async () => {
      try {
        const response = await serviceCreator.getCreatorProfile(
          username,
          token,
        )
        setCreatorProfile(response?.data)
        return response?.data
      } catch (error) {
        throw error
      }
    },
    enabled: !!username,
    retry: 1,
    onError: (error: any) => {
      return error
    },
    useErrorBoundary: (error) => error.response?.status === 500,
    refetchOnWindowFocus: false,
  })
}

export const useCreatorRating = (username: string) => {
  return useQuery({
    queryKey: ['creator-rating', username],
    queryFn: async () => {
      try {
        const response = await serviceCreator.getCreatorRating(
          username,
        )
        return response?.data
      } catch (error) {
        throw error
      }
    },
    enabled: !!username,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
