import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISessionBookingModel from 'stores/domains/Session/SessionBooking/interface'

const SessionBookingModel: ISessionBookingModel = {
  isLoading: false,
  isSuccess: false,
  isFullyBooked: false,
  isError: false,
  errorMessage: '',
  totalBooking: 0,
  bookingID: '',

  setBookingID: action((state, payload) => {
    state.bookingID = payload
  }),
  setTotalBooking: action((state, payload) => {
    state.totalBooking = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsFullyBooked: action((state, payload) => {
    state.isFullyBooked = payload
  }),
  setSuccess: action((state, payload) => {
    state.isSuccess = payload
  }),
  setError: action((state, payload) => {
    state.isSuccess = false
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setBookingSession: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const resp = await apiClient({
          url: `/session/v1/book/${sessionID}/session`,
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setLoading(false)
        actions.setTotalBooking(
          get(resp, 'data.data.total_booked', 0),
        )
        actions.setSuccess(true)
        actions.setBookingID(get(resp, 'data.data.booking'))
      } catch (error) {
        if (get(error, 'response.data.code') === 'FAIL') {
          actions.setError(
            get(error, 'response.data.errors', [
              'unknown error',
            ]).join(','),
          )
        } else if (
          get(error, 'response.data.code') === 'SESSION_FULLY_BOOKED'
        ) {
          actions.setIsFullyBooked(true)
        } else if (
          get(error, 'response.data.code') === 'USER_ALREADY_BOOKED'
        ) {
          actions.setSuccess(true)
        } else {
          actions.setError(get(error, 'response.data.message'))
        }
      }
    },
  ),
}

export default SessionBookingModel
