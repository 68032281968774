import 'dayjs/locale/id'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { IEventTimezone } from 'components/commons/Content/interface'
import { getLocalStorage } from 'helpers/local-storage'
import appConfig from 'configs/app'

dayjs.extend(timezone)
dayjs.extend(utc)

interface IDateFormatter {
  date: string
  format?: string
  timezone?: IEventTimezone
}

const dateFormatter = ({
  date,
  format = 'DD/MM/YYYY',
  timezone,
}: IDateFormatter): string => {
  const lang = getLocalStorage('i18nextLng') || appConfig.defaultLang
  dayjs.locale(lang)
  const formattedDate = dayjs(date)
  if (!timezone) return formattedDate.format(format)

  try {
    const dateFormat = formattedDate.tz(timezone.name).format(format)
    return dateFormat
  } catch (error) {
    return formattedDate.format(format)
  }
  // return `${dateFormat} ${timezone.abbreviation}`
}

export const checkTimeHasPassed = (
  date: string,
  duration: number,
): boolean => {
  dayjs.locale('id')
  const fdate = dayjs(date).add(duration, 'm')
  if (fdate.isAfter(dayjs())) return false
  return true
}

export const dateStartAddDuration = (
  date: string,
  duration: number,
  format = 'DD/MM/YYYY',
): string => {
  dayjs.locale('id')
  const fdate = dayjs(date).add(duration, 'm')
  return fdate.format(format)
}

export const checkMultipleDay = (
  startDate: string,
  endDate: string,
  timezone?: IEventTimezone,
) => {
  const start = dateFormatter({
    date: startDate,
    timezone: timezone,
    format: 'YYYY-MM-DD',
  })
  const end = dateFormatter({
    date: endDate,
    timezone: timezone,
    format: 'YYYY-MM-DD',
  })
  return !dayjs(start).isSame(dayjs(end))
}

export default dateFormatter
