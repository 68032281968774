import { trackEvent, getStateFromSource } from 'helpers/analytics'

export default function ContentTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const {
    pathname,
    states,
    query,
    contentDetail,
    contentUserDetail,
  } = data
  const state_name = getStateFromSource(pathname) || ''
  const creator_category_id =
    states.creatorProfile?.creatorProfileData?.category?.id || ''
  const creator_category =
    states.creatorProfile?.creatorProfileData?.category?.name || ''
  const creator_user_id =
    states.creatorProfile?.creatorProfileData?.creator
      ?.creator_user_id || ''
  const creator_name =
    states.creatorProfile?.creatorProfileData?.creator?.name || ''
  const creator_id =
    states.creatorProfile?.creatorProfileData?.creator?.creator_id ||
    ''
  const creator_group =
    contentUserDetail?.creator?.creator_group || ''
  const is_community_leader =
    contentUserDetail?.creator?.is_community_leader

  const content_title = contentDetail?.title || ''
  const content_id = contentDetail?.product_uid || ''
  const content_photo_image_path = contentDetail?.cover || ''
  const content_price_initial = contentUserDetail?.price || ''
  const content_price_final = contentUserDetail?.sale_price || ''
  const content_type = contentDetail?.type?.name?.toLowerCase()
  const content_items_sold = contentDetail?.stats?.total_sales || ''
  const content_created_date = contentDetail?.created_at || ''
  const content_description = contentDetail?.description || ''
  const content_description_count =
    contentDetail?.description?.length || ''
  const content_has_teaser =
    contentDetail?.teaser?.length > 0 ? true : false
  const source =
    query.source && typeof query.source === 'string'
      ? getStateFromSource(query.source)
      : 'unknown'
  const source_action = query?.source_action || 'unknown'
  const content_state =
    contentUserDetail?.status_order?.toLowerCase() === 'completed'
      ? 'purchased'
      : 'unpurchased'
  const content_duration = contentDetail?.content_length || ''
  const content_similar_count = states.moreContent?.data?.length || 0
  const purchase_content_datetime = new Date().toISOString()
  const content_page_count = contentDetail?.content_length || ''
  const content_is_promoted =
    contentUserDetail?.promoter_commission_estimation > 0 || false
  const content_promoter_commission =
    contentUserDetail?.promoter_commission_estimation || ''
  let state_sub_name = query?.source_action || ''
  if (query?.source_action === 'trigger_select_content') {
    state_sub_name = 'keyword_search'
  }

  const subscribe_to_creator = contentUserDetail?.is_subscriber
  const content_access_source =
    contentUserDetail?.content_access_source || 'none'
  const package_id = contentUserDetail?.subscribe_package_list?.map(
    (item) => item.package_id,
  )
  const package_title =
    contentUserDetail?.subscribe_package_list?.map(
      (item) => item.title,
    )
  const baseProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_photo_image_path,
    content_price_initial,
    content_price_final,
    content_type,
    content_items_sold,
    content_created_date,
    content_description,
    content_description_count,
    content_has_teaser,
  }

  const basePromoterProperties = {
    state_name,
    content_is_promoted,
    content_promoter_commission,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_price_initial,
    content_price_final,
    content_type,
    content_items_sold,
  }

  const videoProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_duration,
    content_type,
    content_price_initial,
    content_price_final,
    content_description,
  }

  const documentProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_page_count,
    content_type,
    content_price_initial,
    content_price_final,
    content_description,
  }

  const subscriptionProperties = {
    subscribe_to_creator,
    content_access_source,
    package_id,
    package_title,
  }

  if (content_type === 'document') {
    delete baseProperties['content_items_sold']
    delete baseProperties['content_photo_image_path']

    baseProperties['content_page_count'] = content_page_count

    if (content_price_initial === '') {
      baseProperties['content_price_initial'] = 0
      documentProperties['content_price_initial'] = 0
    }
    if (content_price_final === '') {
      baseProperties['content_price_final'] = 0
      documentProperties['content_price_final'] = 0
    }
  }

  if (content_type === 'video') {
    baseProperties['content_page_count'] = content_page_count

    if (content_price_initial === '') {
      baseProperties['content_price_initial'] = 0
      videoProperties['content_price_initial'] = 0
    }
    if (content_price_final === '') {
      baseProperties['content_price_final'] = 0
      videoProperties['content_price_final'] = 0
    }
    if (content_duration === '') {
      videoProperties['content_duration'] = 0
    }
  }

  const eventProperties = {
    back_from_content_detail: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_content_detail: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      source,
      source_action,
      content_state,
      content_duration,
      content_similar_count,
      creator_group,
      is_community_leader,
      state_sub_name,
    },
    click_view_content: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
    },
    click_purchase_content: {
      ...baseProperties,
      ...additionalProperties,
      modal_name: 'PURCHASE_CONTENT',
      creator_group,
      is_community_leader,
    },
    click_more_options: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
    },
    close_more_options: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      content_duration,
      modal_name: 'SHARE_OR_REPORT_CONTENT',
    },
    select_share_content: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      content_duration,
      modal_name: 'SHARE_CONTENT',
    },
    select_report_content: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      content_duration,
      modal_name: 'REPORT_CONTENT',
    },
    cancel_share_content: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      content_duration,
      modal_name: 'SHARE_CONTENT',
    },
    cancel_report_content: {
      ...baseProperties,
      ...additionalProperties,
      ...subscriptionProperties,
      content_duration,
      modal_name: 'REPORT_CONTENT',
    },
    purchase_content_completed: {
      ...baseProperties,
      ...additionalProperties,
      modal_name: 'PURCHASE_CONTENT',
      purchase_content_datetime,
      creator_group,
      is_community_leader,
    },
    purchase_content_confirm: {
      ...baseProperties,
      ...additionalProperties,
      modal_name: 'PURCHASE_CONTENT',
    },

    // Promoter
    click_promote_content: {
      ...basePromoterProperties,
      ...additionalProperties,
    },
    trigger_close_promote_content: {
      ...basePromoterProperties,
      ...additionalProperties,
    },
    click_content_hub: {
      ...basePromoterProperties,
      ...additionalProperties,
    },
    cancel_promote_content: {
      ...basePromoterProperties,
      ...subscriptionProperties,
      ...additionalProperties,
    },
    submit_promote_content: {
      ...basePromoterProperties,
      ...subscriptionProperties,
      ...additionalProperties,
    },

    // Content Delivery
    open_content_player:
      content_type === 'document'
        ? {
            source,
            source_action,
            ...documentProperties,
            ...additionalProperties,
            ...subscriptionProperties,
            creator_group,
            is_community_leader,
          }
        : {
            source,
            source_action,
            ...videoProperties,
            ...additionalProperties,
            ...subscriptionProperties,
            creator_group,
            is_community_leader,
          },
    close_content_player:
      content_type === 'document'
        ? {
            ...documentProperties,
            ...subscriptionProperties,
            ...additionalProperties,
          }
        : {
            ...videoProperties,
            ...subscriptionProperties,
            ...additionalProperties,
          },
    play_content: {
      ...videoProperties,
      ...additionalProperties,
      ...subscriptionProperties,
    },
    pause_content: {
      ...videoProperties,
      ...additionalProperties,
      ...subscriptionProperties,
    },
    click_next_page: {
      ...documentProperties,
      ...additionalProperties,
    },
    click_previous_page: {
      ...documentProperties,
      ...additionalProperties,
    },
    click_creator_profile:
      content_type === 'document'
        ? {
            ...documentProperties,
            ...additionalProperties,
          }
        : {
            ...videoProperties,
            ...additionalProperties,
          },
    maximize_screen_content:
      content_type === 'document'
        ? {
            ...documentProperties,
            ...additionalProperties,
            ...subscriptionProperties,
          }
        : {
            ...videoProperties,
            ...additionalProperties,
            ...subscriptionProperties,
          },
    minimize_screen_content:
      content_type === 'document'
        ? {
            ...documentProperties,
            ...additionalProperties,
            ...subscriptionProperties,
          }
        : {
            ...videoProperties,
            ...additionalProperties,
            ...subscriptionProperties,
          },
    click_community_profile: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
