export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

const isEnabled =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENABLED === 'true'

export const pageview = (url) => {
  if (isEnabled && process.env.NEXT_IS_SERVER !== 'true')
    window?.['dataLayer']?.push({
      event: 'pageview',
      page: url,
    })
}
