import * as React from 'react'
import {
  Divider,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material'
import { useRouter } from 'next/router'

import redirect from 'helpers/redirect'

import MenuContentItem from 'components/commons/MenuContentItem'
import { MenuProps, menuItems } from './menuItems'
import { getStateFromSource, trackEvent } from 'helpers/analytics'

const MenuTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.text.primary,
}))

interface ICreateMenuList extends StackProps {
  title?: string
}

const defaultDivider = (
  <Divider
    sx={(theme) => ({
      background: theme.palette.background.secondary,
    })}
  />
)

const CreateMenuList = ({
  title,
  p = 2,
  spacing = 2,
  divider = defaultDivider,
  ...rest
}: ICreateMenuList) => {
  const { pathname } = useRouter()
  const menuList = React.useMemo(() => {
    return menuItems
  }, [menuItems])

  const handleClickMenu = (menu: MenuProps) => {
    const { url, eventName } = menu
    const eventProperties = {
      state_name: getStateFromSource(pathname),
      modal_name: 'CREATE_PRODUCT',
    }
    trackEvent(eventName, eventProperties)
    redirect(url)
  }

  return (
    <Stack p={p} spacing={spacing} {...rest}>
      {title && <MenuTitle variant="h3">{title}</MenuTitle>}
      <Stack divider={divider} spacing={2}>
        {menuList.map((menu, index) => (
          <MenuContentItem
            key={index}
            onClick={() => handleClickMenu(menu)}
            {...menu}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default CreateMenuList
