import { action } from 'easy-peasy'
import indexOf from 'lodash/indexOf'
import IVoiceDetectedModel from 'stores/commons/models/VoiceDetected/interface'

const VoiceDetected: IVoiceDetectedModel = {
  speakers: [],
  isVoiceOn: false,
  addSpeaker: action((state, payload) => {
    if (indexOf(state.speakers, payload) === -1) {
      state.speakers = [...state.speakers, payload]
    }
  }),
  removeSpeaker: action((state, payload) => {
    state.speakers = state.speakers.filter(
      (speaker) => speaker !== payload,
    )
  }),
  clearSpeaker: action((state) => {
    state.speakers = []
  }),
  setVoiceOn: action((state, payload) => {
    state.isVoiceOn = payload
  }),
}

export default VoiceDetected
