export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

const isEnabled =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ENABLED === 'true'

export const pageview = () => {
  if (isEnabled && process.env.NEXT_IS_SERVER !== 'true')
    window['fbq']('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  if (isEnabled && process.env.NEXT_IS_SERVER !== 'true')
    window['fbq']('track', name, options)
}
