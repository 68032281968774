import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'

import Button from '../Button'
import CommonStack from '../Stack'
import Box from '../Box'
import CommonTypography from '../Typography'
import ActionButton from '../Button/Action'
import Typography from '../Typography'
import { styled, useTheme } from '@mui/material'

type DrawerProps = {
  fullWidth?: boolean
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: '100vw',
  [theme.breakpoints.up('sm')]: {
    width: '480px',
  },
  minHeight: '20vh',
}))

const StyledStack = styled(CommonStack)<DrawerProps>(
  ({ fullWidth = false, theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: fullWidth ? '' : '500px',
    },
    minHeight: '15vh',
  }),
)
const DrawerHeader = styled(CommonStack)<DrawerProps>(
  ({ fullWidth = false, theme }) => ({
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      width: fullWidth ? '' : '500px',
    },
    marginTop: '8px',
  }),
)
const DrawerTitleContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}))

const DrawerTitle = styled(CommonTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.h4,
  marginLeft: '16px',
  wordBreak: 'break-all',
}))

const DrawerContentContainer = styled(Box)({
  width: '100%',
  padding: '0 16px',
})
const DrawerContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  paddingLeft: 16,
  paddingRight: 16,
}))

const CloseDrawerButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.tertiery,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  svg: {
    color: theme.palette.tiptip[500],
  },
}))

const ActionButtonContainer = styled(Box)({
  marginBottom: '24px !important',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
})

const ContainerBtn = styled(CommonStack)({
  display: 'flex',
  width: '100%',
  padding: '0px 16px',
  flexDirection: 'row',
})

function BottomDrawer({
  id,
  title,
  cancelLabel,
  content,
  showDrawer,
  onCloseAction,
  onSubmitAction,
  onLeftBtnAction,
  submitLabel,
  isRequesting,
  fullWidth,
  hideButtonRequest,
  hideCancelButton,
  cancelBtnId,
  onCancelAction,
  hideHeader,
  LeftBtnLabel,
  LeftBtnId,
  activeButton,
  hideCloseButton,
  submitButtonId,
  swapButtonPosition,
  closeButtonId,
  disablePadding,
  disabledSubmitBtn,
  sx,
  textTransform,
  drawerWrapperId = null,
  textError = '',
}): JSX.Element {
  const theme = useTheme()
  return (
    <StyledDrawer
      id={id}
      anchor="bottom"
      open={showDrawer}
      PaperProps={{
        sx: {
          width: '100vw',
          margin: '0 auto',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          background: theme.palette.background.primary,
          [theme.breakpoints.up('sm')]: {
            width: fullWidth ? 'auto' : '500px',
            margin: '0 auto',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            background: theme.palette.background.primary,
          },
          ...sx,
        },
      }}
      onClose={() => onCloseAction()}
    >
      <StyledStack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        fullWidth={fullWidth}
        id={drawerWrapperId}
      >
        {!hideHeader && (
          <DrawerHeader
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            fullWidth={fullWidth}
          >
            <DrawerTitleContainer>
              <DrawerTitle>{title}</DrawerTitle>
            </DrawerTitleContainer>
            {!hideCloseButton && (
              <CloseDrawerButton
                buttonVariant="nude"
                variant="text"
                onClick={() => onCloseAction()}
                sx={{
                  textTransform: 'unset',
                }}
                id={closeButtonId}
              >
                {cancelLabel && cancelLabel !== '' ? (
                  cancelLabel
                ) : (
                  <CloseIcon />
                )}
              </CloseDrawerButton>
            )}
          </DrawerHeader>
        )}
        <DrawerContentContainer
          sx={{ padding: disablePadding ? '0px' : '0px 16px' }}
        >
          <DrawerContent>{content}</DrawerContent>
        </DrawerContentContainer>

        {(!hideButtonRequest || !hideCancelButton) && (
          <ActionButtonContainer>
            {!hideButtonRequest && (
              <ContainerBtn
                direction="row"
                justifyContent={fullWidth ? 'center' : 'flex-start'}
                alignItems="center"
                spacing={2}
                style={
                  swapButtonPosition
                    ? {
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }
                    : {}
                }
              >
                {LeftBtnLabel !== '' && (
                  <ActionButton
                    id={LeftBtnId}
                    buttonVariant="outlined"
                    disableElevation
                    fullWidth
                    onClick={onLeftBtnAction}
                    disabled={isRequesting}
                    sx={{
                      textTransform: 'unset',
                      border:
                        !activeButton &&
                        `1px solid ${theme.palette.text.tertiery}`,
                      color:
                        !activeButton && theme.palette.text.tertiery,
                    }}
                  >
                    {LeftBtnLabel}
                  </ActionButton>
                )}
                <Button
                  disableElevation
                  fullWidth
                  onClick={onSubmitAction}
                  disabled={isRequesting || disabledSubmitBtn}
                  sx={{
                    height: '44px',
                    textTransform: textTransform,
                  }}
                  id={submitButtonId}
                  style={
                    swapButtonPosition
                      ? { marginLeft: 0, marginRight: 16 }
                      : {}
                  }
                >
                  {submitLabel}
                </Button>
              </ContainerBtn>
            )}
            {!hideCancelButton && (
              <ContainerBtn
                direction="row"
                justifyContent={fullWidth ? 'center' : 'flex-start'}
                alignItems="center"
                spacing={2}
              >
                <Button
                  id={cancelBtnId}
                  buttonVariant="outlined"
                  disableElevation
                  fullWidth
                  onClick={onCancelAction}
                  disabled={isRequesting}
                  sx={{
                    height: '44px',
                    textTransform: 'unset',
                  }}
                  style={{ marginTop: 12 }}
                >
                  {cancelLabel}
                </Button>
              </ContainerBtn>
            )}
            {textError !== '' && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: '12px',
                  margin: '8px 16px',
                }}
              >
                {textError}
              </Typography>
            )}
          </ActionButtonContainer>
        )}
      </StyledStack>
    </StyledDrawer>
  )
}

BottomDrawer.defaultProps = {
  id: '',
  background: '',
  title: '',
  content: '',
  cancelLabel: '',
  showDrawer: false,
  onCloseAction: () => {},
  onSubmitAction: () => {},
  onLeftBtnAction: () => {},
  submitLabel: '',
  isRequesting: false,
  fullWidth: false,
  hideButtonRequest: false,
  hideHeader: false,
  hideCancelButton: true,
  cancelBtnId: '',
  onCancelAction: () => {},
  LeftBtnLabel: '',
  LeftBtnId: '',
  leftBtnLoading: false,
  activeButton: false,
  hideCloseButton: false,
  submitButtonId: '',
  swapButtonPosition: false,
  closeButtonId: '',
  disablePadding: false,
  variant: 'contained',
  disabledSubmitBtn: false,
  sx: {},
  textTransform: 'unset',
  textError: '',
}

export default BottomDrawer
