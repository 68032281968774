import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM14.69 14.19L6.5 18L10.31 9.81L18.5 6L14.69 14.19Z"
        fill="#F87500"
      />
      <path
        d="M12.5 10C11.3909 10 10.5 10.8909 10.5 12C10.5 13.1091 11.3909 14 12.5 14C13.6091 14 14.5 13.1091 14.5 12C14.5 10.8909 13.6091 10 12.5 10Z"
        fill="#CB1C4F"
      />
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
