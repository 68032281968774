import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={8}
        y={32}
        width={40}
        height={32}
        rx={6}
        fill="#FC8032"
      />
      <path
        d="M17 2.118v7.993a5.005 5.005 0 0 0-3.886-.339c-1.564.508-2.766 1.768-3.046 3.25-.536 2.902 2.17 5.379 5.356 4.924 2.287-.329 3.909-2.234 3.909-4.341v-9.37h2.333C22.95 4.235 24 3.282 24 2.117 24 .954 22.95 0 21.666 0h-2.333c-1.284 0-2.334.953-2.334 2.118Z"
        fill="#43AC6D"
      />
      <rect
        x={11}
        y={22}
        width={28}
        height={28}
        rx={6}
        fill="url(#a)"
      />
      <rect
        x={20}
        y={31}
        width={15}
        height={3}
        rx={1.5}
        fill="#2F39A3"
      />
      <rect
        x={15}
        y={36}
        width={20}
        height={3}
        rx={1.5}
        fill="#2F39A3"
      />
      <rect x={28} width={28} height={28} rx={6} fill="url(#b)" />
      <path
        d="M38 8.972v10.056c0 .767.858 1.233 1.518.815l8.024-5.028a.963.963 0 0 0 0-1.64l-8.024-5.018c-.66-.418-1.518.048-1.518.815Z"
        fill="#FFD0A4"
      />
      <path
        d="M15.043 44.633A4 4 0 0 1 18.802 42h31.487c2.776 0 4.708 2.758 3.76 5.367l-5.091 14A4 4 0 0 1 45.198 64H13.711c-2.776 0-4.708-2.758-3.76-5.367l5.091-14Z"
        fill="#FDAE2A"
      />
      <defs>
        <linearGradient
          id="a"
          x1={16}
          y1={44.5}
          x2={10.5}
          y2={21.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#484FF9" />
          <stop offset={1} stopColor="#3B95FF" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={34.5}
          y1={22}
          x2={67.5}
          y2={-11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1C4F" />
          <stop offset={1} stopColor="#F87500" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
