import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'

interface BubbleDateProps {
  text: string
}

export default function BubbleDate({ text }: BubbleDateProps) {
  return (
    <Stack p={1} borderRadius="4px" textTransform="uppercase" m={0}>
      <Typography size="xs" bold color="primary" m={0}>
        {text}
      </Typography>
    </Stack>
  )
}
