import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={240}
      height={181}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M223.946 180.5c10.21-17.65 16.054-38.143 16.054-60 0-66.274-53.726-120-120-120S0 54.226 0 120.5c0 21.857 5.844 42.35 16.054 60h207.892Z"
        fill="url(#a)"
      />
      <path
        d="M23.483 136.159a1.627 1.627 0 1 1 2.3-2.301 1.627 1.627 0 0 1-2.3 2.301Zm204.27-5.782a1.876 1.876 0 1 1-3.753 0 1.876 1.876 0 0 1 3.753 0ZM216 134.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-20.584-78.835a1.566 1.566 0 1 1-2.928 1.114 1.566 1.566 0 0 1 2.928-1.114Zm-17.386.352a1.566 1.566 0 1 1-2.928 1.114 1.566 1.566 0 0 1 2.928-1.114Zm8.372-12.003c.362-.362.134-1.221-.506-2.195a.867.867 0 0 1 0-.952c.64-.974.868-1.833.506-2.195-.361-.362-1.221-.133-2.194.506a.864.864 0 0 1-.952 0c-.974-.64-1.834-.868-2.196-.506-.361.362-.133 1.221.506 2.195a.863.863 0 0 1 0 .952c-.639.974-.867 1.833-.506 2.195.362.362 1.222.133 2.196-.506a.867.867 0 0 1 .952 0c.973.64 1.833.868 2.194.506Zm-117.889 1c.363-.362.134-1.221-.506-2.195a.867.867 0 0 1 0-.952c.64-.974.869-1.833.506-2.195-.361-.362-1.22-.133-2.194.506a.865.865 0 0 1-.952 0c-.974-.64-1.834-.868-2.195-.506-.362.362-.133 1.221.506 2.195.19.29.19.663 0 .952-.64.974-.868 1.833-.506 2.195.361.362 1.22.133 2.195-.506.29-.19.662-.19.952 0 .973.64 1.833.868 2.194.506Zm-51.44 79.631c.416 0 .779-.626.971-1.554a.706.706 0 0 1 .548-.548c.928-.192 1.553-.555 1.553-.971 0-.416-.625-.779-1.553-.971a.705.705 0 0 1-.548-.548c-.192-.928-.555-1.553-.971-1.553-.416 0-.78.625-.972 1.553a.704.704 0 0 1-.547.548c-.928.192-1.554.555-1.554.971 0 .416.626.779 1.554.971.275.058.49.272.547.548.193.928.556 1.554.972 1.554Zm46.79-93.341a2.482 2.482 0 1 1 4.201-2.643 2.482 2.482 0 0 1-4.201 2.643ZM50.04 38.75a1.623 1.623 0 1 1 3.165.72 1.623 1.623 0 0 1-3.164-.72Z"
        fill="#8082EF"
      />
      <path
        d="M182.998 177.56c9.724-13.365 15.328-29.893 14.985-47.691-.838-43.385-36.688-77.877-80.072-77.039-43.385.839-77.877 36.688-77.039 80.073.323 16.713 5.845 32.102 14.989 44.664 18.704-.372 40.49-.585 63.755-.585 23.109 0 44.761.21 63.382.578Z"
        fill="#12D862"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m189.23 95.28-59.616 1.151c-3.178.062-5.972 2.44-6.212 5.61a6.088 6.088 0 0 0 6.186 6.566l16.596-.321a6.087 6.087 0 1 1 .235 12.171l-9.957.192a6.09 6.09 0 0 0-5.929 6.8c.363 3.158 3.247 5.427 6.425 5.366l61.04-1.179a87.024 87.024 0 0 0-.015-1.768c-.241-12.474-3.376-24.213-8.753-34.588Z"
        fill="#0083EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m188.64 94.165-59.049 1.14c-3.824.073-7.036 2.996-7.313 6.651a7.247 7.247 0 0 0 1.948 5.52 7.198 7.198 0 0 0 5.382 2.258l16.597-.321a4.908 4.908 0 0 1 3.534 1.385 4.923 4.923 0 0 1 1.52 3.478 4.93 4.93 0 0 1-1.384 3.535 4.933 4.933 0 0 1-3.479 1.52l-9.957.192a7.229 7.229 0 0 0-5.292 2.465 7.247 7.247 0 0 0-1.734 5.59c.413 3.596 3.656 6.365 7.418 6.365l.148-.001 61.007-1.179c.012-.748.014-1.5.006-2.254l-61.057 1.179c-2.668.068-4.995-1.866-5.283-4.367a4.923 4.923 0 0 1 1.192-3.849 4.968 4.968 0 0 1 3.638-1.696l9.958-.192a7.167 7.167 0 0 0 5.059-2.211 7.16 7.16 0 0 0 2.013-5.14 7.162 7.162 0 0 0-2.21-5.059 7.164 7.164 0 0 0-4.997-2.015l-.143.001-16.597.32a5.007 5.007 0 0 1-3.701-1.552 4.925 4.925 0 0 1-1.339-3.801c.19-2.51 2.435-4.517 5.11-4.568l60.162-1.162a78.317 78.317 0 0 0-1.157-2.232Z"
        fill="#141220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m42.082 145.261 60.823-1.175a6.086 6.086 0 0 0-.235-12.171l-27.107.524a5.533 5.533 0 1 1-.214-11.064l9.404-.182a6.086 6.086 0 1 0-.234-12.17l-3.873.074a6.638 6.638 0 0 1-6.767-6.51 6.639 6.639 0 0 1 6.51-6.766l19.363-.375a6.087 6.087 0 0 0-.236-12.17l-42.843.828c-10.227 13.553-16.154 30.506-15.8 48.799a79.09 79.09 0 0 0 1.209 12.358Z"
        fill="#0083EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m42.29 146.383 60.636-1.171c3.978-.076 7.15-3.375 7.073-7.351a7.221 7.221 0 0 0-7.21-7.074l-.141.001-27.107.524h-.086a4.41 4.41 0 0 1-4.404-4.32 4.411 4.411 0 0 1 4.32-4.491l9.404-.182c3.977-.076 7.15-3.375 7.073-7.35a7.166 7.166 0 0 0-2.21-5.059c-1.39-1.336-3.215-2.065-5.141-2.014l-3.873.075c-3.038.041-5.559-2.367-5.618-5.405a5.52 5.52 0 0 1 5.406-5.619l19.361-.374c3.978-.077 7.15-3.375 7.073-7.351a7.163 7.163 0 0 0-2.211-5.06 7.14 7.14 0 0 0-5.14-2.013l-41.943.81a78.928 78.928 0 0 0-1.726 2.287l43.712-.844a4.873 4.873 0 0 1 3.535 1.385 4.928 4.928 0 0 1 1.52 3.478 4.965 4.965 0 0 1-4.863 5.054l-19.362.375c-4.282.082-7.698 3.633-7.616 7.914.083 4.283 3.633 7.712 7.916 7.616l3.872-.075a4.907 4.907 0 0 1 3.534 1.384 4.927 4.927 0 0 1 1.52 3.478 4.966 4.966 0 0 1-4.862 5.055l-9.405.182a6.668 6.668 0 0 0-6.53 6.788c.071 3.671 3.107 6.588 6.788 6.529l27.106-.524h.097a4.966 4.966 0 0 1 4.958 4.864 4.965 4.965 0 0 1-4.863 5.054l-60.993 1.178c.123.752.256 1.501.4 2.246Z"
        fill="#141220"
      />
      <path
        d="M119.449 54.13c-.505 0-1.006.005-1.513.015-42.59.823-76.572 36.142-75.749 78.733.303 15.678 5.257 30.655 14.337 43.362 19.114-.376 40.911-.573 63.092-.573 22.037 0 43.705.195 62.719.565 9.702-13.582 14.657-29.59 14.334-46.337-.813-42.084-35.311-75.764-77.22-75.764ZM55.861 178.883c-.42 0-.816-.201-1.063-.541-9.653-13.261-14.922-28.965-15.24-45.413-.85-44.04 34.287-80.561 78.327-81.412a81.81 81.81 0 0 1 1.565-.015c43.339 0 79.007 34.823 79.847 78.342.34 17.555-4.928 34.323-15.236 48.49a1.311 1.311 0 0 1-1.088.541c-19.162-.378-41.071-.578-63.357-.578-22.43 0-44.468.203-63.73.586h-.025Z"
        fill="#141220"
      />
      <path
        d="M59.304 82.926c13.656-16.918 34.308-28.003 57.62-28.755a72.316 72.316 0 0 1 1.866-.038c.219-.002.439-.003.659-.003 16.936 0 32.663 5.501 45.445 14.834-12.347-7.953-26.698-12.205-41.67-12.205a77.44 77.44 0 0 0-25.405 4.246c-1.264 2.773-4.886 5.92-9.714 8.114-3.454 1.57-6.868 2.351-9.601 2.351-.23 0-.456-.005-.675-.016A78.077 78.077 0 0 0 65.334 82.81l-6.03.116Z"
        fill="#89ECB1"
      />
      <path
        d="M59.304 82.926c13.403-16.604 33.546-27.59 56.327-28.704.43-.02.862-.038 1.293-.051-23.312.752-43.964 11.837-57.62 28.755ZM165.457 69.33a58.855 58.855 0 0 0-.563-.366c-12.782-9.333-28.509-14.834-45.445-14.834a90.272 90.272 0 0 0 .018 0c16.754 0 32.731 5.324 45.99 15.2Z"
        fill="#8080B9"
      />
      <path
        d="M194.586 135.142c.022-.768.032-1.54.031-2.312l2.055-.041a77.296 77.296 0 0 1-.074 2.314l-2.012.039Zm-44.025-23.314a4.935 4.935 0 0 0-1.253-.161l-.097.001-1.409.027a5.045 5.045 0 0 0-.1-.683l-.314-1.482a4.91 4.91 0 0 1 2.352 1.269c.323.309.597.656.821 1.029Zm-46.15 34.723-.736-.491-.186-.88c3.71-.36 6.584-3.532 6.51-7.319a7.179 7.179 0 0 0-1.544-4.319 7.241 7.241 0 0 1 2.339 1.514 7.168 7.168 0 0 1 2.21 5.059 7.179 7.179 0 0 1-1.327 4.308 145.27 145.27 0 0 1-7.266 2.128Zm-29.844-15.328a4.413 4.413 0 0 1-3.516-4.231 4.413 4.413 0 0 1 4.32-4.491l9.404-.181c3.266-.063 5.989-2.298 6.816-5.297l1.12 5.466a7.187 7.187 0 0 1-4.93 2.085l-9.405.181a4.412 4.412 0 0 0-4.32 4.491c.014.714.198 1.386.511 1.977Zm4.34-23.498c-2.217-.683-3.853-2.717-3.9-5.159a5.52 5.52 0 0 1 5.405-5.618l7.041-.136.472 2.302-4.509.087a5.518 5.518 0 0 0-5.404 5.618 5.475 5.475 0 0 0 .896 2.906Zm26.891-22.117a7.224 7.224 0 0 0-.49-.704c.421.169.825.377 1.21.625-.244.025-.482.052-.72.08Zm21.792-6.991a6.656 6.656 0 0 1-5.326-2.669 6.633 6.633 0 0 0 2.319.415l.132-.001 14.384-.278a6.668 6.668 0 0 0 6.53-6.788 6.622 6.622 0 0 0-1.328-3.858 6.67 6.67 0 0 1 4.333 6.113 6.668 6.668 0 0 1-6.531 6.787l-14.383.278-.13.001Z"
        fill="#0EAD65"
      />
      <path
        d="m36.981 175.316 83.535-1.613a6.086 6.086 0 0 0-.235-12.171l-83.535 1.613.235 12.171Z"
        fill="#0083EF"
      />
      <path
        d="m37.895 164.251.191 9.917 82.408-1.591a4.967 4.967 0 0 0 4.863-5.055 4.926 4.926 0 0 0-1.521-3.478 4.898 4.898 0 0 0-3.534-1.385l-82.407 1.592Zm-.914 12.192c-.613 0-1.115-.49-1.127-1.104l-.235-12.171a1.129 1.129 0 0 1 1.105-1.149l83.534-1.613a7.15 7.15 0 0 1 5.141 2.014 7.167 7.167 0 0 1 2.211 5.059c.077 3.976-3.096 7.274-7.073 7.351l-83.534 1.613h-.022Z"
        fill="#141220"
      />
      <path
        d="m145.295 99.666-.51-2.4 17.248-.332c-.171.8-.364 1.61-.575 2.42l-16.163.312Zm41.173-.795c-.394-.803-.8-1.597-1.219-2.386l3.116-.06c.4.789.787 1.585 1.16 2.387l-3.057.059Z"
        fill="#80C1F7"
      />
      <path
        d="m144.785 97.266 17.248-.333-17.248.333Zm40.464-.781 3.115-.06h.001l-3.116.06Z"
        fill="#8080B9"
      />
      <path
        d="M132.809 129.884a4.713 4.713 0 0 1-1.147-2.494v-.001l-.002-.018-.001-.004-.001-.006-.001-.008-.001-.006v-.007l-.001-.006a4.97 4.97 0 0 1 .306-2.416 4.972 4.972 0 0 1 4.523-3.141l9.957-.193a7.162 7.162 0 0 0 3.848-1.197l.022-.014c.338-.228.659-.484.959-.77a58.133 58.133 0 0 1-3.896 4.373l-9.764.189a4.967 4.967 0 0 0-3.638 1.695 4.918 4.918 0 0 0-1.191 3.849c.007.059.02.116.028.175Zm19.825-12.051a7.168 7.168 0 0 0 .88-3.599l-.004-.141a7.25 7.25 0 0 0-.222-1.527 7.2 7.2 0 0 1 1.182 2.627 54.137 54.137 0 0 1-1.836 2.64Z"
        fill="#80C1F7"
      />
      <path
        d="M131.662 127.39v-.001.001Zm-.002-.018-.001-.005.001.005Zm-.002-.011-.001-.008.001.008Zm-.002-.014v-.007.007Zm-.001-.013Zm.306-2.416Zm4.523-3.141 9.957-.192-9.957.192Zm13.805-1.39.022-.014-.022.014Zm3.221-6.294a7.262 7.262 0 0 0-.238-1.55l.016.023a7.26 7.26 0 0 1 .222 1.527Z"
        fill="#8080B9"
      />
      <path
        d="M107.566 139.318a4.928 4.928 0 0 0-1.34-2.261 4.985 4.985 0 0 0-3.48-1.386l-.055.001-1.203.023-.553-2.619 1.756-.034h.055c.839 0 1.656.201 2.382.588a4.964 4.964 0 0 1 2.601 3.959l.001.005v.004a4.957 4.957 0 0 1-.164 1.72Zm-32.162-3.12c-1.714 0-3.327-.67-4.566-1.862a6.615 6.615 0 0 1-1.91-6.103c.1.494.255.967.457 1.414a6.626 6.626 0 0 0 1.453 2.06c1.239 1.192 2.852 1.862 4.567 1.862.06 0 .12-.001.18-.004l19.316-.372.536 2.619-19.853.383a5.36 5.36 0 0 1-.18.003Z"
        fill="#80C1F7"
      />
      <path
        d="M107.731 137.598v-.004.004Zm-.001-.008a4.97 4.97 0 0 0-1.504-3.163 4.928 4.928 0 0 1 1.504 3.163Zm-38.345-7.943a6.599 6.599 0 0 1-.456-1.414l.001-.01c.099.493.251.97.455 1.424Z"
        fill="#8080B9"
      />
      <path
        d="M89.415 116.426a4.918 4.918 0 0 0-1.34-2.263 4.981 4.981 0 0 0-3.484-1.384h-.05l-3.873.074c-.054.002-.107.002-.16.002-4.221 0-7.673-3.389-7.756-7.616a7.836 7.836 0 0 1 .112-1.454c.631 3.66 3.822 6.441 7.628 6.441.058 0 .117 0 .176-.002l3.872-.074.03-.001h.02a4.99 4.99 0 0 1 3.385 1.291l.002.002.097.091a4.926 4.926 0 0 1 1.52 3.637 4.955 4.955 0 0 1-.18 1.256Z"
        fill="#80C1F7"
      />
      <path
        d="M89.595 115.17v-.158a4.98 4.98 0 0 0 0 .158Zm-1.52-3.636-.098-.092.098.092Zm-.1-.093-.003-.003.003.003Zm-7.482-1.215c-3.806 0-6.998-2.781-7.629-6.441.003-.009.004-.019.006-.029.62 3.676 3.808 6.47 7.637 6.47l.16-.002c-.057.002-.116.002-.174.002Zm.175-.002 3.873-.075h.03l-.03.001-3.873.074Z"
        fill="#8080B9"
      />
      <path
        d="m124.694 75.235 14.383-.277a5.533 5.533 0 0 0-.213-11.065l-14.384.278a5.533 5.533 0 1 0 .214 11.064Z"
        fill="#0083EF"
      />
      <path
        d="M138.972 65.02h-.087l-14.384.278a4.373 4.373 0 0 0-3.089 1.35 4.376 4.376 0 0 0-1.231 3.14 4.382 4.382 0 0 0 1.351 3.091 4.43 4.43 0 0 0 3.14 1.23l14.384-.278a4.412 4.412 0 0 0 4.32-4.49 4.412 4.412 0 0 0-4.404-4.322Zm-14.389 11.343a6.617 6.617 0 0 1-4.614-1.86 6.614 6.614 0 0 1-2.041-4.671 6.621 6.621 0 0 1 1.859-4.747 6.621 6.621 0 0 1 4.671-2.041l14.384-.277c3.669-.069 6.716 2.858 6.787 6.53a6.668 6.668 0 0 1-6.53 6.787l-14.384.278-.132.001Z"
        fill="#141220"
      />
      <path
        d="M123.106 73.844c-1.641-.593-2.849-2.127-2.921-3.965l-.001-.006v-.004l-.002-.081-.001-.053v-.007a4.411 4.411 0 0 1 4.321-4.43l4.855-.094 9.528-.183.087-.001c.186 0 .37.011.55.034h.006l.006.001a4.416 4.416 0 0 1 3.172 2.045 4.368 4.368 0 0 0-1.481-.262h-.086l-14.383.279a4.412 4.412 0 0 0-4.321 4.49 4.37 4.37 0 0 0 .671 2.237Z"
        fill="#80C1F7"
      />
      <path
        d="m120.185 69.88-.001-.006.001.006Zm-.001-.01-.003-.08v-.055.053l.003.082Zm-.003-.137v-.005.005Zm9.176-4.528 9.528-.184h.086a4.454 4.454 0 0 1 .001 0h-.087l-9.528.184Zm10.177-.15h-.006.006Z"
        fill="#8080B9"
      />
      <path
        d="M43.216 144.112a78.58 78.58 0 0 1-1.043-13.196c.097-17.08 5.787-32.907 15.352-45.701l42.013-.812h.083a4.953 4.953 0 0 1 3.612 1.545c-2.177.327-4.06.712-5.682 1.123l-32.008.618a8.459 8.459 0 0 0-6.88 3.805c-8.571 12.944-13.026 28.159-12.72 44.013.054 2.776.258 5.526.598 8.242l-.282.304-3.043.059Z"
        fill="#80C1F7"
      />
      <path
        d="M57.525 85.214h.001l42.013-.811h.012-.012l-42.014.811Z"
        fill="#8080B9"
      />
      <path
        d="m108.906 149.548-3.118-2.078.144-.003a7.21 7.21 0 0 0 5.745-3.044 120.097 120.097 0 0 0 10.459-3.897 22.85 22.85 0 0 0-2.45 4.794l-10.392 3.996a1.37 1.37 0 0 0-.388.232Zm84.465-2.638-.291-.539a14.442 14.442 0 0 0-2.792-3.647l-7.77-7.349 12.068-.233a78.639 78.639 0 0 1-1.215 11.768Zm-30.898-52.24c1.875-10.768.475-20.356-.547-25.95l.001.002c8.737 6.728 16.394 15.516 22.096 25.532l-21.55.416Z"
        fill="#0D9767"
      />
      <path
        d="m182.518 135.374-2.394-2.265 14.493-.28c.001.773-.009 1.545-.031 2.312l-12.068.233Zm-76.73 12.095-1.378-.918a144.96 144.96 0 0 0 7.266-2.128 7.206 7.206 0 0 1-5.745 3.043l-.143.003Z"
        fill="#0A7969"
      />
      <path
        d="m177.788 130.899-.12-.113-7.047-12.415a3.389 3.389 0 0 0-4.463-1.359l-7.478 3.739a10.552 10.552 0 0 0-4.269 3.902l-3.503 5.671a22.337 22.337 0 0 0-7.24-1.192c-.74 0-1.461.035-2.16.098a64.135 64.135 0 0 0 5.865-5.255l.194-.004a7.162 7.162 0 0 0 5.059-2.21 7.17 7.17 0 0 0 2.014-5.141 7.123 7.123 0 0 0-.171-1.427 53.602 53.602 0 0 0 6.989-15.84l25.01-.483c4.823 9.816 7.774 20.621 8.114 31.705l-16.794.324Zm-26.519-11.297.12-.116-.12.116Zm.133-.129.003-.003-.003.003Zm1.097-1.405Z"
        fill="#005BC9"
      />
      <path
        d="m180.124 133.109-2.336-2.209 16.794-.324a78.927 78.927 0 0 1 .035 2.253l-14.493.28Zm-28.854-13.507c.47-.585.928-1.179 1.364-1.769a7.298 7.298 0 0 1-1.229 1.637l-.003.004-.013.012-.119.116Zm10.763-22.67c.164-.758.31-1.513.44-2.262l21.55-.416c.42.737.829 1.482 1.227 2.23l-23.217.449Z"
        fill="#141220"
      />
      <path
        d="M161.458 99.353c.212-.809.404-1.62.575-2.42l23.217-.448c.418.79.825 1.583 1.218 2.386l-25.01.483Z"
        fill="#5987CF"
      />
      <path
        d="M162.033 96.934v-.001l23.217-.448-23.217.449Z"
        fill="#5959A4"
      />
      <path
        d="M147.373 123.976a58.133 58.133 0 0 0 3.896-4.373c.04-.038.08-.077.119-.117l.014-.012.003-.004.094-.096a7.304 7.304 0 0 0 1.135-1.541 54.817 54.817 0 0 0 1.835-2.64c.103.461.162.938.171 1.428a7.165 7.165 0 0 1-2.014 5.14 7.163 7.163 0 0 1-5.059 2.211l-.194.004Z"
        fill="#5987CF"
      />
      <path
        d="m151.39 119.486.013-.012-.013.012Zm.016-.016.095-.096-.095.096Z"
        fill="#5959A4"
      />
      <path
        d="m213.345 168.718 2.034-3.217a2.974 2.974 0 0 1 1.869-1.315l3.995-.888c.946-.21 1.87.43 2.007 1.389l.133.927 8.501 13.526h-20.482l1.943-10.422Z"
        fill="#FF653B"
      />
      <path
        d="M212.985 177.825h16.52l-7.236-11.511a1.319 1.319 0 0 1-.188-.514l-.133-.928a.335.335 0 0 0-.146-.236.34.34 0 0 0-.274-.054l-3.995.887a1.672 1.672 0 0 0-1.043.735l-1.898 3.003-1.607 8.618Zm18.899 2.629h-20.482c-.39 0-.761-.174-1.011-.474a1.319 1.319 0 0 1-.281-1.081l1.943-10.422c.031-.164.092-.32.181-.461l2.033-3.217a4.321 4.321 0 0 1 2.696-1.896l3.995-.888a2.982 2.982 0 0 1 3.594 2.486l.092.65 8.354 13.289a1.316 1.316 0 0 1-1.114 2.014Z"
        fill="#141220"
      />
      <path
        d="m215.663 170.085-.877-1.185.439-.694 1.265-2.002a1.672 1.672 0 0 1 1.021-.729l.023-.006 3.994-.887.021-.004a.078.078 0 0 1 .016-.002l-.404.915-2.501.556a1.673 1.673 0 0 0-1.043.734l-1.898 3.003-.056.301Z"
        fill="#FFA389"
      />
      <path
        d="m214.786 168.9.439-.695-.439.695Zm2.18-3.186Zm4.583-1.137.016-.002-.016.002Z"
        fill="#66A"
      />
      <path
        d="M227.987 175.022a.772.772 0 0 1-.201-.027l-6.15-1.7a.759.759 0 0 1-.438-.329l-.841-1.357a3.99 3.99 0 0 1-.257-3.711l1.822-4.133a.752.752 0 1 1 1.375.606l-1.823 4.134a2.484 2.484 0 0 0 .161 2.312l.685 1.108 5.866 1.621a.752.752 0 0 1-.199 1.476Z"
        fill="#141220"
      />
      <path
        d="m221.837 172.571-3.241 3.953 3.241-3.953Z"
        fill="#FEF5E7"
      />
      <path
        d="M218.596 177.275a.752.752 0 0 1-.581-1.227l3.241-3.954a.752.752 0 0 1 1.162.953L219.177 177a.747.747 0 0 1-.581.275Z"
        fill="#141220"
      />
      <path
        d="m133.558 147.188 7.124-8.239 8.115-2.705 6.733-10.9a9.253 9.253 0 0 1 3.737-3.416l7.478-3.74a2.075 2.075 0 0 1 2.733.832l7.143 12.586 12.762 12.073a13.097 13.097 0 0 1 2.539 3.315l4.408 8.186-3.864 23.96h-47.147l-11.761-31.952Z"
        fill="#FF653B"
      />
      <path
        d="M146.237 177.825h45.11l3.616-22.414-4.196-7.793a11.85 11.85 0 0 0-2.286-2.984l-12.762-12.072a1.325 1.325 0 0 1-.24-.307l-7.144-12.586a.758.758 0 0 0-1.001-.304l-7.478 3.738a7.94 7.94 0 0 0-3.207 2.933l-6.733 10.899c-.161.262-.41.458-.702.556l-7.773 2.591-6.381 7.38 11.177 30.363Zm46.23 2.629H145.32a1.313 1.313 0 0 1-1.233-.86l-11.762-31.952a1.313 1.313 0 0 1 .24-1.314l7.123-8.238a1.32 1.32 0 0 1 .579-.388l7.663-2.555 6.482-10.494a10.557 10.557 0 0 1 4.269-3.901l7.478-3.74a3.387 3.387 0 0 1 4.463 1.36l7.047 12.415 12.619 11.937a14.446 14.446 0 0 1 2.793 3.647l4.408 8.186c.137.254.187.547.14.832l-3.864 23.96a1.315 1.315 0 0 1-1.298 1.105Z"
        fill="#141220"
      />
      <path
        d="M154.928 132.206c-.818-.5-1.384-.79-1.551-.873l3.272-5.297a7.95 7.95 0 0 1 3.207-2.933l7.478-3.738a.941.941 0 0 1 .065-.03l.05-.017-.073 1.73-6.018 3.008a7.945 7.945 0 0 0-3.207 2.932l-3.223 5.218Z"
        fill="#FFA389"
      />
      <path
        d="M156.821 125.768a7.94 7.94 0 0 1 3.035-2.665l7.478-3.739-7.478 3.739a7.94 7.94 0 0 0-3.035 2.665Z"
        fill="#66A"
      />
      <path
        d="m109.766 150.542 20.095-7.729s11.207 11.208 11.594 11.98c.386.773 11.593 0 11.979.773.386.773 11.208 23.574 11.208 23.574l-64.824.496 9.948-29.094Z"
        fill="#FF653B"
      />
      <path
        d="M142.451 153.942c.09.117.15.205.179.263a1.132 1.132 0 0 0-.179-.263Zm-2.172 1.439.019.037-.019-.037Zm-29.468-3.832-9.149 26.758 60.908-.467c-3.519-7.414-8.908-18.761-10.087-21.223-.967-.122-3.375-.122-5.039-.122-5.559 0-6.598-.072-7.127-1.042-.602-.804-5.862-6.177-10.782-11.105l-18.724 7.201Zm-10.992 29.402a1.315 1.315 0 0 1-1.245-1.741l9.948-29.093c.126-.368.409-.662.772-.802l20.095-7.728a1.315 1.315 0 0 1 1.402.297c6.469 6.469 10.32 10.439 11.484 11.839.859.142 3.423.142 5.169.142 5.696 0 6.648.076 7.167 1.114.387.775 10.776 22.665 11.219 23.597a1.315 1.315 0 0 1-1.178 1.878l-64.823.497h-.01Z"
        fill="#141220"
      />
      <path
        d="m112.999 152.276-1.492-.994 7.28-2.8c-.162.821-.21 1.368-.222 1.53l-.015.13-5.551 2.134Z"
        fill="#FFA389"
      />
      <path
        d="M111.507 151.282h-.001l7.281-2.8s0-.001 0 0l-7.28 2.8Z"
        fill="#66A"
      />
      <path
        d="m139.201 179.364-10.678-8.775-10.641-13.477-28.66-19.107a3.027 3.027 0 0 0-3.067-.172l-11.168 5.753-1.056 35.94 65.27-.162Z"
        fill="#FF653B"
      />
      <path
        d="m76.277 144.4-.993 33.808 60.257-.15-7.854-6.453a1.291 1.291 0 0 1-.197-.201l-10.511-13.314-28.488-18.991a1.717 1.717 0 0 0-1.734-.098l-10.48 5.399Zm-2.347 36.441a1.315 1.315 0 0 1-1.314-1.354l1.056-35.939c.014-.479.286-.911.712-1.131l11.169-5.753a4.348 4.348 0 0 1 4.398.247l28.66 19.107c.115.077.216.171.303.279l10.552 13.367 10.569 8.684a1.315 1.315 0 0 1-.831 2.331l-65.27.162h-.004Z"
        fill="#141220"
      />
      <path
        d="m97.042 161.271-.446-1.076 3.745-.43.225 1.101-3.524.405Zm9.61-1.103-.233-1.1 10.26-1.177.3.2.646.818-10.973 1.259Zm-26.397-15.324-1.809-1.561 8.311-4.282a1.69 1.69 0 0 1 .784-.19l.046.001.034.002.715 1.868-8.08 4.162Z"
        fill="#FFA389"
      />
      <path
        d="M87.166 138.854c.123-.028.249-.042.374-.042h.047-.047c-.125 0-.25.014-.374.042Z"
        fill="#66A"
      />
      <path
        d="m45.616 146.678 3.354-3.612a6.767 6.767 0 0 0 1.8-4.249l.163-3.11a4.458 4.458 0 0 1 1.39-3.007l4.23-3.995a2.177 2.177 0 0 1 2.918-.065l35.224 30.404 8.502 20.482-60.684-.386 3.103-32.462Z"
        fill="#FF653B"
      />
      <path
        d="m43.959 177.834 57.264.365-7.623-18.363-34.988-30.201a.86.86 0 0 0-1.156.026l-4.23 3.995a3.129 3.129 0 0 0-.98 2.121l-.164 3.109a8.095 8.095 0 0 1-2.148 5.075l-3.051 3.286-2.924 30.587Zm59.238 3.007h-.008l-60.685-.387a1.316 1.316 0 0 1-1.3-1.439l3.104-32.462a1.31 1.31 0 0 1 .345-.769l3.354-3.612a5.466 5.466 0 0 0 1.45-3.424l.163-3.11a5.747 5.747 0 0 1 1.8-3.894l4.23-3.994a3.482 3.482 0 0 1 4.68-.106l35.224 30.405c.155.134.277.302.355.491l8.503 20.482a1.316 1.316 0 0 1-1.215 1.819Z"
        fill="#141220"
      />
      <path
        d="m73.823 175.733-.538-.635 6.854-3.016a4.211 4.211 0 0 0 2.466-3.173l.525-3.151a2.733 2.733 0 0 1 1.704-2.091l8.923-3.454.29.7-8.087 3.13a2.733 2.733 0 0 0-1.703 2.091l-.525 3.151a4.212 4.212 0 0 1-2.466 3.172l-7.444 3.276Zm-9.067-10.701-.622-.736 4.746-6.937.527.332.26.164-4.91 7.177Zm-15.951-16.919-1.72-1.086 2.848-3.066a8.095 8.095 0 0 0 2.148-5.076l.164-3.109c.03-.563.207-1.099.51-1.56l.002-.002c.132-.202.29-.389.468-.558l4.23-3.995.007-.006a.86.86 0 0 1 1.149-.02l4.294 3.706-.004.019-2.788-2.405a.86.86 0 0 0-1.155.025l-4.23 3.995a3.133 3.133 0 0 0-.98 2.12l-.164 3.11a8.094 8.094 0 0 1-2.149 5.075l-2.63 2.833Z"
        fill="#FFA389"
      />
      <path
        d="m47.085 147.027 2.848-3.066-2.848 3.066ZM52 139.679c.04-.262.067-.527.081-.793l.164-3.11a3.125 3.125 0 0 1 0 .001l-.164 3.109a7.918 7.918 0 0 1-.081.793Zm.756-5.463v-.002.002Zm4.706-4.561Z"
        fill="#66A"
      />
      <path
        d="m14.951 179.379 20.531-35.204a2.387 2.387 0 0 1 3.336-.815l12.595 7.955 23.573 27.824-60.035.24Z"
        fill="#FF653B"
      />
      <path
        d="M37.545 144.306c-.085 0-.17.01-.256.032a1.059 1.059 0 0 0-.672.501l-19.372 33.216 54.913-.219-21.618-25.517-12.425-7.847a1.069 1.069 0 0 0-.57-.166ZM14.95 180.694a1.315 1.315 0 0 1-1.135-1.978l20.53-35.202a3.668 3.668 0 0 1 2.32-1.731 3.669 3.669 0 0 1 2.854.466l12.595 7.955c.113.071.215.16.301.261l23.573 27.825a1.313 1.313 0 0 1-.998 2.164l-60.034.24h-.005Z"
        fill="#141220"
      />
      <path
        d="m45.908 177.941-6.67-7.116c.818.044 1.607.065 2.366.065 11.816 0 16.571-5.071 17.773-6.665l1.95.825.027.032c-.022.021-.043.04-.063.065L51.148 177.92l-5.24.021Zm16.021-12.179-.306-.362.306.362Z"
        fill="#CC5146"
      />
      <path
        d="m52.188 179.529-5.696-.023a1.315 1.315 0 0 0-.304-1.266l-.28-.299 5.239-.021 1.13-.005-.294.371a.75.75 0 0 0 .276 1.151l-.07.092Zm9.74-13.767-.306-.362-.269-.318-.028-.032.228.097a.753.753 0 0 0 .9-.251l.171.07a.743.743 0 0 0-.206.181l-.49.615Z"
        fill="#141220"
      />
      <path
        d="m51.148 177.92 10.143-12.773c.02-.024.041-.043.063-.064l.269.317.306.363-9.651 12.153-1.13.004Z"
        fill="#E09790"
      />
      <path
        d="m51.148 177.92 1.13-.004-1.13.004Zm10.475-12.52-.27-.317.27.317Z"
        fill="#66A"
      />
      <path
        d="m25.286 164.267 7.294-12.507 4.038-6.922a1.058 1.058 0 0 1 .67-.501c.006-.001.01-.003.016-.003a1.054 1.054 0 0 1 .811.138l.974.615c-.25.082-.46.244-.593.474l-10.893 18.677a7.501 7.501 0 0 0-2.317.029Z"
        fill="#FFA389"
      />
      <path
        d="m25.286 164.267 7.295-12.507-7.295 12.507Zm13.803-19.18-.974-.615.975.615Zm-1.99-.685a1.12 1.12 0 0 1 .19-.065l.015-.003-.015.003a1.096 1.096 0 0 0-.19.065Z"
        fill="#66A"
      />
      <path
        d="M61.846 165.206a.744.744 0 0 1-.293-.06l-8.3-3.511a2.31 2.31 0 0 0-2.395.378l-2.344 2.032a3.807 3.807 0 0 1-5.009-.032l-3.736-3.321a3.787 3.787 0 0 1-1.235-3.374l1.7-11.906a.751.751 0 0 1 1.488.212l-1.701 11.907c-.109.764.17 1.526.747 2.038l3.736 3.321a2.3 2.3 0 0 0 3.026.019l2.343-2.032a3.825 3.825 0 0 1 3.966-.627l8.3 3.512a.752.752 0 0 1-.293 1.444Z"
        fill="#141220"
      />
      <path
        d="m63.006 165.614-10.434 13.139 10.434-13.139Z"
        fill="#FEF5E7"
      />
      <path
        d="M52.572 179.504a.752.752 0 0 1-.588-1.218l10.435-13.139a.75.75 0 1 1 1.176.934l-10.434 13.14a.75.75 0 0 1-.589.283Zm33.621-16.617a.747.747 0 0 1-.57-.263l-.936-1.091a4.704 4.704 0 0 0-3.014-1.612l-9.682-1.174a5.072 5.072 0 0 1-2.088-.744l-4.91-3.1a5.05 5.05 0 0 1-2.28-3.41l-1.468-8.516a5.036 5.036 0 0 1 .035-1.894l1.818-8.663a.75.75 0 1 1 1.47.309l-1.818 8.662c-.092.44-.1.887-.024 1.331l1.469 8.515a3.55 3.55 0 0 0 1.6 2.395l4.91 3.101c.441.28.948.459 1.466.522l9.683 1.174a6.207 6.207 0 0 1 3.974 2.127l.935 1.091a.751.751 0 0 1-.57 1.24Z"
        fill="#141220"
      />
      <path
        d="m70.733 157.63-6.193 9.05 6.192-9.05Z"
        fill="#FEF5E7"
      />
      <path
        d="M64.539 167.432a.75.75 0 0 1-.62-1.176l6.193-9.05a.753.753 0 0 1 1.044-.197c.342.235.43.703.196 1.045l-6.192 9.051a.753.753 0 0 1-.621.327Zm7.356 8.209a.75.75 0 0 1-.303-1.439l7.942-3.496a2.715 2.715 0 0 0 1.59-2.044l.524-3.15a4.242 4.242 0 0 1 2.643-3.246l10.132-3.923a.752.752 0 0 1 .543 1.402l-10.133 3.922a2.733 2.733 0 0 0-1.703 2.091l-.525 3.15a4.211 4.211 0 0 1-2.466 3.174l-7.943 3.494a.737.737 0 0 1-.301.065Zm15.843 4.636a.751.751 0 0 1-.628-1.162l4.099-6.278a6.221 6.221 0 0 1 4.127-2.719l3.48-.607a.75.75 0 1 1 .259 1.48l-3.48.607a4.717 4.717 0 0 0-3.128 2.06l-4.1 6.278a.749.749 0 0 1-.63.341Z"
        fill="#141220"
      />
      <path
        d="M95.01 160.373a.752.752 0 0 1-.085-1.498l22.914-2.63a.752.752 0 1 1 .172 1.493l-22.914 2.629a.826.826 0 0 1-.086.006Zm14.298-8.339a.467.467 0 0 1-.052-.003l-13.46-.915a5.607 5.607 0 0 1-4.84-3.58l-3.919-10.251a.752.752 0 0 1 1.403-.537l3.92 10.251a4.1 4.1 0 0 0 3.539 2.617l13.46.917a.75.75 0 0 1-.051 1.501Zm41.025 4.361h-.002a1.16 1.16 0 0 1-1.158-1.162l.011-7.207-3.593-10.634a1.16 1.16 0 0 1 2.198-.742l3.653 10.816c.041.12.061.246.061.373l-.011 7.398c0 .64-.52 1.158-1.159 1.158Z"
        fill="#141220"
      />
      <path
        d="M139.909 152.453a.743.743 0 0 1-.467-.165.75.75 0 0 1-.12-1.055l8.067-10.112a.752.752 0 1 1 1.174.937l-8.066 10.112a.75.75 0 0 1-.588.283Zm22.674-6.632c-.329 0-.663-.065-.983-.197a2.523 2.523 0 0 1-1.559-2.06l-.224-1.901a4.936 4.936 0 0 1 .73-3.206l4.999-7.893a10.751 10.751 0 0 0 1.638-4.997l.325-7.674a.753.753 0 0 1 .784-.719.75.75 0 0 1 .717.782l-.326 7.694a12.274 12.274 0 0 1-1.868 5.718l-5 7.893a3.422 3.422 0 0 0-.506 2.227l.223 1.901c.045.382.285.699.641.846.356.147.749.091 1.05-.147l14.331-11.346a.752.752 0 0 1 .932 1.179l-14.33 11.345a2.528 2.528 0 0 1-1.574.555Zm-3.036 23.221a.752.752 0 0 1-.222-1.469l11.292-3.508a7.943 7.943 0 0 0 3.574-2.298l7.849-8.79-.758-15.537a.752.752 0 1 1 1.501-.074l.773 15.844a.754.754 0 0 1-.19.537l-8.055 9.021a9.444 9.444 0 0 1-4.249 2.733l-11.292 3.506a.72.72 0 0 1-.223.035Z"
        fill="#141220"
      />
      <path
        d="m182.806 153.248 13.139 3.091-13.139-3.091Z"
        fill="#FEF5E7"
      />
      <path
        d="M195.945 157.09a.742.742 0 0 1-.173-.02l-13.139-3.091a.75.75 0 1 1 .344-1.463l13.14 3.092a.751.751 0 0 1-.172 1.482Z"
        fill="#141220"
      />
      <path
        d="m176.429 179.333 16.616-22.801 9.191-3.37a2.3 2.3 0 0 1 3.024 1.602l1.312 5.246 14.298 19.323h-44.441Z"
        fill="#FF653B"
      />
      <path
        d="M179.014 178.018h39.249l-12.748-17.225a1.322 1.322 0 0 1-.218-.463l-1.312-5.247a.98.98 0 0 0-.495-.632.979.979 0 0 0-.801-.054l-8.815 3.233-14.86 20.388Zm41.857 2.63h-44.442a1.316 1.316 0 0 1-1.063-2.089l16.618-22.801c.153-.21.365-.37.61-.46l9.19-3.37a3.623 3.623 0 0 1 2.938.2 3.622 3.622 0 0 1 1.814 2.318l1.248 4.993 14.143 19.112a1.315 1.315 0 0 1-1.056 2.097Z"
        fill="#141220"
      />
      <path
        d="m180.763 175.618 13.11-17.988 4.478-1.642 3.553-1.304-.725 1.301-6.555 2.404-12.41 17.028c-.471.079-.954.147-1.451.201Z"
        fill="#FFA389"
      />
      <path
        d="m180.763 175.618 13.11-17.989 4.478-1.641-4.478 1.642-13.11 17.988Z"
        fill="#66A"
      />
      <path
        d="M202.993 168.321a.751.751 0 0 1-.478-.172l-2.627-2.167a4.56 4.56 0 0 1-1.082-5.739l4.342-7.788a.75.75 0 1 1 1.312.731l-4.341 7.789a3.056 3.056 0 0 0 .725 3.848l2.415 1.992 8.612-.108a.752.752 0 0 1 .019 1.502l-8.887.112h-.01Z"
        fill="#141220"
      />
      <path
        d="M191.385 179.255a.75.75 0 0 1-.371-1.405l6.43-3.645a6.741 6.741 0 0 0 2.717-2.862l2.067-4.133a.75.75 0 1 1 1.344.672l-2.067 4.133a8.243 8.243 0 0 1-3.32 3.497l-6.43 3.645a.74.74 0 0 1-.37.098Z"
        fill="#141220"
      />
      <path
        d="m7.357 179.14 6.184-4.251 2.145-4.023a5.637 5.637 0 0 1 2.803-2.548l5.677-2.365a6.182 6.182 0 0 1 3.59-.356l5.028 1.006c.554.111 1.06.392 1.446.804l11 11.733H7.356Z"
        fill="#FF653B"
      />
      <path
        d="M11.591 177.825h30.604l-8.924-9.518a1.392 1.392 0 0 0-.744-.415l-5.028-1.005a4.836 4.836 0 0 0-2.827.28l-5.677 2.365a4.326 4.326 0 0 0-2.149 1.953l-2.145 4.023a1.324 1.324 0 0 1-.415.465l-2.695 1.852Zm33.639 2.629H7.358a1.314 1.314 0 0 1-.745-2.398l5.919-4.069 1.994-3.739a6.969 6.969 0 0 1 3.457-3.143l5.678-2.365a7.462 7.462 0 0 1 4.354-.431l5.027 1.005c.828.165 1.57.578 2.148 1.195l10.999 11.732a1.314 1.314 0 0 1-.96 2.213Z"
        fill="#141220"
      />
      <path
        d="M14.309 177.825H11.59l2.695-1.852c.173-.12.316-.279.415-.465l2.145-4.023a4.33 4.33 0 0 1 2.148-1.953l5.678-2.365a4.856 4.856 0 0 1 2.827-.28l3.301.66-.258.15-1.165-.233a4.838 4.838 0 0 0-2.827.28l-5.678 2.366a4.327 4.327 0 0 0-2.148 1.953l-2.145 4.022c-.1.186-.242.345-.415.465l-1.855 1.275Z"
        fill="#FFA389"
      />
      <path
        d="M14.309 177.825H11.59l2.695-1.853c.173-.118.316-.278.415-.464l2.145-4.023a4.332 4.332 0 0 1 2.149-1.953l5.677-2.365a4.857 4.857 0 0 1 2.827-.28l3.302.66-3.302-.66a4.844 4.844 0 0 0-2.827.28l-5.677 2.365a4.33 4.33 0 0 0-2.149 1.953l-2.145 4.023a1.324 1.324 0 0 1-.415.465l-2.695 1.852h2.718Z"
        fill="#66A"
      />
      <path
        d="M16.654 179.97a.75.75 0 0 1-.389-1.395l7.677-4.619a1.95 1.95 0 0 0 .818-.984l.491-1.309a3.45 3.45 0 0 1 1.5-1.772l6.508-3.768a.75.75 0 1 1 .753 1.3l-6.507 3.768c-.389.225-.69.58-.847 1l-.49 1.309a3.46 3.46 0 0 1-1.451 1.743l-7.677 4.619a.748.748 0 0 1-.386.108Zm115.545-18.096a.747.747 0 0 1-.416-.126l-6.762-4.507a.753.753 0 0 1-.3-.851l4.21-13.431a.752.752 0 0 1 1.434.45l-4.042 12.894 5.9 3.933 9.463-5.602a.752.752 0 0 1 .765 1.293l-9.869 5.842a.74.74 0 0 1-.383.105Zm16.529 17.656a.75.75 0 0 1-.614-.318l-6.386-9.015a.752.752 0 0 1 .023-.9l11.27-14.274a.752.752 0 0 1 1.179.931l-10.921 13.833 6.061 8.557a.75.75 0 0 1-.612 1.186Z"
        fill="#141220"
      />
      <path
        d="M128.442 169.763a.75.75 0 0 1-.677-1.074l3.756-7.889a.752.752 0 0 1 1.356.646l-3.756 7.888a.751.751 0 0 1-.679.429Z"
        fill="#141220"
      />
      <path
        d="M123.747 179.341a.752.752 0 0 1-.678-1.075l4.132-8.64a.75.75 0 1 1 1.356.649l-4.132 8.639a.753.753 0 0 1-.678.427Z"
        fill="#141220"
      />
      <path
        d="m22.509 176.572 2.208-1.328a3.464 3.464 0 0 0 1.45-1.743l.491-1.31c.158-.42.458-.775.846-1l5.264-3.047.003-.002.411.083a1.398 1.398 0 0 0-.225-.166l.003-.002c.114.068.219.151.311.25l2.073 2.21c-.273 2.198-2.267 6.029-12.835 6.055Z"
        fill="#E2603C"
      />
      <path
        d="m33.182 168.225-.412-.082.187-.084c.08.047.155.103.225.166Z"
        fill="#E2927B"
      />
      <path
        d="m32.769 168.144.002-.002-.002.002Zm.189-.085-.021-.013.002-.001.022.012-.003.002Z"
        fill="#CC5146"
      />
      <path
        d="m44.83 179.019-.144-.008c-.262-.019-8.348-.029-16.613-.033l16.187-.142-.206-.227.307.003c-1.01-1.333-6.25-7.219-9.877-11.24l-1.523.685-.022-.012 1.074-.622a.747.747 0 0 0 .34-.424l.231-.105.096.106c10.398 11.525 10.289 11.741 10.21 11.898l-.06.121Zm-26.195-.044h-.12l.2-.12-.08.12Z"
        fill="#141220"
      />
      <path
        d="m32.77 168.143.166-.097a.213.213 0 0 1 .021.013l-.186.084Z"
        fill="#CC8284"
      />
      <path
        d="M15.941 178.975h-.666l1.733-.847-.743.447a.747.747 0 0 0-.324.4Z"
        fill="#141220"
      />
      <path
        d="M18.515 178.975h-2.573a.747.747 0 0 1 .324-.4l.743-.447 3.237-1.583-.43.649-2.836 1.387h1.916l-.182.274-.2.12Zm2.609-2.42a64.69 64.69 0 0 1-.837-.03l4.757-2.326 1.627-3.742 7.682-3.458a.747.747 0 0 1-.34.424l-1.074.622-.002.001-.166.096-.002.002-5.797 2.609-1.625 3.736-.065.032-4.158 2.034Zm-2.547 2.507.057-.087 9.439.003-9.496.084Z"
        fill="#141220"
      />
      <path
        d="m20.245 176.545.014-.021.027.001-.04.02Z"
        fill="#141220"
      />
      <path
        d="M42.194 177.825H20.427l2.082-1.253c10.567-.025 12.562-3.856 12.835-6.054l6.85 7.307Z"
        fill="#B54D47"
      />
      <path
        d="M28.073 178.979c-3.277-.002-6.582-.004-9.438-.004l.08-.12.455-.273c6.549 0 21.534.005 24.884.027l.206.227-16.187.143Zm-9.359-.124.181-.274h.274l-.455.274Zm1.101-1.661.43-.649.041-.02c.286.013.563.022.837.03l-1.308.639Z"
        fill="#141220"
      />
      <path
        d="M46.027 163.468a2.29 2.29 0 0 1-1.523-.577l-3.736-3.321a2.286 2.286 0 0 1-.747-2.038l1.554-10.875 8.965 5.662 8.631 10.188-5.332-2.256a3.82 3.82 0 0 0-3.966.627l-2.343 2.032c-.43.372-.967.558-1.503.558Z"
        fill="#B2474C"
      />
      <path
        d="m61.319 163.417-2.148-.91-8.631-10.188-8.966-5.662.046-.322 9.817 4.271 9.882 12.811Z"
        fill="#141220"
      />
      <path
        d="m46.108 164.367-2.966-1.603-3.928-4.97 1.844-11.704.563.245-.046.321-1.554 10.875c-.109.764.17 1.526.747 2.038l3.736 3.321c.433.385.978.577 1.523.577.537 0 1.073-.186 1.504-.558l2.343-2.032a3.819 3.819 0 0 1 3.965-.627l5.332 2.256 2.149.91.26.336-9.391-3.005-6.081 3.62Z"
        fill="#141220"
      />
      <path
        d="m55.223 176.623 4.766-6.003 3.11-3.477 3.33 3.931c-.872 1.948-3.476 4.791-11.206 5.549Z"
        fill="#E2603C"
      />
      <path
        d="m73.325 178.987 1.234-.018-.299-.381.443-.004.212.381h.83l.005.007-2.425.015Z"
        fill="#141220"
      />
      <path
        d="m59.99 170.621 2.93-3.69.18.212-3.11 3.478Z"
        fill="#CC5146"
      />
      <path
        d="m66.84 169.629-2.018-2.252.055-.024 1.972 2.201-.008.075Zm-3.741-2.486-.18-.212.675-.85a.747.747 0 0 0 .121-.218l.139-.156.25.279-.185.27a.794.794 0 0 0-.041.068l-.023-.026-.756.845Z"
        fill="#141220"
      />
      <path
        d="m52.809 178.7.36-1.043 10.546-11.794a.776.776 0 0 1-.12.218l-.676.849-2.93 3.69-5.419 6.061-.262.02-1.5 1.999Zm12.013-11.324-.944-1.052a.794.794 0 0 1 .041-.068l.185-.27.379.423-.193.29.587.653a.672.672 0 0 1-.055.024Zm9.599 10.706-2.24-2.499a.11.11 0 0 0 .015-.007l.365-.16 1.6 1.784a9.501 9.501 0 0 1-.238-.012l.498.894Z"
        fill="#141220"
      />
      <path
        d="m72.18 175.583-.429-.479.05-.024.045.05.32-.153.394.44-.364.16-.016.006Zm-19.075 3.7a.932.932 0 0 0 .056-.062l.087-.11 20.077-.124-20.22.296Zm-.742.01.445-.593-.142.414.581-.004-.087.11a.605.605 0 0 1-.055.062l-.742.011Z"
        fill="#141220"
      />
      <path
        d="m54.203 177.908 1.02-1.285c7.731-.758 10.335-3.601 11.207-5.549l5.729 6.762-17.956.072Z"
        fill="#B54D47"
      />
      <path
        d="m53.247 179.11.314-.395 20.698-.127.3.381-1.235.018-20.077.123Z"
        fill="#141220"
      />
      <path
        d="m52.666 179.114.143-.414 1.5-1.999.261-.02-1.08 1.208-.277.827.349-.002-.314.396-.582.004Zm16.633-6.745-2.458-2.74.008-.075 1.918 2.141.532.674Z"
        fill="#141220"
      />
      <path
        d="m86.546 161.393-.718-.837a6.207 6.207 0 0 0-3.973-2.127l-9.683-1.174a3.572 3.572 0 0 1-1.467-.522l-4.91-3.101a3.548 3.548 0 0 1-1.6-2.395l-1.468-8.515a3.524 3.524 0 0 1 .024-1.331l1.45-6.909 28.442 24.55-6.097 2.361Zm6.115-2.368-28.457-24.562 28.457 24.562Zm-28.99-5.336-.4-.267-.078-.492c.137.267.297.521.477.759Z"
        fill="#B2474C"
      />
      <path
        d="m92.66 159.025-28.456-24.562.222-1.057 24.813 20.891a55.367 55.367 0 0 1-.99-.311l5.859 4.48-1.447.559Z"
        fill="#141220"
      />
      <path
        d="M92.643 159.032 64.2 134.482l.004-.019 28.457 24.562-.018.007Z"
        fill="#B27282"
      />
      <path
        d="M86.052 161.585a15.044 15.044 0 0 0-1.192-.803l-2.27-1.693-11.11-1.34-.007.001a.751.751 0 0 0-1.361-.544l-.366.534-6.076-4.05a5.12 5.12 0 0 1-.477-.759l-1.659-10.687 2.597-9.087.295.25-.222 1.056-.003.02-1.45 6.909a3.52 3.52 0 0 0-.024 1.33l1.468 8.515a3.55 3.55 0 0 0 1.6 2.395l4.91 3.102c.442.279.949.459 1.467.522l9.683 1.173a6.207 6.207 0 0 1 3.973 2.127l.718.837-.495.192Z"
        fill="#141220"
      />
      <path
        d="m70.101 157.94-.07-.01-.285-.19.366-.534a.75.75 0 0 1 1.362.544l-1.37.185-.003.005Zm16.075 3.822-.055-.041.063-.035s-.046-.037-.132-.101l.494-.192 6.098-2.361.017-.007 1.447-.559.301.23-8.233 3.066Z"
        fill="#141220"
      />
      <path
        d="M72.205 168.505c-1.498 0-3.321-.359-5.54-1.221l-.9-1.063 5.26-7.689c.315.103.64.176.966.215l7.08.858c.038 2.016-.311 8.9-6.866 8.9Z"
        fill="#E2603C"
      />
      <path
        d="m72.167 174.977-.046-.051 8.804-4.225.65-2.469 1.588-4.868 2.623-1.457.39-.145.178.134-2.824 1.614-2.342 7.177-9.021 4.29Zm-6.677-7.445-.353-.395a.613.613 0 0 0 .02-.028l.333.423Zm5.78-9.356.038-.058.264.039-.09-.01-.213.029Z"
        fill="#141220"
      />
      <path
        d="m65.136 167.137-.654-.728 5.618-8.469 1.208.179-.04.057-.934.126-5.453 8.22c-.098-.045-.198-.093-.297-.14l.212.268-.009.013.146.163.223.283-.02.028Zm6.71 7.993-.045-.05.32-.153.046.05-.321.153Zm-4.996-5.576-1.973-2.201a.764.764 0 0 0 .26-.216l.353.395 1.376 1.746a3.187 3.187 0 0 1-.017.276Z"
        fill="#141220"
      />
      <path
        d="m64.876 167.353-.586-.654.192-.29.654.728a.763.763 0 0 1-.26.216Zm3.892 4.342-1.918-2.141c.011-.116.015-.209.016-.275l1.902 2.416Zm17.018-9.788.334-.186.055.041-.389.145Zm-.926-1.125a14.716 14.716 0 0 0-2.09-1.066l-.333-.248-.53-.064c-.73-.23-1.499-.379-2.261-.379-.2 0-.4.01-.6.032v.002l-7.475-.902-.263-.039.044-.064a.746.746 0 0 0 .12-.304l.008-.001 11.11 1.34 2.27 1.693Z"
        fill="#141220"
      />
      <path
        d="m71.308 158.119-1.207-.179.003-.005 1.369-.185a.74.74 0 0 1-.121.304l-.044.065Zm-.073 16.41-1.305-1.456-.516-.657 1.85 2.064-.029.049Zm.517.575-.517-.575.029-.049.537.6-.05.024Z"
        fill="#141220"
      />
      <path
        d="m71.159 174.733-1.861-2.364.631.704 1.248 1.59a1.086 1.086 0 0 0-.018.07Z"
        fill="#0E0A6C"
      />
      <path
        d="m71.534 175.208-.375-.475a.85.85 0 0 1 .019-.07l.408.52-.052.025Z"
        fill="#0E0A6C"
      />
      <path
        d="m69.93 173.073-.631-.704-.531-.674.646.721.516.657Zm16.046-11.459-1.117-.832c.57.347.99.651 1.192.803l-.075.029Z"
        fill="#141220"
      />
      <path
        d="m86.12 161.721-.143-.108.075-.028c.086.064.132.101.132.101l-.063.035Z"
        fill="#141220"
      />
      <path
        d="m72.272 173.902-5.607-6.618c2.219.862 4.042 1.221 5.54 1.221 6.555 0 6.905-6.884 6.867-8.9l2.602.316a4.701 4.701 0 0 1 3.013 1.613l.373.435-.769.297a4.242 4.242 0 0 0-2.642 3.246l-.525 3.15a2.715 2.715 0 0 1-1.59 2.044l-7.262 3.196Z"
        fill="#B54D47"
      />
      <path
        d="m71.51 174.244-6.02-6.712-.334-.423.003-.004.011-.016 6.387 7.128-.048.027Z"
        fill="#141220"
      />
      <path
        d="m65.156 167.109-.223-.284.237.264-.011.016-.003.004Zm6.965 7.818-.611-.683a.432.432 0 0 1 .048-.027l.385.431 8.923-4.244.709-2.171-.65 2.469-8.803 4.225Zm-7.325-8.277-.212-.268.297.139-.085.129Zm18.367-3.286.04-.121 2.275-1.3.072.052.236-.088-2.623 1.457ZM71.264 174.48l-1.85-2.064-2.55-3.249s.002.039.002.111l-1.376-1.746 6.02 6.712a.737.737 0 0 0-.246.236Z"
        fill="#141220"
      />
      <path
        d="m71.801 175.08-.537-.6a.737.737 0 0 1 .246-.236l.611.683-.32.153Zm-2.387-2.664-.646-.721-1.902-2.417a1.77 1.77 0 0 0-.002-.111l2.55 3.249Zm16.129-10.634-2.772-2.066c.798.325 1.518.717 2.09 1.066l1.116.831-.434.169Z"
        fill="#141220"
      />
      <path
        d="m85.55 161.995-.072-.052.159-.091-.095-.07.434-.168.145.107-.335.187-.236.087Zm-3.643-2.591-2.86-.345v-.002c.2-.022.4-.032.6-.032.762 0 1.53.149 2.26.379Z"
        fill="#141220"
      />
      <path
        d="m87.764 178.113-11.99-.076-.688-.813h.04c11.746-.001 18.582-10.302 20.24-13.134l2.33 5.616-2.36.412a6.228 6.228 0 0 0-4.127 2.719l-3.445 5.276Z"
        fill="#CC5146"
      />
      <path
        d="M87.207 178.965H76.29a1.31 1.31 0 0 0-.3-.675l-.215-.254 11.99.077-.557.852Zm10.49-9.259-2.332-5.616c.265-.451.397-.713.397-.713l2.525 6.226-.59.103Z"
        fill="#141220"
      />
      <path
        d="M76.29 178.965h-.546l-1.583-1.764c.311.014.62.022.925.023l.689.812.214.254c.166.195.267.431.3.675Zm11.49 0h-.572l.557-.852 3.445-5.276a6.228 6.228 0 0 1 4.127-2.719l2.36-.412.59-.103.293.723-5.635 1.502-4.434 6.353-.73.784Zm-12.037 0h-.828l-.213-.38.17-.001-.45-.502-.5-.894.239.013 1.582 1.764Z"
        fill="#141220"
      />
      <path
        d="M90.382 177.086h-.153l2.238-3.428a4.717 4.717 0 0 1 3.127-2.06l2.693-.469.59 1.421c-.823 1.581-3.086 4.536-8.495 4.536Z"
        fill="#E2603C"
      />
      <path
        d="m88.766 179.327.112-.171 13.27-.379-.073-.185.147-.004-1.183-2.602-1.752-4.406s-.031.116-.109.313l-.387-.853.284-.049a.604.604 0 0 0 .113-.029l3.639 8.005-14.061.36Z"
        fill="#141220"
      />
      <path
        d="m87.41 179.362.37-.397h.182l.548-.785.162-.174 4.341-6.162 5.865-1.564.31.682a.812.812 0 0 1-.113.029l-.284.049-.133-.293-5.405 1.441-3.445 4.887a17.047 17.047 0 0 1-.437-.022l-.574 1.397-.459.494.26-.006-.094.228.374-.01-.113.171-1.355.035Z"
        fill="#141220"
      />
      <path
        d="M87.962 178.965h-.183l.73-.784-.547.784Z"
        fill="#141220"
      />
      <path
        d="m101.223 178.198-11.671-.074.678-1.038h.152c5.41 0 7.673-2.955 8.496-4.536l2.345 5.648Z"
        fill="#B54D47"
      />
      <path
        d="m88.878 179.156.15-.229 13.047-.334.073.185-13.27.378Z"
        fill="#141220"
      />
      <path
        d="m88.504 179.167.094-.229.43-.011-.15.229-.374.011Zm.293-.716.574-1.398c.148.01.294.018.437.023l-.838 1.189-.173.186Zm12.242-2.465-1.861-4.093c.077-.197.11-.313.11-.313l1.751 4.406Zm6.368-25.381a.073.073 0 0 1-.022-.006c.03.004.038.006.022.006Z"
        fill="#141220"
      />
      <path
        d="m105.219 150.25-.67-.045-.098-.467.768.512Z"
        fill="#B2474C"
      />
      <path
        d="m106.954 150.369-1.735-.118-.767-.512-.221-1.041c1.22.764 2.188 1.36 2.723 1.671Z"
        fill="#141220"
      />
      <path
        d="m98.297 149.78-2.326-.159-.248-.02a4.101 4.101 0 0 1-3.363-2.602l-2.68-7.01.083-.042v-.001l7.552 5.035.982 4.799Z"
        fill="#B2474C"
      />
      <path
        d="m97.314 144.98-7.551-5.034-.134-.089-.25-.652c1.082.698 4.33 2.791 7.776 4.997l.16.778Z"
        fill="#141220"
      />
      <path
        d="m89.68 139.989-.051-.132.134.089v.001l-.084.042Z"
        fill="#B27282"
      />
      <path d="m89.763 139.946-.134-.089.134.089Z" fill="#474799" />
      <path
        d="M107.385 150.6c-.196-.024-1.306-.122-2.805-.251l-.031-.143.67.045 1.735.118c.224.13.371.21.431.231Zm-9.08-.783-2.334-.196 2.326.159.008.037Zm-2.583-.216-1.462-.123-2.213-1.525-3.046-8.993.38.245.248.652.05.132 2.68 7.01a4.101 4.101 0 0 0 3.363 2.602Z"
        fill="#141220"
      />
      <path
        d="m95.996 158.751-.087-.211a1.314 1.314 0 0 0-.355-.491l-3.47-2.995c2.494.57 4.65.8 6.504.8.325 0 .641-.007.948-.021l.502 2.454-4.042.464Z"
        fill="#CC5146"
      />
      <path
        d="M95.405 158.803a.75.75 0 0 0-.982-.46l-.258.1-4.926-4.146a42.79 42.79 0 0 0 2.845.758l3.47 2.995c.155.133.276.301.355.49l.087.211-.028.003-.563.049Z"
        fill="#141220"
      />
      <path
        d="m94.635 158.869-.227-.173.04-.014-.283-.239.258-.1a.75.75 0 0 1 .982.46l-.77.066Z"
        fill="#141220"
      />
      <path
        d="m106.108 157.591-.656-3.11c1.5-.711 2.438-1.546 2.952-2.107l6.34 4.226-8.636.991Z"
        fill="#CC5146"
      />
      <path
        d="m114.743 156.599-6.34-4.226c.135-.147.241-.275.32-.378l.533.036.052.002.031-.001 6.366 4.457-.962.11Zm-18.774 2.155.028-.003 4.041-.463.024.114-4.093.352Zm10.199-.879-.06-.284 8.635-.991.962-.11.718.501-10.255.884Z"
        fill="#141220"
      />
      <path
        d="M109.308 152.034a.467.467 0 0 1-.052-.003l-.533-.035c.128-.166.187-.264.187-.264l.429.301-.031.001Zm-15.2 6.432-5.858-4.48c.335.109.665.212.99.311l4.926 4.146-.058.023Zm.302.23-.302-.23.058-.022.282.238-.039.014Z"
        fill="#141220"
      />
      <path
        d="m124.821 178.085 1.822-3.807c.055 1.466.548 2.976 1.361 3.766l.035.033-3.218.008Z"
        fill="#E2603C"
      />
      <path
        d="M129.686 179.163h-5.453a.743.743 0 0 0 .192-.249l.069-.146h4.44c.238.149.49.28.752.395Z"
        fill="#141220"
      />
      <path
        d="M124.234 179.163h-.418l2.963-6.518a6.08 6.08 0 0 0-.141 1.264l-2.209 4.859h.065l-.069.146a.753.753 0 0 1-.191.249Z"
        fill="#141220"
      />
      <path
        d="m104.043 178.137-1.294-3.118c.24-.006.476-.015.709-.026l.644 3.144h-.059Z"
        fill="#CC5146"
      />
      <path
        d="M104.496 179.321c-.003-.01-.004-.021-.006-.031a.95.95 0 0 0 .032.031h-.026Zm-.338-.906-.115-.278h.059l.056.278Z"
        fill="#141220"
      />
      <path
        d="m103.463 179.341-2.067-4.32a35.5 35.5 0 0 0 1.353-.002l1.294 3.117.115.279.084.407a.929.929 0 0 0 .248.467l.005.032-1.032.02Z"
        fill="#141220"
      />
      <path
        d="M110.433 178.121a.788.788 0 0 0-.017-.11l-.879-4.169c8.016-2.954 8.795-10.653 8.712-14.145l1.192 1.511a22.91 22.91 0 0 0 6.776 10.477l-3.063 6.404-12.721.032Z"
        fill="#CC5146"
      />
      <path
        d="M110.038 179.215a3.11 3.11 0 0 0 .358-.737.963.963 0 0 0 .036-.357l12.721-.032-.084.177a.751.751 0 0 0-.023.596l-.051.103-12.957.25Zm9.402-18.006-1.192-1.511c-.026-1.088-.136-1.768-.136-1.768l.711.818c.169.86.377 1.681.617 2.461Z"
        fill="#141220"
      />
      <path
        d="M123.047 178.862a.751.751 0 0 1 .023-.596l.084-.177 3.063-6.404c.08.071.159.139.237.206l-3.407 6.971Z"
        fill="#141220"
      />
      <path
        d="M165.178 142.541a25.244 25.244 0 0 0-2.857-4.076l4.495-7.096a12.281 12.281 0 0 0 1.868-5.718l.212-4.994 6.583 11.598c.064.114.145.217.24.307l.941.89-11.482 9.089Z"
        fill="#B2474C"
      />
      <path
        d="m176.66 133.452-.942-.89a1.325 1.325 0 0 1-.24-.307l-6.582-11.597.059-1.394.907.226 7.533 13.38-.735.582Z"
        fill="#141220"
      />
      <path
        d="M165.233 142.64a4.827 4.827 0 0 0-.055-.098l11.482-9.089.735-.583.257.456-12.419 9.314Zm-2.912-4.174c-.17-.198-.34-.392-.512-.582l5.7-9.817.376-7.888.455-1.069.616.154-.06 1.394-.212 4.993a12.274 12.274 0 0 1-1.868 5.718l-4.495 7.097Z"
        fill="#141220"
      />
      <path
        d="M164.182 166.064c1.657-3.027 2.764-5.99 3.318-8.879 9.185-7.188 10.12-18.818 10.169-22.616l.093-.074 3.54 3.348.738 15.134-7.85 8.791a7.951 7.951 0 0 1-3.573 2.298l-6.435 1.998Z"
        fill="#CC5146"
      />
      <path
        d="m181.301 137.843-3.539-3.348.68-.538 3.268 2.61.011.152a.75.75 0 0 0-.44.721l.02.403Z"
        fill="#141220"
      />
      <path
        d="M177.669 134.569c.01-.791-.018-1.243-.018-1.243l.791.631-.68.538-.093.074Zm-13.702 31.883c.073-.129.144-.259.215-.388l6.435-1.998a7.951 7.951 0 0 0 3.573-2.298l7.85-8.791-.739-15.134-.02-.403a.752.752 0 0 1 .441-.721l1.007 14.472.048 1.305a.753.753 0 0 0-.631 1.112l-9.009 10.146-1.215.408-7.955 2.29Z"
        fill="#141220"
      />
      <path
        d="M182.146 153.608a.751.751 0 0 1 .631-1.111l.015.384-.646.727Z"
        fill="#141220"
      />
      <path
        d="m193.456 154.981-9.928-2.336-.649-13.311 5.602 5.299a11.822 11.822 0 0 1 2.285 2.984l3.754 6.974-1.064.39Z"
        fill="#B2474C"
      />
      <path
        d="m194.52 154.591-3.754-6.974a11.78 11.78 0 0 0-2.286-2.983l-5.601-5.3-.092-1.871 8.387 7.883 4.284 8.901-.938.344Z"
        fill="#141220"
      />
      <path
        d="m183.528 152.645-.551-.129a.704.704 0 0 0-.157-.02l-.091-1.305-.527-14.279.586.551.091 1.871.649 13.311Z"
        fill="#141220"
      />
      <path
        d="m193.189 155.08-10.384-1.832-.013-.366.051-.058-.023-.327a.765.765 0 0 1 .157.019l.551.13 9.928 2.336-.267.098Z"
        fill="#141220"
      />
      <path
        d="m196.107 155.594-2.918-.515.267-.098 1.065-.39.938-.344.648 1.347Zm-13.33-3.098-.048-1.306.09 1.306-.015-.001-.027.001Zm.015.385-.015-.385h.043l.023.327-.051.058Zm-7.448 25.709h-9.508l-.006-.014a8.053 8.053 0 0 0-.083-.174h9.733l-.114.157-.022.031Z"
        fill="#141220"
      />
      <path
        d="M165.837 178.59h-.769l-.104-.188h.784l.083.174.006.014Zm10.875 0h-1.367a.833.833 0 0 1 .021-.031l.115-.157h1.369l-.138.188Zm15.164-22.685-3.497-.822c.109-.224.166-.346.166-.346l3.475.973c-.013.015-.025.032-.037.048l-.107.147Z"
        fill="#141220"
      />
      <path
        d="m178.78 175.762 13.992-19.645-.896-.211.107-.148.037-.048 1.22.342-14.454 19.71h-.006Zm-1.88 2.64h-.05l.472-.644-.119.218-.303.426Zm-11.936 0h-1.211l-1.612-4.483c.051-.111.099-.224.145-.337l2.678 4.82Z"
        fill="#141220"
      />
      <path
        d="M166.784 174.363c-1.025 0-2.076-.128-3.146-.405l-.909-1.913a7.657 7.657 0 0 0 .026-2.604l9.059-2.813a9.455 9.455 0 0 0 4.248-2.732l8.055-9.022a.748.748 0 0 0 .191-.501l3.961.932c-1.604 3.221-10.075 19.058-21.485 19.058Z"
        fill="#CC5146"
      />
      <path
        d="M162.755 169.441a1.694 1.694 0 0 0-.011-.07l-.114-.7c.147-.229.291-.457.433-.685l7.999-2.485a9.444 9.444 0 0 0 4.249-2.733l7.757-8.687 1.24.291c0 .18-.063.359-.191.502l-8.055 9.022a9.458 9.458 0 0 1-4.248 2.732l-9.059 2.813Z"
        fill="#CC8284"
      />
      <path
        d="M163.639 173.958a13.388 13.388 0 0 1-1.335-.419c.19-.481.334-.98.426-1.493l.909 1.912Zm-.577-5.972c.22-.356.432-.711.637-1.065l8.223-2.759 1.146-.33.069-.078.006-.001 9.251-9.876a.746.746 0 0 0 .238.102l.435.102-7.757 8.688a9.45 9.45 0 0 1-4.249 2.732l-7.999 2.485Z"
        fill="#141220"
      />
      <path
        d="m188.268 155.305-3.961-.932-1.239-.292-.435-.102a.736.736 0 0 1-.238-.102l.155-.166 5.828 1.372a8.83 8.83 0 0 1-.11.222Zm-16.345 8.857 1.215-.408-.069.079-1.146.329Z"
        fill="#141220"
      />
      <path
        d="M175.901 177.825h-10.428l-1.835-3.866c1.07.276 2.12.404 3.146.404 11.41 0 19.88-15.836 21.484-19.058l3.453.813-15.82 21.707Z"
        fill="#A3414F"
      />
      <path
        d="M175.481 178.402h-9.734l-.273-.577h10.428l-.421.577Z"
        fill="#141220"
      />
      <path
        d="M165.747 178.402h-.784l-2.678-4.82.018-.043c.449.166.892.305 1.335.419l1.835 3.867.274.577Zm25.975-22.285-3.453-.812.11-.222 3.497.823-.154.211Z"
        fill="#141220"
      />
      <path
        d="M176.85 178.402h-1.37l.421-.577 15.821-21.708.154-.211.896.21-13.992 19.646c-.125.004-.25.008-.377.01l-1.081 1.986-.472.644Z"
        fill="#141220"
      />
      <path
        d="m226.527 173.087-4.207-1.163-.685-1.107a2.486 2.486 0 0 1-.161-2.313l.896-2.031 4.157 6.614Z"
        fill="#B2474C"
      />
      <path
        d="m228.472 173.63-.144-.029a.794.794 0 0 0-.141-.055l-1.66-.458-4.157-6.614.485-1.101 5.617 8.257Z"
        fill="#141220"
      />
      <path
        d="m228.328 173.601-5.749-1.143a.747.747 0 0 0-.743-.639.777.777 0 0 0-.218.032l-.977-2.863 1.736-4.319.478.704-.485 1.101-.896 2.031a2.487 2.487 0 0 0 .161 2.313l.685 1.107 4.207 1.163 1.659.458c.05.014.097.033.142.055Z"
        fill="#141220"
      />
      <path
        d="m222.58 172.458-.808-.16-.153-.447a.75.75 0 0 1 .961.607Z"
        fill="#141220"
      />
      <path
        d="M229.504 177.825h-8.114l-.67-.905c3.457-.131 4.541-1.708 4.872-2.532l2.121.586 1.791 2.851Z"
        fill="#CC5146"
      />
      <path
        d="M222.17 179.132a1.333 1.333 0 0 0-.243-.582l-.537-.725h8.114l-1.791-2.851.073.021a.752.752 0 0 0 .851-1.1h.1l2.63 4.883-9.197.354Z"
        fill="#141220"
      />
      <path
        d="M227.988 175.022a.772.772 0 0 1-.201-.027l-.073-.021-2.121-.586a2.1 2.1 0 0 0 .14-.493h2.904a.752.752 0 0 1-.649 1.127Zm-6.387 4.132-2.254-2.254c.316.019.617.029.903.029.161 0 .318-.003.47-.009l.67.905.537.726c.129.174.21.374.243.581l-.569.022Z"
        fill="#141220"
      />
      <path
        d="m198.607 176.452.33-.188a8.237 8.237 0 0 0 3.32-3.497l1.85-3.699 7.463-.094-.558-.753-7.657.096-1.849 3.699a8.243 8.243 0 0 1-3.32 3.497l-1.63.924 1.63-.924a8.245 8.245 0 0 0 3.32-3.498l1.849-3.699 7.657-.096 2.213 2.992c-1.243 1.533-5.129 5.07-14.618 5.24Z"
        fill="#CC5146"
      />
      <path
        d="m219.809 178.303-6.096-7.789s-.141.271-.488.698l-2.213-2.992.878-.011a.75.75 0 0 0 .66-.408l7.382 10.5-.123.002Z"
        fill="#141220"
      />
      <path
        d="M197.892 176.458c-.435 0-.879-.007-1.336-.021l1.629-.924a8.237 8.237 0 0 0 3.32-3.497l1.849-3.699 7.657-.096.558.754-7.463.093-1.849 3.699a8.249 8.249 0 0 1-3.32 3.497l-.331.188a41.87 41.87 0 0 1-.714.006Z"
        fill="#CC8284"
      />
      <path
        d="m203.354 168.316.132-.262 8.944-.423.119.169a.748.748 0 0 1-.66.409l-.878.011-7.657.096Z"
        fill="#141220"
      />
      <path
        d="M196.555 176.437c-.36-.012-.729-.028-1.104-.049l4.24-2.873.007-.011.343-.212.748-1.657 2.071-3.552.626-.029-.131.262-1.849 3.699a8.249 8.249 0 0 1-3.32 3.497l-1.631.925Z"
        fill="#141220"
      />
      <path
        d="M190.811 178.018h-10.493l1.896-2.6c10.503-1.77 14.785-9.516 16.045-12.43a4.547 4.547 0 0 0 1.629 2.995l2.091 1.725-1.818 3.636a6.75 6.75 0 0 1-2.716 2.862l-6.431 3.645a.772.772 0 0 0-.203.167Z"
        fill="#CC5146"
      />
      <path
        d="m176.675 178.948.529-.972 1.384-1.945.199-.269c.68-.026 1.339-.074 1.976-.144l-1.749 2.4h11.797a.755.755 0 0 0-.123.766l-14.013.164Z"
        fill="#141220"
      />
      <path
        d="M180.319 178.018h-1.305l1.749-2.4c.497-.054.98-.121 1.451-.2l-1.895 2.6Z"
        fill="#CC8284"
      />
      <path
        d="M180.319 178.018h-1.305 1.305Zm-1.305 0 1.749-2.4-1.749 2.4Z"
        fill="#52529F"
      />
      <path
        d="m201.98 167.708-2.092-1.726a4.553 4.553 0 0 1-1.629-2.995c.3-.693.428-1.113.428-1.113l.612 1.403 1.79 1.961 1.338 1.724a.754.754 0 0 0-.199.249l-.248.497Z"
        fill="#141220"
      />
      <path
        d="M190.688 178.784a.755.755 0 0 1 .326-.933l6.431-3.645a6.741 6.741 0 0 0 2.717-2.862l1.818-3.636.249-.497a.757.757 0 0 1 .198-.248l.165.213.212-.01-2.015 4.47-1.09 1.869-4.626 2.862-.036-.003-.375.256-3.488 2.158-.486.006Zm-12.1-2.752.192-.27h.006l-.198.27Zm-1.385 1.944.119-.218 1.266-1.726-1.385 1.944Zm.118-.218 1.081-1.986c.127-.002.252-.006.378-.011l-.193.27-1.266 1.727Z"
        fill="#141220"
      />
      <path
        d="m199.698 173.504 1.09-1.869-.747 1.657-.343.212Zm-8.925 5.777.209-.143a.751.751 0 0 0 .774.019l.684-.388 27.369-.466.665.851-29.701.127Z"
        fill="#141220"
      />
      <path
        d="M191.385 179.255a.754.754 0 0 1-.404-.117l3.681-2.518.41-.254.379.023-3.526 2.388.515-.008-.685.388a.74.74 0 0 1-.37.098Z"
        fill="#141220"
      />
      <path
        d="M218.262 178.018h-22.42l2.765-1.566c9.489-.17 13.375-3.707 14.618-5.24l5.037 6.806Zm-24.497 0 2.79-1.581h.001l-2.791 1.581Z"
        fill="#A3414F"
      />
      <path
        d="m192.44 178.77 1.325-.752h24.498l-5.038-6.806c.347-.427.488-.697.488-.697l6.096 7.789-27.369.466Z"
        fill="#141220"
      />
      <path
        d="M195.842 178.018h-2.076l2.79-1.581a43.635 43.635 0 0 0 2.051.015l-2.765 1.566Z"
        fill="#A36880"
      />
      <path
        d="m191.925 178.777 3.526-2.388c.375.02.744.036 1.104.048l-2.79 1.581-1.325.751-.515.008Zm2.737-2.157.375-.256.035.002-.41.254Z"
        fill="#141220"
      />
      <path
        d="m203.259 166.815-2.415-1.992a3.128 3.128 0 0 1-.278-.26l-.364-.469a3.058 3.058 0 0 1-.083-3.12l3.223-5.781.098-.037a.985.985 0 0 1 .554-.036l-.009-.037 1.312 5.246c.041.167.115.324.218.463l4.395 5.939-6.651.084Z"
        fill="#B2474C"
      />
      <path
        d="m209.91 166.731-4.395-5.939a1.309 1.309 0 0 1-.218-.462l-1.312-5.247a.963.963 0 0 0-.303-.499l.221-.397 2.297 3.179 5.286 9.346-1.576.019Z"
        fill="#141220"
      />
      <path
        d="m203.342 155.193.339-.61a.969.969 0 0 1 .304.5l.009.037a.956.956 0 0 0-.554.036l-.098.037Z"
        fill="#B27282"
      />
      <path
        d="M203.528 167.131a.75.75 0 0 0-.951-.263l-1.487-1.63-.524-.675c.086.091.179.178.278.26l2.416 1.992 6.65-.084 1.577-.019.022.039-7.981.38Zm-3.326-3.037-1.139-1.469.345-1.712 4.14-7.218.355.492-.221.397-.34.609-3.223 5.782c-.556.998-.5 2.194.083 3.119Z"
        fill="#141220"
      />
      <path
        d="m202.803 167.166.015-.033-.242-.265a.757.757 0 0 1 .951.263l-.724.035Z"
        fill="#141220"
      />
      <path
        d="m202.427 166.962-1.337-1.724 1.487 1.63a.785.785 0 0 0-.15.094Z"
        fill="#141220"
      />
      <path
        d="m202.592 167.176-.165-.213a.783.783 0 0 1 .149-.095l.242.265-.014.033-.212.01Z"
        fill="#141220"
      />
      <path
        d="M97.607 125.582a1.127 1.127 0 0 1-1.096-.863l-7.653-31.711a1.124 1.124 0 0 1 .302-1.065c.204-.201 5.198-4.945 23.706-5.753.139-.01 16.262-1.069 23.698-4.084a3.256 3.256 0 0 1 4.424 2.355l.834 4.124a1.127 1.127 0 0 1-.765 1.298l-1.461.46a1.126 1.126 0 0 1 .511 1.61l-.18.303 1.881.187c.488.049.888.408.991.888l1.943 9.157a3.764 3.764 0 0 1-1.479 3.83l-.556.402.62-.05a2.594 2.594 0 0 1 2.734 2.039l.54 2.547a3.755 3.755 0 0 1-1.826 4.051c-2.712 1.506-8.547 4.033-17.156 4.033a46.18 46.18 0 0 1-3.565-.14 32.043 32.043 0 0 0-.659-.005c-3.303 0-20.027.288-24.934 5.995-.217.252-.53.392-.854.392Z"
        fill="#CB1C4F"
      />
      <path
        d="M137.803 80.737c-.57 0-1.129.109-1.662.325-7.144 2.897-22.684 3.959-23.348 4.003-18.598.817-23.883 5.54-24.425 6.077a2.254 2.254 0 0 0-.605 2.13l7.654 31.711a2.254 2.254 0 0 0 3.9.942c3.556-4.137 15.193-5.604 24.079-5.604.303 0 .51.003.607.004a47.02 47.02 0 0 0 3.617.141c8.864 0 14.896-2.616 17.703-4.175a4.83 4.83 0 0 0 2.38-5.279l-.538-2.537a3.727 3.727 0 0 0-1.995-2.557 4.883 4.883 0 0 0 .674-3.663l-1.942-9.158a2.256 2.256 0 0 0-1.981-1.775l-.528-.053a2.15 2.15 0 0 0-.034-.3l.037-.011a2.254 2.254 0 0 0 1.532-2.596l-.834-4.123a4.387 4.387 0 0 0-4.291-3.502Zm0 2.254c.976 0 1.874.667 2.081 1.694l.834 4.123-6.248 1.971 4.672.596-1.095 1.825 3.65.365 1.943 9.157a2.634 2.634 0 0 1-1.035 2.682l-3.829 2.763 4.642-.374a1.458 1.458 0 0 1 1.542 1.15l.54 2.546a2.606 2.606 0 0 1-1.271 2.832c-2.608 1.448-8.246 3.892-16.609 3.892-1.122 0-2.292-.044-3.511-.14 0 0-.254-.005-.713-.005-3.685 0-20.584.335-25.788 6.387l-7.654-31.711s4.675-4.63 22.962-5.428c0 0 16.377-1.046 24.073-4.165.268-.11.544-.16.814-.16Z"
        fill="#141220"
      />
      <path
        d="M144.959 108.942a1.457 1.457 0 0 0-1.542-1.149l-2.038.164h.001l2.037-.164a1.457 1.457 0 0 1 1.542 1.149Zm-6.392-16.609.574-.958-.574.958Zm.574-.958-1.592-.203 1.592.203Z"
        fill="#FF6EC1"
      />
      <path
        d="m138.567 92.333.574-.958-.574.958Zm.574-.958-1.592-.203 1.592.203Z"
        fill="#66A"
      />
      <path
        d="M93.051 92.41c-.14-.549-.25-.997-.328-1.326 3.087-1.412 9.165-3.287 20.192-3.769 0 0 8.318-.53 15.883-1.965 2.314-.439 4.558-.961 6.471-1.58.012.373.02.828.016 1.355-8.256 2.464-21.619 3.317-21.619 3.317-11.554.505-17.675 2.54-20.615 3.968Zm43.954-9.266.002-.001h-.002Zm.003-.001Zm.036-.014h.001-.001Z"
        fill="#E84977"
      />
      <path
        d="M92.723 91.085c3.087-1.413 9.165-3.287 20.192-3.77 0 0 8.318-.53 15.883-1.964-7.565 1.434-15.883 1.965-15.883 1.965-11.027.482-17.105 2.356-20.192 3.769Z"
        fill="#66A"
      />
      <path
        d="M110.703 119.051c17.845-5.227 22.83-19.18 24.141-28.224l2.704.345-2.328.735 3.346.426-.52.867 3.65.365-2.304-.23-.595.992 3.127.313.592 2.792 1.123 5.29a2.631 2.631 0 0 1-1.035 2.682l-3.829 2.763 2.603-.21-1.851 1.336 4.641-.373a1.447 1.447 0 0 1 .825.184l-.034-.161.539 2.546a2.603 2.603 0 0 1-1.271 2.832c-1.411.784-3.707 1.859-6.844 2.681-2.661.697-5.926 1.211-9.765 1.211-1.121 0-2.291-.044-3.51-.14 0 0-.254-.005-.714-.005-1.884 0-7.225.087-12.691.983Z"
        fill="#AE123F"
      />
      <path
        d="m110.7 119.052.004-.001c5.466-.896 10.807-.983 12.691-.983.46 0 .714.005.714.005 1.218.096 2.389.14 3.51.14 3.839 0 7.104-.514 9.765-1.211-2.662.697-5.926 1.211-9.765 1.211-1.122 0-2.292-.044-3.51-.14 0 0-.253-.005-.711-.005-1.882 0-7.228.088-12.698.984Zm28.076-10.885 3.829-2.763-3.829 2.763Zm3.741-10.735-.592-2.792-.228-1.075.82 3.867Z"
        fill="#141220"
      />
      <path
        d="m139.527 109.293 1.852-1.336 2.038-.164a1.457 1.457 0 0 1 1.542 1.15l.034.161a1.447 1.447 0 0 0-.825-.184l-4.641.373Zm-.961-16.96-3.346-.426 2.328-.735 1.593.203-.575.958Zm3.359 2.307-3.127-.313.595-.993 2.304.23.228 1.075Z"
        fill="#D23261"
      />
      <path d="m141.925 94.64-.228-1.075.228 1.074Z" fill="#52529F" />
      <path
        d="M134.87 90.653c.313-2.216.405-4.126.415-5.527.884-.264 1.71-.546 2.455-.848a2.148 2.148 0 0 1 2.112.28 2.116 2.116 0 0 0-2.05-1.567 2.146 2.146 0 0 0 1.364.49c.355.294.618.706.718 1.203l.554 2.737.28 1.387-4.303 1.358-1.545.487Zm.399-6.883Zm1.719-.62a.296.296 0 0 0 .017-.006l-.017.007Zm.019-.007h.001-.001Zm.019-.007.018-.007-.018.007Zm.019-.007.025-.01-.025.01Z"
        fill="#AE1240"
      />
      <path
        d="m134.87 90.653 1.545-.487-1.545.487Zm5.848-1.845-.28-1.387.28 1.387Zm-5.449-5.038Zm3.897-.289Z"
        fill="#141220"
      />
      <path
        d="M135.285 85.126c.004-.528-.004-.983-.016-1.356a25.039 25.039 0 0 0 1.719-.62l.017-.006.002-.001h.001l.017-.007a.31.31 0 0 0 .019-.007h.001l.025-.01a2.16 2.16 0 0 1 .732-.128c.933 0 1.796.613 2.049 1.568a2.144 2.144 0 0 0-2.111-.28c-.745.3-1.571.583-2.455.847Z"
        fill="#D23261"
      />
      <path
        d="M105.161 178.633 87.185 90.819a1.896 1.896 0 0 1 3.711-.78l18.601 88.166s-.854 2.817-4.336.428Z"
        fill="#E0E0E0"
      />
      <path
        d="M106.003 178.061c.821.507 1.51.698 1.963.54.282-.099.458-.333.55-.496l-18.54-87.872a.956.956 0 0 0-1.872.397l17.899 87.431Zm1.616 2.473c-.895 0-1.896-.377-2.989-1.127a.934.934 0 0 1-.388-.586L86.265 91.007a2.813 2.813 0 0 1 .418-2.142 2.817 2.817 0 0 1 1.817-1.21 2.85 2.85 0 0 1 3.314 2.187l18.602 88.168a.96.96 0 0 1-.02.467c-.044.144-.467 1.425-1.808 1.896-.308.108-.63.161-.969.161Z"
        fill="#141220"
      />
      <path
        d="M104.976 173.039 88.106 90.63a.954.954 0 0 1 1.31-1.07.949.949 0 0 0-.559 1.07l14.607 71.356c.687 4.421 1.213 8.208 1.512 11.053Z"
        fill="#F0F0F0"
      />
      <path
        d="m104.976 173.04-16.87-82.41 16.87 82.409v.001ZM88.449 89.687Z"
        fill="#8080B9"
      />
      <path
        d="M127.909 172.204c-.653 1.407-.285 4.161.749 5.166.689.669 1.578 1.091 2.519 1.28 5.638 1.136 13.245 1.93 25.309-1.134 3.416-.868 5.78-4.099 5.384-7.601a9.322 9.322 0 0 0-.053-.393l-.252-1.548-33.656 4.23Z"
        fill="#C5D3E2"
      />
      <path
        d="M128.612 173.063c-.253 1.164.067 3.018.701 3.634.527.512 1.236.869 2.05 1.033 5.645 1.137 13.017 1.893 24.893-1.124 3.007-.764 5.02-3.595 4.682-6.585a7.627 7.627 0 0 0-.048-.347l-.106-.654-32.172 4.043Zm12.319 7.547c-3.928 0-7.137-.475-9.939-1.038-1.168-.236-2.201-.764-2.988-1.528-1.311-1.274-1.788-4.421-.945-6.235a.934.934 0 0 1 .734-.536l33.655-4.23a.94.94 0 0 1 1.044.781l.252 1.548c.023.142.043.289.06.438.444 3.918-2.173 7.623-6.086 8.617-6.381 1.621-11.511 2.183-15.787 2.183Z"
        fill="#141220"
      />
      <path
        d="M136.384 178.502a50.708 50.708 0 0 1-5.022-.772 4.861 4.861 0 0 1-.408-.101l2.73-2.644c1.852.444 3.724.671 5.575.732l-2.875 2.785Z"
        fill="#DCE5EE"
      />
      <path
        d="M136.384 178.503a50.708 50.708 0 0 0 0-.001v.001Z"
        fill="#66A"
      />
      <path
        d="M130.89 133.884s10.59-6.955 21.967-1.551c0 0 24.826 12.162 8.196 36.681 0 0-19.622 10.988-33.784 2.091-3.299-2.787-8.969-9.375-7.581-20.989 0 0 .607-9.964 11.202-16.232Z"
        fill="#C5D3E2"
      />
      <path
        d="M130.89 133.884h.004-.004Zm-2.955 36.309c12.62 7.858 30.14-.883 32.339-2.04 4.793-7.157 6.398-13.887 4.772-20.008-2.591-9.757-12.584-14.751-12.684-14.801-10.635-5.049-20.754 1.417-20.855 1.483l-.043.027c-9.965 5.895-10.645 15.237-10.651 15.331l-.006.065c-1.305 10.933 3.875 17.172 7.128 19.943Zm12.391 5.542c-4.579.001-9.336-.962-13.657-3.676a1.25 1.25 0 0 1-.127-.093c-3.593-3.036-9.403-9.922-7.976-21.954.056-.79.979-10.713 11.729-17.085.717-.464 11.389-7.148 23.046-1.612.461.225 11.032 5.511 13.884 16.251 1.818 6.846.056 14.274-5.24 22.081a1.112 1.112 0 0 1-.381.35c-.581.325-10.424 5.738-21.278 5.738Z"
        fill="#141220"
      />
      <path
        d="M164.29 160.371h-.381c1.186-3.877 1.319-7.589.386-11.1-2.591-9.757-12.584-14.751-12.684-14.8a19.948 19.948 0 0 0-8.636-1.934c-.254 0-.506.005-.754.014a24.705 24.705 0 0 0-3.18-.631 22.822 22.822 0 0 1 4.705-.503c2.692 0 5.634.511 8.616 1.927.086.043 7.495 3.745 11.213 10.992a19 19 0 0 1 1.718 4.859c.204 1.009.324 2.034.358 3.075.084 2.609-.371 5.315-1.361 8.101Z"
        fill="#E2E9F1"
      />
      <path
        d="M165.651 152.27Zm-2.076-7.934c-3.718-7.247-11.127-10.949-11.213-10.992a19.915 19.915 0 0 0-8.615-1.926c-1.684 0-3.269.2-4.706.502l-.029-.003a22.752 22.752 0 0 1 4.714-.506c2.694 0 5.643.512 8.636 1.933.086.043 7.495 3.745 11.213 10.992Z"
        fill="#8080B9"
      />
      <path
        d="M158.392 155.633h-.03c-.171 0-.341-.011-.508-.035.076-.362.141-.725.196-1.092 2.103-.037 3.793-1.92 3.775-4.215a4.7 4.7 0 0 0-.078-.809c.284.577.448 1.237.453 1.935.009 1.104-.373 2.148-1.075 2.94-.721.815-1.691 1.268-2.733 1.276Z"
        fill="#9EA9CC"
      />
      <path
        d="M161.073 150.296c.016 1.897-1.356 3.446-3.063 3.46-1.706.014-3.103-1.513-3.118-3.409-.016-1.897 1.356-3.446 3.063-3.46 1.707-.013 3.103 1.513 3.118 3.409Z"
        fill="#29B6F6"
      />
      <path
        d="M157.978 147.638h-.016c-.611.006-1.187.279-1.622.771a2.864 2.864 0 0 0-.697 1.931c.012 1.473 1.063 2.664 2.345 2.664h.017c1.289-.01 2.329-1.222 2.317-2.702-.006-.731-.264-1.412-.728-1.919-.438-.48-1.012-.745-1.616-.745Zm.01 6.869c-2.106 0-3.829-1.857-3.847-4.154-.009-1.104.373-2.148 1.074-2.94.722-.815 1.693-1.268 2.734-1.277h.03c1.03 0 1.996.437 2.724 1.232a4.369 4.369 0 0 1 1.122 2.923c.019 2.307-1.689 4.198-3.808 4.216h-.029Z"
        fill="#141220"
      />
      <path
        d="M158.21 152.993a18.701 18.701 0 0 0 .029-1.676c.756-.288 1.378-.894 1.739-1.672.166.31.277.661.321 1.037.014-.125.024-.251.023-.379v-.001a2.996 2.996 0 0 0-.192-1.039v-.002c.123.324.189.677.192 1.041.012 1.4-.919 2.56-2.112 2.691Z"
        fill="#2192DC"
      />
      <path
        d="M158.146 153.751c.027-.251.048-.505.064-.758 1.193-.131 2.123-1.291 2.112-2.691a3.005 3.005 0 0 0-.192-1.041c.122-.367.19-.764.19-1.178.462.595.746 1.366.753 2.213.015 1.847-1.284 3.363-2.927 3.455Z"
        fill="#141220"
      />
      <path
        d="M159.978 149.645c-.412-.776-1.156-1.294-2.002-1.294h-.016l-.048.001a18.22 18.22 0 0 0-.15-.702 1.99 1.99 0 0 1 .026-.003h.008l.005-.001h.003l.003-.001h.007c.049-.004.097-.006.147-.007h.015c.962 0 1.793.669 2.154 1.624a3.632 3.632 0 0 1-.152.383Z"
        fill="#94DBFB"
      />
      <path
        d="M157.788 147.646h.001-.001Zm.006 0h.002-.002Zm.007 0h.003-.003Zm.006-.001h.004-.004Zm.007 0a2.34 2.34 0 0 1 .146-.007h.001c-.05.001-.098.003-.147.007Z"
        fill="#8080B9"
      />
      <path
        d="M160.299 150.683a2.936 2.936 0 0 0-.32-1.038c.057-.123.107-.251.151-.382.121.319.189.671.192 1.04a3.19 3.19 0 0 1-.023.38Z"
        fill="#90C9EE"
      />
      <path
        d="M157.308 151.701c.086 10.62-9.531 19.309-21.48 19.406-11.948.097-21.704-8.435-21.79-19.056-.086-10.621 9.531-19.31 21.479-19.407 11.949-.096 21.705 8.436 21.791 19.057Z"
        fill="#FFA726"
      />
      <path
        d="m135.72 133.582-.195.001c-5.549.045-10.746 2.003-14.632 5.515-3.855 3.483-5.956 8.08-5.917 12.945.04 4.864 2.215 9.428 6.126 12.848 3.897 3.407 9.047 5.277 14.523 5.277l.195-.001c11.412-.092 20.63-8.373 20.548-18.459-.039-4.865-2.215-9.427-6.126-12.847-3.896-3.408-9.047-5.279-14.522-5.279Zm-.095 38.465c-5.931 0-11.52-2.035-15.759-5.742-4.321-3.779-6.724-8.838-6.768-14.247-.043-5.409 2.277-10.506 6.536-14.354 4.228-3.819 9.866-5.95 15.875-5.999l.211-.001c5.931 0 11.52 2.036 15.758 5.742 4.321 3.779 6.725 8.838 6.768 14.247.09 11.121-9.964 20.252-22.411 20.352l-.21.002Z"
        fill="#141220"
      />
      <path
        d="M120.568 150.749a24.4 24.4 0 0 1 .058-.521c.002-.018.004-.036.004-.054.007-.095.693-9.534 10.741-15.478a22.74 22.74 0 0 1 1.858-.979 23.884 23.884 0 0 1 2.296-.133l.195-.001c.635 0 1.264.028 1.888.078-3.872.995-6.424 2.634-6.474 2.668a.657.657 0 0 0-.044.028c-8.105 4.793-10.068 11.866-10.522 14.392Z"
        fill="#FFD393"
      />
      <path
        d="M140.966 163.255c-.293 0-.59-.017-.89-.051-2.386-.276-3.768-1.551-3.768-1.551.704.13 1.37.19 2.001.19 7.402-.001 9.909-8.234 9.909-8.234l.04.144a7.47 7.47 0 0 1 .035 3.766 17.775 17.775 0 0 1-5.962 5.611 7.476 7.476 0 0 1-1.365.125Zm-6.336-14.395a5.19 5.19 0 0 1-.008-10.379 5.19 5.19 0 0 1 .008 10.379Zm-8.364 4.697a2.167 2.167 0 1 1-.004-4.338 2.167 2.167 0 0 1 .004 4.338Z"
        fill="#FFCA7D"
      />
      <path
        d="M135.625 170.168c-3.709 0-7.268-.857-10.39-2.47-.393-.479-.785-1-1.166-1.565a49.78 49.78 0 0 0 4.374.202c6.025-.001 10.528-1.25 13.888-3.205 2.934-.542 5.253-2.794 5.962-5.611 4.563-6.891 3.688-15.565 3.476-17.17 2.947 3.204 4.565 7.17 4.599 11.359.081 10.086-9.137 18.367-20.549 18.46h-.194Z"
        fill="#CC8635"
      />
      <path
        d="M134.779 171.54c-3.639-.168-6.607-1.522-8.675-2.848a19.742 19.742 0 0 1-.869-.994c3.122 1.613 6.681 2.47 10.39 2.47h.194c11.412-.093 20.63-8.374 20.549-18.46-.034-4.189-1.653-8.155-4.599-11.359a8.35 8.35 0 0 0-.037-.262c8.639 4.883 3.232 20.089 3.232 20.089-5.921 7.032-11.395 10.102-16.086 11.045a83.88 83.88 0 0 1-4.099.319Z"
        fill="#141220"
      />
      <path
        d="M142.332 163.13a17.785 17.785 0 0 0 5.962-5.611c-.709 2.817-3.028 5.069-5.962 5.611Z"
        fill="#CCA27B"
      />
      <path
        d="M140.237 173.438c-3.159-.001-6.387-.491-9.431-1.765l.422-.006c1.434.252 2.905.38 4.397.38l.21-.001a24.768 24.768 0 0 0 8.722-1.642c15.702-2.96 19.196-10.032 19.196-10.032h.156c-.775 2.533-2.001 5.136-3.673 7.802-1.636.856-10.519 5.264-19.999 5.264Zm20.504-5.996.002-.004-.002.004Zm.027-.042.009-.014-.009.014Zm.026-.042.012-.018a.14.14 0 0 1-.012.018Zm.028-.042c.003-.007.008-.014.013-.021-.005.007-.01.014-.013.021Zm.026-.041.012-.021-.012.021Zm.026-.042.016-.024-.016.024Zm.027-.042.023-.037-.023.037Zm.026-.041c1.457-2.308 2.579-4.569 3.363-6.778-.784 2.209-1.906 4.471-3.363 6.778Z"
        fill="#8A93C0"
      />
      <path
        d="M142.62 173.85c-7.766 0-13.411-2.168-13.411-2.168h.157c.486 0 .966-.004 1.439-.01 3.045 1.274 6.273 1.764 9.432 1.765 9.479 0 18.363-4.408 19.999-5.265v.001l.038-.02c.159-.238.315-.475.467-.712l.002-.004.025-.038.008-.014a.211.211 0 0 0 .018-.027l.012-.018a.194.194 0 0 1 .015-.024l.013-.021.014-.02.012-.021.014-.021a.213.213 0 0 1 .016-.025l.01-.017.024-.037c0-.002.002-.003.003-.005 1.457-2.307 2.578-4.568 3.363-6.778h.589l-3.756 8.64c-6.398 3.745-12.986 4.839-18.503 4.839Z"
        fill="#141220"
      />
      <path
        d="M160.236 168.174c1.672-2.666 2.898-5.269 3.673-7.802h.382c-.785 2.209-1.907 4.47-3.364 6.778l-.003.004-.023.037-.011.018a.333.333 0 0 1-.016.024c-.004.007-.008.014-.014.021a.113.113 0 0 1-.012.021l-.013.02a.24.24 0 0 0-.013.021c-.006.009-.01.017-.016.024l-.011.018-.018.028-.009.014-.025.038-.002.004c-.152.236-.308.474-.467.711a.66.66 0 0 1-.038.02"
        fill="#9EA3CB"
      />
      <path
        d="m160.236 168.174.038-.021a45.495 45.495 0 0 0 0 .001l-.038.02Zm.507-.736.025-.038-.025.038Zm.034-.052.018-.028-.018.028Zm.029-.046c.006-.007.01-.015.016-.024l-.016.024Zm.029-.045.013-.02-.013.02Zm.025-.041Zm.03-.045.011-.018-.011.018Zm.034-.055.003-.004-.003.004Z"
        fill="#5959A4"
      />
      <path
        d="M135.625 172.047c-1.492 0-2.963-.129-4.396-.381a97.793 97.793 0 0 0 3.551-.126 16.88 16.88 0 0 0 4.099-.32 68.462 68.462 0 0 0 5.678-.816 24.76 24.76 0 0 1-8.932 1.643Z"
        fill="#141220"
      />
      <path
        d="M135.534 171.558c-.255 0-.506-.007-.755-.018a85.106 85.106 0 0 0 4.099-.32 16.948 16.948 0 0 1-3.344.338Z"
        fill="#141220"
      />
      <path
        d="M146.654 178.4c3.197-1.071 3.915-2.999 4.047-4.124 4.936-1.32 8.779-3.169 10.264-3.938.167 2.871-1.809 5.531-4.709 6.268-3.586.911-6.761 1.478-9.602 1.794Z"
        fill="#8A93C0"
      />
      <path
        d="M143.643 179.193c-1.042 0-1.677-.039-1.677-.039 1.97-.076 3.5-.355 4.688-.754 2.841-.316 6.016-.883 9.602-1.794 2.9-.736 4.876-3.396 4.709-6.267.347-.18.566-.301.639-.341.06-.035.118-.074.171-.118l.104.359c-1.068 8.197-13.633 8.954-18.236 8.954Z"
        fill="#141220"
      />
      <path
        d="M150.702 174.276c.062-.53-.006-.882-.006-.882l10.991-3.82.089.305a1.19 1.19 0 0 1-.172.118c-.073.04-.291.161-.639.341-1.484.769-5.328 2.618-10.263 3.938Z"
        fill="#141220"
      />
      <path
        d="m98.866 123.265-6.442-30.534.26-.137c1.596 6.349 6.801 25.143 13.047 27.531-2.671.745-5.109 1.76-6.865 3.14Zm-6.73-31.9Z"
        fill="#AE1240"
      />
      <path
        d="m99.166 124.687-.301-1.423c1.757-1.379 4.194-2.394 6.865-3.139.228.087.456.152.686.194l-7.25 4.368Zm-7.031-33.323-.116-.546.218-.065c.028.124.068.293.118.504a21.06 21.06 0 0 0-.22.107Z"
        fill="#141220"
      />
      <path
        d="m92.424 92.73-.288-1.366.22-.107c.082.342.192.794.329 1.337a11.86 11.86 0 0 0-.26.137Z"
        fill="#D23261"
      />
      <path
        d="m98.458 125.114-7.21-34.063.772-.233.116.546.288 1.367 6.442 30.533.3 1.423-.708.427Z"
        fill="#141220"
      />
      <path
        d="m99.252 125.096-.086-.409 7.25-4.368a3.861 3.861 0 0 1-.685-.194c.242-.068.487-.133.733-.197.189.035.378.053.568.053h.708l-8.488 5.115Zm-6.896-33.84-.119-.503-.217.065-.081-.387.588-.177.046.206c.006.024.057.243.15.625h-.001c-.128.058-.25.115-.366.172Z"
        fill="#141220"
      />
      <path
        d="M92.684 92.594a123.98 123.98 0 0 1-.328-1.337c.117-.057.238-.114.365-.172h.001c.08.328.19.777.329 1.326-.129.062-.25.123-.367.183Z"
        fill="#E84977"
      />
      <path
        d="m98.37 125.628-5.879-26.8-1.704-8.05 1.152-.347.082.387-.773.234 7.21 34.063.709-.428.086.409-.883.532Z"
        fill="#141220"
      />
      <path
        d="m107.95 178.606.003-.001.01-.004c-.353-.083-.76-.262-1.208-.539l-1.997-9.75c-1.357-10.218-4.201-26.251-7.007-41.234l10.765 51.028c-.092.162-.268.397-.549.495l-.017.005Z"
        fill="#BBBDC8"
      />
      <path
        d="M107.097 178.768a10.65 10.65 0 0 1-1.657-.138c.145-.485.173-1.454.101-2.827l.463 2.259c.631.39 1.186.593 1.614.593.122 0 .232-.016.332-.049l.017-.005c.281-.099.457-.333.549-.496l-10.765-51.027a2690.641 2690.641 0 0 0-3.623-18.944l15.325 69.863c-.357.599-1.368.771-2.356.771Z"
        fill="#141220"
      />
      <path
        d="M107.619 178.655c-.429 0-.982-.203-1.615-.594l-.463-2.259c-.096-1.837-.371-4.397-.782-7.49l1.996 9.75c.448.277.855.456 1.208.539l-.009.004-.003.001c-.1.033-.211.049-.332.049Z"
        fill="#C8C9D5"
      />
      <path
        d="M107.618 178.655c-.428 0-.982-.203-1.614-.593l-.463-2.259v-.001l.463 2.259c.633.391 1.186.594 1.615.594a1.057 1.057 0 0 0-.001 0Z"
        fill="#6E70A9"
      />
      <path
        d="m104.758 168.312-1.294-6.326c-2.358-15.182-6.612-37.843-10.059-55.504l4.346 20.596c2.806 14.983 5.65 31.016 7.007 41.234Z"
        fill="#B3B3CA"
      />
      <path
        d="M107.106 179.163c-1.01 0-1.915-.177-1.992-.192l-.36-.072.26-.26c.347-.347.301-2.364-.039-5.599l.566 2.763c.072 1.373.044 2.342-.101 2.827.432.071 1.04.138 1.657.138.987 0 1.999-.172 2.356-.771l-15.325-69.863a2690.641 2690.641 0 0 1 3.623 18.944l-4.346-20.596a2563.1 2563.1 0 0 0-3.427-17.27l.385-.081 2.127 9.697 5.696 26.91.183-.11 11.499 52.424-.031.063c-.421.841-1.637 1.048-2.731 1.048Z"
        fill="#141220"
      />
      <path
        d="m105.541 175.802-.565-2.763c-.3-2.845-.825-6.632-1.512-11.053l1.295 6.325c.411 3.094.686 5.654.782 7.491Z"
        fill="#C0C0D7"
      />
      <path
        d="m105.541 175.803-.565-2.764.565 2.763v.001Z"
        fill="#6666AB"
      />
      <path
        d="m98.186 125.738-5.696-26.91 5.879 26.8-.183.11ZM.382 179.077c4.985-.681 9.97-.844 14.954-.928 2.492-.045 4.984-.055 7.477-.043 2.492-.003 4.984.028 7.476.05 4.985.041 9.97.051 14.953.045H209.73l7.476-.003 3.739-.032 3.738-.054c4.984-.015 9.969.023 14.953.965a.446.446 0 0 1 0 .877c-4.984.94-9.969.98-14.953.964l-3.738-.054-3.739-.032-7.476-.002H45.242a1656.3 1656.3 0 0 0-14.953.043c-2.492.023-4.984.054-7.476.051a321.31 321.31 0 0 1-7.477-.044c-4.985-.083-9.969-.246-14.954-.926a.444.444 0 0 1 0-.877Z"
        fill="#141220"
      />
      <g clipPath="url(#b)">
        <path
          d="m129.834 99.56 2.306-3.104c-5.789-4.302-13.025-5.198-19.391-3.099l4.004 2.976c4.46-.685 9.185.333 13.081 3.228Zm-2.307 3.105a13.534 13.534 0 0 0-5.497-2.411l4.687 3.484.81-1.073Zm-12.73 7.404 4.656 3.46 3.46-4.656c-2.563-1.917-6.201-1.38-8.116 1.196Zm-10.624-19.663-1.626 2.188 4.734 3.518a21.464 21.464 0 0 0-4.901 4.73l3.105 2.307a17.292 17.292 0 0 1 5.086-4.592l3.477 2.584a13.415 13.415 0 0 0-5.459 4.315l3.104 2.306a9.57 9.57 0 0 1 6.204-3.761l10.989 8.166 1.626-2.189-26.339-19.572Z"
          fill="#E1E0E2"
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1={120}
          y1={0.5}
          x2={120}
          y2={180.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#484FF9" stopOpacity={0.42} />
          <stop offset={1} stopColor="#484FF9" stopOpacity={0} />
        </linearGradient>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="rotate(-8.384 644.957 -631.736)"
            d="M0 0h32.816v32.816H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
