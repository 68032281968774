import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function CommunityTracker(
  eventName: string,
  data?,
  additionalProperties?,
) {
  const {
    pathname,
    query,
    community,
    profileData = {},
    creatorProfile = {},
  } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const sourceProperties = {
    source,
    source_action,
  }
  const communityProperties = isEmpty(community)
    ? {}
    : {
        community_id: community.mask_id,
        community_name: community.name,
        community_description: community.description,
        community_category_id: community.category.id,
        community_category: community.category.name,
        community_owner_user_id: community.owner_id,
        community_tagging: community.community_tagging,
        is_owner_community: community.is_owner,
        is_member_community: community.is_member,
        community_is_private: community.is_private,
      }

  const user_type = profileData?.isCreator ? 'creator' : 'supporter'

  const creator_category_id = creatorProfile?.category?.id || ''
  const creator_category = creatorProfile?.category?.name || ''
  const creator_user_id =
    creatorProfile?.creator?.creator_user_id || ''
  const creator_name = creatorProfile?.creator?.name || ''
  const creator_id = creatorProfile?.creator?.creator_id || ''

  const creatorProperties = {
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
  }

  const eventProperties = {
    click_browse_community: {
      state_name,
      user_type,
    },
    click_my_community: {
      state_name,
      modal_name: 'USER_NAVIGATION_MENU',
      user_type,
    },
    visit_my_community: {
      state_name,
      ...sourceProperties,
      user_type,
    },
    select_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
      user_type,
    },
    click_create_community: {
      state_name,
      user_type,
      ...additionalProperties,
    },
    visit_create_community: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
      user_type,
    },
    visit_create_community_member_detail: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    back_to_create_community: {
      state_name,
      ...additionalProperties,
      user_type,
    },
    select_community_category: {
      state_name,
      ...additionalProperties,
    },
    create_community_completed: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    visit_community_profile: {
      state_name,
      ...communityProperties,
      ...sourceProperties,
      ...additionalProperties,
      state_sub_name: 'digital_content',
    },
    click_report_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_share_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_join_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_leave_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_instagram: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_facebook: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_twitter: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_youtube: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_linkedin: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_tiktok: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_view_community_member: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_digital_content_tab: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_event_tab: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    select_content: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    select_event: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_edit_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_create_content: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
      user_type,
    },
    click_create_event: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
      user_type,
    },
    cancel_edit_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    edit_community_completed: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    cancel_leave_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    leave_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    cancel_report_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    submit_report_community_reason: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    report_community_completed: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    cancel_share_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    submit_share_community: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    visit_community_member: {
      state_name,
      ...sourceProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_name_fill_in: {
      state_name,
      ...additionalProperties,
    },
    close_creator_registration_success: {
      state_name,
      modal_name: 'CREATOR_REGISTRATION_SUCCESS',
    },
    click_create_digital_content: {
      state_name,
      modal_name: 'CREATOR_REGISTRATION_SUCCESS',
    },
    click_create_product: {
      state_name,
      modal_name: 'CREATOR_REGISTRATION_SUCCESS',
    },
    click_turn_on_private_community: {
      state_name,
    },
    click_turn_off_private_community: {
      state_name,
    },
    click_view_community_member_request: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    click_community_setting: {
      state_name,
      ...communityProperties,
      ...additionalProperties,
    },
    submit_community_member_request: {
      state_name,
      ...additionalProperties,
      ...communityProperties,
      ...creatorProperties,
    },
    cancel_community_member_request: {
      state_name,
      ...additionalProperties,
      ...communityProperties,
      ...creatorProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
