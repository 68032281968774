import { ImageType } from 'react-images-uploading'

import {
  FormBecomeCreatorValuesProps,
  FormUserValuesProps,
  UserAccount,
} from 'src/@types/user'

import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { CreatorClass } from 'stores/domains/User/UserProfile'

export const getUserProfile = async () => {
  const token = getIdToken()
  if (!token) return {}
  const response = await apiClient({
    url: '/user/v3/profile',
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getUsername = async (token?: string) => {
  if (!token) return {}
  const response = await apiClient({
    url: '/user/v1/profile/username',
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const updateReferralCode = async (code: string) => {
  return await apiClient({
    url: `/user/v1/profile/referrer-code`,
    method: 'PUT',
    data: {
      referrer_code: code,
    },
    token: getIdToken(),
  })
}

export const postRegistration = async (
  payload: Partial<FormUserValuesProps>,
) => {
  return await apiClient({
    url: `/user/v3/profile/register`,
    method: 'POST',
    data: {
      ...payload,
      day: payload?.day || null,
      month: payload?.month || null,
      year: payload?.year || null,
      name: payload?.name?.trim(),
      bio: payload?.bio?.trim(),
    },
    token: getIdToken(),
  })
}

export const postCreateRole = async (
  payload: FormBecomeCreatorValuesProps,
  type: CreatorClass,
) => {
  return await apiClient({
    url: type === 'CREATOR' ? `user/v3/creator` : '/user/v1/business',
    method: 'POST',
    data: {
      ...payload,
      category_id: Number(payload.category_id),
    },
    token: getIdToken(),
  })
}

export const putUserAccount = async (
  payload: Partial<UserAccount>,
) => {
  return await apiClient({
    url: `/user/v3/profile/account`,
    method: 'PUT',
    data: payload,
    token: getIdToken(),
  })
}

export const postRegistrationPresignURL = async (data: ImageType) => {
  const filename = data?.file?.name
  return await apiClient({
    url: `/user/v1/presign-url`,
    method: 'POST',
    token: getIdToken(),
    data: {
      target: 'header_image',
      ext: '.' + filename.substring(filename.lastIndexOf('.') + 1),
    },
  }).catch((error) => {
    return Promise.reject(error)
  })
}
