import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import { sendLog } from 'helpers/log'
import { severityLevelFromString } from '@sentry/utils'
import ISessionLivestreamModel from 'stores/commons/models/SessionLivestream/interface'
import get from 'lodash/get'
import agoraConfig from 'configs/agora'
import authConfig from 'configs/auth'

const SessionLivestream: ISessionLivestreamModel = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isSuccessGetCurrentSupporter: false,
  isSessionStarted: false,
  isConnectionLeave: false,
  isDisconnected: false,
  isUIDBanned: false,
  isShowTroubleShooting: false,
  isShowModalNeedSignUp: false,
  supporterRole: undefined,
  isAudienceDisallowTurnOnCamera: false,
  isAudienceDisallowTurnOnMicrophone: false,
  errorMessage: '',
  moderationRemoteUsers: [],
  isTokenExpired: false,
  broadcasters: [],
  supporterJoinedDateTime: '',
  connectionState: '',
  rtmConnectionState: {
    currentState: 'CONNECTING',
    reason: '',
  },
  rtcConnectionState: {
    currentState: 'CONNECTING',
    prevState: 'DISCONNECTED',
    reason: '',
  },
  participantJoinCount: 0,
  guestJoinCount: 0,
  isShowModalPermissionAccess: false,
  typeModalPermissionAccess: '',
  isUserTippingCoin: false,
  externalLink: '',
  isExternalLinkDrawer: false,
  setShowModalNeedSignUp: action((state, payload) => {
    state.isShowModalNeedSignUp = payload
  }),
  setUserTippingCoin: action((state, payload) => {
    state.isUserTippingCoin = payload
  }),
  isShownModalSessionOption: false,
  isShownModalReport: false,
  setIsShownModalSessionOption: action((state, payload) => {
    state.isShownModalSessionOption = payload
  }),
  setIsShownModalReport: action((state, payload) => {
    state.isShownModalReport = payload
  }),
  setShowModalPermissionAccess: action((state, payload) => {
    state.isShowModalPermissionAccess = payload.show
    state.typeModalPermissionAccess = payload.type
  }),
  setConnectionState: action((state, payload) => {
    state.connectionState = payload
  }),
  setParticipantJoinCount: action((state, payload) => {
    state.participantJoinCount = payload
  }),
  setGuestJoinCount: action((state, payload) => {
    state.guestJoinCount = payload
  }),
  setIsSuccessGetCurrentSupporter: action((state, payload) => {
    state.isSuccessGetCurrentSupporter = payload
  }),
  setSupporterJoinedDateTime: action((state, payload) => {
    state.supporterJoinedDateTime = payload
  }),
  setBroadcasters: action((state, payload) => {
    state.isSuccessGetCurrentSupporter = true
    // @ts-ignore
    state.broadcasters = payload
      .filter(
        (item) => item.uid !== agoraConfig.userIDCreator && item,
      )
      .map((item) => item.user_id)
  }),
  setTokenExpired: action((state, payload) => {
    state.isTokenExpired = payload
  }),
  setModerationRemoteUsers: action((state, payload) => {
    const newModerationRemoteUsers =
      state.moderationRemoteUsers.filter(
        (item) => item.uid !== payload.uid,
      )
    state.moderationRemoteUsers = [
      ...newModerationRemoteUsers,
      payload,
    ]
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setDisconnected: action((state, payload) => {
    state.isDisconnected = payload
  }),
  setSuccess: action((state, payload) => {
    state.isSuccess = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = payload
  }),
  setSessionStarted: action((state, payload) => {
    state.isSessionStarted = payload
  }),
  setUserUIDBanner: action((state, payload) => {
    state.isUIDBanned = payload
  }),
  setConnectionLeave: action((state, payload) => {
    state.isConnectionLeave = payload
  }),
  setConnectionStateChange: thunk((actions, payload) => {
    if (
      (payload.currentState === 'DISCONNECTED' &&
        payload.reason === 'LEAVE') ||
      (payload.currentState === 'RECONNECTING' &&
        payload.reason === 'INTERRUPTED')
    ) {
      actions.setConnectionLeave(true)
    } else if (
      (payload.currentState === 'DISCONNECTED' &&
        payload.reason === 'UID_BANNED') ||
      (payload.currentState === 'ABORTED' &&
        payload.reason === 'REMOTE_LOGIN')
    ) {
      actions.setUserUIDBanner(true)
      sendLog(
        `uid banned, reason: ${payload.reason}`,
        severityLevelFromString('info'),
      )
    } else if (
      payload.currentState === 'DISCONNECTED' &&
      payload.prevState === 'CONNECTING'
    ) {
      actions.setDisconnected(true)
    }
    actions.setConnectionState(payload.currentState)
    actions.setRTMConnectionStateChange(payload)
  }),

  getCurrentSupporterJoined: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/session/v1/live/${sessionID}/participant`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })

        actions.setBroadcasters(
          get(response, 'data.data.broadcasters', []),
        )
      } catch (error) {
        sendLog(error)
      }
    },
  ),
  setShowTroubleShooting: action((state, payload) => {
    state.isShowTroubleShooting = payload
  }),
  setIsExternalLinkDrawer: action((state, payload) => {
    state.isExternalLinkDrawer = payload
  }),
  setExternalLink: action((state, payload) => {
    state.externalLink = payload
  }),
  setRTMConnectionStateChange: action((state, payload) => {
    state.rtmConnectionState = payload
  }),
  setRTCConnectionStateChange: action((state, payload) => {
    state.rtcConnectionState = payload
  }),
  setSupporterRole: action((state, payload) => {
    state.supporterRole = payload
  }),
  setIsAudienceDisallowTurnOnCamera: action((state, payload) => {
    state.isAudienceDisallowTurnOnCamera = payload
  }),
  setIsAudienceDisallowTurnOnMicrophone: action((state, payload) => {
    state.isAudienceDisallowTurnOnMicrophone = payload
  }),
}

export default SessionLivestream
