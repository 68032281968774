import { useState } from 'react'

import LoadingButton from 'components/commons/LoadingButton'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'
import MessageIllustration from 'assets/svg/user/message-illustration.svg'

export interface MessageEmptyStateProps {
  isRoom?: boolean
  onClickCreator?: () => void
}

export default function MessageEmptyState({
  onClickCreator,
  isRoom = false,
}: MessageEmptyStateProps) {
  const [isLoading, setIsLoading] = useState(false)

  const handleOnClickCreator = () => {
    if (onClickCreator) {
      setIsLoading(true)
      onClickCreator()
    }
  }

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="100%"
    >
      <MessageIllustration />
      <Typography
        sx={{
          typography: {
            xs: 'h4',
            md: 'h4Desk',
          },
        }}
      >
        Mari memulai obrolan!
      </Typography>
      <Typography variant="normalRegular">
        {isRoom
          ? 'Pilih pengguna di samping atau cari profil Kreator di TipTip untuk memulai percakapan'
          : 'Cari profil Kreator di TipTip untuk memulai percakapan'}
      </Typography>
      {!isRoom && (
        <LoadingButton
          buttonVariant="filled"
          size="small"
          onClick={handleOnClickCreator}
          loading={isLoading}
        >
          Cari Kreator
        </LoadingButton>
      )}
    </Stack>
  )
}
