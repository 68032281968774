import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function TransactionHistoryTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const { pathname, query, transaction } = data
  const state_name = getStateFromSource(pathname)
  const source = isEmpty(query?.source)
    ? 'unknown'
    : getStateFromSource(String(query?.source))
  const source_action = isEmpty(query?.source_action)
    ? 'unknown'
    : String(query?.source_action)

  const order_created_date = transaction?.payment?.order_date || ''
  const order_expired_date = transaction?.payment?.expiry_date || ''
  const order_status = transaction?.payment?.status || ''
  const order_invoice_id = transaction?.payment?.invoice_id || ''
  const order_subtotal = transaction?.payment?.sub_total || 0
  const order_admin_fee = transaction?.payment?.transaction_fee || 0
  const order_tax_value = transaction?.payment?.tax || 0
  const order_discount = transaction?.payment?.disc_amount || 0
  const order_total_price = transaction?.payment?.total || 0

  const content_id = transaction?.products[0]?.product_uid || ''
  const content_title = transaction?.products[0]?.title || ''
  const content_price_initial = transaction?.products[0]?.price || 0
  const content_price_final =
    transaction?.products[0]?.sale_price || 0
  const content_type =
    transaction?.products[0]?.product_type?.name || ''
  const creator_user_id =
    transaction?.products[0]?.creator?.creator_id || ''
  const creator_name = transaction?.products[0]?.creator?.name || ''
  const creator_id =
    transaction?.products[0]?.creator?.creator_id || ''

  const orderProperties = {
    order_created_date,
    order_expired_date,
    order_status,
    order_invoice_id,
    order_subtotal,
    order_admin_fee,
    order_tax_value,
    order_discount,
    order_total_price,
  }

  const contentProperties = {
    content_id,
    content_title,
    content_price_initial,
    content_price_final,
    content_type,
    creator_user_id,
    creator_name,
    creator_id,
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    click_transaction_history: {
      state_name,
    },
    visit_transaction_history: {
      state_name,
      ...sourceProperties,
    },
    cancel_order: {
      state_name,
      ...orderProperties,
    },
    confirm_order: {
      state_name,
      ...orderProperties,
    },
    click_view_product: {
      state_name,
      ...orderProperties,
      ...contentProperties,
    },
    click_reorder: {
      state_name,
      ...orderProperties,
    },
    click_library: {
      state_name,
    },
    apply_filter_option: {
      state_name,
      ...additionalProperties,
    },
    click_browse_search: {
      state_name,
    },
    click_clear_filter: {
      state_name,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
