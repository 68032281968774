import afterLogout from 'helpers/auth/after-logout'
import redirect from 'helpers/redirect'

/**
 * Do force logout
 *
 * @returns
 */
export const forceLogout = () => {
  try {
    afterLogout(
      () => {
        redirect('/token-expired', { isReplace: true })
      },
      false,
      true,
      false,
    )
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
