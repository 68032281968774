import * as React from 'react'
import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  SvgIcon,
} from '@mui/material'

interface MoreMenuItemProps extends MenuItemProps {
  title: string
  onClick: React.MouseEventHandler<HTMLLIElement>
  icon?: typeof SvgIcon
}

export function MoreMenuItem({
  title,
  onClick,
  icon: Icon,
  sx,
  ...rest
}: MoreMenuItemProps) {
  return (
    <MenuItem
      onClick={onClick}
      sx={(theme) => ({
        ':hover': {
          backgroundColor: theme.palette.background.secondary,
        },
        ...sx,
      })}
      {...rest}
    >
      {Icon && (
        <ListItemIcon>
          <Icon fontSize="small" color="secondary" />
        </ListItemIcon>
      )}
      {title}
    </MenuItem>
  )
}
