import {
  TextField,
  InputAdornment,
  IconButton,
  Drawer,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { Search, ArrowBackIos } from '@mui/icons-material'
import Stack from 'components/commons/Stack'
import {
  DrawerContentContainer,
  DrawerHeader,
} from 'containers/commons/SearchPage/v2/styled'
import Chip from 'components/commons/Chip'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useLocationStore } from 'stores/domains/Social'
import { useFilterProvinceList } from 'hooks/domains/Social'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import Typography from 'components/commons/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import { useTranslation } from 'react-i18next'
import StateImage from 'components/commons/StateImage'
import Attention from 'components/commons/Attention/v2'
import { ButtonRectangle } from 'components/commons/Attention/v2/styled'
import { defaultLocation } from 'constants/common'

export const StyleContainer = styled(Drawer)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  '& .MuiPaper-root': {
    background: theme.palette.background.primary,
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const ProvinceWrapper = styled('div')({
  '>div': {
    margin: '6px 4px',
  },
})

interface ISearchBarWithBackBtn {
  showPage?: boolean
  onCloseAction?: Function
  statusPage?: string
}

export default function SearchBarWithBackBtn({
  showPage,
  onCloseAction,
}: ISearchBarWithBackBtn): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isReady } = useRouter()
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const {
    data: filterProvinceList,
    refetch: getfilterProvinceList,
    isError,
  } = useFilterProvinceList('', 1, 10)

  const { setSelectedFilterProvince, selectedFilterProvince } =
    useLocationStore((state) => ({
      setSelectedFilterProvince: state.setSelectedFilterProvince,
      selectedFilterProvince: state.selectedFilterProvince,
    }))

  const filteredProvince = filterProvinceList?.filter((province) =>
    province?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const handleClick = (item) => {
    if (selectedFilterProvince.id === item.id) {
      setSelectedFilterProvince({
        name: '',
        id: null,
        thumbnail_image: '',
      })
    } else {
      setSelectedFilterProvince(item)
    }
    onCloseAction()
    setSearchTerm('')
  }

  const handleClear = () => {
    setSearchTerm('')
  }

  function proviceListWithChip() {
    return (
      <ProvinceWrapper>
        <Chip
          label={t('allLocation')}
          avatar={
            <LocationOnIcon
              sx={{
                color:
                  selectedFilterProvince.id === null
                    ? 'secondary'
                    : `${theme.palette.primary.main} !important`,
                backgroundColor: 'unset !important',
              }}
            />
          }
          sx={{
            padding: '8px',
          }}
          color={
            selectedFilterProvince.id === null ? 'primary' : 'default'
          }
          onClick={() => {
            handleClick(defaultLocation)
          }}
        />
        {filterProvinceList?.map((item) => (
          <Chip
            id={`b-location-province-${item.name}`}
            label={item?.name}
            key={item.id}
            sx={{
              padding: '8px',
            }}
            color={
              selectedFilterProvince.id === item.id
                ? 'primary'
                : 'default'
            }
            onClick={() => {
              handleClick(item)
            }}
          />
        ))}
      </ProvinceWrapper>
    )
  }

  function filterProvinceListVertical() {
    return (
      <Stack gap={2}>
        {filteredProvince?.map((item) => (
          <Stack
            direction={'row'}
            gap={1.5}
            key={`search-province-${item.id}`}
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              padding: '13px 8px',
              borderRadius: `12px`,
              background: theme.palette.background.secondary,
            }}
            onClick={() => {
              handleClick(item)
            }}
          >
            <LocationCityIcon color={'secondary'} />
            <Typography>{t(item?.name)}</Typography>
          </Stack>
        ))}
        {filteredProvince?.length === 0 && <EmptyComponent />}
      </Stack>
    )
  }

  const EmptyComponent = () => {
    return (
      <Attention
        sx={{ padding: { xs: '32px 0px' } }}
        title={t('keyCouldntFindLocation')}
        subtitle={t('keyTheLocationYouAre')}
        image={<StateImage type="empty-search" />}
      />
    )
  }

  const ErrorComponent = () => {
    return (
      <Attention
        sx={{ padding: { xs: '32px 0px' } }}
        title={t('otherApiErrorTitle')}
        subtitle={t('otherApiErrorSubtitle')}
        image={<StateImage type="system-error" />}
        actions={[
          <ButtonRectangle
            key={1}
            onClick={() => getfilterProvinceList()}
          >
            {t('retry')}
          </ButtonRectangle>,
        ]}
      />
    )
  }

  useEffect(() => {
    if (isReady) {
      setSearchTerm('')
      getfilterProvinceList()
      setIsOpenSearch(false)
    }
  }, [isReady])

  useEffect(() => {
    if (searchTerm !== '') {
      setIsOpenSearch(true)
    } else {
      setIsOpenSearch(false)
    }
  }, [searchTerm])

  return (
    <StyleContainer
      anchor="bottom"
      open={showPage}
      PaperProps={{
        sx: {
          width: isDesktop ? '100vw' : '480px',
          height: '100vh',
          margin: '0 auto',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          background: theme.palette.background.secondary,
        },
      }}
      onClose={() => {
        onCloseAction(), setSearchTerm('')
      }}
      id="p-search-province"
    >
      <Stack
        justifyContent="flex-start"
        alignItems="start"
        spacing={2}
      >
        <DrawerHeader
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: `16px`,
          }}
        >
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              onCloseAction(), setSearchTerm('')
            }}
          >
            <ArrowBackIos color="secondary" />
          </IconButton>
          {/* Search Bar */}
          <TextField
            placeholder={t('searchHintFilterLocations')}
            variant="outlined"
            fullWidth
            autoComplete="off"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={handleClear}>
                      <CancelIcon color={'secondary'} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                backgroundColor: theme.palette.grey[200],
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </DrawerHeader>
        <DrawerContentContainer
          sx={{
            marginTop: '16px',
            width: '100vw',
            padding: '0px 16px',
          }}
        >
          {!isError ? (
            !isOpenSearch ? (
              proviceListWithChip()
            ) : (
              filterProvinceListVertical()
            )
          ) : (
            <ErrorComponent />
          )}
        </DrawerContentContainer>
      </Stack>
    </StyleContainer>
  )
}
