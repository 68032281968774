import Box from 'components/commons/Box'
import { styled } from '@mui/material/styles'

interface IImageProps {
  src: string
  loading: string
}
export const StyledHexagonContainer = styled(Box)<IImageProps>(
  ({ src, loading, theme }) => ({
    height: 72,
    width: 72,
    '&::before': {
      display: 'block',
      background:
        loading === 'true'
          ? theme.palette.text.primary
          : `url(${src}) center/cover`,
      paddingTop: '86.6%',
      content: '""',
      WebkitClipPath:
        'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
      clipPath:
        'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
    },
  }),
)
