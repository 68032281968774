import { SxProps, styled } from '@mui/material'
import StateImage from 'components/commons/StateImage'
import ActionButton from '../Button/Action'
import Box from 'components/commons/Box'
import {
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import Stack from '../Stack'
import { useTranslation } from 'react-i18next'
import VoucherErrorContent from 'components/domains/Wallet/VoucherError'
import { getIdToken } from 'helpers/auth'

const StyledStateImage = ({ type }) => (
  <StateImage
    type={type}
    sx={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '24px',
    }}
  />
)

const ContainerBtn = styled(Stack)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  marginTop: '24px',
  justifyContent: 'space-between',
})

export default function ErrorResponseCode({
  code,
  showCTA,
  onCTA,
  data,
  sxContainer,
  disabled,
}: {
  code?: string
  showCTA?: boolean
  onCTA?: Function | any
  data?: any
  sxContainer?: SxProps
  disabled?: boolean
}) {
  const { t } = useTranslation()
  switch (code) {
    case 'INSUFFICIENT_QUOTA':
      return (
        <Box sx={sxContainer}>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>
            {t('eventTicketSelectionSoldOutTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSoldOutDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventTicketSelectionSeeOtherEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'PRODUCT_UNAVAILABLE':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventUnpublishedButtonLabel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'SALE_NOT_STARTED':
      return (
        <Box>
          <StyledStateImage type="date-time" />
          <ErrorLabel>
            {t('eventDetailsErrorSaleNotStartedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsErrorSaleNotStartedSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'USER_OVERBOOKED':
      return (
        <Box>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>{t('eventUserOverbookedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUserOverbookedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INVALID_SALES_PERIOD':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>
            {t('eventTicketSelectionSalesEndedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSalesEndedDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'ID_NUMBER_ALREADY_USED':
      return (
        <Box>
          <StyledStateImage type="user-alert" />
          <ErrorLabel>
            {t('key_duplicate_id_number_title')}
          </ErrorLabel>
          <ErrorDesc>
            {t('key_duplicate_id_number_desc', {
              id_number: data?.id_card?.join(', '),
            })}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                {t('contactCs')}
              </ActionButton>
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('key_too_many_request_share_cta')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'NOT_ENOUGH_QUOTA':
      return (
        <Box>
          <StateImage
            type="ticket-not-available"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('eventDetailsErrorQuantityExceedsQuotaTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsQuantityExceedsQuotaSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('refresh')}
              >
                {t('refresh')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'FAIL':
      return (
        <Box>
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('paymentWidgetErrorWithRetryTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetErrorWithRetryDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INSUFFICIENT_BALANCE':
      return (
        <Box>
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('paymentWidgetInsufficientCoinBalanceTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetInsufficientCoinBalanceDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('back')}
              >
                {t('back')}
              </ActionButton>
              <ActionButton fullWidth onClick={() => onCTA('topup')}>
                {t('topUpCoinButtonTitle')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BAD_REQUEST_COIN':
      return (
        <Box>
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>{t('topUpCoinExceededLimitTitle')}</ErrorLabel>
          <ErrorDesc sx={{ textTransform: 'capitalize' }}>
            {data}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('back')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'NOT_FOUND':
      return (
        <Box>
          <StateImage
            type="no-respond"
            sx={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '16px',
            }}
          />
          <ErrorLabel>
            {t('vaPaymentInstructionWidgetCanceledTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetCanceledDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'PRODUCT_UNAVAILABLE':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="calendar"
          />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('eventUnpublishedButtonLabel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'SESSION_FULLY_BOOKED':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="unhappy_booking"
          />
          <ErrorLabel>
            {t('sessionFullyBookedWidgetTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('purchaseTicketBottomSheetSessionFullyBookedSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('refresh')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'GUEST_PENDING_TRANSACTION':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="guest_pending"
          />
          <ErrorLabel>{t('key_previous_order_not_paid')}</ErrorLabel>
          <ErrorDesc>
            {data?.body || t('vaPaymentInstructionWidgetErrorDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {!getIdToken() && (
                <ActionButton
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => onCTA('cancel')}
                >
                  {t('transactionHistoryScreenCancelBtn')}
                </ActionButton>
              )}
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('continue')}
              >
                {t(
                  'guestPurchasePendingPaymentCompletePaymentButton',
                )}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INVALID_VOUCHER':
      return (
        <Box>
          <VoucherErrorContent />
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('understand')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BOOKING_EXPIRED':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="no-respond"
          />
          <ErrorLabel>{t('key_time_is_up_title')}</ErrorLabel>
          <ErrorDesc>{t('key_time_is_up_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('select_ticket')}
              >
                {t('eventTicketSelectTicketButton')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'EDIT_CONFIRMATION':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="user-alert"
          />
          <ErrorLabel>
            {t('key_change_contact_info_title')}
          </ErrorLabel>
          <ErrorDesc>{t('key_change_contact_info_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => {
                    onCTA('continue_booking')
                  }}
                >
                  {t('key_cancel')}
                </ActionButton>
              }
              <ActionButton
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('edit_booking')}
              >
                {t('key_change_contact_info_cta')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'CANCEL_CONFIRMATION':
      return (
        <Box>
          <StateImage
            id="c-cancel-confirmation-error-modal-img"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="ticket-not-available"
          />
          <ErrorLabel>
            {t('key_order_will_canceled_title')}
          </ErrorLabel>
          <ErrorDesc>{t('key_order_will_canceled_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  id="b-cancel-booking-error-modal"
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => {
                    onCTA('cancel_booking')
                  }}
                >
                  {t('transactionHistoryScreenCancelBtn')}
                </ActionButton>
              }
              <ActionButton
                id="b-continue-booking-error-modal"
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('continue_booking')}
              >
                {t('key_continue_order_btn')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'BOOKING_ONGOING':
      return (
        <Box>
          <StateImage
            id="c-ongoing-booking-error-modal-img"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="ticket-not-available"
          />
          <ErrorLabel>{t('key_pending_order')}</ErrorLabel>
          <ErrorDesc>{t('key_pending_order_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  id="b-create-new-booking-error-modal"
                  disabled={disabled}
                  fullWidth
                  buttonVariant="outlined"
                  onClick={() => onCTA('create_new_booking')}
                >
                  {t('key_create_new_order')}
                </ActionButton>
              }
              <ActionButton
                id="b-finish-booking-error-modal"
                disabled={disabled}
                fullWidth
                onClick={() => onCTA('finish_booking')}
              >
                {t('key_complete_Order')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'CHECKOUT_INVALID_STATUS':
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            type="session-almost-full"
          />
          <ErrorLabel>{t('key_order_invalid')}</ErrorLabel>
          <ErrorDesc>{t('key_order_invalid_desc')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              {
                <ActionButton
                  disabled={disabled}
                  fullWidth
                  onClick={() => onCTA('reorder')}
                >
                  {t('key_reorder_btn')}
                </ActionButton>
              }
            </ContainerBtn>
          )}
        </Box>
      )
    default:
      return (
        <Box>
          <StyledStateImage type={''} />
          <ErrorLabel>{t('otherApiErrorTitle')}</ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetErrorDesc')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
  }
}
