import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISessionExtendModel from 'stores/domains/Creator/SessionExtend/interface'

const SessionExtend: ISessionExtendModel = {
  isError: false,
  isExtendedEarly: false,
  isLoading: false,
  isExtended: false,
  extendedMinutes: 0,
  errorMessage: '',
  setIsExtendedEarly: action((state, payload) => {
    state.isError = true
    state.isLoading = false
    state.isExtendedEarly = true
    state.errorMessage = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isExtendedEarly = false
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
    state.isExtended = false
  }),
  setExtended: action((state, payload) => {
    state.isError = false
    state.errorMessage = ''
    state.isExtended = payload.is_extend
    state.extendedMinutes = payload.extended_minutes
  }),
  setSessionExtend: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/session/v1/live/${sessionID}/extend`,
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setLoading(false)
        actions.setExtended(get(response, 'data.data', {}))
      } catch (error) {
        if (
          get(error, 'response.data.code', '') ===
          'FAIL_UNABLE_EXTEND_SESSION_EARLY'
        ) {
          actions.setIsExtendedEarly(
            get(error, 'response.data.message', 'unknown error'),
          )
        } else {
          actions.setError(
            get(error, 'response.data.message', error?.message),
          )
        }
      }
    },
  ),
}

export default SessionExtend
