import apiClient from 'helpers/api-client'

export const getCreatorProfile = async (
  username: string,
  token?: string,
) => {
  const url = token
    ? `/user/v1/creator/${username}/profile`
    : `user/guest/v1/creator/${username}/profile`

  const response = await apiClient({
    url,
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getCreatorRating = async (username: string) => {
  const response = await apiClient({
    url: `rating/guest/v1/creator/${username}/overall`,
    method: 'GET',
  })
  return response?.data
}
