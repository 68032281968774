import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'
import { useCategoryStore } from 'stores/domains/User'
import * as service from 'services/user'

export const useCategory = () => {
  const { setCategory } = useCategoryStore((state) => ({
    setCategory: state.setCategory,
  }))

  return useQuery({
    queryKey: ['category'],
    queryFn: async () => {
      try {
        const response = await service.getCategories()
        return get(response, 'data.data', [])
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setCategory(data)
    },
    refetchOnWindowFocus: false,
  })
}
