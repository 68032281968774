import { ReactNode } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  Breakpoint,
  FormControl,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material'
import Typography from 'components/commons/CustomTypography'
import Stack from 'components/commons/Stack'
import { StyledInputLabel } from '../styled'

type IProps = {
  name: string
  info?: string
  action?: ReactNode
  readOnly?: boolean
  mobileIn?: Breakpoint
}

export type Props = IProps & TextFieldProps

export default function RHFTextField({
  name,
  label,
  info,
  id,
  action,
  readOnly = false,
  required,
  ...other
}: Props) {
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message) return <>{message}</>
  }

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <StyledInputLabel
          sx={{ position: 'relative', marginBottom: '4px' }}
          shrink
          htmlFor={id}
          required={required}
        >
          {label}
        </StyledInputLabel>
      )}
      {info && (
        <Typography
          as="span"
          size="xs"
          sx={(theme) => ({
            position: 'absolute',
            right: 0,
            top: 0,
            color: theme.palette.text.secondary,
          })}
        >
          {info}
        </Typography>
      )}
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) =>
          action ? (
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={0.5}
            >
              <StyledTextField
                {...field}
                id={id}
                fullWidth
                error={!!error}
                helperText={renderErrorText(error?.message)}
                noLabel={!Boolean(label)}
                InputProps={{
                  readOnly,
                }}
                required={required}
                {...other}
              />
              {action}
            </Stack>
          ) : (
            <StyledTextField
              {...field}
              id={id}
              fullWidth
              error={!!error}
              helperText={renderErrorText(error?.message)}
              noLabel={!Boolean(label)}
              InputProps={{
                readOnly,
              }}
              required={required}
              {...other}
            />
          )
        }
      />
    </FormControl>
  )
}

export const StyledTextField = styled(TextField)<{
  noLabel?: boolean
}>(({ theme, noLabel = false }) => ({
  '& .MuiInput-root': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiery,
    borderRadius: 8,
    border: `none`,
  },
  '& .MuiInputAdornment-root': {
    width: 20,
  },
  input: {
    fontSize: 14,
    padding: '12px',
    backgroundColor: theme.palette.background.tertiery,
    borderRadius: 8,

    '&[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.tertiery,
    margin: noLabel && 0,

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    ':hover': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.error.main,
      borderWidth: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: 1,
    },
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: `${theme.palette.text.primary} !important`,
  },
}))
