import { getUrlWithProtocol } from 'helpers/url'

export function urlify(text: string) {
  const patternTiptipUrls =
    /(https?:\/\/([\w\d]+\.)?([go\.]+)?tiptip\.id.*)|(www\.([go\.]+)?tiptip\.id)|(([go\.]+)?tiptip\.id)/g
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const currentUrl = window.location.href

  if (patternTiptipUrls.test(text)) {
    return text.replace(patternTiptipUrls, (url) => {
      const targetUrl = `${currentUrl}&redirect=${getUrlWithProtocol(
        url,
      )}&external=false`
      return '<a href="' + targetUrl + '">' + url + '</a>'
    })
  } else {
    return text.replace(urlRegex, (url) => {
      const targetUrl = `${currentUrl}&redirect=${url}&external=true`
      return '<a href="' + targetUrl + '">' + url + '</a>'
    })
  }
}
