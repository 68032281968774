import { SxProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

interface INewFeatureBoxProps {
  sxContainer?: SxProps
}

export const NewFeatureBox = ({
  sxContainer,
}: INewFeatureBoxProps) => {
  const theme = useTheme()
  return (
    <Typography
      sx={{
        ...theme.typography.smallBold,
        ...sxContainer,
        background: theme.palette.info.main,
        borderRadius: '4px',
        color: theme.palette.text.primary,
        padding: '2px 6px',
      }}
    >
      NEW
    </Typography>
  )
}
