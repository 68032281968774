import { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  ButtonProps,
} from '@mui/material'
import Button from 'components/commons/Button'
import Grid from 'components/commons/Grid'

type IProps = {
  name: string
  options: {
    label: string
    value: any
    icon?: string | JSX.Element
  }[]
  limit?: number
}

type Props = IProps & Omit<ButtonProps, 'size'>

export default function RHFButtonGroup({
  name,
  options,
  limit,
  ...other
}: Props) {
  const { control } = useFormContext()
  const [isCollapsed, setIsCollapsed] = useState(!!limit)

  const handleCollapse = () => {
    setIsCollapsed((value) => !value)
  }

  const getLimit = (): number | undefined => {
    if (!limit) return undefined
    if (!isCollapsed) return options.length
    return limit
  }

  const renderErrorText = (message: string | undefined) => {
    if (message) {
      return <FormHelperText error>{message}</FormHelperText>
    }
  }

  return (
    <FormControl variant="standard" fullWidth>
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) => (
          <>
            <Grid container spacing={1.5} py={2}>
              {options.slice(0, getLimit()).map((option) => (
                <Grid xs={12} md={4} item key={option.value}>
                  <Button
                    key={option.value}
                    {...field}
                    buttonVariant="ghost"
                    buttonType="primary"
                    className={
                      field.value === option.value && 'active'
                    }
                    sx={({ palette, typography }) => ({
                      ...typography.smallRegular,
                      color: palette.text.primary,
                      bgcolor: palette.background.tertiery,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      columnGap: '8px',
                      ':active, &.active': {
                        border: `1px solid ${palette.tiptip[500]}`,
                        bgcolor: palette.action.ghostPressed,
                      },
                    })}
                    onClick={(e) => {
                      e.preventDefault()
                      field.onChange(option.value)
                    }}
                    fullWidth
                    {...other}
                  >
                    {option.icon && <span>{option.icon}</span>}
                    {option.label}
                  </Button>
                </Grid>
              ))}
              {!!limit && (
                <Button
                  buttonVariant="nude"
                  buttonType="primary"
                  onClick={handleCollapse}
                  sx={{
                    marginTop: 1,
                    marginLeft: 0.5,
                    fontWeight: 400,
                  }}
                >
                  {isCollapsed ? 'Lihat selengkapnya' : 'Sembunyikan'}
                </Button>
              )}
            </Grid>
            {renderErrorText(error?.message)}
          </>
        )}
      />
    </FormControl>
  )
}
