import { action } from 'easy-peasy'
import IStreamRTM from 'stores/commons/models/StreamRTM/interface'

const StreamRTM: IStreamRTM = {
  isError: false,
  isLoading: false,
  isLogin: false,
  errorMessage: '',
  participants: [],
  clientRTM: null,
  channelRTM: null,
  chatMessages: [],
  requestRaisehandLoading: false,
  isEnableLeaveSession: false,
  sessionEnded: {
    is_session_ended: false,
    kick_time_out: 0,
  },
  sessionExtend: {
    is_session_extended: false,
    duration_extend: 0,
  },
  sessionAutoCanceled: false,

  setLogin: action((state, payload) => {
    state.isLogin = payload
  }),
  setSessionAutoCanceled: action((state, payload) => {
    state.sessionAutoCanceled = payload
  }),
  setSessionEnded: action((state, payload) => {
    state.sessionEnded = {
      is_session_ended: payload.is_session_ended,
      kick_time_out: payload.kick_time_out,
    }
  }),
  setSessionExtend: action((state, payload) => {
    state.sessionExtend = {
      is_session_extended: payload.is_session_extended,
      duration_extend: payload.duration_extend,
    }
  }),
  setEnableLeaveSession: action((state, payload) => {
    state.isEnableLeaveSession = payload
  }),
  setRequestRaisehandLoading: action((state, payload) => {
    state.requestRaisehandLoading = payload
  }),
  setClientRTM: action((state, payload) => {
    state.clientRTM = payload
  }),
  setChannelRTM: action((state, payload) => {
    state.channelRTM = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setParticipantMembers: action((state, payload) => {
    state.participants = payload
  }),
  appendChatMessage: action((state, payload) => {
    state.chatMessages = [...state.chatMessages, payload]
  }),
  sendMessageToChannel: action((state, payload) => {
    ;(async () => {
      await state.channelRTM?.sendMessage(payload.message)
    })()
  }),
  sendMessageToPeer: action((state, payload) => {
    ;(async () => {
      await state.clientRTM?.sendMessageToPeer(
        payload.message,
        payload.peerId,
      )
    })()
  }),
  clearChatMessages: action((state) => {
    state.chatMessages = []
  }),
}

export default StreamRTM
