import { useInfiniteQuery } from '@tanstack/react-query'
import { getListCommunities } from 'services/social'
import { useCommunityStore } from 'stores/domains/Social'
import get from 'lodash/get'

import { getErrorMessage } from 'helpers/error'

interface IListCommunityParams {
  lmc?: number
  lfc?: number
}

/**
 * @param {number} lmc limit for my community
 * @param {number} lfc limit for followed community
 */

export const useListCommunity = ({
  lmc = 1,
  lfc = 5,
}: IListCommunityParams) => {
  const { setCommunityData, metadata } = useCommunityStore(
    (state) => ({
      setCommunityData: state.setCommunityData,
      metadata: state.metadata,
    }),
  )
  return useInfiniteQuery({
    queryKey: ['community-list', lmc, lfc],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getListCommunities({
        pmc: 1,
        pfc: pageParam,
        lmc,
        lfc,
      })
      return {
        data: get(response, 'data.data', []),
        metadata: get(response, 'data._metadata', {}),
      }
    },
    enabled: false,
    retry: false,
    getNextPageParam: () => {
      return metadata?.page_followed_community + 1
    },
    onSuccess: (data) => {
      return data?.pages?.map((item) => setCommunityData(item))
    },
    onError: (err) => Error(getErrorMessage(err)),
    useErrorBoundary: (error: any) => error.response?.status === 500,
  })
}
