import { useQuery } from '@tanstack/react-query'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'
import * as service from 'services/subscription'

export const useCreatorSubscription = (
  creatorId: string,
  pt: string,
) => {
  return useQuery({
    queryKey: ['creator-subscription', creatorId, pt],
    queryFn: async () => {
      try {
        const response = await service.getCreatorSubscriptionList(
          creatorId,
          pt,
        )
        return response?.data
      } catch (error) {
        throw error
      }
    },
    enabled: !!creatorId,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCreatorUserSubscription = (username: string) => {
  const token = getIdToken()
  return useQuery({
    queryKey: ['creator-user-subscription', username, token],
    queryFn: async () => {
      if (isGuestMode()) return {}
      const response = await service.getIsUserSubscribedToCreator(
        username,
        token,
      )
      return response?.data
    },
    enabled: !!username && !!token,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
