import redirect from 'helpers/redirect'
import Router from 'next/router'
import appConfig from 'configs/app'
import {
  destroySessionStorage,
  getSessionStorage,
} from 'helpers/session-storage'
import { getLocalStorage } from 'helpers/local-storage'
import { loggedInSSO } from 'constants/no-back-redirect'

// Declarations
export type IWindowTarget = '_blank' | '_parent' | '_self' | '_top'

const isSafeUrlToRedirect = (ref: string): boolean => {
  // If path begin with `//`
  if (ref.indexOf('//') === 0) return false

  // If path begin with `/`
  if (ref.indexOf('/') === 0) return true

  // If path begin with encoded `/`
  if (ref.indexOf('%2F') === 0) return true

  return false
}

const redirectToHome = (shouldRedirect: boolean): string => {
  if (shouldRedirect) redirectWithCheckingSource('/')
  return '/'
}

/**
 * Redirect to a target page along with the `ref` query string
 *
 * @param  {string} [target="/"] - Target URL to redirect
 * @param {boolean} useCurrentAsReferrer - Should we use the current page as referrer
 * @param  {boolean} [shouldRedirect=true] - Should it redirect or return the new URL
 * @param  {boolean} [isReplace=false] - Should the redirection replace or be pushed in the router history
 * @returns {string} Return the new URL only if the `shouldRedirect` is set to `false`, otherwise it will return empty string
 */
const redirectWithReferrer = (
  target: string = '/',
  useCurrentAsReferrer: boolean = true,
  shouldRedirect: boolean = true,
  isReplace: boolean = false,
): string => {
  if (!isSafeUrlToRedirect(target)) {
    return redirectToHome(shouldRedirect)
  }

  let referrer = ''
  let qs = ''

  if (useCurrentAsReferrer) {
    const { asPath } = Router
    referrer = encodeURIComponent(asPath ? asPath : '')
    qs = asPath ? '?ref=' + referrer : ''
  } else {
    const { query } = Router
    const ref = query.ref ?? '/'
    referrer = encodeURIComponent(
      ref.toString() ? ref.toString() : '',
    )
    const includesQuery = target.includes('?')
    qs = ref.toString()
      ? `${includesQuery ? '&' : '?'}ref=` + referrer
      : ''
  }

  if (!isSafeUrlToRedirect(referrer)) {
    return redirectToHome(shouldRedirect)
  }

  const newTarget = `${target}${qs}`

  if (shouldRedirect) {
    redirect(newTarget, { isReplace })
    return ''
  }

  return newTarget
}

/**
 * Redirect to the referrer URL taken from the `ref` query string
 *
 * @param  {string} [target="/"] - Target URL to redirect if the referrer is not available
 * @param  {boolean} [shouldRedirect=true] - Should it redirect or return the new URL
 * @returns {string} Return the new URL only if the `shouldRedirect` is set to `false`, otherwise it will return empty string
 */
const redirectToReferrer = (
  target: string = '/',
  shouldRedirect: boolean = true,
  isReplace: boolean = false,
): string => {
  const { query } = Router

  const referrerPath = (query.ref || target).toString()
  const blacklistedPaths = ['/token-expired']

  if (!isSafeUrlToRedirect(referrerPath)) {
    return redirectToHome(shouldRedirect)
  }

  let newTarget = target
  if (!blacklistedPaths.includes(referrerPath))
    newTarget = referrerPath

  if (shouldRedirect) {
    redirect(newTarget, { isReplace })
    return ''
  }
  // return newTarget
}

/**
 * Open the given URL into a new target
 *
 * @param  {string} url
 * @param  {IWindowTarget} [target='_blank']
 * @returns void
 */

const openInNewTab = (
  url: string,
  target: IWindowTarget = '_blank',
): void => {
  window.open(url, target)
}

const redirectWithCheckingSource = (target: string = '/') => {
  const authMethod = getLocalStorage('authMethod')
  if (getSessionStorage('source') === 'ABOUT_PROMOTER') {
    destroySessionStorage('source')
    redirect(appConfig.promoterUrl)
  } else if (loggedInSSO.includes(authMethod)) {
    redirect(target, { isReplace: true })
  } else {
    redirectToReferrer(target, true, true)
  }
}

export {
  isSafeUrlToRedirect,
  redirectToHome,
  redirectWithReferrer,
  redirectToReferrer,
  openInNewTab,
  redirectWithCheckingSource,
}
