/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { Toolbar, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import ClearIcon from '@mui/icons-material/Clear'
import Search from 'components/commons/Search/v2'
import Stack from 'components/commons/Stack'
import { useEffect } from 'react'
import {
  setLocalStorage,
  getLocalStorage,
  removeElementLocalStorage,
  destroyLocalStorage,
} from 'helpers/local-storage'
import isEmpty from 'helpers/is-empty'
import { useRouter } from 'next/router'
import redirect from 'helpers/redirect'
import { useStoreState, useStoreActions } from 'stores/hooks'
import trackEvent from 'src/trackers'
import {
  DrawerContent,
  DrawerContentContainer,
  DrawerHeader,
  HistoryContent,
  HistoryWrapper,
  StyleAppBar,
  StyleContainer,
  StyledStack,
  StyledTitle,
} from './styled'
import { checkQuery } from 'helpers/browse'
import { useCategoryStore } from 'stores/domains/User'
import { useBrowseStore } from 'stores/domains/Browse'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

interface ISearchHistory {
  data: any
  handleRemoveItem?: Function
  handleRemoveAll?: Function
  handleRedirectPage?: Function
}

function SearchHistory({
  data,
  handleRemoveItem,
  handleRemoveAll,
  handleRedirectPage,
}: ISearchHistory): JSX.Element {
  const theme = useTheme()
  return (
    <Box>
      {data && data.length !== 0 && (
        <StyledTitle>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 700,
              fontSize: '16px',
            }}
          >
            Riwayat Pencarian
          </Typography>
          <Typography
            id="b-search-delete-all"
            sx={{
              color: theme.palette.tiptip[500],
              fontWeight: 700,
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onClick={() => handleRemoveAll()}
          >
            Hapus Semua
          </Typography>
        </StyledTitle>
      )}
      <HistoryContent>
        {data &&
          data.map((item, index) => {
            return (
              <HistoryWrapper
                key={index}
                onClick={(e) => handleRedirectPage(e, item)}
                id={`b-search-delete-${index}`}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1.5}
                >
                  <RotateLeftIcon
                    sx={{
                      color: theme.palette.info.main,
                    }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                    }}
                  >
                    {item}
                  </Typography>
                </Stack>
                <ClearIcon
                  sx={{
                    color: theme.palette.text.tertiery,
                  }}
                  onClick={(e) => handleRemoveItem(e, item)}
                />
              </HistoryWrapper>
            )
          })}
      </HistoryContent>
    </Box>
  )
}

interface ISearchPage {
  showPage?: boolean
  onCloseAction?: Function
  statusPage?: string
  pathname?: string
}

function SearchPage({
  showPage,
  onCloseAction,
  pathname,
}: ISearchPage): JSX.Element {
  const theme = useTheme()
  const { query } = useRouter()
  const trackerData = { pathname, query }
  const { isReady } = useRouter()

  const { setSearchHistory } = useStoreActions(
    (action) => action.search,
  )

  const { selectedCategories } = useCategoryStore((state) => ({
    selectedCategories: state.selectedCategories,
  }))
  const { keyword, currentStatusPage, setKeyword } = useBrowseStore(
    (state) => ({
      keyword: state.keyword,
      currentStatusPage: state.currentStatusPage,
      setKeyword: state.setKeyword,
    }),
  )
  const { sessionData } = useStoreState(
    (state) => state.browseSession,
  )
  const { creatorData } = useStoreState(
    (state) => state.browseCreator,
  )
  const { categories } = useCategoryStore((state) => ({
    categories: state.categories,
  }))
  const { searchHistory, searchData } = useStoreState(
    (state) => state.search,
  )

  const handleDeleteKey = () => {
    setKeyword('')
  }

  const checkKeywordsToLocalStorage = (key) => {
    if (key !== '') {
      let arrayObject = getLocalStorage('search')
      if (arrayObject !== undefined) {
        if (arrayObject.indexOf(key) === -1) {
          if (arrayObject.length > 4) {
            arrayObject.shift()
          }
          arrayObject.push(key)
          setLocalStorage('search', arrayObject)
          setSearchHistory(getLocalStorage('search').reverse())
        }
      } else {
        setLocalStorage('search', [key])
        setSearchHistory(getLocalStorage('search').reverse())
      }
    }
  }

  const getPropertiesClickSearchButton = (keyword) => {
    return {
      search_box: keyword,
      search_history: searchHistory,
      selected_browse_and_search_menu: `${
        query?.status || 'all'
      }_tab`,
      list_selected_category_id: isEmpty(query.category_id)
        ? []
        : checkQuery(query.category_id),
      list_selected_category_name: isEmpty(query.category_id)
        ? []
        : categories
            .filter((cat) => selectedCategories?.includes(cat.id))
            .map((cat) => cat.name),
      browse_session_list:
        query.status === 'session' && sessionData.sessionList.length
          ? sessionData.sessionList.map((session) => session.id)
          : [],
      browse_creator_list:
        query.status === 'creator'
          ? creatorData.creatorList.map(
              (creator) => creator.creator_id,
            )
          : [],
      browse_creator_user_id_list:
        query.status === 'creator'
          ? creatorData.creatorList.map(
              (creator) => creator.creator_user_id,
            )
          : [],
      browse_creator_name_list:
        query.status === 'creator'
          ? creatorData.creatorList.map((creator) => creator.name)
          : [],
      browse_community_list:
        query?.status === 'community'
          ? searchData.community.map((community) => community.mask_id)
          : [],
      browse_community_num:
        query?.status === 'community'
          ? searchData.community.length
          : 0,
    }
  }

  const handleRemoveItem = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    removeElementLocalStorage('search', key)
    setSearchHistory(getLocalStorage('search').reverse())
  }

  const handleRemoveAll = () => {
    destroyLocalStorage('search')
    setSearchHistory([])
  }

  const redirectPage = (key) => {
    const keyword = key !== '' ? `&q=${key}` : ''
    const type = currentStatusPage ?? 'all'
    const url = `/browse?source=${pathname}&source_action=click_search_button&status=${type}${keyword}`
    setKeyword(key)
    redirect(url)
  }

  const handleRedirectPage = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    onCloseAction()
    redirectPage(key)
  }

  const handleEnterPress = (currentKey) => {
    const getSearchProperties =
      getPropertiesClickSearchButton(currentKey)

    trackEvent.home('click_search_button', trackerData, {
      ...getSearchProperties,
    })
    checkKeywordsToLocalStorage(currentKey)
    onCloseAction()
    redirectPage(currentKey)
  }

  useEffect(() => {
    let el = getLocalStorage('search')
    if (isReady && !isEmpty(el)) {
      setSearchHistory(el.reverse())
    } else {
      setSearchHistory([])
    }
  }, [isReady, searchHistory.length])

  useEffect(() => {
    if (isReady && showPage) {
      trackEvent.home('click_search_box', {
        search_box: keyword,
        search_history: searchHistory,
      })
    }
  }, [isReady, showPage])

  return (
    <StyleContainer
      anchor="bottom"
      open={showPage}
      PaperProps={{
        sx: {
          width: '100vw',
          height: '100vh',
          margin: '0 auto',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          background: theme.palette.background.secondary,
          '@media (min-width:480px)': {
            width: '480px',
            margin: '0 auto',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            background: theme.palette.background.secondary,
          },
        },
      }}
      onClose={() => onCloseAction()}
      id="p-search"
    >
      <StyledStack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={6}
      >
        <DrawerHeader
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyleAppBar>
            <Toolbar>
              <IconButton
                id="b-search-close"
                onClick={() => onCloseAction()}
              >
                <ArrowBackIcon
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                />
              </IconButton>
              <Search
                value={keyword}
                autoFocus={true}
                handleEnterPress={handleEnterPress}
                handleDeleteKey={handleDeleteKey}
              />
            </Toolbar>
          </StyleAppBar>
        </DrawerHeader>
        <DrawerContentContainer>
          <DrawerContent>
            <SearchHistory
              data={searchHistory}
              handleRedirectPage={handleRedirectPage}
              handleRemoveItem={handleRemoveItem}
              handleRemoveAll={handleRemoveAll}
            />
          </DrawerContent>
        </DrawerContentContainer>
      </StyledStack>
    </StyleContainer>
  )
}

export default SearchPage
