import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  styled,
} from '@mui/material'

type IProps = {
  name: string
  label?: string
  placeholder?: string
}

type Props = IProps & ToggleButtonGroupProps

const StyledToggleButtonGroup = styled(ToggleButtonGroup)<{
  withLabel?: boolean
}>(({ theme, withLabel = false }) => ({
  width: '100%',
  marginTop: withLabel && '20px',
  color: theme.palette.text.secondary,
}))

export default function RHFToggleGroup({
  name,
  label,
  id,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <InputLabel
          sx={(theme) => ({
            ...theme.typography.normalBold,
            transform: 'none',
          })}
          shrink
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control as any}
        render={({ field }) => (
          <StyledToggleButtonGroup
            {...field}
            withLabel={Boolean(label)}
            id={id}
            exclusive
            fullWidth
            {...other}
          />
        )}
      />
    </FormControl>
  )
}
