import { styled, SxProps, Collapse } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import dateFormatter from 'helpers/formats/date'
import CountdownTimer from '../CountdownTimer'
import CommonTypography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(Stack)(({ theme }) => ({
  color: 'white',
  background: theme.palette.info.main,
  textAlign: 'center',
  justifyContent: 'center',
  borderRadius: 6,
  padding: 8,
  gap: '8px',
  p: {
    color: 'white',
  },
}))

interface IExpiryTimeBannerProps {
  isOpen: boolean
  remainingTime?: number
  expiryDate: string
  message?: string
  sx?: SxProps
  isModalView?: boolean
  onHandleTimerFinished?: VoidFunction
}
function ExpiryTimeBanner(props: IExpiryTimeBannerProps) {
  const { t } = useTranslation()

  const handleCustomDateWithTimeZone = (date) => {
    const dateFormat = dateFormatter({
      date: date,
      format: `DD MMM YYYY, HH:mm`,
    })

    const getTimeZone = dateFormatter({
      date: date,
      format: `ZZ`,
    })

    let timeZone = ''
    switch (getTimeZone) {
      case '+0700':
        timeZone = 'WIB'
        break
      case '+0800':
        timeZone = 'WITA'
        break
      default:
        timeZone = 'WIT'
        break
    }

    return dateFormat + ' ' + timeZone
  }

  return (
    <Collapse in={props.isOpen}>
      <StyledContainer sx={{ ...props.sx }}>
        <Stack
          direction={
            !props.isModalView
              ? { xs: 'column', md: 'row' }
              : 'column'
          }
          sx={{
            justifyContent: 'center',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Stack
            direction={'row'}
            sx={{ alignItems: 'center', gap: '4px' }}
          >
            <AccessTimeIcon sx={{ fontSize: '12px' }} />
            <CommonTypography sx={{ fontSize: '12px' }}>
              {t('key_remaining_payment_time')} :
            </CommonTypography>
          </Stack>
          <CountdownTimer
            expiryDate={props.expiryDate}
            withText
            sx={{
              ' p': {
                fontSize: '16px',
                fontWeight: 700,
              },
            }}
            onTimerFinished={props.onHandleTimerFinished}
          />
        </Stack>
        <CommonTypography sx={{ fontSize: '12px' }}>
          {handleCustomDateWithTimeZone(props.expiryDate)}
        </CommonTypography>
      </StyledContainer>
    </Collapse>
  )
}

export default ExpiryTimeBanner
