import isEmpty from 'helpers/is-empty'
import objectToQueryString from 'helpers/transform/object-to-query-string'
import { getSessionStorage } from 'helpers/session-storage'
import { noBackURLs } from 'constants/no-back-redirect'

/**
 * Concat between path and query
 *
 * @param path
 * @param query
 * @returns
 */
const mergePathWithQuery = (
  path: string,
  query: object = {},
): string => {
  return [path, objectToQueryString(query)].join('?')
}

/**
 * Generate previous url
 *
 * @param source
 * @param source_action
 *
 * @returns {string}
 */
const generatePreviousURL = (
  source?: string,
  source_action?: string,
): string => {
  const prevPath = getSessionStorage('prevPath')
  const prevQuery = getSessionStorage('prevQuery')
  if (isEmpty(prevPath)) {
    return '/'
  }
  if (
    noBackURLs.some((blockedRoute) =>
      String(prevPath).includes(blockedRoute),
    )
  ) {
    return '/'
  }
  if (isEmpty(source)) {
    return mergePathWithQuery(prevPath, prevQuery)
  }
  const newSource = { source, source_action }
  const newQuery = {
    ...prevQuery,
    ...newSource,
  }
  return mergePathWithQuery(prevPath, newQuery)
}

// TODO: should be removed
// Waiting for OTP WA already in Prod, since it will impact to many files
const getPreviousURL = (source?: string, source_action?: string) =>
  generatePreviousURL(source, source_action)

export { getPreviousURL, generatePreviousURL }
