import React, { memo, useEffect, useMemo, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/SearchRounded'
import CancelIcon from '@mui/icons-material/CancelRounded'
import isEmpty from 'helpers/is-empty'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { MenuList, useMediaQuery } from '@mui/material'
import SearchHistory from 'components/commons/SearchHistory'
import redirect from 'helpers/redirect'
import {
  destroyLocalStorage,
  getLocalStorage,
  removeElementLocalStorage,
} from 'helpers/local-storage'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import { useBrowseStore } from 'stores/domains/Browse'
import Box from 'components/commons/Box'
import { useTranslation } from 'react-i18next'
import Divider from 'components/commons/Divider'
import Stack from 'components/commons/Stack'
import LocationHeaderMobile from 'containers/commons/Header/LocationHeaderMobile'

const SearchContainer = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '8px',
  minWidth: '177px',
  width: '100%',
  input: {
    ...theme.typography.normalRegular,
    color: theme.palette.text.primary,
    fontFamily: '"Source Sans 3"',
  },
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    width: '100%',
    padding: '2px 0 5px',
  },
  '& .MuiInputBase-root:after': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    opacity: 'unset',
    WebkitTextFillColor: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    border: `1px solid ${theme.palette.background.tertiery}`,
    input: {
      paddingLeft: '12px !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 12px',
    input: {
      height: '32px',
    },
    '& .MuiInputBase-input': {
      padding: '0px',
    },
  },
}))

const StyledMenu = styled(MenuList)(({ theme }) => ({
  background: theme.palette.background.primary,
  boxShadow: '0px 8px 8px rgba(161, 160, 166, 0.12)',
  borderRadius: 12,
  color: theme.palette.text.primary,
  padding: 12,
  width: '47%',
  marginTop: 5,
  position: 'absolute',
  top: 50,
  zIndex: 99,
}))

const StyledIconButton = styled(IconButton)({
  borderRadius: 0,
})

interface ISearch {
  autoFocus?: boolean
  disabled?: boolean
  handleEnterPress?: Function
  placeholder?: string
  handleOnFocus?: Function
  searchId?: string
}
function Search({
  autoFocus = false,
  disabled,
  handleEnterPress,
  placeholder,
  handleOnFocus,
  searchId,
}: ISearch): JSX.Element {
  const { t } = useTranslation()

  const { pathname, query, isReady } = useRouter()
  const trackerData = { pathname, query }
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const { currentStatusPage, setKeyword } = useBrowseStore(
    (state) => ({
      currentStatusPage: state.currentStatusPage,
      setKeyword: state.setKeyword,
    }),
  )
  const { searchHistory, historyOpen, isSearchBarActive } =
    useStoreState((state) => state.search)
  const { setSearchHistory, setHistoryOpen, setIsSearchBarActive } =
    useStoreActions((action) => action.search)

  const [showDeleteBtn, setShowDeleteBtn] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [searchLabel, setSearchLabel] = useState('Search in TipTip')

  const handleUserInput = (e) => {
    setSearchInput(e.target.value)

    if (e.target.value.length !== 0) {
      setShowDeleteBtn(true)
    } else {
      setShowDeleteBtn(false)
    }
  }
  const onDeleteInput = (disabled) => {
    if (!disabled) {
      setShowDeleteBtn(false)
      setSearchInput('')
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setSearchInput(e.target.value)
      setKeyword(e.target.value)
      handleEnterPress(e.target.value)
    }
  }

  const handleClickSearch = () => {
    trackEvent.home('click_search_box', trackerData, {
      search_box: searchInput,
      search_history: searchHistory,
    })
    handleOnFocus(searchInput)
    if (searchHistory.length > 0) setHistoryOpen(true)
  }

  const handleRedirectPage = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    const keyword = key !== '' ? `&q=${key}` : ''
    const url = `/browse?source=${pathname}&source_action=click_search_box&status=${currentStatusPage}${keyword}`
    setSearchInput(key)
    setHistoryOpen(false)
    redirect(url)
  }

  const handleRemoveItem = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    removeElementLocalStorage('search', key)
    setSearchHistory(getLocalStorage('search').reverse())
  }

  const handleRemoveAll = () => {
    destroyLocalStorage('search')
    setSearchHistory([])
    setHistoryOpen(false)
  }

  const handleClickSearchIcon = () => {
    handleEnterPress(searchInput)
    setHistoryOpen(false)
  }

  useEffect(() => {
    setHistoryOpen(false)
    setIsSearchBarActive(false)
  }, [])

  useEffect(() => {
    if (isReady && !isEmpty(query.q)) {
      if (searchInput !== String(query.q)) {
        setSearchInput(String(query.q))
      }
    } else {
      setSearchInput('')
    }
  }, [isReady, query.q])

  useEffect(() => {
    if (searchInput !== '' && historyOpen) {
      setHistoryOpen(false)
    }

    if (searchInput === '' && !historyOpen && isSearchBarActive) {
      setHistoryOpen(true)
    }
  }, [searchInput])

  const isSearchActive = useMemo(
    () => isSearchBarActive,
    [isSearchBarActive],
  )

  useEffect(() => {
    setSearchLabel(t('cariDiTiptip'))
  }, [t])

  return (
    <>
      <SearchContainer
        sx={{
          border: {
            xs: `1px solid ${theme.palette.border.minimal}`,
            md: `1px solid ${
              isSearchActive
                ? theme.palette.tiptip[300]
                : theme.palette.background.tertiery
            }`,
          },
          background: {
            xs: theme.palette.background.secondary,
            md: theme.palette.background.tertiery,
          },
          borderRadius: '22px',
          padding: '3px',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: `center`,
          }}
        >
          <InputBase
            autoComplete="off"
            id={searchId}
            autoFocus={autoFocus}
            onChange={handleUserInput}
            onClick={handleClickSearch}
            onKeyDown={(e) => onKeyDown(e)}
            onFocus={() => {
              setIsSearchBarActive(true)
              if (searchHistory.length > 0)
                setHistoryOpen(searchInput === '')
            }}
            onBlur={() => setIsSearchBarActive(false)}
            value={searchInput}
            disabled={disabled}
            placeholder={
              isEmpty(placeholder) ? searchLabel : placeholder
            }
            startAdornment={
              <StyledIconButton
                aria-label="search"
                onClick={handleClickSearchIcon}
                sx={{
                  display: {
                    xs: 'inline-flex',
                    md: 'none',
                  },
                  svg: {
                    color: theme.palette.text.tertiery,
                  },
                }}
              >
                <SearchIcon />
              </StyledIconButton>
            }
            endAdornment={
              <>
                {showDeleteBtn ||
                (searchInput !== '' && searchInput !== undefined) ? (
                  <IconButton
                    onClick={() => onDeleteInput(disabled)}
                    type="submit"
                    sx={{
                      p: '10px',
                      color: theme.palette.text.tertiery,
                    }}
                    aria-label="search"
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                ) : null}
                <StyledIconButton
                  aria-label="search"
                  onClick={handleClickSearchIcon}
                  sx={{
                    background: theme.palette.tiptip[500],
                    borderRadius: '19px',
                    display: {
                      xs: 'none',
                      md: 'inline-flex',
                    },
                    ':hover': {
                      background: theme.palette.tiptip[500],
                    },
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                </StyledIconButton>
              </>
            }
          />
          {!isDesktop && (
            <Stack direction={'row'} gap={1.5}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  my: 0.75,
                }}
              />
              <LocationHeaderMobile />
            </Stack>
          )}
        </Stack>

        {historyOpen && (
          <StyledMenu id="c-commons-search-history-menu">
            <SearchHistory
              data={searchHistory}
              handleRedirectPage={handleRedirectPage}
              handleRemoveItem={handleRemoveItem}
              handleRemoveAll={handleRemoveAll}
            />
          </StyledMenu>
        )}
      </SearchContainer>
    </>
  )
}

export default memo(Search)
