interface IVerifiedIcon {
  size: 'sm' | 'lg'
}

const ICON_SIZES = {
  lg: {
    size: 24,
    c: 12,
    r: 6,
    d: 'M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z',
  },
  sm: {
    size: 16,
    c: 8,
    r: 4,
    d: 'M15.3332 8.19922L13.7065 6.33922L13.9332 3.87922L11.5265 3.33255L10.2665 1.19922L7.99984 2.17255L5.73317 1.19922L4.47317 3.32589L2.0665 3.86589L2.29317 6.33255L0.666504 8.19922L2.29317 10.0592L2.0665 12.5259L4.47317 13.0726L5.73317 15.1992L7.99984 14.2192L10.2665 15.1926L11.5265 13.0659L13.9332 12.5192L13.7065 10.0592L15.3332 8.19922ZM6.25317 10.8726L4.6665 9.27255C4.4065 9.01255 4.4065 8.59255 4.6665 8.33255L4.71317 8.28589C4.97317 8.02589 5.39984 8.02589 5.65984 8.28589L6.73317 9.36589L10.1665 5.92589C10.4265 5.66589 10.8532 5.66589 11.1132 5.92589L11.1598 5.97255C11.4198 6.23255 11.4198 6.65255 11.1598 6.91255L7.21317 10.8726C6.93984 11.1326 6.51984 11.1326 6.25317 10.8726Z',
  },
}

export default function VerifiedIcon({ size = 'lg' }: IVerifiedIcon) {
  const ic = ICON_SIZES[size]
  return (
    <svg
      width={ic.size}
      height={ic.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={ic.c} cy={ic.c} r={ic.r} fill="#F6F6F6" />
      <path d={ic.d} fill="#326DE1" />
    </svg>
  )
}
