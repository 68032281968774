import { getCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'
import { getWithExpiry } from 'helpers/local-storage'

const getPromoterToken = (contentUid: any) => {
  const token =
    getCookie('affiliate$' + contentUid) !== undefined
      ? getCookie('affiliate$' + contentUid)
      : !isEmpty(getWithExpiry('affiliate$' + contentUid))
      ? JSON.parse(getWithExpiry('affiliate$' + contentUid))
          .promoterToken
      : ''

  return token
}

export { getPromoterToken }
