import { useRouter } from 'next/router'
import { useTheme } from '@mui/material'

import {
  ChatPeer,
  GroupRoomMessage,
} from 'stores/domains/User/Message'
import trackEvent from 'src/trackers'

import BubbleAlert from './BubleAlert'
import BubbleChat from './BubbleChat'
import BubbleDate from './BubbleDate'
import BubbleUnread from './BubbleUnread'

import Stack from 'components/commons/Stack'
import { useEffect, useRef } from 'react'

interface IMessageRoom {
  messages?: GroupRoomMessage[]
  userId?: string
  isBlocked?: boolean
  isMobile?: boolean
  currentPeer?: ChatPeer
  userProfile?: any
}

export default function MessageRoom({
  messages,
  userId,
  isBlocked = false,
  isMobile = false,
  currentPeer,
  userProfile = {},
}: IMessageRoom) {
  const theme = useTheme()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }

  const isSenderCreator = userProfile?.creator?.status === 'verified'

  const handleClickTnc = () => {
    trackEvent.user('click_tnc', trackerData, {
      from_user_id: userProfile?.user_id,
      from_username: userProfile?.username,
      from_user_type: isSenderCreator ? 'creator' : 'supporter',
      from_creator_id: isSenderCreator
        ? userProfile?.creator?.creator_id
        : 'none',
      from_creator_name: isSenderCreator
        ? userProfile?.creator?.name
        : 'none',
      to_user_id: currentPeer.id || 'none',
      to_username: currentPeer.username || 'none',
      to_user_type: currentPeer.isCreator ? 'creator' : 'supporter',
      to_creator_id: currentPeer.creator?.id || 'none',
      to_creator_name: currentPeer.creator?.name || 'none',
    })
  }

  const messageRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: 'auto',
        block: isMobile ? 'start' : 'end',
        inline: 'start',
      })
    }
  }, [messages])

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="start"
      spacing={2}
      paddingX={{ xs: 1, md: 2 }}
      margin={{ xs: '60px 0 78px 0', md: 0 }}
      bgcolor={theme.palette.background.primary}
      height="100%"
      width="100%"
      sx={{ overflow: 'hidden', overflowY: 'auto' }}
      id="c-users-chat-room-chats"
    >
      <BubbleAlert mb={4} onClickSecurity={handleClickTnc} />
      <>
        {messages &&
          messages.length > 0 &&
          messages.map((message, key) => (
            <>
              {message.title?.toLowerCase() === 'unread' ? (
                <BubbleUnread key={key} text={message.title} />
              ) : (
                <BubbleDate key={key} text={message.title} />
              )}
              <Stack width="100%" spacing={2}>
                {message.messages.map((msg, i) => (
                  <BubbleChat
                    key={i}
                    message={msg}
                    isSelf={msg.from_user_id === userId}
                  />
                ))}
              </Stack>
              <div ref={messageRef} />
            </>
          ))}
        {isBlocked && <BubbleUnread text="PENGGUNA INI DIBLOKIR" />}
      </>
    </Stack>
  )
}
