import { ClassAttributes, HTMLAttributes } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { MUIStyledCommonProps } from '@mui/system'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import CommonTypography from 'components/commons/Typography'
import CommonStack from 'components/commons/Stack'

type StyledContainerProps = MUIStyledCommonProps<Theme> &
  ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    isXenditIframe: boolean
    isEventOrderDetail: boolean
    isVoucher: boolean
  }

export const StyledContainer = styled(Box)<StyledContainerProps>(
  ({ theme, isEventOrderDetail, isXenditIframe, isVoucher }) => ({
    display: isEventOrderDetail ? 'flex' : 'block',
    background: theme.palette.background.primary,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: 'auto',
    margin: 'auto 0',
    padding: isXenditIframe ? '16px 0px 0px 0px' : '24px 16px',
    minHeight:
      (isEventOrderDetail && 520) || (isEventOrderDetail && '380px'),
    maxHeight:
      (isVoucher && '100vh') || (isEventOrderDetail && '650px'),
    maxWidth: isXenditIframe ? '920px' : '490px',
    borderRadius: '12px',
    overflow: (isXenditIframe && 'auto') || (isVoucher && 'scroll'),
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
      height: isEventOrderDetail && '90%',
    },
  }),
)

export const ModalTitle = styled(CommonTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '20px',
  lineHeight: '20px',
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  wordBreak: 'break-word',
}))

export const ModalContent = styled(Box)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
}))

export const CloseModalButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.tertiery,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  svg: {
    color: theme.palette.tiptip[500],
  },
}))

export const ActionButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginTop: 'auto',
})

type StyledButtonProps = MUIStyledCommonProps<Theme> & {
  background?: string
  variant?: 'text' | 'outlined' | 'contained'
} & ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement>

export const StyledSubmitButton = styled(Button)(
  ({ background = '' }: StyledButtonProps) => ({
    borderRadius: '8px',
    color: 'white',
    background: background !== '' ? background : null,
  }),
)

export const ContainerBtn = styled(CommonStack)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
})
