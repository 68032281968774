import { APP_DOMAIN } from 'constants/index'
import { NextPageContext } from 'next'
import nookies from 'nookies'
import authConfig from 'configs/auth'
import isEmpty from 'helpers/is-empty'

const IS_VERCEL = Boolean(process.env.NEXT_PUBLIC_IS_VERCEL)

/**
 * Returns cookie value
 *
 * @param context
 * @param cookieName
 */
const getCookie = (
  cookieName: string,
  context?: NextPageContext,
): string => {
  if (context?.req && context?.req.headers) {
    const value = nookies.get(context)[cookieName]
    return context.res && value
  }
  const value = nookies.get({})[cookieName]
  return value
}

/**
 * Returns true after cookie is set
 *
 * @param context
 * @param name
 * @param value
 * @param path
 * @param expiry
 * @param domain
 */
const setCookie = (
  name: string,
  value: string,
  path = '/',
  expiry?: Date,
  context?: NextPageContext,
  domain = `.${APP_DOMAIN}`,
): boolean => {
  const cookieDomain = IS_VERCEL ? undefined : domain
  if (context?.req && context?.req.headers) {
    nookies.set(context, name, value, {
      path,
      expires: expiry,
      domain: cookieDomain,
    })
    return true
  }
  nookies.set({}, name, value, {
    path,
    expires: expiry,
    domain: cookieDomain,
  })
  return true
}

/**
 * Return true after cookie is destroyed
 *
 * @param context
 * @param name
 * @param path
 * @param domain
 */
const destroyCookie = (
  name: string,
  path = '/',
  context?: NextPageContext,
  domain = `.${APP_DOMAIN}`,
): boolean => {
  const cookieDomain = IS_VERCEL ? undefined : domain
  if (context?.req && context?.req.headers) {
    nookies.destroy(context, name, { path, domain: cookieDomain })
    return true
  }
  nookies.destroy({}, name, { path, domain: cookieDomain })
  return true
}

const isGuestMode = (): boolean => {
  return (
    isEmpty(getCookie(authConfig.idTokenName)) ||
    isEmpty(getCookie('username'))
  )
}

const forceDeleteCookies = () => {
  var allCookies = document.cookie.split(';')

  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (var i = 0; i < allCookies.length; i++) {
    document.cookie =
      allCookies[i] + '=;expires=' + new Date(0).toUTCString()
  }
}

export {
  getCookie,
  setCookie,
  destroyCookie,
  isGuestMode,
  forceDeleteCookies,
}
