import * as React from 'react'
import MainLogo from 'assets/logos/logo-mobile.svg'
import {
  ImageWrapper,
  ButtonWrapper,
  VoucherButton,
  VoucherCardWrapper,
  VoucherContent,
  VoucherContentWrapper,
  VoucherDesc,
  VoucherDescText,
  VoucherHeader,
  VoucherName,
  DescWrapper,
} from './styled'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { useTheme } from '@mui/material/styles'
import dateFormatter from 'helpers/formats/date'
import appConfig from 'configs/app'
import trackEvent from 'src/trackers'
import { useTranslation } from 'react-i18next'
import Box from 'components/commons/Box'
import NumberBaseFormatter from 'helpers/formats/number-base-format'

export interface IVoucherCard {
  rule: string
  name: string
  expired: string
  description: string
  max_discount_amount: number
  is_grey_out: boolean
  short_description: string
}
interface IVoucherCardProps {
  voucherListData: IVoucherCard
  voucherChecker: Function
  voucherDetailsHandler?: Function
  isLoading: boolean
  trackerData?: any
}

export default function VoucherCard({
  voucherListData,
  voucherChecker,
  voucherDetailsHandler,
  isLoading,
  trackerData,
}: IVoucherCardProps) {
  const { t } = useTranslation()

  const theme = useTheme()
  return (
    <VoucherCardWrapper
      onClick={() => {
        if (voucherDetailsHandler) {
          trackEvent.paymentCart(`select_voucher`, trackerData, {
            voucher_code: voucherListData?.rule,
          })
          !isLoading ? voucherDetailsHandler(voucherListData) : ''
        }
      }}
      sx={{
        maxWidth: 448,
      }}
    >
      <VoucherContent>
        <ImageWrapper
          sx={{ position: 'relative' }}
          urlBg={`${appConfig.assetBaseUrl}/voucher/voucher_2desc_${
            voucherListData.is_grey_out ? 'disabled' : 'active'
          }.png`}
        >
          <VoucherContentWrapper>
            <VoucherHeader>
              <VoucherName>{voucherListData.name}</VoucherName>
              <MainLogo />
            </VoucherHeader>
            <DescWrapper>
              <Box>
                {voucherListData?.short_description !== '' && (
                  <VoucherDesc>
                    <ErrorOutlineRoundedIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      {voucherListData.short_description}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
                {voucherListData.max_discount_amount && (
                  <VoucherDesc>
                    <MonetizationOnOutlinedIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      Max. Discount{' Rp '}
                      {NumberBaseFormatter(
                        voucherListData.max_discount_amount,
                      )}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
                {voucherListData?.expired !== undefined && (
                  <VoucherDesc>
                    <AccessTimeIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      {t('voucherInboxScreenExpired')}{' '}
                      {dateFormatter({
                        date: voucherListData.expired,
                        format: 'D MMMM YYYY',
                      })}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
              </Box>
            </DescWrapper>
            <ButtonWrapper>
              <VoucherButton
                disabled={isLoading || voucherListData.is_grey_out}
                variant="contained"
                onClick={(event) => {
                  voucherChecker(voucherListData?.rule, true)
                  event.stopPropagation()
                }}
              >
                {t('voucherInboxScreenVoucherButton')}
              </VoucherButton>
            </ButtonWrapper>
          </VoucherContentWrapper>
        </ImageWrapper>
      </VoucherContent>
    </VoucherCardWrapper>
  )
}
