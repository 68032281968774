import isEmpty from 'helpers/is-empty'

const formatURL = (
  status,
  type,
  role,
  categoryID,
  eventType,
  key,
  action?: string | string[],
  eventStatusFilter?: string | string[],
  provinceID?: string | string[],
) => {
  let newURL = ''
  const newType = convertArrayToQueryString('type', type)
  const newRole = convertArrayToQueryString('role', role)
  const category_id = convertArrayToQueryString(
    'category_id',
    categoryID,
  )
  const event_type = convertArrayToQueryString(
    'event_type',
    eventType,
  )
  const search = key !== '' ? `&q=${key}` : ''
  const sourceAction = action ? `&source_action=${action}` : ''
  const eventFilter = convertArrayToQueryString(
    'event_status',
    eventStatusFilter,
  )

  const province_id = provinceID ? `&province_id=${provinceID}` : ''
  newURL = `/browse?status=${status}${newType}${newRole}${category_id}${event_type}${search}${sourceAction}${eventFilter}${province_id}`

  return newURL
}

const formatThematicURL = (sectionId, type, categoryID) => {
  let newURL = ''
  const newType = convertArrayToQueryString('type', type)
  const category_id = convertArrayToQueryString(
    'category_id',
    categoryID,
  )
  newURL = `/section/${sectionId}/?${newType}${category_id}`
  return newURL
}

const convertArrayToQueryString = (key, data) => {
  if (!isEmpty(key)) {
    const params = new URLSearchParams()
    let array = data
    if (!Array.isArray(array)) {
      array = [data]
    }
    array.map((id) => (id ? params.append(key, id) : '')).toString()
    const newParams =
      params.toString() !== ''
        ? '&' + params.toString()
        : params.toString()
    return newParams
  }
  return ''
}

const checkQuery = (data) => {
  let results = []
  if (data) {
    if (Array.isArray(data)) {
      results = data.map((i) => {
        if (i !== '') return parseInt(i, 10)
      })
    } else {
      results = [parseInt(data, 10)]
    }
  }
  return results
}

const checkQueryFromString = (data) => {
  let results = []
  if (data) {
    if (Array.isArray(data)) {
      results = data.map((i) => {
        if (i !== '') return i
      })
    } else {
      results = [data]
    }
  }
  return results
}

const getCategoriesFromQuery = (data) => {
  const newArray = checkQuery(data)
  return newArray
}

const getEventTypesFromQuery = (data) => {
  const newArray = checkQuery(data)
  return newArray
}

const getPriceTypeFromQuery = (data) => {
  const newArray = checkQuery(data)
  return newArray
}

const getRoleTypeFromQuery = (data) => {
  const newArray = checkQueryFromString(data)
  return newArray
}

export {
  formatURL,
  formatThematicURL,
  convertArrayToQueryString,
  checkQuery,
  getCategoriesFromQuery,
  getEventTypesFromQuery,
  getPriceTypeFromQuery,
  getRoleTypeFromQuery,
}
