import authConfig from 'configs/auth'
import { setCookie } from 'helpers/cookie'
import {
  destroyLocalStorage,
  setWithExpiry,
} from 'helpers/local-storage'
import { addDays } from 'helpers/date-time'
import { setFcmToken } from 'helpers/firebase'

interface LoginData {
  token: string
  accessToken: string
  refreshToken?: string
}

/**
 * Handles after login steps
 *
 * @param data LoginData
 * @param cb callback function, usually used to redirect to referer or main page
 * @param path cookie path
 *
 * @notes if all services already migrate to apigateway please remove cookies rll_acct and rll_reft
 */
const afterLogin = (
  data: LoginData,
  // eslint-disable-next-line no-unused-vars
  cb: (params?: any) => void,
  path = '/',
  withoutCallFirebase = false,
): void => {
  if (!withoutCallFirebase) setFcmToken('SIGN_IN')
  setCookie(
    authConfig.idTokenName,
    data.token,
    path,
    addDays(authConfig.refreshTokenExpiryDays),
  )
  setWithExpiry(
    authConfig.accessTokenName,
    data.accessToken,
    addDays(authConfig.refreshTokenExpiryDays),
  )
  setWithExpiry(
    authConfig.refreshTokenName,
    data.refreshToken,
    addDays(authConfig.refreshTokenExpiryDays),
  )
  destroyLocalStorage('dailyBonus')
  destroyLocalStorage('idempotent')
  cb()
}

export default afterLogin
