import formatDate from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import parseDate from 'date-fns/parse'

function transformDateTime(
  dateTime: string,
  isRoom?: boolean,
): string {
  if (!dateTime) return ''
  const date = parseDate(
    dateTime,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(),
  )
  if (isToday(date)) {
    return formatDate(date, 'HH:mm')
  }
  if (!isRoom && isYesterday(date)) {
    return 'Yesterday'
  }
  return formatDate(date, 'dd/MM/yy')
}

export default transformDateTime
