import { action, thunk } from 'easy-peasy'
import get from 'lodash/get'
import { getCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import IBankListModel from 'stores/domains/Creator/BankList/interface'

const Category: IBankListModel = {
  isError: false,
  isLoading: false,
  errorMessage: '',
  bankListData: [],
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setBankList: action((state, payload) => {
    state.bankListData = payload
  }),
  getBankList: thunk(async (actions, _payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/user/v1/bank`,
        method: 'GET',
        token: getCookie(authConfig.idTokenName),
      })
      actions.setLoading(false)
      actions.setBankList(get(response, 'data.data', []))
    } catch (error) {
      actions.setError(error.message)
    }
  }),
}

export default Category
