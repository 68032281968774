import Stack from 'components/commons/Stack'
import { styled } from '@mui/material/styles'
import Typography from 'components/commons/Typography'
import Button from 'components/commons/Button'

export const GroupIconContainer = styled(Stack)({
  width: '15%',
  height: '60px',
  marginLeft: '18px',
  svg: {
    width: '36px',
    height: '36px',
  },
})
export const ReferralCodeContainer = styled(Stack)({
  width: '100%',
  height: 'auto',
  marginLeft: '24px',
  paddingRight: '14px !important',
})
export const ReferralCodeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  lineHeight: '12px',
}))

export const ReferralCodeValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}))

export const StyledStack = styled(Stack)({
  width: '100%',
})
export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  background: theme.colors.gradient1,
  display: 'block',
  marginRight: '24px',
}))

export const StyledInfoContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.tertiery,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  borderRadius: 8,
  margin: '8px 16px 0px 16px',
  svg: {
    color: theme.palette.info.main,
    marginRight: 8,
  },
}))

export const StyledInfoDesc = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  p: {
    fontSize: 12,
    lineHeight: '16px',
  },
}))

export const StyledMoreReferralLabel = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.blue[500],
    cursor: 'pointer',
  }),
)

export const StyledIconContainer = styled(Button)(({ theme }) => ({
  background: theme.palette.info.main,
  width: '36px',
  height: '36px',
  borderRadius: '100px',
  minWidth: '36px',
  padding: '8px',
  svg: {
    color: 'white',
    width: '20px',
    height: '20px',
  },
  marginLeft: '0px !important',
}))
