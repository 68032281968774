import { useUserAgent as getUserAgent } from 'next-useragent'
import appConfig from 'configs/app'
import includes from 'lodash/includes'

export const isIOS = (
  version: number,
  uaString?: string,
): boolean => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  return ua.isIos && ua.osVersion >= version
}

export const isAndroid = (uaString?: string): boolean => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  return ua.isAndroid
}

export const isMobile = (uaString?: string): boolean => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  return ua.isMobile
}

export const isDesktop = (uaString?: string): boolean => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  return ua.isDesktop
}

export const isTablet = (uaString): boolean => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  return ua.isTablet || ua.isIpad
}

export const isOptimalBrowser = (): boolean => {
  const ua = getUserAgent(window.navigator.userAgent)
  if (ua.isMobile) {
    return includes(
      appConfig.tiptipBrowserSupport,
      ua.browser.toLowerCase(),
    )
  }
  return ua.isChrome || ua.isFirefox || ua.isSafari
}

export const getBrowser = (
  withVersion?: boolean,
  uaString?: string,
): string => {
  let ua
  if (uaString) {
    ua = getUserAgent(uaString)
  } else {
    ua = getUserAgent(window.navigator.userAgent)
  }
  if (withVersion) {
    return `${ua.browser} v${ua.browserVersion}`
  }
  return ua?.browser || 'Unknown Browser'
}
