import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import IIncomeBalanceModel from 'stores/domains/Creator/Income/interface'
import { setDefaultProperties } from 'helpers/analytics'

const IncomeBalanceModel: IIncomeBalanceModel = {
  isLoading: true,
  isError: false,
  incomeBalanceData: {
    balance: 0,
    is_withdraw_eligible: false,
    bank_verification_status: '',
    withdraw_message: '',
    last_withdraw_status: '',
    is_promoter: false,
    is_need_agreement: false,
    is_withdraw_need_agreement: false,
    note_list: '',
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIncomeBalance: action((state, payload) => {
    setDefaultProperties({ income_balance: payload?.balance })
    state.incomeBalanceData = payload
  }),
  getIncomeBalance: thunk(
    async (actions, _payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/wallet/v2/income/balance`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setLoading(false)
        actions.setIncomeBalance({
          bank_verification_status: 'UNVERIFIED', // Provide default value
          ...response?.data?.data,
        })
      } catch (error) {
        actions.setError(error.message)
      }
    },
  ),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default IncomeBalanceModel
