import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'

import { useMissionCategoryStore } from 'stores/domains/Social'
import { getMissionCategories } from 'services/social'

export const useCommunityMissionCategories = () => {
  const { setMissionCategory } = useMissionCategoryStore((state) => ({
    setMissionCategory: state.setMissionCategory,
  }))

  return useQuery({
    queryKey: ['community-mission-categories'],
    queryFn: async () => {
      try {
        const response = await getMissionCategories('COMMUNITY')
        return get(response, 'data.data', {})
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setMissionCategory(data),
  })
}
