import {
  Box,
  LinearProgress,
  LinearProgressProps,
  useTheme,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { MouseEventHandler } from 'react'

export interface LinearProgressWithIconProps
  extends LinearProgressProps {
  onCancel: MouseEventHandler<SVGSVGElement>
}

export default function LinearProgressWithIcon(
  props: LinearProgressWithIconProps,
) {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: '12px',
            borderRadius: '8px',
            background: theme.palette.background.tertiery,
            ...props.sx,
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.success.main,
            },
          }}
        />
      </Box>
      <CloseRoundedIcon
        sx={(theme) => ({
          color: theme.palette.text.tertiery,
          cursor: 'pointer',
        })}
        onClick={props.onCancel}
      />
    </Box>
  )
}
