import { useTheme } from '@mui/material/styles'
import appConfig from 'configs/app'
import redirect from 'helpers/redirect'
import MainLogo from 'assets/logos/logo.svg'
import { SxProps } from '@mui/material'
import Twitter from 'assets/svg/socialmedia/display/Twitter.svg'
import Youtube from 'assets/svg/socialmedia/display/Youtube.svg'
import Instagram from 'assets/svg/socialmedia/display/Instagram.svg'
import Linkedin from 'assets/svg/socialmedia/display/Linkedin.svg'
import Facebook from 'assets/svg/socialmedia/display/Facebook.svg'
import Tiktok from 'assets/svg/socialmedia/display/Tiktok.svg'
import Pinterest from 'assets/svg/socialmedia/display/Pinterest.svg'
import { openInNewTab } from 'helpers/redirector'
import { useStoreState } from 'stores/hooks'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import {
  companyInfo,
  downloadLinks,
  socmedLinks,
} from 'constants/common'
import {
  StyledContainer,
  StyledDivider,
  StyledEmailIcon,
  StyledImage,
  StyledSectionName,
  StyledWhatsAppIcon,
  StyledWorkIcon,
  LinkContainer,
  ContactAddress,
  ContactContainer,
  ContactFooter,
  ContactItem,
  LinkLabel,
  SocialMediaLabel,
} from './styled'
import Typography from 'components/commons/Typography'
import Box from 'components/commons/Box'
import Grid from 'components/commons/Grid'
import { useTranslation } from 'react-i18next'

type IFooterProps = {
  sx?: SxProps
}

function Footer({ sx = {} }: IFooterProps) {
  const { t } = useTranslation()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const { headerType } = useStoreState((state) => state.screenLayout)
  const theme = useTheme()
  const redirectURL = (url) => {
    window.open(url, '_blank')
  }

  return (
    <>
      <StyledDivider />
      <Box
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.border.minimal}`,
          background: theme.palette.background.secondary,
          paddingBottom: '24px',
        })}
      >
        <StyledContainer sx={sx}>
          <Grid
            width="100%"
            container
            xl
            textAlign={{ xs: 'center', md: 'left' }}
          >
            <Grid item xs={12} md={3}>
              <LinkContainer onClick={() => redirect('/')}>
                <MainLogo />
              </LinkContainer>
              <Typography variant="normalBold">
                PT. Tiptip Network Indonesia
              </Typography>
              <ContactAddress
                textAlign={{ xs: 'center', md: 'left' }}
                px={{ xs: '32px' }}
                pl={{ md: '0px' }}
                sx={{ marginTop: '8px' }}
              >
                {companyInfo.address}
              </ContactAddress>

              <ContactContainer sx={{ marginTop: { xs: '20px' } }}>
                <StyledWhatsAppIcon />
                <ContactItem>
                  <SocialMediaLabel>Whatsapp</SocialMediaLabel>
                  <ContactAddress>
                    {socmedLinks.whatsapp}
                  </ContactAddress>
                </ContactItem>
              </ContactContainer>

              <ContactContainer>
                <StyledEmailIcon />
                <ContactItem>
                  <SocialMediaLabel>Email</SocialMediaLabel>
                  <ContactAddress>{socmedLinks.email}</ContactAddress>
                </ContactItem>
              </ContactContainer>

              <ContactContainer>
                <StyledWorkIcon />
                <ContactItem>
                  <SocialMediaLabel>
                    {t('businessInfo')}
                  </SocialMediaLabel>
                  <ContactAddress>
                    {companyInfo.business}
                  </ContactAddress>
                </ContactItem>
              </ContactContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ marginTop: { xs: '4px', md: '12px' } }}
            >
              <StyledSectionName>{t('company')}</StyledSectionName>

              <LinkLabel
                onClick={() => {
                  trackEvent.home('click_about_us', trackerData)
                  redirectURL(appConfig.aboutTiptipUrl)
                }}
                sx={{ marginTop: { xs: '16px', md: '24px' } }}
              >
                {t('aboutUs')}
              </LinkLabel>
              <LinkLabel
                onClick={() => {
                  trackEvent.home('click_tnc', trackerData)
                  redirectURL(
                    `${appConfig.supportCenterUrl}solutions/articles/72000528312-syarat-dan-ketentuan`,
                  )
                }}
              >
                {t('termsAndConditions')}
              </LinkLabel>
              <LinkLabel
                onClick={() => {
                  trackEvent.home('click_help_center', trackerData)
                  redirectURL(`${appConfig.supportCenterUrl}home`)
                }}
              >
                {t('supportCenter')}
              </LinkLabel>
              <LinkLabel
                onClick={() => {
                  trackEvent.home('click_career', trackerData)
                  redirectURL(
                    `${appConfig.aboutTiptipUrl}/comingsoon`,
                  )
                }}
              >
                {t('career')}
              </LinkLabel>
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              sx={{ marginTop: { xs: '12px' } }}
            >
              <StyledSectionName>{t('followUs')}</StyledSectionName>
              <Box
                sx={{
                  marginTop: { xs: '16px', md: '24px' },
                  marginRight: { xs: '0px', md: '90px' },
                  svg: {
                    marginRight: '16px',
                    cursor: 'pointer',
                  },
                }}
              >
                <Instagram
                  onClick={() => {
                    trackEvent.home(
                      'click_company_instagram',
                      trackerData,
                    )
                    redirectURL(socmedLinks.instagram)
                  }}
                  sx={{ cursor: 'pointer' }}
                />
                <Twitter
                  onClick={() => {
                    trackEvent.home(
                      'click_company_twitter',
                      trackerData,
                    )
                    redirectURL(socmedLinks.twitter)
                  }}
                  sx={{ cursor: 'pointer' }}
                />
                <Youtube
                  onClick={() => {
                    trackEvent.home(
                      'click_company_youtube',
                      trackerData,
                    )
                    redirectURL(socmedLinks.youtube)
                  }}
                  sx={{ cursor: 'pointer' }}
                />
                <Linkedin
                  onClick={() => {
                    trackEvent.home(
                      'click_company_linkedin',
                      trackerData,
                    )
                    redirectURL(socmedLinks.linkedin)
                  }}
                />
                <Facebook
                  onClick={() => {
                    trackEvent.home(
                      'click_company_facebook',
                      trackerData,
                    )
                    redirectURL(socmedLinks.facebook)
                  }}
                />
                <Tiktok
                  onClick={() => {
                    trackEvent.home(
                      'click_company_tiktok',
                      trackerData,
                    )
                    redirectURL(socmedLinks.tiktok)
                  }}
                />
                <Pinterest
                  onClick={() => {
                    trackEvent.home(
                      'click_company_pinterest',
                      trackerData,
                    )
                    redirectURL(socmedLinks.pinterest)
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              sx={{ marginTop: { xs: '24px', md: '12px' } }}
            >
              <StyledSectionName>Download App</StyledSectionName>
              <Box
                sx={{
                  marginTop: { xs: '16px', md: '24px' },
                  marginBottom: { xs: '32px' },
                  flexDirection: { md: 'column', lg: 'row' },
                }}
                justifyContent="space-evenly"
                display="flex"
              >
                <StyledImage
                  onClick={() => {
                    trackEvent.home(
                      'click_download_app_android',
                      trackerData,
                    )
                    openInNewTab(downloadLinks.googlePlay)
                  }}
                  src={`${appConfig.assetBaseUrl}/webp/playstore_logo.webp`}
                  sx={{
                    marginRight: { xs: '14px', md: 0, lg: '14px' },
                    cursor: 'pointer',
                  }}
                />
                <StyledImage
                  onClick={() => {
                    trackEvent.home(
                      'click_download_app_ios',
                      trackerData,
                    )
                    openInNewTab(downloadLinks.appStore)
                  }}
                  src={`${appConfig.assetBaseUrl}/webp/appstore_logo.webp`}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
        <StyledDivider sx={sx} />
        <ContactAddress
          sx={{
            marginTop: '24px !important',
            padding: '0 60px !important',
            textAlign: 'center',
            color: theme.palette.text.secondary,
            display: headerType === 'search' && {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
          Kementerian Perdagangan Republik Indonesia
        </ContactAddress>
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '8px',
            display:
              headerType === 'search'
                ? {
                    xs: 'none',
                    md: 'flex',
                  }
                : 'flex',
          }}
        >
          <StyledWhatsAppIcon />
          <ContactAddress
            sx={{
              marginLeft: { xs: '4px' },
              display: headerType === 'search' && {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            0853-1111-1010
          </ContactAddress>
        </Box>
      </Box>

      <ContactFooter
        sx={{
          display: headerType === 'search' && {
            xs: 'none',
            md: 'block',
          },
          marginBottom: {
            xs: '4.2rem',
            md: 0,
          },
        }}
      >
        PT. TIPTIP NETWORK INDONESIA © 2022 - 2024
      </ContactFooter>
    </>
  )
}

export default Footer
