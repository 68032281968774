import {
  useFlag,
  useFlagsStatus,
  useUnleashClient,
  useUnleashContext,
} from '@unleash/proxy-client-react'
import isEmpty from 'helpers/is-empty'
import { getLocalStorage } from 'helpers/local-storage'
import { useEffect } from 'react'

export function useFeatureFlags(flagsName) {
  const enabled = useFlag(flagsName)
  const updateContext = useUnleashContext()
  const { flagsReady, flagsError } = useFlagsStatus()
  //example feature flags
  const client = useUnleashClient()
  //example start clients manually
  useEffect(() => {
    const asyncProcess = async () => {
      const userId = getLocalStorage('userId')?.id
      //start the feature flags request
      client.start()
      if (!isEmpty(userId)) {
        await updateContext({ userId })
      }
      //Stop the feature flags request
      client.stop()
    }
    asyncProcess()
  }, [])

  if (flagsReady && enabled && !flagsError) {
    return true
  }
}
