import { IReadNotificationParams } from 'constants/notification'
import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'

interface Params {
  page?: number
  limit?: number
  type?: 1 | 2 // transaction | information
}

export const getListNotification = async (params?: Params) => {
  return await apiClient({
    url: 'message/v1/notification/list',
    method: 'GET',
    params,
    token: getIdToken(),
  })
}

export const readNotification = async ({
  notification_id,
  bulk,
}: IReadNotificationParams) => {
  return await apiClient({
    url: 'message/v1/notification/read',
    method: 'PUT',
    data: {
      notification_id,
      bulk,
    },
    token: getIdToken(),
  })
}
