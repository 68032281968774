import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.699 40.918h24.042v22.81H12.322A4.624 4.624 0 0 1 7.7 59.104V40.918Z"
        fill="#FC8032"
      />
      <path
        d="M55.783 40.918H31.741v22.81H51.16a4.623 4.623 0 0 0 4.623-4.624V40.918ZM5.673 36.866c-.769-1.537.349-3.345 2.068-3.345h18.616c.849 0 1.629.464 2.033 1.21l3.351 6.187H7.7l-2.026-4.052Z"
        fill="#FDAE2A"
      />
      <path
        d="M57.81 36.866c.768-1.537-.35-3.345-2.068-3.345H37.125c-.848 0-1.629.464-2.032 1.21l-3.352 6.187h24.043l2.026-4.052Z"
        fill="#FC8032"
      />
      <circle cx={32} cy={15} r={10.5} fill="url(#a)" />
      <path
        d="M32.104 9.656h-.208l-1.709 3.454h3.626l-1.71-3.454ZM34.898 13.11h3.352l-1.345-2.723c-.22-.448-.669-.73-1.163-.73H33.19l1.709 3.453ZM38.094 14.097h-5.607v6.81l5.607-6.81ZM31.513 20.906v-6.809h-5.607l5.607 6.81ZM29.102 13.11l1.703-3.454h-2.547c-.494 0-.942.283-1.163.73L25.75 13.11h3.352Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.247 20.806c-.766 0-1.387.62-1.387 1.387v.694h-.694a1.387 1.387 0 1 0 0 2.774h.694v.693a1.387 1.387 0 0 0 2.774 0v-.693h.694a1.387 1.387 0 1 0 0-2.774h-.694v-.694c0-.766-.62-1.387-1.387-1.387ZM48.456 3.468c-.766 0-1.387.62-1.387 1.387v.693h-.694a1.387 1.387 0 0 0 0 2.774h.694v.694a1.387 1.387 0 1 0 2.774 0v-.694h.693a1.387 1.387 0 1 0 0-2.774h-.693v-.693c0-.766-.62-1.387-1.387-1.387Z"
        fill="#43AC6D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.07 2.452a1.387 1.387 0 0 0-1.96 0l-.491.49-.49-.49a1.387 1.387 0 0 0-1.962 1.962l.49.49-.49.49a1.387 1.387 0 1 0 1.961 1.962l.49-.49.491.49a1.387 1.387 0 0 0 1.962-1.962l-.49-.49.49-.49a1.387 1.387 0 0 0 0-1.962Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.812 21.822a1.387 1.387 0 0 0-1.961 0l-.49.49-.491-.49a1.387 1.387 0 1 0-1.962 1.961l.49.49-.49.491a1.387 1.387 0 1 0 1.962 1.962l.49-.49.49.49a1.387 1.387 0 1 0 1.962-1.962l-.49-.49.49-.49a1.387 1.387 0 0 0 0-1.962Z"
        fill="url(#c)"
      />
      <circle cx={8.091} cy={11.559} r={1.156} fill="#3B95FF" />
      <circle cx={31.741} cy={1.156} r={1.156} fill="#3B95FF" />
      <circle cx={56.547} cy={12.715} r={1.156} fill="#3B95FF" />
      <path
        d="M10.272 15.84a.929.929 0 0 1 .083-1.486.878.878 0 0 1 1.013.033c1.313.998 4.9 3.852 6.548 6.419 1.586 2.47 2.688 6.795 3.053 8.368a.868.868 0 0 1-.379.924c-.518.337-1.218.06-1.381-.535-.511-1.86-1.604-5.41-3.026-7.601-1.44-2.217-4.381-4.834-5.91-6.123ZM30.786 28.406a.82.82 0 0 1 .797-.976c.352 0 .66.23.75.57.215.83.576 2.393.564 3.535-.011 1.095-.359 2.605-.567 3.416a.763.763 0 0 1-.745.57.817.817 0 0 1-.79-.977c.165-.844.368-2.072.368-3.009 0-.953-.21-2.25-.377-3.13ZM53.099 15.84a.929.929 0 0 0-.084-1.486.878.878 0 0 0-1.012.033c-1.313.998-4.901 3.852-6.549 6.419-1.586 2.47-2.687 6.795-3.052 8.368a.868.868 0 0 0 .379.924c.517.337 1.218.06 1.381-.535.51-1.86 1.603-5.41 3.026-7.601 1.44-2.217 4.38-4.834 5.91-6.123Z"
        fill="#3B95FF"
      />
      <defs>
        <linearGradient
          id="a"
          x1={32}
          y1={4.5}
          x2={32}
          y2={25.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8ABFE" />
          <stop offset={1} stopColor="#787DFC" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={19.071}
          y1={7.356}
          x2={14.167}
          y2={2.452}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1C4F" />
          <stop offset={1} stopColor="#FF223E" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={56.812}
          y1={26.726}
          x2={51.908}
          y2={21.822}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1C4F" />
          <stop offset={1} stopColor="#FF223E" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
