import { useQuery } from '@tanstack/react-query'
import { useProfileUserStore } from 'stores/domains/User'
import { getIdToken } from 'helpers/auth'
import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import { getLocalStorage } from 'helpers/local-storage'
import { getSessionStorage } from 'helpers/session-storage'
import { useRouter } from 'next/router'
import * as service from 'services/user'

export const useProfileUser = () => {
  const token = getIdToken()
  const { pathname } = useRouter()
  const authMethod = getLocalStorage('authMethod')

  const { isError, setUserProfile, setUserError } =
    useProfileUserStore((state) => ({
      isError: state.isError,
      setUserProfile: state.setUserProfile,
      setUserError: state.setError,
    }))

  return useQuery({
    queryKey: ['profile-user'],
    queryFn: async () => {
      if (!token) return {}
      try {
        const response = await service.getUserProfile()
        return response?.data
      } catch (error) {
        if (
          error?.response?.data?.code ===
            'POST_REGISTRATION_INCOMPLETE' &&
          !pathname.includes('/user/post-registration')
        ) {
          if (authMethod === 'google') {
            const prevPath: string =
              getSessionStorage('oAuthRoute')?.replace('?', '&') ??
              '/'
            redirect(`/user/post-registration?ref=${prevPath}`, {
              isReplace: true,
            })
          } else {
            let ref: string = '/'
            if (!isEmpty(getSessionStorage('currentQuery')?.ref)) {
              ref = getSessionStorage('currentQuery').ref
            } else {
              ref = getSessionStorage('currentPath') ?? '/'
            }
            const newRef = ref?.replace('?', '&')
            redirect(`/user/post-registration?ref=${newRef}`, {
              isReplace: true,
            })
          }
        }
        throw error
      }
    },
    onSuccess: (data) => setUserProfile(data),
    onError(err) {
      if (!isError) setUserError(err)
    },
    enabled: false,
    retry: 1,
    refetchOnWindowFocus: false,
  })
}

export const useProfileUsername = () => {
  const token = getIdToken()

  const { isError, setUsername, setUserError } = useProfileUserStore(
    (state) => ({
      isError: state.isError,
      setUsername: state.setUsername,
      setUserError: state.setError,
    }),
  )

  return useQuery({
    queryKey: ['profile-user-name', token],
    queryFn: async () => {
      if (!token) return {}
      try {
        const response = await service.getUsername(token)
        return response?.data
      } catch (error) {
        throw error
      }
    },
    onSuccess: (data) => setUsername(data),
    onError(err) {
      if (!isError) setUserError(err)
    },
    enabled: false,
    retry: 1,
    refetchOnWindowFocus: false,
  })
}

export const useProfilePhone = () => {
  const token = getIdToken()
  return useQuery({
    queryKey: ['profile-phone', token],
    queryFn: async () => {
      if (!token) return {}
      try {
        const response = await service.getPhoneVerified(token)
        return response?.data
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    refetchOnWindowFocus: false,
  })
}
