import MaintenanceBody from 'components/domains/Home/MaintenanceBody'
import ErrorResponseCode from 'components/commons/ErrorResponseCode'
import redirect from 'helpers/redirect'
import appConfig from 'configs/app'

interface IErrorPayment {
  itemType: string
  errorMessageCheckout?: String
  isErrorCart?: boolean | any
  isErrorCheckout?: boolean | any
  isErrorVaStatus?: boolean | any
  isErrorVaInstruction?: boolean | any
  isErrorVoucher?: boolean | any
  setShowTopUpModal?: Function
  cancelTransaction?: Function
  onClosePaymentHandler: Function
  onRetryHandler: Function
  onContinueTransaction: Function
  isPaymentLoading: boolean
}

export default function ErrorPayment({
  itemType,
  errorMessageCheckout,
  isErrorCart,
  isErrorCheckout,
  isErrorVaStatus,
  isErrorVaInstruction,
  isErrorVoucher,
  setShowTopUpModal,
  cancelTransaction,
  onRetryHandler,
  onClosePaymentHandler,
  onContinueTransaction,
  isPaymentLoading,
}: IErrorPayment) {
  const errorSource =
    isErrorCheckout?.response?.data?.code ||
    isErrorCart?.response?.data?.code ||
    isErrorVaStatus?.reponse?.data?.code ||
    isErrorVaInstruction?.response?.data?.code ||
    isErrorVoucher?.response?.data?.code
  const errorStatus =
    isErrorCheckout?.response?.status ||
    isErrorCart?.response?.status ||
    isErrorVaStatus?.response?.status ||
    isErrorVaInstruction?.response?.status ||
    isErrorVoucher?.response?.status

  const ErrorRender = () => {
    if (errorStatus === 503) {
      return <MaintenanceBody isButtonHide />
    } else if (
      errorSource === 'INSUFFICIENT_QUOTA' ||
      errorSource === 'SESSION_FULLY_BOOKED'
    ) {
      if (itemType === 'session') {
        return (
          <ErrorResponseCode
            code={'SESSION_FULLY_BOOKED'}
            showCTA
            onCTA={() => {
              onClosePaymentHandler()
              window.location.reload()
            }}
            sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
          />
        )
      } else {
        return (
          <ErrorResponseCode
            code={'INSUFFICIENT_QUOTA'}
            showCTA
            onCTA={(cta) => {
              switch (cta) {
                case 'browse_events':
                  redirect('/browse?status=event')
                case 'back_to_event':
                  window.location.reload()
              }
            }}
            sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
          />
        )
      }
    } else if (errorSource === 'USER_OVERBOOKED') {
      return (
        <ErrorResponseCode
          code={'USER_OVERBOOKED'}
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorSource === 'NOT_ENOUGH_QUOTA') {
      return (
        <ErrorResponseCode
          code={'NOT_ENOUGH_QUOTA'}
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
            window.location.reload()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorSource === 'CHECKOUT_INVALID_STATUS') {
      return (
        <ErrorResponseCode
          code={'CHECKOUT_INVALID_STATUS'}
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
            window.location.reload()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorStatus === 500) {
      return (
        <ErrorResponseCode
          code={'FAIL'}
          showCTA
          onCTA={() => {
            onRetryHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (isErrorCheckout?.response?.status === 406) {
      return (
        //if coin balance < price
        <ErrorResponseCode
          code={'INSUFFICIENT_BALANCE'}
          showCTA
          onCTA={(cta) => {
            switch (cta) {
              case 'back':
                onRetryHandler()
                break
              case 'topup':
                setShowTopUpModal()
                onRetryHandler()
            }
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorSource === 'SALE_NOT_STARTED') {
      return (
        <ErrorResponseCode
          code={'SALE_NOT_STARTED'}
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorSource === 'INVALID_SALES_PERIOD') {
      return (
        <ErrorResponseCode
          code="INVALID_SALES_PERIOD"
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorSource === 'PRODUCT_UNAVAILABLE') {
      return (
        <ErrorResponseCode
          code="PRODUCT_UNAVAILABLE"
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        />
      )
    } else if (errorStatus === 400 && itemType === 'coin') {
      return (
        //topup error
        <ErrorResponseCode
          code="BAD_REQUEST_COIN"
          showCTA
          onCTA={() => {
            onRetryHandler()
          }}
          sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
          data={errorMessageCheckout}
        />
      )
    } else if (errorStatus === 404) {
      if (errorSource === 'BOOKING_EXPIRED') {
        return (
          <ErrorResponseCode
            code="BOOKING_EXPIRED"
            showCTA
            onCTA={() => {
              onClosePaymentHandler()
              window.location.reload()
            }}
            sxContainer={{
              paddingBottom: { xs: '16px', md: 0 },
            }}
          />
        )
      } else
        return (
          <ErrorResponseCode
            code="NOT_FOUND"
            showCTA
            onCTA={() => {
              onClosePaymentHandler()
              window.location.reload()
            }}
            sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
          />
        )
    } else if (errorSource === 'ID_NUMBER_ALREADY_USED') {
      return (
        <ErrorResponseCode
          code="ID_NUMBER_ALREADY_USED"
          data={
            isErrorCheckout?.response?.data?.data ||
            isErrorCart?.response?.data?.data
          }
          showCTA
          onCTA={(cta) => {
            switch (cta) {
              case 'contact_cs':
                window.open(
                  `${appConfig.supportCenterUrl}home`,
                  '_blank',
                )
                break
              case 'close':
                onClosePaymentHandler()
            }
          }}
        />
      )
    } else if (errorSource === 'GUEST_PENDING_TRANSACTION') {
      return (
        <ErrorResponseCode
          code="GUEST_PENDING_TRANSACTION"
          data={
            isErrorCheckout?.response?.data.data ||
            isErrorCart?.response?.data.data
          }
          disabled={isPaymentLoading}
          showCTA
          onCTA={(cta) => {
            switch (cta) {
              case 'cancel':
                cancelTransaction()
                break
              case 'continue':
                onContinueTransaction()
            }
          }}
        />
      )
    } else if (errorSource === 'INVALID_VOUCHER') {
      return (
        <ErrorResponseCode
          code="INVALID_VOUCHER"
          showCTA
          onCTA={() => {
            onClosePaymentHandler()
          }}
        />
      )
    } else {
      return (
        <ErrorResponseCode
          showCTA
          onCTA={(cta) => {
            switch (cta) {
              case 'close':
                onClosePaymentHandler()
            }
          }}
        />
      )
    }
  }

  return <>{ErrorRender()}</>
}
