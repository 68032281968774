import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'

interface BubbleUnreadProps {
  text: string
}

export default function BubbleUnread({ text }: BubbleUnreadProps) {
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
      sx={{
        ':before': {
          content: '""',
          width: '100%',
          height: '1px',
          position: 'absolute',
          left: 0,
        },
      }}
    >
      <Stack paddingX={1} zIndex={1}>
        <Stack
          p={1}
          borderRadius="4px"
          textTransform="uppercase"
          m={0}
        >
          <Typography size="xs" bold color="primary" m={0}>
            {text}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
