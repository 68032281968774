const setSessionStorage = (
  key: string,
  value: string | number | object | Array<any>,
): void => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.sessionStorage
  ) {
    return
  }
  if (value && typeof value === 'string') {
    sessionStorage.setItem(key, value)
  } else {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

const getSessionStorage = (key: string): any => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.sessionStorage
  ) {
    return undefined
  }
  const storageValue = sessionStorage.getItem(key)
  if (
    storageValue === null ||
    storageValue === undefined ||
    storageValue === 'undefined'
  )
    return undefined
  try {
    var o = JSON.parse(storageValue)
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}
  return storageValue
}

const destroySessionStorage = (key: string): void => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.sessionStorage
  ) {
    return
  }
  sessionStorage.removeItem(key)
}

interface PayloadChannelInfo {
  rtmToken: string
  rtcToken: string
  username: string
  rtm_status?: 'SUCCESS' | 'FAILED'
  rtc_status?: 'SUCCESS' | 'FAILED'
  totalTipping?: number
}

const getChannelInfo = (sessionID: string): PayloadChannelInfo => {
  return getSessionStorage(`id_session_${sessionID}`)
}

const setChannelInfo = (
  sessionID: string,
  payload: PayloadChannelInfo,
) => {
  setSessionStorage(`id_session_${sessionID}`, payload)
}

const updateChannelInfo = (
  sessionID: string,
  key: string,
  value: string,
) => {
  const channelInfo: PayloadChannelInfo = getChannelInfo(sessionID)
  setSessionStorage(`id_session_${sessionID}`, {
    ...channelInfo,
    [key]: value,
  })
}

export {
  setSessionStorage,
  destroySessionStorage,
  getSessionStorage,
  setChannelInfo,
  getChannelInfo,
  updateChannelInfo,
}
