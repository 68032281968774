import { useInfiniteQuery } from '@tanstack/react-query'
import { getErrorMessage } from 'helpers/error'
import get from 'lodash/get'
import {
  ICommunityContentParams,
  getCommunityContent,
} from 'services/content'

export const useListCommunityContent = (
  id: string,
  params: ICommunityContentParams,
) => {
  return useInfiniteQuery({
    queryKey: ['community-content-list', id, params],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await getCommunityContent(id, {
          lc: 10,
          pc: pageParam,
          pe: pageParam,
          ...params,
        })
        return {
          data: get(
            response,
            params.tab === 'event'
              ? 'data.data.event'
              : 'data.data.content',
            [],
          ),
          metadata: get(response, 'data._metadata', {}),
        }
      } catch (error) {
        throw new Error(getErrorMessage(error))
      }
    },
    enabled: false,
    retry: false,
    getNextPageParam: ({ metadata }) => {
      const isLoadMore =
        params.tab !== 'event'
          ? metadata?.load_more_content
          : metadata?.load_more_event
      if (!isLoadMore) {
        return undefined
      }
      return params.tab !== 'event'
        ? metadata?.page_content + 1
        : metadata?.page_event + 1
    },
  })
}
