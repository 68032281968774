import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ITippingCoinModel from 'stores/domains/Wallet/models/TippingCoin/interface'

const TippingCoinModel: ITippingCoinModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  coins: [],

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setCoins: action((state, payload) => {
    state.coins = payload
  }),
  getTippingCoin: thunk(async (actions, _payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: '/wallet/v1/coin/list',
        method: 'GET',
        token: getCookie(authConfig.idTokenName),
      })
      actions.setLoading(false)
      actions.setCoins(get(response, 'data.data', {}))
    } catch (error) {
      if (get(error, 'response.data.code') === 'FAIL') {
        actions.setError(
          get(error, 'response.data.errors', ['unknown error']).join(
            ',',
          ),
        )
      } else {
        actions.setError(get(error, 'response.data.message'))
      }
    }
  }),
}

export default TippingCoinModel
