import { StateCreator } from 'zustand'
import { IMission, IMissionCategoryStore } from './interface'

const INITIAL_DATA: IMission = {
  percentage: 0,
  sections: [],
  isCompleted: false,
  isDialog: false,
  isDialogClosed: false,
}

/* eslint-disable-next-line no-unused-vars */
const resetters: (() => void)[] = []

export const createMissionCategoryStore: StateCreator<
  IMissionCategoryStore
> = (set, _get) => {
  resetters.push(() => set(INITIAL_DATA))

  return {
    ...INITIAL_DATA,
    hideDialog() {
      set({
        isDialog: false,
        isDialogClosed: true,
      })
    },
    setMissionCategory(data) {
      const isCompleted = data.percentage === 100
      set((state) => {
        return {
          ...data,
          isCompleted,
          isDialog: !state.isDialogClosed ? isCompleted : false,
        }
      })
    },
  }
}

export const resetMissionCategoryStore = () =>
  resetters.forEach((resetter) => resetter())
