import { ReactNode } from 'react'
import { FormProvider as Form, UseFormReturn } from 'react-hook-form'
import { styled } from '@mui/material'

type Props = {
  children: ReactNode
  methods: UseFormReturn<any>
  onSubmit?: VoidFunction
  autoComplete?: string
}

export default function FormProvider({
  children,
  onSubmit,
  methods,
  autoComplete,
}: Props) {
  return (
    <Form {...methods}>
      <StyledForm
        onSubmit={onSubmit}
        onKeyDown={(event) => {
          if (event.code === 'Enter') event.preventDefault()
        }}
        autoComplete={autoComplete}
      >
        {children}
      </StyledForm>
    </Form>
  )
}

const StyledForm = styled('form')(({ theme }) => ({
  '& .MuiInputBase-root:before': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-root:after': {
    borderBottom: 'none',
  },
  '& .MuiSelect-select': {
    padding: 0,
  },
  '& .MuiTextField-root': {
    marginBottom: 0,
  },
  '& .MuiTypography-root': {
    ...theme.typography.normalRegular,
    lineHeight: '21px',
  },
  '& .MuiFormHelperText-root, & .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.error.main,
    fontSize: '14px',
  },
}))
