import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'

import { useMissionDetailStore } from 'stores/domains/Social'
import { getMissionDetail } from 'services/social'

export const useCommunityMissionDetail = (id: string) => {
  const { setMissionDetail } = useMissionDetailStore((state) => ({
    setMissionDetail: state.setMissionDetail,
  }))

  return useQuery({
    queryKey: ['community-mission-detail', id],
    queryFn: async () => {
      try {
        const response = await getMissionDetail(id)
        return get(response, 'data.data', {})
      } catch (error) {
        throw error
      }
    },
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setMissionDetail(data),
  })
}
