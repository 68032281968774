import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'
import ICreatorSessionModel from 'stores/domains/Creator/Session/interface'

const CreatorSessionModel: ICreatorSessionModel = {
  isLoadingSession: true,
  isError: false,
  creatorSessionData: [],
  errorMessage: '',
  setLoadingSession: action((state, payload) => {
    state.isLoadingSession = payload
  }),
  setCreatorSession: action((state, payload) => {
    state.creatorSessionData = payload
  }),
  getCreatorSession: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoadingSession(true)
        let idToken = getCookie(authConfig.idTokenName)
        let url = ''
        if (!isEmpty(idToken)) {
          url =
            '/session/v1/' +
            payload.username +
            '/creator?page=1&limit=10'
        } else {
          url =
            '/session/guest/v1/' +
            payload.username +
            '/creator?page=1&limit=10'
          idToken = ''
        }
        const { apiClient } = injections
        const response = await apiClient({
          url: url,
          method: 'GET',
          token: idToken,
        })
        if (response?.data?.data.length) {
          actions.setCreatorSession(response.data?.data)
          actions.setLoadingSession(false)
        }
      } catch (error) {
        actions.error(error.response)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoadingSession = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CreatorSessionModel
