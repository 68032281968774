import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import get from 'lodash/get'
import IFilterCreatorModel from './interface'

const FilterCreatorModel: IFilterCreatorModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: [],
  setLoading: action((state, payload) => {
    state.isLoading = payload
    state.isError = false
    state.errorMessage = ''
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  onGetPurchaseFilterCreator: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)

        const { apiClient } = injections
        const resp = await apiClient({
          url: '/order/v1/purchased/filter-creator',
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
          params: {
            ...payload,
          },
        })
        actions.setLoading(false)
        actions.setData(get(resp.data, 'data', []))
      } catch (error) {
        actions.setError(error.message)
      }
    },
  ),
}

export default FilterCreatorModel
