import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M12.2124 12.1921H6.20428C4.55203 12.1921 3.2002 13.5439 3.2002 15.1962L3.2002 18.2002C3.2002 19.8525 4.55203 21.2043 6.20428 21.2043H7.70632V25.7104C7.70632 26.5366 8.38224 27.2125 9.20836 27.2125C10.0345 27.2125 10.7104 26.5366 10.7104 25.7104L10.7104 21.2043H12.2124L19.7226 25.7104V7.68595L12.2124 12.1921Z"
        fill="#FCBB82"
      />
      <path
        d="M23.4777 16.6983C23.4777 14.7006 22.6065 12.8982 21.2246 11.6665L21.2246 21.7151C22.6065 20.4985 23.4777 18.696 23.4777 16.6983Z"
        fill="#FCBB82"
      />
      <path
        d="M25.1922 16.1515C25.1922 16.9776 25.8682 17.6535 26.6943 17.6535L29.6984 17.6535C30.5245 17.6535 31.2004 16.9776 31.2004 16.1515C31.2004 15.3253 30.5245 14.6494 29.6984 14.6494H26.6943C25.8682 14.6494 25.1922 15.3253 25.1922 16.1515Z"
        fill="#FCBB82"
      />
      <path
        d="M24.1098 23.6688C23.6142 24.3297 23.7494 25.2459 24.4102 25.7266C25.2063 26.3124 26.0475 26.9432 26.8436 27.544C27.5044 28.0397 28.4357 27.9045 28.9164 27.2436C28.9164 27.2286 28.9314 27.2286 28.9314 27.2136C29.4271 26.5527 29.2919 25.6214 28.631 25.1408C27.8349 24.5399 26.9938 23.9091 26.2127 23.3233C25.5518 22.8276 24.6205 22.9778 24.1249 23.6387C24.1249 23.6537 24.1098 23.6688 24.1098 23.6688Z"
        fill="#FCBB82"
      />
      <path
        d="M28.9464 5.62925C28.9464 5.61423 28.9314 5.61423 28.9314 5.59921C28.4357 4.93831 27.5044 4.80313 26.8586 5.2988C26.0625 5.89962 25.2063 6.53048 24.4253 7.13129C23.7644 7.62697 23.6442 8.55823 24.1399 9.20411C24.1399 9.21913 24.1549 9.21913 24.1549 9.23415C24.6506 9.89505 25.5668 10.0302 26.2277 9.53456C27.0238 8.94876 27.8649 8.30288 28.661 7.70207C29.3069 7.22142 29.4271 6.29015 28.9464 5.62925Z"
        fill="#FCBB82"
      />
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
