import { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material'

import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import {
  Props as TextFieldProps,
  StyledTextField,
} from './RHFTextField'

type Props = Omit<TextFieldProps, 'action'>

export default function RHFTextField({
  name,
  label,
  id,
  ...other
}: Props) {
  const { control } = useFormContext()
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const renderErrorText = (message: string | undefined) => {
    if (message) return <>{message}</>
  }

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <InputLabel shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            variant="outlined"
            type={passwordVisibility ? 'text' : 'password'}
            id={id}
            fullWidth
            error={!!error}
            helperText={renderErrorText(error?.message)}
            noLabel={!Boolean(label)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    id={`c-users-${name}-icon`}
                    onClick={() =>
                      setPasswordVisibility((state) => !state)
                    }
                  >
                    {passwordVisibility ? (
                      <VisibilityOffRoundedIcon />
                    ) : (
                      <VisibilityRoundedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            {...other}
          />
        )}
      />
    </FormControl>
  )
}
