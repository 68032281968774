import {
  Divider as CommonDivider,
  DividerProps,
  styled,
} from '@mui/material'

interface ICommonDivider extends DividerProps {
  borderColor?: 'minimal' | 'subtitle' | 'bold'
}

const Divider = styled(CommonDivider)<ICommonDivider>(
  ({ borderColor = 'minimal', theme }) => ({
    borderColor: theme.palette.border[borderColor],
  }),
)

export default Divider
