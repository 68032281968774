import * as React from 'react'
import { NextRouter } from 'next/router'
import {
  setSessionStorage,
  getSessionStorage,
  destroySessionStorage,
} from 'helpers/session-storage'
import { skipCommonSessionURLs } from 'constants/no-back-redirect'

interface RoutingContextType {}

const RoutingContext = React.createContext<RoutingContextType>({})

interface RoutingProps {
  children: React.ReactNode
  router: NextRouter
}

const RoutingProvider = ({ children, router }: RoutingProps) => {
  const storePathValues = () => {
    if (
      typeof globalThis === 'undefined' ||
      !globalThis?.sessionStorage ||
      !globalThis?.location?.pathname
    ) {
      return
    }

    const { pathname } = globalThis.location || {}

    const prevPath = getSessionStorage('prevPath')
    if (prevPath === pathname) {
      // infinite loop detected, reset prevPath to home
      setSessionStorage('prevPath', '/')
      setSessionStorage('currentPath', pathname)
      setSessionStorage(
        'currentQuery',
        getSessionStorage('prevQuery'),
      )
      destroySessionStorage('prevQuery')
      return
    }

    const currentPath = getSessionStorage('currentPath')
    const currentQuery = getSessionStorage('currentQuery')
    // skip for some common urls
    if (skipCommonSessionURLs.includes(currentPath)) {
      return
    }
    if (currentPath !== pathname) {
      setSessionStorage('prevPath', currentPath)
      setSessionStorage('currentPath', pathname)
    }

    setSessionStorage('prevQuery', currentQuery)
    setSessionStorage('currentQuery', router.query)
  }

  React.useEffect(() => {
    // reset all history when reach homepage
    if (
      typeof globalThis !== 'undefined' &&
      globalThis?.location?.pathname === '/'
    ) {
      const { pathname } = globalThis.location || {}
      setSessionStorage('prevPath', pathname)
      setSessionStorage('currentPath', pathname)
      destroySessionStorage('currentQuery')
      destroySessionStorage('prevQuery')
    }
    if (router.isReady) {
      storePathValues()
    }
  }, [router.asPath, router.isReady, storePathValues])

  return (
    <RoutingContext.Provider value={{}}>
      {children}
    </RoutingContext.Provider>
  )
}

export { RoutingContext, RoutingProvider }
