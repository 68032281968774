import * as React from 'react'
import { styled, Typography, TypographyProps } from '@mui/material'

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '12px !important',
  lineHeight: '16px',
  letterSpacing: '0.0025em',
}))

interface TextErrorProps extends TypographyProps {
  text: string
}

export default function TextError(props: TextErrorProps) {
  return (
    <Text id="c-text-error" {...props}>
      {props.text}
    </Text>
  )
}
