/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { SxProps } from '@mui/material'
import Button, { ButtonVariant } from '../Button'
import Stack from '../Stack'
import Typography from '../Typography'

type ContainerProps = {
  padding?: string
}

const Container = styled('div')<ContainerProps>(
  ({ padding = '', theme }) => ({
    textAlign: 'center',
    padding: padding ? padding : '15% 20px 0 20px',
    svg: {
      fontSize: 80,
      color: theme.palette.info.main,
    },
  }),
)

const StyledTitle = styled(Typography)(({ theme }) => ({
  margin: '20px 0px 8px 0px',
  color: theme.palette.text.primary,
  ...theme.typography.h4,
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h4Desk,
  },
}))

const StyledDesc = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

const StyledBtn = styled(Button)({
  height: 36,
  textTransform: 'capitalize',
})

interface IAttention {
  image?: string
  title?: string | Element | JSX.Element
  desc?: string | Element | JSX.Element
  handleRedirectBtnFirst?: Function
  handleRedirectBtnTwo?: Function
  children: any
  textBtnFirst?: string
  textBtnTwo?: string
  padding?: string
  isRequestingBtnFirst?: boolean
  isRequestingBtnTwo?: boolean
  sx?: SxProps
  variantBtnFirst?: ButtonVariant
}

function Attention({
  image = '',
  title,
  desc,
  handleRedirectBtnFirst,
  handleRedirectBtnTwo,
  children,
  textBtnFirst = '',
  textBtnTwo = '',
  padding,
  isRequestingBtnFirst,
  isRequestingBtnTwo,
  sx,
  variantBtnFirst = 'outlined',
}: IAttention) {
  return (
    <Container sx={sx} padding={padding}>
      {image ? <img src={image} /> : children}
      {title && <StyledTitle>{title as ReactNode}</StyledTitle>}
      {desc && (
        <StyledDesc variant="normalRegular">
          {desc as ReactNode}
        </StyledDesc>
      )}
      {(textBtnFirst !== '' || textBtnTwo !== '') && (
        <Stack
          direction={'row'}
          gap={2}
          sx={{
            marginTop: '20px',
            justifyContent: 'space-around',
            Button: {
              width: textBtnTwo === '' ? '50%' : '100%',
            },
          }}
        >
          <StyledBtn
            buttonVariant={variantBtnFirst}
            disabled={isRequestingBtnFirst}
            onClick={() => handleRedirectBtnFirst()}
          >
            {textBtnFirst}
          </StyledBtn>
          {handleRedirectBtnTwo && (
            <StyledBtn
              disabled={isRequestingBtnTwo}
              onClick={() => handleRedirectBtnTwo()}
            >
              {textBtnTwo}
            </StyledBtn>
          )}
        </Stack>
      )}
    </Container>
  )
}

export default Attention
