const config = {
  accessTokenName: process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME,
  refreshTokenName: process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME,
  idTokenName: process.env.NEXT_PUBLIC_ID_TOKEN_COOKIE_NAME,
  enableAppleAccount:
    process.env.NEXT_PUBLIC_ENABLE_APPLE_ACCOUNT === 'true',
  refreshTokenExpiryDays: 90,
  affiliateExpiryDays: 7,
  loginPath: '/login',
  registerPath: '/sign-up',
  clearCookiesTimeout: 2500,
}
export default config
