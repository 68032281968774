import { MouseEventHandler } from 'react'
import { useTheme } from '@mui/material'
import Typography from 'components/commons/Typography'
import StateImage from 'components/commons/StateImage'
import appConfig from 'configs/app'
import ActionButton from 'components/commons/Button/Action'

interface IRequestExceedBody {
  backButtonAction?: MouseEventHandler<HTMLButtonElement>
  isButtonHide?: boolean
}
function RequestExceedBody({
  backButtonAction,
  isButtonHide,
}: IRequestExceedBody): JSX.Element {
  const theme = useTheme()

  return (
    <div style={{ textAlign: 'center' }}>
      <StateImage
        src={`${appConfig.assetBaseUrl}/webp/state_features.webp`}
        sx={{
          marginBottom: '24px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '35px',
        }}
      />
      <Typography
        variant="h3"
        color={theme.palette.text.primary}
        sx={{ marginBottom: '10px' }}
      >
        Melebihi Batasan Permintaan
      </Typography>
      <Typography
        variant="normalRegular"
        color={theme.palette.text.secondary}
        sx={{ marginBottom: '24px', display: 'block' }}
      >
        Anda telah melampaui batas permintaan akses tautan, silahkan
        coba lagi setelah 30 menit.
      </Typography>
      {!isButtonHide && (
        <ActionButton
          sx={{
            padding: '12px 24px',
            width: '100%',
          }}
          onClick={backButtonAction}
        >
          Ok, Saya Mengerti
        </ActionButton>
      )}
    </div>
  )
}

export default RequestExceedBody
