import { styled } from '@mui/material/styles'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

interface IContainerProps {
  read: string
}

export const StyledNotificationItemContainer = styled(
  Box,
)<IContainerProps>(({ theme, read }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '8px 16px',
  background:
    read === 'true' ? 'white' : theme.palette.action.ghostHover,
  [theme.breakpoints.up('md')]: {
    background:
      read === 'true' ? 'white' : theme.palette.action.ghostHover,
  },
  ':hover': {
    background: theme.palette.background.tertiery,
  },
  cursor: 'pointer',
  position: 'relative',
}))

export const StyledRedDot = styled(Box)(({ theme }) => ({
  background: theme.palette.tiptip[500],
  width: 8,
  height: 8,
  borderRadius: '100px',
  marginTop: '8px',
}))

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const StyledMessageTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  lineHeight: '21px',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
}))

export const StyledMessageDesc = styled(StyledMessageTitle)(
  ({ theme }) => ({
    ...theme.typography.normalRegular,
    color: theme.palette.text.secondary,
  }),
)

export const StyledDateLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  letterSpacing: '0.0025em',
  color: theme.palette.text.tertiery,
}))

export const StyledImageNotification = styled('img')({
  borderRadius: '8px',
  objectFit: 'cover',
  height: '64px',
})
