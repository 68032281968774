import { styled } from '@mui/material/styles'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'

export const Wrapper = styled(Stack)({
  padding: '10px',
})

export const ButtonAttention = styled(Button)({
  height: '36px',
  padding: '12px 16px',
})

export const ButtonRectangle = styled(ButtonAttention)({
  borderRadius: '4px',
})
