import { Menu, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from 'components/commons/Box'
import Button from 'components/commons/Button'
import Typography from 'components/commons/Typography'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    background: theme.palette.background.secondary,
    [theme.breakpoints.up('md')]: {
      width: '466px',
      marginTop: '20px',
      left: '50% !important',
    },
  },
}))

export const TabPanel = styled(Box)({
  height: `calc(100vh - 173px)`,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: 1,
  borderColor: theme.palette.tiptip[500],
  justifyContent: 'center',
  alignContent: 'center',
  '& .MuiTab-root': {
    color: theme.palette.text.secondary,
    ...theme.typography.normalRegular,
    lineHeight: '16px',
  },
  '& .Mui-selected': {
    color: `${theme.palette.text.primary} !important`,
    ...theme.typography.normalBold,
  },
}))

export const StyledLabelTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  lineHeight: '21px',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
}))

export const StyledMarkAllRead = styled(Button)(({ theme }) => ({
  ...theme.typography.normalRegular,
  lineHeight: '20px',
}))

export const StyledLabelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderBottomRightRadius: '6px',
  borderBottomLeftRadius: '6px',
})

export const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
