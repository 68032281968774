import { Avatar, useTheme } from '@mui/material'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import transformDateTime from 'helpers/transform/date-time'

import CreatorCaption from 'components/commons/CreatorCaption'
import Typography from 'components/commons/CustomTypography'
import { ChatPeer, Message } from 'stores/domains/User/Message'

export interface ChatSectionProps {
  peer: ChatPeer
  isSelected?: boolean
  isUnread?: boolean
  lastMessage?: Message
  onSelected?: () => void
  isSubscribed?: boolean
}

export default function ChatSection({
  peer,
  lastMessage,
  isUnread = false,
  ...props
}: ChatSectionProps) {
  const theme = useTheme()

  const handleClickChat = () => {
    if (props.onSelected) props.onSelected()
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="100%"
      borderLeft={`2px solid ${
        props.isSelected ? theme.palette.tiptip[500] : 'transparent'
      }`}
      bgcolor={props.isSelected && theme.palette.background.tertiery}
      sx={{
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme.palette.background.tertiery,
        },
        borderBottom: `1px solid ${theme.palette.border.bold}`,
        padding: '0px 16px',
      }}
      onClick={handleClickChat}
    >
      <Avatar
        sx={{ backgroundColor: theme.palette.purple[500] }}
        src={peer.profile_image_thumbnail}
        sizes="40px"
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.background.tertiery}`}
        flex={1}
        paddingY={0.5}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={1}
          flex={1}
        >
          <CreatorCaption
            title={peer.callName}
            isVerified={peer.creator?.verified}
            isSubscriber={peer.subscriber}
            isSubscribing={peer.creator?.subscribing}
            size="sm"
          />
          <Typography as="p" size="xs" color="secondary" truncate>
            {lastMessage?.message}&nbsp;
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="flex-end" spacing={1}>
          <Typography as="span" size="xs" color="secondary" truncate>
            {lastMessage && transformDateTime(lastMessage.created_at)}
          </Typography>
          <Box
            borderRadius="100%"
            bgcolor={theme.palette.tiptip[500]}
            visibility={isUnread ? 'visible' : 'hidden'}
            sx={{
              width: '8px',
              height: '8px',
              margin: 0,
              padding: 0,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
