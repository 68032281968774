import CloseIcon from '@mui/icons-material/Close'
import { setCanonicalUrl } from 'helpers/url'
import { getFingerprint } from 'helpers/user-device'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { APP_DOMAIN } from 'constants/index'
import {
  AppLinkButton,
  AppLinkContainer,
  BannerContainer,
  CloseButton,
  CloseButtonContainer,
  LabelContainer,
  StyledLabelMain,
  StyledLabelSub,
} from './styled'
import { SxProps } from '@mui/material'
import { sendLog } from 'helpers/log'

interface IAppBanner {
  hidden?: boolean
  onClose?: Function
  style?: SxProps
}
const AppBanner = ({
  hidden = false,
  onClose = function () {},
  style,
}: IAppBanner) => {
  const [show, setShow] = useState(!hidden)
  const { asPath } = useRouter()
  let timerStoreHandler

  const deeplinkHandler = (deeplinkUrl, storeUrl) => {
    window.onfocus = function () {
      clearTimeout(timerStoreHandler)
    }
    window.location.href = deeplinkUrl
    timerStoreHandler = setTimeout(async () => {
      try {
        await navigator.clipboard.writeText(window?.location?.href)
        window.location.href = storeUrl
      } catch (error) {
        sendLog(error)
      }
    }, 2000)
  }
  const appsChecker = async () => {
    if (navigator && window && setCanonicalUrl()) {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.includes('wv') || userAgent.includes('webkit')) {
        if (/android/i.test(navigator.userAgent)) {
          deeplinkHandler(
            `tiptiptv://open${asPath}`,
            `https://play.google.com/store/apps/details?id=tv.tiptip.app.mobile&referer=utm_content%3D${getFingerprint()}`,
          )
        } else if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
          deeplinkHandler(
            `tiptip://${APP_DOMAIN}${asPath}`,
            'https://apps.apple.com/id/app/tiptip/id1615771123?l=id',
          )
        }
      }
    }
  }

  return (
    show && (
      <BannerContainer sx={style}>
        <LabelContainer>
          <StyledLabelMain>Eksplor di aplikasi</StyledLabelMain>
          <StyledLabelSub>
            untuk fitur lengkap & terbaru
          </StyledLabelSub>
        </LabelContainer>

        <AppLinkContainer>
          <AppLinkButton onClick={appsChecker}>
            Buka App
          </AppLinkButton>
        </AppLinkContainer>
        <CloseButtonContainer>
          <CloseButton
            buttonVariant="nude"
            onClick={() => {
              setShow(false)
              onClose()
            }}
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </CloseButton>
        </CloseButtonContainer>
      </BannerContainer>
    )
  )
}

export default AppBanner
