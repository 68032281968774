import { getErrorCode, getErrorMessage } from 'helpers/error'
import { sendLog } from 'helpers/log'
import { StateCreator } from 'zustand'
import {
  ISupportedCountryStore,
  ISupportedCountryDefault,
} from './interface'

const INITIAL_DATA: ISupportedCountryDefault = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  errorCode: '',
  supportedCountry: [],
}

export const createSupportedCountryStore: StateCreator<
  ISupportedCountryStore
> = (set) => {
  return {
    ...INITIAL_DATA,
    setLoading(loading) {
      return {
        isLoading: loading,
      }
    },
    setError(error) {
      sendLog(error?.message)
      const errorCode = getErrorCode(error)
      set({
        errorCode,
        isError: true,
        errorMessage: getErrorMessage(error),
        isLoading: false,
      })
    },
    setSupportedCountry(data) {
      set({
        supportedCountry: data,
      })
    },
  }
}
