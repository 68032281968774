export default [
  {
    id: 'id',
    label: 'Indonesia',
    flag: `https://flagcdn.com/w20/id.png`,
  },
  {
    id: 'en',
    label: 'English',
    flag: `https://flagcdn.com/w20/us.png`,
  },
]
