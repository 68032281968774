import { useQuery } from '@tanstack/react-query'
import { useCommunityStore } from 'stores/domains/Social'
import { isGuestMode } from 'helpers/cookie'
import get from 'lodash/get'
import { getCommunityInfo } from 'services/social'

export const useCommunityInfo = () => {
  const { setCommunityInfo } = useCommunityStore((state) => ({
    setCommunityInfo: state.setCommunityInfo,
  }))

  return useQuery({
    queryKey: ['community-info'],
    queryFn: async () => {
      if (isGuestMode()) return {}
      try {
        const response = await getCommunityInfo()
        return get(response, 'data.data', {
          is_allowed: false,
          has_community: false,
          community_id: '',
          verification_status: 0,
        })
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onSuccess: (data) => setCommunityInfo(data),
  })
}
