import { action } from 'easy-peasy'
import ISelectedProductsModel from './interface'

const selectedProductsModel: ISelectedProductsModel = {
  selectedProductsData: {
    products: [],
    payment_method: '',
    checkout_source: 'CART',
    user_mask_id: '',
    voucher_code: '',
  },
  setSelectedProductsData: action((state, payload) => {
    state.selectedProductsData = payload
  }),
  clearSelectedProductsData: action((state) => {
    state.selectedProductsData = {
      products: [],
      payment_method: '',
      checkout_source: 'CART',
      user_mask_id: '',
      voucher_code: '',
    }
  }),
}

export default selectedProductsModel
