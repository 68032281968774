import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'

/**
 * Function for get idToken
 */
const getIdToken = (): string => {
  const idToken = getCookie(authConfig.idTokenName)
  return idToken
}

export { getIdToken }
