import { action, thunk } from 'easy-peasy'
import ISignUpBonusBannerModel from 'stores/domains/SignUpBonus/Banner/interface'

const SignUpBonusBannerModel: ISignUpBonusBannerModel = {
  /*
   * To define whether the existing guest are still entitled to the signup bonus
   *
   * The folowing cases will cause the `entitled` to be `false`
   * - Browser failed to fetch the API, could be caused by network issue etc
   * - Signup Bonus is disabled from the Backend
   * - Daily signup bonus coin reached its limit
   */
  entitled: false,

  /*
   * To define whether the banner is displayed or not, or closed by the guest
   */
  opened: false,

  /*
   * Calculated automatically, if `entitled` is set to `false`, this height should be `0`
   */
  elementHeight: 0,

  /*
   * Bonus coins is dynamic amount bonus coin to displayed at banner
   */
  bonusCoins: '',
  bannerSignUp: null,
  bannerSignUpMessage: '',

  setEntitled: action((state, payload) => {
    state.entitled = payload

    // If the user is entitled, show the banner
    if (payload === true) state.opened = true
    // Otherwise, set the height to `0`
    else state.elementHeight = 0
  }),

  setOpened: action((state, payload) => {
    state.opened = payload

    // Set height to `0` if it's closed
    if (payload === false) state.elementHeight = 0
  }),

  setElementHeight: action((state, payload) => {
    state.elementHeight = payload ?? 0
  }),

  setBonusCoins: action((state, payload) => {
    state.bonusCoins = payload
  }),

  setBannerSignUpMessage: action((state, payload) => {
    state.bannerSignUpMessage = payload
  }),
  // Reset all to its default state
  reset: thunk((actions) => {
    actions.setEntitled(false)
    actions.setOpened(false)
  }),

  getToggleStatus: thunk(
    async (actions, _payload, { injections }) => {
      try {
        actions.reset()

        // Request to API
        const { apiClient } = injections
        const url = '/user/guest/v1/feature-flag'
        const res = await apiClient({
          url,
          method: 'GET',
        })

        // Process response
        const config =
          res?.data?.data?.is_signup_bonus_period ?? false

        actions.setEntitled(!!config)
        actions.setOpened(!!config)
      } catch (err) {
        actions.reset()
      }
    },
  ),

  getBonusCoins: thunk(async (actions, _payload, { injections }) => {
    try {
      // Request to API
      const { apiClient } = injections
      const url = '/user/guest/v1/bonus-coins'
      const res = await apiClient({
        url,
        method: 'GET',
      })

      // Process response
      const bonusCoin = res?.data?.data?.bonus_coin_amount || ''
      actions.setBonusCoins(bonusCoin)
    } catch (err) {
      console.error(err)
    }
  }),
  setBannerSignup: action((state, payload) => {
    state.bannerSignUp = payload
  }),
  getBannerSignup: thunk(async (actions, payload, { injections }) => {
    try {
      // Request to API
      const { apiClient } = injections
      const url = `/campaign/guest/v1/banner?id=${payload.params.id}`
      const res = await apiClient({
        url,
        method: 'GET',
      })
      // Process response
      const config = res?.data?.data?.is_active ?? false
      actions.setEntitled(!!config)
      actions.setOpened(res.data.data.is_active)
      actions.setBannerSignUpMessage(res.data.data.text)
      actions.setBannerSignup(res.data.data)
    } catch (err) {
      console.error(err)
    }
  }),
}

export default SignUpBonusBannerModel
