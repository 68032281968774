import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import IBookingDetailModel from 'stores/domains/Booking/Detail/interface'
import { sendLog } from 'helpers/log'

const BookingDetailModel: IBookingDetailModel = {
  isLoading: true,
  isError: false,
  bookingDetailData: {
    session_id: '',
    title: '',
    cover: '',
    creator: {
      creator_id: '',
      creator_user_id: '',
      name: '',
      category: {
        id: 0,
        name: '',
      },
      verified: false,
    },
    start_time: '',
    price: 0,
    session_type: '',
    status: {
      id: 0,
      name: '',
    },
    cancel_reason: '',
    total_tipping: 0,
    seconds_until_session_start: 0,
    booking_time: '',
    booking_list: [],
    price_type: 1,
    reserved_slots: -1,
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setBookingDetail: action((state, payload) => {
    state.bookingDetailData = payload
  }),
  getBookingDetail: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = getCookie(authConfig.idTokenName)
          ? await apiClient({
              url: `/session/v2/book/${payload.session_id}/detail`,
              method: 'GET',
              token: getCookie(authConfig.idTokenName),
            })
          : { data: { result: {} } }
        actions.setBookingDetail(response.data?.data)
        actions.setLoading(false)
      } catch (err) {
        sendLog(
          `Failed to load booking session detail for ${payload.session_id} with error: ${err.message}`,
        )
        actions.setLoading(false)
        actions.error(err.message)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default BookingDetailModel
