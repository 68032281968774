import Stack from 'components/commons/Stack'

import ButtonIcon from 'components/commons/ButtonIcon'

import NotificationContainer from '../../Notification'
import NotificationsIcon from '@mui/icons-material/Notifications'

const NotificationHeader = ({ showBadge = false }) => {
  return (
    <Stack
      position="relative"
      sx={(theme) => ({
        display: 'none',
        ':hover .button': {
          color: theme.palette.tiptip[500],
        },
        ':hover .dropdown-content': {
          visibility: 'visible',
          opacity: 1,
        },
        [theme.breakpoints.up('xs')]: {
          display: 'inline-block',
        },
      })}
    >
      <ButtonIcon
        id="b-users-header-notification"
        className="button"
        badge={{ invisible: !showBadge }}
        icon={NotificationsIcon}
        sx={(theme) => ({
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.tiptip[500],
          },
        })}
      />
      <Stack
        className="dropdown-content"
        visibility="hidden"
        position="absolute"
        paddingY={2.5}
        zIndex={1}
        right={0}
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <Stack
          id="c-notification-menu"
          className="notification-content"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            background: theme.palette.background.secondary,
            borderRadius: '12px',
            width: '466px',
            height: 'auto',
            maxHeight: '80vh',
          })}
        >
          <NotificationContainer
            positionActionButton="bottom"
            isHeader
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default NotificationHeader
