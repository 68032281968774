import { action } from 'easy-peasy'
import IStreamModel from 'stores/commons/models/StreamRTC/interface'

const StreamModel: IStreamModel = {
  isError: false,
  isLoading: false,
  isCreatorLeft: false,
  errorMessage: '',
  clientRTC: null,
  mode: 'live',
  codec: 'vp8',
  localVideoTrack: undefined,
  localAudioTrack: undefined,
  moderationLocalVideoTrack: false,
  moderationLocalAudioTrack: false,
  setCreatorLeft: action((state, payload) => {
    state.isCreatorLeft = payload
  }),
  setModerationLocalVideoTrack: action((state, payload) => {
    state.moderationLocalVideoTrack = payload
  }),
  setModerationLocalAudioTrack: action((state, payload) => {
    state.moderationLocalAudioTrack = payload
  }),
  setLocalVideoTrack: action((state, payload) => {
    state.localVideoTrack = payload
  }),
  setLocalAudioTrack: action((state, payload) => {
    state.localAudioTrack = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setClient: action((state, client) => {
    state.clientRTC = client
  }),
}

export default StreamModel
