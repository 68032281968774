/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    source: '/',
    state: 'HOME',
  },
  {
    source: '/p/[username]',
    state: 'CREATOR_PROFILE',
  },
  {
    source: '/login',
    state: 'SIGN_IN',
  },
  {
    source: '/sign-up',
    state: 'SIGN_UP',
  },
  {
    source: '/forgot-password',
    state: 'FORGET_PASSWORD',
  },
  {
    source: '/new-password',
    state: 'CHANGE_PASSWORD',
  },
  {
    source: '/code-verification/email',
    state: 'EMAIL_VERIFICATION',
  },
  {
    source: '/code-verification/phone',
    state: 'PHONE_NUMBER_VERIFICATION',
  },
  {
    source: '/phone-verification',
    state: 'PHONE_NUMBER_VERIFICATION',
  },
  {
    source: '/user/post-registration',
    state: 'CREATE_ACCOUNT',
  },
  {
    source: '/creator/session/create/type',
    state: 'SESSION_TYPE',
  },
  {
    source: '/creator/session/create/description',
    state: 'SESSION_DESCRIPTION',
  },
  {
    source: '/creator/session/create/poster',
    state: 'SESSION_POSTER',
  },
  {
    source: '/session/[session_id]',
    state: 'SESSION_DETAIL',
  },
  {
    source: '/creator/session/live/[session_id]',
    state: 'CREATOR_LIVE_SESSION',
  },
  {
    source: '/session/live/[session_id]',
    state: 'SUPPORTER_LIVE_SESSION',
  },
  {
    source: '/booking/detail/[session_id]',
    state: 'BOOKING_DETAILS',
  },
  {
    source: '/setting',
    state: 'USER_NAVIGATION_MENU',
  },
  {
    source: '/menu',
    state: 'USER_NAVIGATION_MENU',
  },
  {
    source: '/setting/account',
    state: 'ACCOUNT_SETTINGS',
  },
  {
    source: '/creator/dashboard',
    state: 'CREATOR_DASHBOARD',
  },
  {
    source: '/creator/withdraw-earning',
    state: 'WITHDRAWAL',
  },
  {
    source: 'TopUpCoinDrawer',
    state: 'TOP_UP_COINS',
  },
  {
    source: 'PaymentConfirmationDrawer',
    state: 'PAYMENT_CONFIRMATION',
  },
  {
    source: 'LIST_COIN_BOTTOM_DRAWER',
    state: 'SEND_COINS_TO_CREATOR',
  },
  {
    source: 'MODAL_LEAVE_SESSION',
    state: 'LEAVE_SESSION',
  },
  {
    source: 'CANCEL_SESSION_BOTTOM_DRAWER',
    state: 'CANCEL_SESSION',
  },
  {
    source: 'END_SESSION_MODAL',
    state: 'END_SESSION',
  },
  {
    source: 'EXTEND_SESSION_MODAL',
    state: 'EXTEND_SESSION',
  },
  {
    source: '/session/list/[status]',
    state: 'BROWSE_SESSION',
  },
  {
    source: 'USING_DESKTOP_SHARE_SCREEN',
    state: 'USING_DESKTOP_SHARE_SCREEN',
  },
  {
    source: 'ACCESS_DEVICE_SCREEN',
    state: 'ACCESS_DEVICE_SCREEN',
  },
  {
    source: 'SHARE_SCREEN',
    state: 'SHARE_SCREEN',
  },
  {
    source: '/session/[session_id]/review',
    state: 'RATE_CREATOR',
  },
  {
    source: '/browse',
    state: 'BROWSE_AND_SEARCH',
  },
  {
    source: '/creator/create',
    state: 'CREATOR_REGISTRATION',
  },
  {
    source: '/creator/verification/onprogress',
    state: 'CREATOR_REGISTRATION_VERIFICATION',
  },
  {
    source: '/creator/bank-account/verification',
    state: 'BANK_ACCOUNT_VERIFICATION',
  },
  {
    source: 'CREATOR_HOME',
    state: 'CREATOR_HOME',
  },
  {
    source: '/content/[content_uid]',
    state: 'CONTENT_DETAIL',
  },
  {
    source: '/setting/account',
    state: 'ACCOUNT_SETTINGS',
  },
  {
    source: '/setting/account/information',
    state: 'ACCOUNT_SETTINGS',
  },
  {
    source: '/setting/account/security',
    state: 'ACCOUNT_SETTINGS',
  },
  {
    source: '/setting/account/information/change-username',
    state: 'CHANGE_USERNAME',
  },
  {
    source: '/setting/account/information/change-email',
    state: 'CHANGE_EMAIL',
  },
  {
    source: '/setting/account/information/change-phonenumber',
    state: 'CHANGE_PHONE_NUMBER',
  },
  ,
  {
    source: '/setting/account/information/change-birthdate',
    state: 'CHANGE_DOB',
  },
  ,
  {
    source: '/setting/account/information/change-gender',
    state: 'CHANGE_GENDER',
  },
  {
    source: '/setting/code-verification/email',
    state: 'EMAIL_VERIFICATION',
  },
  {
    source: '/setting/code-verification/phone',
    state: 'PHONE_VERIFICATION',
  },
  {
    source: 'PARTICIPANT_TYPE_INFORMATION',
    state: 'PARTICIPANT_TYPE_INFORMATION',
  },
  {
    source: '/content/video/[content_uid]',
    state: 'CONTENT_PLAYER',
  },
  {
    source: '/content/document/[content_uid]',
    state: 'CONTENT_PLAYER',
  },
  {
    source: '/user/voucher/[content_uid]',
    state: 'VOUCHER_INBOX',
  },
  {
    source: '/user/voucher/voucher-detail/[content_uid]',
    state: 'VOUCHER_DETAILS',
  },
  {
    source: '/section/[section_id]',
    state: 'SECTION_DETAIL',
  },
  {
    source: '/event/[event_uid]',
    state: 'EVENT_DETAIL',
  },
  {
    source: '/library',
    state: 'LIBRARY',
  },
  {
    source: '/special-event',
    state: 'SPECIAL_EVENT_HOME',
  },

  {
    source: '/message',
    state: 'DIRECT_MESSAGE',
  },
  {
    source: '/message/[username]',
    state: 'MESSAGE_DETAIL',
  },
  {
    source: '/community',
    state: 'MY_COMMUNITY',
  },
  {
    source: '/community/registration',
    state: 'CREATE_COMMUNITY',
  },
  {
    source: '/community/registration/detail',
    state: 'CREATE_COMMUNITY',
  },
  {
    source: '/community/registration/member',
    state: 'CREATE_COMMUNITY_MEMBER_DETAIL',
  },
  {
    source: '/k/[community_id]',
    state: 'COMMUNITY_PROFILE',
  },
  {
    source: '/k/[community_id]/member',
    state: 'COMMUNITY_MEMBER',
  },
  { source: '/cart', state: 'CART_DETAIL' },
  {
    source: '/payment',
    state: 'CART_PAYMENT_CONFIRMATION',
  },
  {
    source: '/transaction-history',
    state: 'TRANSACTION_HISTORY',
  },
]
