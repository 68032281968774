import { NextRouter } from 'next/router'
import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import { getLocalStorage } from 'helpers/local-storage'
import isEmpty from 'helpers/is-empty'

export default function UserTracker(
  eventName: string,
  data?: Pick<NextRouter, 'pathname' | 'query'>,
  additionalProperties?: {},
) {
  const authMethod = getLocalStorage('authMethod')
  const { pathname, query } = data
  const state_name = getStateFromSource(pathname)
  const auth_method = authMethod
    ? authMethod === 'phoneNumber'
      ? 'phone_number'
      : authMethod
    : 'unknown'
  const auth_datetime = getDateTimeUTC(new Date().toISOString())
  const set_coin_balance = true
  const set_income_balance = true
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties = {
    state_name,
    set_coin_balance,
    set_income_balance,
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const creatorProperties = {
    state_name: 'CREATOR_PROFILE',
    ...additionalProperties,
  }
  const supporterProperties = {
    state_name: 'SUPPORTER_PROFILE',
    ...additionalProperties,
  }

  const eventProperties = {
    skip_post_registration_process: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
      sign_up_method: auth_method,
      create_account_datetime: auth_datetime,
    },
    sign_up_completed: {
      ...baseProperties,
      ...additionalProperties,
      sign_up_method: auth_method,
      create_account_datetime: auth_datetime,
    },
    sign_in_completed: {
      ...baseProperties,
      state_name: 'SIGN_IN',
      sign_in_method: auth_method,
      login_datetime: auth_datetime,
    },
    visit_personal_information: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    start_explore_tiptip: {
      ...baseProperties,
      ...additionalProperties,
      sign_up_method: auth_method,
      create_account_datetime: auth_datetime,
    },
    click_change_username: { ...additionalProperties },
    click_change_fullname: { ...additionalProperties },
    click_change_email: { ...additionalProperties },
    click_change_category: { ...additionalProperties },
    click_change_phone_number: { ...additionalProperties },
    click_change_contact_number: { ...additionalProperties },
    click_change_bio: { ...additionalProperties },
    click_change_dob: { ...additionalProperties },
    click_change_gender: { ...additionalProperties },
    click_change_user_instagram: { ...additionalProperties },
    click_change_user_facebook: { ...additionalProperties },
    click_change_user_twitter: { ...additionalProperties },
    click_change_user_youtube: { ...additionalProperties },
    click_change_user_linkedin: { ...additionalProperties },
    click_change_user_tiktok: { ...additionalProperties },
    click_sign_in: {
      state_name,
      ...additionalProperties,
    },
    click_sign_up: {
      state_name,
      ...additionalProperties,
    },
    click_google_sign_in: {
      state_name,
      ...additionalProperties,
    },
    left_personal_information: {
      state_name,
    },
    visit_change_username: {
      state_name,
      ...sourceProperties,
    },
    left_change_username: {
      state_name,
    },
    visit_change_email: {
      state_name,
      ...sourceProperties,
    },
    submit_username: { ...additionalProperties },
    submit_fullname: { ...additionalProperties },
    submit_email: { ...additionalProperties },
    submit_bio: { ...additionalProperties },
    submit_category: { ...additionalProperties },
    submit_phone_number: { ...additionalProperties },
    submit_contact_number: { ...additionalProperties },
    submit_gender: { ...additionalProperties },
    submit_dob: { ...additionalProperties },
    submit_user_instagram: { ...additionalProperties },
    submit_user_facebook: { ...additionalProperties },
    submit_user_twitter: { ...additionalProperties },
    submit_user_youtube: { ...additionalProperties },
    submit_user_linkedin: { ...additionalProperties },
    submit_user_tiktok: { ...additionalProperties },
    left_change_email: {
      state_name,
    },
    visit_email_verification: {
      state_name,
      ...sourceProperties,
    },
    visit_phone_number_verification: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    visit_sign_in: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    trigger_register: {
      state_name,
    },
    trigger_resend_email_verification: {
      state_name,
    },
    trigger_resend_phone_verification: {
      state_name,
      ...additionalProperties,
    },
    trigger_verified_phone: {
      state_name,
      ...additionalProperties,
    },
    trigger_change_password: {
      state_name,
    },
    cancel_email_verification: {
      state_name,
    },
    cancel_phone_number_verification: {
      state_name,
      ...additionalProperties,
    },
    cancel_sign_in: {
      state_name,
      ...additionalProperties,
    },
    visit_change_phone_number: {
      state_name,
      ...sourceProperties,
    },
    left_change_phone_number: {
      state_name,
    },
    select_country_code: {
      state_name,
      modal_name: 'COUNTRY_CODE',
      ...additionalProperties,
    },
    select_email_sign_in_tab: {
      state_name,
      ...additionalProperties,
    },
    select_phone_sign_in_tab: {
      state_name,
      ...additionalProperties,
    },
    select_phone_sign_up_tab: {
      state_name,
      ...additionalProperties,
    },
    select_email_sign_up_tab: {
      state_name,
      ...additionalProperties,
    },
    click_change_country_code: {
      state_name,
    },
    cancel_change_country_code: {
      state_name,
      modal_name: 'COUNTRY_CODE',
      ...additionalProperties,
    },
    visit_change_gender: {
      state_name,
      ...sourceProperties,
    },
    left_change_gender: {
      state_name,
    },
    visit_change_dob: {
      state_name,
      ...sourceProperties,
    },
    left_change_dob: {
      state_name,
    },
    click_referral_code_fill_in: {
      state_name,
      ...additionalProperties,
    },
    creator_click_copy_referral_code: {
      state_name,
      ...additionalProperties,
    },
    submit_referral_code: {
      state_name,
      modal_name: 'REFERRAL_CODE_SUBMISSION',
      ...additionalProperties,
    },
    cancel_referral_code_fill_in: {
      state_name,
      modal_name: 'REFERRAL_CODE_SUBMISSION',
    },
    creator_close_referral_code_list: {
      state_name,
      modal_name: 'REFERRAL_CODE_LIST',
      ...additionalProperties,
    },
    visit_other_menu: {
      state_name,
      ...sourceProperties,
      set_coin_balance: true,
    },
    visit_sign_up: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    cancel_sign_up: {
      state_name,
      ...additionalProperties,
    },
    click_share_referral_code: {
      state_name,
      ...additionalProperties,
    },
    share_referral_code: {
      state_name,
      ...additionalProperties,
    },
    cancel_share_referral_code: {
      state_name,
      ...additionalProperties,
    },
    click_phone_verification_whatsapp: {
      state_name,
      modal_name: 'CHOOSE_PHONE_VERIFICATION_METHOD',
    },
    click_phone_verification_sms: {
      state_name,
      modal_name: 'CHOOSE_PHONE_VERIFICATION_METHOD',
    },
    cancel_choose_phone_verification_method: {
      state_name,
      modal_name: 'CHOOSE_PHONE_VERIFICATION_METHOD',
    },
    cancel_phone_number_fill_in: {
      state_name,
      modal_name: 'PHONE_NUMBER',
    },
    // Creator Profile
    visit_creator_profile: {
      ...sourceProperties,
      ...creatorProperties,
    },
    click_share_creator_profile: { ...creatorProperties },
    cancel_share_creator: { ...creatorProperties },
    click_report_creator_profile: { ...creatorProperties },
    cancel_report_creator: { ...creatorProperties },
    submit_report_creator_reason: { ...creatorProperties },
    edit_creator_profile: { ...creatorProperties },
    select_digital_content_tab: { ...creatorProperties },
    select_offline_content_tab: { ...creatorProperties },
    select_1_on_1_session_tab: { ...creatorProperties },
    select_event_tab: { ...creatorProperties },
    select_group_session_tab: { ...creatorProperties },
    click_creator_instagram: { ...creatorProperties },
    click_creator_facebook: { ...creatorProperties },
    click_creator_twitter: { ...creatorProperties },
    click_creator_youtube: { ...creatorProperties },
    click_creator_linkedin: { ...creatorProperties },
    click_creator_tiktok: { ...creatorProperties },
    click_edit_creator_photo: { ...creatorProperties },
    // Direct message
    click_message: {
      state_name,
    },
    click_message_creator: { ...creatorProperties },
    click_message_supporter: { ...supporterProperties },
    visit_direct_message: {
      state_name,
      ...sourceProperties,
    },
    click_browse_creator: {
      state_name,
      ...additionalProperties,
    },
    select_direct_message: {
      state_name,
      ...additionalProperties,
    },
    visit_message_detail: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    click_tnc: {
      state_name,
      ...additionalProperties,
    },
    click_more_options: {
      state_name,
      ...additionalProperties,
    },
    click_block_user: {
      state_name,
      ...additionalProperties,
    },
    block_user: {
      state_name,
      ...additionalProperties,
    },
    unblock_user: {
      state_name,
      ...additionalProperties,
    },
    click_creator_profile: {
      state_name,
      ...additionalProperties,
    },
    click_supporter_profile: {
      state_name,
      ...additionalProperties,
    },
    send_message: {
      state_name,
      ...additionalProperties,
    },
    click_direct_link: {
      state_name,
      ...additionalProperties,
    },
    click_profile_dropdown: {
      state_name,
    },
    click_topup: {
      state_name,
      ...additionalProperties,
    },
    cancel_creator_registration: {
      state_name,
    },
    visit_creator_registration: {
      state_name,
      ...sourceProperties,
    },
    creator_registration_completed: {
      state_name,
      ...additionalProperties,
    },
    visit_account_settings: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    click_personal_information: {
      state_name,
      ...additionalProperties,
    },
    click_change_password: {
      state_name,
      ...additionalProperties,
    },
    update_personal_information_completed: {
      state_name: 'ACCOUNT_SETTINGS',
      state_sub_name: 'PERSONAL_INFORMATION',
    },
    change_password_completed: { ...additionalProperties },
    click_my_supporter_profile: {
      state_name,
      ...additionalProperties,
    },
    click_my_creator_profile: {
      state_name,
      ...additionalProperties,
    },
    // Supporter Profile
    visit_supporter_profile: {
      ...sourceProperties,
      ...supporterProperties,
    },
    click_edit_supporter_profile: { ...supporterProperties },
    click_edit_creator_profile: { ...supporterProperties },
    click_share_supporter_profile: { ...supporterProperties },
    cancel_share_supporter: { ...supporterProperties },
    click_supporter_instagram: { ...supporterProperties },
    click_supporter_facebook: { ...supporterProperties },
    click_supporter_twitter: { ...supporterProperties },
    click_supporter_youtube: { ...supporterProperties },
    click_supporter_linkedin: { ...supporterProperties },
    click_supporter_tiktok: { ...supporterProperties },
    click_edit_supporter_photo: { ...supporterProperties },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
