import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import get from 'lodash/get'
import map from 'lodash/map'

export default function ContentTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const { pathname, states, query } = data
  const state_name = getStateFromSource(pathname) || ''
  const source =
    query.source && typeof query.source === 'string'
      ? getStateFromSource(query.source)
      : 'unknown'
  const source_action = query?.source_action || 'unknown'

  const { session_id } = query

  const sourceProperties = {
    source,
    source_action,
  }

  const sessionProperties = {
    creator_id: get(
      states.sessionDetail,
      'sessionData.creator.creator_id',
      '',
    ),
    creator_user_id: get(
      states.sessionDetail,
      'sessionData.creator.creator_user_id',
      '',
    ),
    creator_category: get(
      states.sessionDetail,
      'sessionData.creator.category.name',
      '',
    ),
    creator_category_id: get(
      states.sessionDetail,
      'sessionData.creator.category.id',
      '',
    ),
    session_id: session_id,
    session_type: get(
      states.sessionDetail,
      'sessionData.session_type',
    ),
    list_user_booked: states.participantList.participants.map(
      (participant) => participant.user_id,
    ),
    num_user_booked: states.participantList.participants.length,
    max_num_participant: get(
      states.sessionDetail,
      'sessionData.slots',
      0,
    ),
    session_status: 'live',
    session_price_per_participant: get(
      states.sessionDetail,
      'sessionData.price',
      0,
    ),
    session_title: get(states.sessionDetail, 'sessionData.title'),
    session_description: get(
      states.sessionDetail,
      'sessionData.description',
    ),
    estimate_session_start_datetime: get(
      states.sessionDetail,
      'sessionData.start_time',
    ),
    estimate_session_duration:
      get(states.sessionDetail, 'sessionData.duration', 0) * 60, // seconds
    estimate_remaining_time: get(
      states.joinSession,
      'data.remaining_time',
      0,
    ),
    session_photo_image_path: get(
      states.sessionDetail,
      'sessionData.cover',
    ),
    actual_session_start_datetime: getDateTimeUTC(
      new Date().toISOString(),
    ),
    num_user_current_join: states.remoteUser.users.length,
    list_user_current_join: map(
      states.remoteUser.users,
      (user) => user.user_id,
    ),
  }

  const defaultValue = {
    state_name,
    session_id,
    ...sessionProperties,
    ...additionalProperties,
  }

  const eventProperties = {
    creator_click_end_session: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_camera_permission_denied: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_audio_permission_denied: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_select_input_source: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_stop_share_screen: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_view_chat: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_turn_on_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_turn_off_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_turn_on_audio: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_turn_off_audio: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_click_share_screen: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_disable_participant_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_enable_participant_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_enable_participant_audio: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_disable_participant_audio: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_confirm_share_screen: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_share_screen_fail_mobile: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_share_screen_fail_no_access: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_cancel_share_screen: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_click_audio_video_settings: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_close_audio_video_settings: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_select_save_setting: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_select_audio_video_setting_tab: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_start_session_completed: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_open_extend_session: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_extend_session: {
      ...defaultValue,
      ...sourceProperties,
    },
    creator_cancel_end_session: {
      ...defaultValue,
      ...sourceProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
