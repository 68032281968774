import { CircularProgress, Stack, StackProps } from '@mui/material'
import Typography from 'components/commons/CustomTypography'

interface LoadingStateProps extends StackProps {
  title?: string
}

export default function LoadingState({
  title = 'Loading...',
  ...rest
}: LoadingStateProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={2}
      {...rest}
    >
      <CircularProgress />
      <Typography as="span" size="xs" color="tertiery">
        {title}
      </Typography>
    </Stack>
  )
}
