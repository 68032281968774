import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { SxProps } from '@mui/material'

interface ILoaderProps {
  height?: string
  iconWidth?: string
  iconHeight?: string
  sxContainer?: SxProps
}
function Loader({
  height = '100vh',
  iconWidth,
  iconHeight,
  sxContainer,
}: ILoaderProps): JSX.Element {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        minHeight: { height },
        ...sxContainer,
      }}
    >
      <CircularProgress
        color="primary"
        sx={{ width: iconWidth, height: iconHeight }}
      />
    </Stack>
  )
}

export default Loader
