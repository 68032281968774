import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { generateShortUuid } from 'helpers/generate/uuid'

export const generateDeepLink = async (
  url: string,
  idempotentKey: string,
) => {
  try {
    const response = await apiClient({
      url: getIdToken()
        ? `/promoter/v3/deep-link`
        : `/promoter/guest/v3/deep-link`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': idempotentKey },
      data: {
        url: url,
        source: 'MAIN_APP',
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const saveInterstitial = async (
  identifierId: string,
  shortUrl: string,
) => {
  try {
    const response = await apiClient({
      url: '/promoter/guest/v1/interstitial',
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': generateShortUuid() },
      data: {
        identifier_id: identifierId,
        short_url: shortUrl,
        data: '{"key":"value"}',
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const generatePromoterLink = async ({
  product_uid,
  product_type,
  creator_id,
}) => {
  return await apiClient({
    url: '/promoter/v1/link/generate',
    method: 'POST',
    token: getIdToken(),
    data: {
      product_id: product_uid,
      product_type: product_type,
      creator_id: creator_id,
    },
  })
}
