import BrowseTracker from 'src/trackers/domains/Browse'
import UserTracker from 'trackers/domains/User'
import ContentTracker from 'src/trackers/domains/Content'
import EventTracker from 'trackers/domains/Event'
import CreatorTracker from 'trackers/domains/Creator'
import SessionTracker from 'trackers/domains/Session'
import HomeTracker from 'trackers/domains/Home'
import SubscriptionTracker from 'trackers/domains/Creator/subscription'
import PaymentTracker from 'trackers/domains/Wallet'
import CartTracker from 'src/trackers/domains/Cart'
import PaymentCartTracker from 'trackers/domains/Payment'
import TransactionHistoryTracker from 'trackers/domains/TransactionHistory'
import CommunityTracker from 'trackers/domains/Community/community'

const trackEvent = {
  user: UserTracker,
  content: ContentTracker,
  event: EventTracker,
  browse: BrowseTracker,
  creator: CreatorTracker,
  session: SessionTracker,
  home: HomeTracker,
  subscription: SubscriptionTracker,
  payment: PaymentTracker,
  cart: CartTracker,
  paymentCart: PaymentCartTracker,
  transactionHistory: TransactionHistoryTracker,
  community: CommunityTracker,
}

export default trackEvent
