import { StateCreator } from 'zustand'
import { ICartListItemsStore } from './interface'

export const createCartListItemsStore: StateCreator<
  ICartListItemsStore
> = (set) => ({
  // states
  cartListItems: {
    products: [],
    total: 0,
    total_item: 0,
    is_all_available: true,
  },
  responseDelete: {
    code: '',
    message: '',
    data: {},
    errors: [],
  },
  // actions
  setCartListItems: async (payload) => {
    set({ cartListItems: payload })
  },
  setDeleteCartItem: async (payload) => {
    set({ responseDelete: payload })
  },
  resetDataDeleteItem: () => {
    set({
      responseDelete: {
        code: '',
        message: '',
        data: {},
        errors: [],
      },
    })
  },
})
