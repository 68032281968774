import { action } from 'easy-peasy'
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import ISearchModel from './interface'
import get from 'lodash/get'

const Search: ISearchModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  searchHistory: [],
  historyOpen: false,
  searchData: {
    content: [],
    creator: [],
    session: [],
    event: [],
    community: [],
    limit_content: 10,
    limit_creator: 4,
    limit_session: 3,
    limit_event: 4,
    limit_community: 4,
    load_more_content: false,
    load_more_creator: false,
    load_more_session: false,
    load_more_event: false,
    load_more_community: false,
    page_content: 1,
    page_creator: 1,
    page_session: 1,
    page_event: 1,
    page_community: 1,
    total: 0,
  },
  isSearchBarActive: false,

  setSearchHistory: action((state, data) => {
    state.searchHistory = data
  }),

  checkKeywordsToLocalStorage: action((state, key) => {
    let arrayObject = getLocalStorage('search')
    if (arrayObject !== undefined) {
      if (arrayObject.indexOf(key) === -1) {
        if (arrayObject.length > 4) {
          arrayObject.shift()
        }
        arrayObject.push(key)
        setLocalStorage('search', arrayObject)
        state.searchHistory = getLocalStorage('search').reverse()
      }
    } else {
      setLocalStorage('search', [key])
      state.searchHistory = getLocalStorage('search').reverse()
    }
  }),

  setHistoryOpen: action((state, isOpen) => {
    state.historyOpen = isOpen
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setSearchData: action((state, payload) => {
    state.searchData.content = payload.data.content
    state.searchData.creator = payload.data.creator
    state.searchData.session = payload.data.session
    state.searchData.event = payload.data.event
    state.searchData.community = payload.data.community
  }),
  setContentData: action((state, payload) => {
    if (payload.tab !== 'all') {
      state.searchData.creator = payload.data.creator
      state.searchData.session = payload.data.session
      state.searchData.event = payload.data.event
      state.searchData.community = payload.data.community
    }
    state.searchData.content =
      payload._metadata.page_content > 1
        ? [
            ...state.searchData.content,
            ...get(payload, 'data.content', []),
          ]
        : payload.data.content
  }),
  setCreatorData: action((state, payload) => {
    if (payload.tab !== 'all') {
      state.searchData.content = payload.data.content
      state.searchData.session = payload.data.session
      state.searchData.event = payload.data.event
      state.searchData.community = payload.data.community
    }
    state.searchData.creator =
      payload._metadata.page_creator > 1
        ? [
            ...state.searchData.creator,
            ...get(payload, 'data.creator', []),
          ]
        : payload.data.creator
  }),
  setSessionData: action((state, payload) => {
    if (payload.tab !== 'all') {
      state.searchData.content = payload.data.content
      state.searchData.creator = payload.data.creator
      state.searchData.event = payload.data.event
      state.searchData.community = payload.data.community
    }
    state.searchData.session =
      payload._metadata.page_session > 1
        ? [
            ...state.searchData.session,
            ...get(payload, 'data.session', []),
          ]
        : payload.data.session
  }),
  setEventData: action((state, payload) => {
    if (payload.tab !== 'all') {
      state.searchData.content = payload.data.content
      state.searchData.creator = payload.data.creator
      state.searchData.session = payload.data.session
      state.searchData.community = payload.data.community
    }
    state.searchData.event =
      payload._metadata.page_event > 1
        ? [
            ...state.searchData.event,
            ...get(payload, 'data.event', []),
          ]
        : payload.data.event
  }),
  setCommunityData: action((state, payload) => {
    if (payload.tab !== 'all') {
      state.searchData.content = payload.data.content
      state.searchData.session = payload.data.session
      state.searchData.event = payload.data.event
      state.searchData.creator = payload.data.creator
    }
    state.searchData.community =
      payload._metadata.page_community > 1
        ? [
            ...state.searchData.community,
            ...get(payload, 'data.community', []),
          ]
        : payload.data.community
  }),
  setMetaData: action((state, payload) => {
    for (const property in payload._metadata) {
      state.searchData[property] = payload._metadata[property]
    }
  }),
  setIsSearchBarActive: action((state, payload) => {
    state.isSearchBarActive = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
}

export default Search
