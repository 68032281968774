import { Button as CommonButton, styled } from '@mui/material'

export type ButtonVariant = 'filled' | 'nude' | 'outlined' | 'ghost'
interface ButtonProps {
  buttonType?: 'primary' | 'secondary'
  buttonVariant?: ButtonVariant
  size?: 'large' | 'small'
}

const Button = styled(CommonButton)<ButtonProps>(
  ({
    size = 'large',
    buttonType = 'primary',
    buttonVariant = 'filled',
    theme,
  }) => ({
    padding: '12px 8px',
    [theme.breakpoints.up('sm')]: {
      padding: '12px 16px',
      ...(size === 'large' && {
        padding: '12px 16px',
      }),
    },
    height: '32px',
    ...theme.typography.normalBold,
    ...(buttonType === 'primary' && {
      borderRadius: '12px',
    }),
    ...(buttonType === 'secondary' && {
      borderRadius: '18px',
    }),
    ...(buttonVariant === 'filled' && {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.secondary.main,
      ':hover': {
        backgroundColor: theme.palette.action.hover,
      },
      ':focus': {
        backgroundColor: theme.palette.action.focus,
      },
      ':disabled': {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.text.tertiery,
      },
    }),
    ...(buttonVariant === 'outlined' && {
      border: `1px solid ${theme.palette.action.active}`,
      backgroundColor: 'white',
      color: theme.palette.action.active,
      ':hover': {
        border: `1px solid ${theme.palette.action.active}`,
        backgroundColor: theme.palette.tiptip[500_32],
        color: 'white',
      },
      ':focus': {
        color: theme.palette.action.focus,
        border: `1px solid ${theme.palette.action.focus}`,
      },
      ':disabled': {
        color: theme.palette.text.tertiery,
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    }),

    ...(buttonVariant === 'nude' && {
      border: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.action.active,
      ':hover': {
        color: theme.palette.action.hover,
        backgroundColor: 'transparent',
      },
      ':focus': {
        color: theme.palette.action.focus,
      },
      ':disabled': {
        color: theme.palette.text.tertiery,
      },
    }),
    ...(buttonVariant === 'ghost' && {
      border: 'none',
      backgroundColor: theme.palette.action.ghostActive,
      color: theme.palette.action.active,
      ':hover': {
        backgroundColor: theme.palette.action.ghostHover,
      },
      ':focus': {
        backgroundColor: theme.palette.action.ghostPressed,
      },
      ':disabled': {
        color: theme.palette.text.tertiery,
        backgroundColor: theme.palette.action.disabled,
      },
    }),
  }),
)

export default Button
