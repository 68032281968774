import { NextPageContext } from 'next'
import nookies from 'nookies'
import amplifyConfig from 'configs/amplify'
import {
  setCookie,
  destroyCookie,
  forceDeleteCookies,
} from 'helpers/cookie'

const cognitoPrefix =
  'CognitoIdentityServiceProvider.' +
  amplifyConfig.aws_user_pools_web_client_id

/**
 * Returns cookie value
 *
 * @param context
 * @param cookieName
 */
const getLastAuthUser = (context?: NextPageContext): string => {
  if (context?.req && context?.req.headers) {
    const value =
      nookies.get(context)[cognitoPrefix + '.LastAuthUser']
    return context.res && value
  }
  const value = nookies.get({})[cognitoPrefix + '.LastAuthUser']
  return value
}

const createLastAuthUser = (value: string) => {
  setCookie(cognitoPrefix + '.LastAuthUser', value)
}

const deleteLastAuthUser = () => {
  forceDeleteCookies()
  destroyCookie(cognitoPrefix + '.LastAuthUser', '/')
}

/**
 * Returns token value
 *
 * @param context
 * @param cookieName
 */
const getCognitoToken = (
  cookieName: string,
  context?: NextPageContext,
): string => {
  if (context?.req && context?.req.headers) {
    const userId = getLastAuthUser(context)
    const value =
      nookies.get(context)[
        cognitoPrefix + '.' + userId + '.' + cookieName
      ]
    return context.res && value
  }
  const userId = getLastAuthUser()

  const value = nookies.get({})[
    cognitoPrefix + '.' + userId + '.' + cookieName
  ]

  return value
}

export {
  getCognitoToken,
  getLastAuthUser,
  createLastAuthUser,
  deleteLastAuthUser,
}
