import { action } from 'easy-peasy'
import IPurchasedListModel from './interface'

const PurchasedList: IPurchasedListModel = {
  data: [],
  setData: action((state, payload) => {
    state.data = payload
  }),
}

export default PurchasedList
