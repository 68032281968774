import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import { getChannelInfo } from 'helpers/session-storage'
import get from 'lodash/get'
import map from 'lodash/map'

export default function SessionTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const { pathname, states, query } = data
  const state_name = getStateFromSource(pathname) || ''
  const source =
    query.source && typeof query.source === 'string'
      ? getStateFromSource(query.source)
      : 'unknown'
  const source_action = query?.source_action || 'unknown'

  const { session_id } = query
  const channelInfo = getChannelInfo(session_id)

  const sourceProperties = {
    source,
    source_action,
  }

  const sessionProperties = {
    session_id,
    session_status: get(
      states.sessionDetail,
      'sessionData.session_status',
      '',
    ).toLowerCase(),
    session_type: get(
      states.sessionDetail,
      'sessionData.session_type',
    ),
    session_title: get(states.sessionDetail, 'sessionData.title'),
    session_price_per_participant: get(
      states.sessionDetail,
      'sessionData.price',
      0,
    ),
    max_num_participant: get(
      states.sessionDetail,
      'sessionData.slots',
      0,
    ),
    session_description: get(
      states.sessionDetail,
      'sessionData.description',
    ),
    estimate_session_start_datetime: get(
      states.sessionDetail,
      'sessionData.start_time',
    ),
    estimate_session_duration:
      get(states.sessionDetail, 'sessionData.duration', 0) * 60, // seconds
    session_photo_image_path: get(
      states.sessionDetail,
      'sessionData.cover',
    ),
    creator_id: get(
      states.sessionDetail,
      'sessionData.creator.creator_id',
      '',
    ),
    creator_name: get(
      states.sessionDetail,
      'sessionData.creator.name',
      '',
    ),
    creator_user_id: get(
      states.sessionDetail,
      'sessionData.creator.creator_user_id',
      '',
    ),
    creator_category: get(
      states.sessionDetail,
      'sessionData.creator.category.name',
      '',
    ),
    creator_category_id: get(
      states.sessionDetail,
      'sessionData.creator.category.id',
      '',
    ),
    creator_group: get(
      states.sessionDetail,
      'sessionData.creator.creator_group',
      '',
    ),
    is_community_leader: get(
      states.sessionDetail,
      'sessionData.creator.is_community_leader',
      '',
    ),
    user_join_session_datetime:
      states.sessionLivestream.supporterJoinedDateTime,
    actual_session_start_datetime: getDateTimeUTC(
      new Date().toISOString(),
    ),
    num_user_booked: states.participantList.participants.length,
    list_user_booked: map(
      states.participantList.participants,
      (participant) => participant.user_id,
    ),
    num_user_current_join: states.remoteUser.users.length,
    list_user_current_join: map(
      states.remoteUser.users,
      (user) => user.user_id,
    ),
  }

  const defaultValue = {
    state_name,
    session_id,
    ...sessionProperties,
    ...additionalProperties,
  }

  const eventProperties = {
    participant_audio_been_enabled: {
      ...defaultValue,
      ...sourceProperties,
    },
    participant_audio_been_disabled: {
      ...defaultValue,
      ...sourceProperties,
      participant_total_tipping_amount: channelInfo?.totalTipping,
      participant_audio_status: 'OFF',
    },
    send_session_chat: {
      ...defaultValue,
      ...sourceProperties,
      modal_name: 'LIVE_SESSION',
    },
    turn_on_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    turn_off_camera: {
      ...defaultValue,
      ...sourceProperties,
    },
    session_raise_hand: {
      ...defaultValue,
      ...sourceProperties,
    },
    turn_off_audio: {
      ...defaultValue,
      ...sourceProperties,
    },
    session_landscape_mode: {
      ...defaultValue,
      ...sourceProperties,
    },
    close_sign_up_modal: {
      ...defaultValue,
      ...sourceProperties,
    },
    click_sign_up: {
      ...defaultValue,
      ...sourceProperties,
    },
    click_topup: {
      ...defaultValue,
      ...sourceProperties,
      modal_name: getStateFromSource('LIST_COIN_BOTTOM_DRAWER'),
    },
    cancel_send_tip: {
      ...defaultValue,
      ...sourceProperties,
    },
    session_exit_cancel: {
      ...defaultValue,
      ...sourceProperties,
      modal_name: getStateFromSource('MODAL_LEAVE_SESSION'),
    },
    click_session_exit: {
      ...defaultValue,
      ...sourceProperties,
    },
    select_tipping_amount: {
      ...defaultValue,
      ...sourceProperties,
      modal_name: getStateFromSource('LIST_COIN_BOTTOM_DRAWER'),
      total_tipping_user_this_session:
        states.sendTippingCoin.tipAccumulation,
      total_tipping_this_session: channelInfo?.totalTipping,
    },
    click_send_tip: {
      ...defaultValue,
      ...sourceProperties,
    },
    participant_camera_been_disabled: {
      ...defaultValue,
      ...sourceProperties,
    },
    join_session: {
      ...defaultValue,
      ...sourceProperties,
    },
    leave_session_completed: {
      ...defaultValue,
      ...sourceProperties,
    },
    tip_session_completed: {
      ...defaultValue,
      ...sourceProperties,
      modal_name: getStateFromSource('LIST_COIN_BOTTOM_DRAWER'),
      total_tipping_this_session: channelInfo?.totalTipping,
      trnx_success: true,
    },
    select_report_session: {
      ...defaultValue,
      ...sourceProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
