import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function HomeTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const {
    pathname,
    query,
    sectionCampaignDetail = {},
    profileData = {},
  } = data
  const state_name = getStateFromSource(pathname)
  const source =
    !query?.source || isEmpty(query.source)
      ? 'unknown'
      : getStateFromSource(String(query.source))
  const source_action =
    !query?.source_action || isEmpty(query.source_action)
      ? 'unknown'
      : String(query.source_action)

  const sourceProperties = {
    source,
    source_action,
  }
  const user_type = profileData?.isCreator ? 'creator' : 'supporter'

  const eventProperties = {
    visit_home: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    click_creator_registration: {
      modal_name: 'BECOME_A_CREATOR',
      state_name,
    },
    click_sign_up: {
      state_name,
    },
    click_sign_in: {
      state_name,
    },
    click_search_button: {
      state_name,
      ...additionalProperties,
    },
    select_category: {
      state_name,
      ...additionalProperties,
    },
    select_creator: {
      state_name,
      ...additionalProperties,
    },
    click_browse_creator: {
      state_name,
      ...additionalProperties,
    },
    click_browse_content: {
      state_name,
      ...additionalProperties,
    },
    select_content: {
      state_name,
      ...additionalProperties,
    },
    select_event: {
      state_name,
      ...additionalProperties,
    },
    click_digital_content_tab: {
      state_name,
      ...additionalProperties,
    },
    click_event_tab: {
      state_name,
      ...additionalProperties,
    },
    click_learn_more: {
      state_name,
      modal_name: 'BECOME_A_CREATOR',
    },
    close_become_a_creator: {
      state_name,
      modal_name: 'BECOME_A_CREATOR',
    },
    click_start_selling_content: {
      state_name,
      modal_name: 'BECOME_A_CREATOR',
    },
    click_search_box: {
      state_name,
      ...additionalProperties,
    },
    trigger_categories_drop_down: {
      state_name,
    },
    click_home: {
      state_name,
    },
    click_about_us: {
      state_name,
    },
    click_tnc: {
      state_name,
    },
    click_help_center: {
      state_name,
    },
    click_download_app_android: {
      state_name,
    },
    click_download_app_ios: {
      state_name,
    },
    click_library: {
      state_name,
    },
    click_company_instagram: {
      state_name,
    },
    click_company_twitter: {
      state_name,
    },
    click_company_youtube: {
      state_name,
    },
    click_company_linkedin: {
      state_name,
    },
    click_company_facebook: {
      state_name,
    },
    click_company_tiktok: {
      state_name,
    },
    click_company_pinterest: {
      state_name,
    },
    click_carousel_banner: {
      state_name,
      ...additionalProperties,
    },
    click_banner: {
      state_name,
      ...additionalProperties,
    },
    click_success_story_banner: {
      state_name,
      ...additionalProperties,
    },
    click_other_option: {
      state_name,
    },
    click_apply_creator_now: {
      state_name: 'SUPPORTER_PROFILE',
      ...additionalProperties,
    },
    click_browse_search: {
      state_name,
      ...additionalProperties,
    },
    click_see_section_detail: {
      state_name,
      ...additionalProperties,
    },
    click_career: {
      state_name,
    },
    visit_section_detail: {
      state_name,
      section_id: sectionCampaignDetail?.section_id || '',
      section_title: sectionCampaignDetail?.section_title || '',
      section_content_type: sectionCampaignDetail?.section_type || '',
      ...additionalProperties,
      ...sourceProperties,
    },
    click_stay_supporter: {
      state_name,
      modal_name: 'WELCOME_MESSAGE_NEW_SUPPORTER',
    },
    click_create_community: {
      state_name,
      modal_name: 'WELCOME_MESSAGE_NEW_SUPPORTER',
      user_type,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
