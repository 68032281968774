import 'dayjs/locale/id'
import dayjs, { ConfigType, Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import getRangeArray from 'helpers/array/range'
import _get from 'lodash/get'
import _findKey from 'lodash/findKey'

dayjs.extend(timezone)
dayjs.extend(utc)

export function getDateTimeUTC(date: ConfigType): string {
  return dayjs(date).utc().format()
}

export function getTimeAfterMinutes(
  date: ConfigType,
  minutes: number,
): string {
  const formattedDate = dayjs(date)
  return formattedDate.add(minutes, 'm').format('hh:mm a')
}

export function getDateTimeAfterMinutes(
  date: ConfigType,
  minutes: number,
): string {
  const formattedDate = dayjs(date)
  return formattedDate.add(minutes, 'm').utc().format()
}

export function getDateTimeAfterMonths(
  date: ConfigType,
  months: number,
): string {
  const formattedDate = dayjs(date)
  return formattedDate.add(months, 'M').utc().format()
}

export function getTimezoneAbbreviation(
  date = Date.now(),
  locale = 'id-ID',
): string {
  const shortTimezone = new Intl.DateTimeFormat(locale, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short',
  })
    .format(date)
    .split(' ')[1]
  return shortTimezone
}

export function convertSecondsToDate(
  seconds: number,
  format = 'DD/MM/YYYY',
): string {
  const miliseconds: number = dayjs().second(seconds).valueOf()
  const date = dayjs(miliseconds).format(format)
  return date
}

export function durationSessionPercentage(
  timerSeconds: number,
  durationSeconds: number,
): number {
  let percentage = 0
  if (durationSeconds >= timerSeconds) {
    percentage = Math.floor(
      ((durationSeconds - timerSeconds) / durationSeconds) * 100,
    )
  }
  return percentage
}

export function getSecondsDateRange(
  startDate: string,
  endDate: string,
): number {
  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  return Math.floor((end - start) / 1000)
}

export function toHHMMSS(
  secs,
  options?: {
    withoutHour: boolean
  },
): string {
  const sec_num: number = parseInt(secs, 10)
  const hours: number = Math.floor(sec_num / 3600)
  let minutes: number = Math.floor(sec_num / 60) % 60
  const seconds: number = sec_num % 60

  if (options?.withoutHour) {
    minutes += hours * 60
    return [minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':')
  }
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .join(':')
}

export function getMidnightDate(): Date {
  let midnightDate = new Date()
  midnightDate.setHours(23, 59, 59, 0)
  return midnightDate
}

export function addDays(days: number = 0, date = new Date()): Date {
  date.setDate(date.getDate() + days)
  return date
}

export function handleSetTotalDays(
  date: string,
  month: string,
  year: string,
) {
  if (
    month === '04' ||
    month === '06' ||
    month === '09' ||
    month === '11'
  ) {
    return {
      totalDays: getRangeArray(1, 30),
      isValid: date !== '' && parseInt(date) > 30,
    }
  } else if (month === '02') {
    if (
      year !== '' &&
      parseInt(year) % 4 == 0 &&
      (parseInt(year) % 100 != 0 || parseInt(year) % 400 == 0)
    ) {
      return {
        totalDays: getRangeArray(1, 29),
        isValid: date !== '' && parseInt(date) > 29,
      }
    } else {
      return {
        totalDays: getRangeArray(1, 28),
        isValid: date !== '' && parseInt(date) > 28,
      }
    }
  }

  return {
    totalDays: getRangeArray(1, 31),
    isValid: date !== '' && parseInt(date) > 31,
  }
}

/**
 * Create a range of Day.js dates between a start and end date.
 */
export function getDaysBetween(start: Dayjs, end: Dayjs) {
  const range = []
  let current = start
  while (!current.isAfter(end)) {
    range.push(current)
    current = current.add(1, 'days')
  }
  return range
}

export function formatDateRange(
  startDate: ConfigType,
  endDate: ConfigType,
  timezone: string,
) {
  dayjs.locale('id')

  const isSingleDay = dayjs(
    dayjs(startDate).format('YYYY-MM-DD'),
  ).isSame(dayjs(endDate).format('YYYY-MM-DD'))

  if (isSingleDay) {
    // Senin, 29 Jul, 11:00
    return dayjs(startDate)
      .tz(timezone)
      .format('dddd, DD MMM, HH:mm z')
  }
  const dateRangeFormat = [
    dayjs(startDate).tz(timezone).format('DD MMM'),
    dayjs(endDate).tz(timezone).format('DD MMM'),
  ].join(' - ')
  const hourFormat = dayjs(startDate).tz(timezone).format('HH:mm')

  // 28 Jul - 29 Jul, 11.00
  return [dateRangeFormat, hourFormat].join(', ')
}

export function getEventDateRange(data: any) {
  const key =
    _findKey(data, 'event_started_at_tz') ||
    _findKey(data, 'event_started_at')
  const result = _get(data, key, data || {})

  const startDate =
    result?.event_started_at_tz || result?.event_started_at
  const endDate =
    result?.event_ended_at_tz || result?.event_ended_at_tz

  return {
    startDate,
    endDate,
  }
}
