import { action } from 'easy-peasy'
import IBrowseContentModel from 'stores/domains/Content/models/interface'
import get from 'lodash/get'

const BrowseContentModel: IBrowseContentModel = {
  isLoading: true,
  isError: false,
  contentData: {
    contentList: [],
    loadMore: false,
    sources: '',
    currentPage: 1,
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setBrowseContent: action((state, response) => {
    state.contentData = {
      contentList:
        get(response, 'data._metadata.page', 1) > 1
          ? [
              ...state.contentData.contentList,
              ...get(response, 'data.data', []),
            ]
          : get(response, 'data.data', []),
      loadMore: get(response, 'data._metadata.load_more', false),
      sources: get(response, 'data._metadata.sources', ''),
      currentPage: get(response, 'data._metadata.page', 1),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
}

export default BrowseContentModel
