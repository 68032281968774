import ampConfig from 'configs/amplify'
import { forceDeleteCookies } from 'helpers/cookie'

const logoutSSO = () => {
  forceDeleteCookies()
  localStorage.clear()
  const { domain, redirectSignOut, responseType } = ampConfig.oauth

  const params = {
    client_id: ampConfig.Auth.userPoolWebClientId,
    logout_uri: redirectSignOut,
    redirect_uri: redirectSignOut,
    response_type: responseType,
  }
  const queryParams = new URLSearchParams(params).toString()
  const url = `https://${domain}/logout?${queryParams}`

  window.location.href = url
}

export default logoutSSO
