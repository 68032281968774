const getRangeArray = (start, stop, step = 1) => {
  var a = [start],
    b = start
  while (b < stop) {
    a.push((b += step || 1))
  }
  return b > stop ? a.slice(0, -1) : a
}

export default getRangeArray
