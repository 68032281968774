import { useEffect, useState } from 'react'

function useReady() {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (mounted) return
    setMounted(true)
  }, [])

  return { mounted }
}

export default useReady
