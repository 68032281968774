import { useRouter } from 'next/router'

import authConfig from 'configs/auth'
import trackEvent from 'src/trackers'
import { redirectWithReferrer } from 'helpers/redirector'
import { getIdToken } from 'helpers/auth'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

function MobileSignIn(): JSX.Element {
  const { t } = useTranslation()
  const { pathname, query } = useRouter()

  const trackerData = { pathname, query }
  const idToken = getIdToken()

  const includeUrls = ['/']

  const handleClickLogin = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_sign_in', trackerData)
    redirectWithReferrer(authConfig.loginPath)
  }

  if (idToken || !includeUrls.includes(String(pathname))) {
    return null
  }

  return (
    <Stack>
      <Button
        id="b-users-header-sign-in"
        buttonVariant="filled"
        size="large"
        fullWidth
        onClick={handleClickLogin}
      >
        {t('signInTitle')}
      </Button>
    </Stack>
  )
}

export default MobileSignIn
