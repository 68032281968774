import { Stack, StackProps, Typography } from '@mui/material'
import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'

export interface MenuContentItemProps {
  title: string
  description: string
  icon: JSX.Element
}

interface IMenuContentItem
  extends MenuContentItemProps,
    Omit<StackProps, 'title'> {
  onClick?: () => void
}

function MenuContentItem({
  title,
  description,
  icon,
  onClick,
  ...props
}: IMenuContentItem) {
  const { t } = useTranslation()

  return (
    <Stack
      display="flex"
      flexDirection="row"
      justifyContent="left"
      alignItems="center"
      spacing={2}
      columnGap={2}
      onClick={() => {
        if (onClick) onClick()
      }}
      sx={{
        cursor: !!onClick ? 'pointer' : 'default',
      }}
      {...props}
    >
      <Stack>{icon}</Stack>
      <Stack spacing={1}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h4"
            fontWeight="700"
            color="text.primary"
          >
            {t(title)}
          </Typography>
          {!!onClick && (
            <IconKeyboardArrowRight
              fontSize="small"
              color="primary"
            />
          )}
        </Stack>
        <Typography variant="p" color="text.secondary">
          {t(description)}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default MenuContentItem
