import { trackEvent, getStateFromSource } from 'helpers/analytics'

export default function PaymentTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const {
    pathname,
    states,
    contentDetail,
    contentUserDetail,
    eventDetail,
    eventUserDetail,
    itemType,
    contentCommunityInfo,
    saleTimeLeft,
    productEventDetail,
  } = data

  const productDetail = contentDetail || eventDetail
  const productUserDetail = contentUserDetail || eventUserDetail
  const state_name =
    (itemType === 'event'
      ? 'ETICKET_ADDITIONAL_FORM'
      : getStateFromSource(pathname)) || ''
  const creator_category_id =
    states?.creatorProfile?.creatorProfileData?.category?.id || ''
  const creator_category =
    states?.creatorProfile?.creatorProfileData?.category?.name || ''
  const creator_user_id =
    states?.creatorProfile?.creatorProfileData?.creator
      ?.creator_user_id || ''
  const creator_name =
    states?.creatorProfile?.creatorProfileData?.creator?.name || ''
  const creator_id =
    states?.creatorProfile?.creatorProfileData?.creator?.creator_id ||
    ''
  const content_title = productDetail?.title || ''
  const content_id = productDetail?.product_uid || ''
  const content_photo_image_path = productDetail?.cover || ''
  const content_price_initial = productUserDetail?.price || ''
  const content_price_final = productUserDetail?.sale_price || ''
  const content_type = productDetail?.type?.name?.toLowerCase()
  const content_items_sold = productDetail?.stats?.total_sales || ''
  const content_created_date = productDetail?.created_at || ''
  const content_description = productDetail?.description || ''
  const content_description_count =
    productDetail?.description?.length || ''
  const content_has_teaser =
    productDetail?.teaser?.length > 0 ? true : false
  const content_duration = productDetail?.content_length || ''
  const content_page_count = productDetail?.content_length || ''

  const community_id = contentCommunityInfo?.mask_id || ''
  const community_name = contentCommunityInfo?.name || ''
  const community_is_private = contentCommunityInfo?.is_private || ''

  const event_id = eventDetail?.product_uid || ''
  const event_title = eventDetail?.title || ''
  const event_type_id = eventDetail?.type?.id || ''
  const event_type_name = eventDetail?.type?.name.toLowerCase() || ''
  const event_num_sold = eventDetail?.stats?.total_sales || ''
  const event_promoter_commission = eventUserDetail?.pro_com_est || ''
  const event_price_initial = eventUserDetail?.price || ''
  const event_price_final = eventUserDetail?.sale_price || ''
  const event_is_promoted = eventUserDetail?.pro_com_est > 0 || false
  const event_is_seat_selection_set =
    eventUserDetail?.event_summary?.is_seating_required || false
  const event_is_custom_form_set =
    eventUserDetail?.event_summary?.additional_form?.id !== '' ||
    false
  const event_is_first_purchase =
    eventUserDetail?.event_summary?.status_order === '' || false
  const event_has_teaser = eventDetail?.teaser !== '' || ''
  const event_poster_image_path = eventDetail?.cover || ''
  const event_created_date = eventDetail?.created_at || ''
  const event_sale_end_date =
    eventUserDetail?.event_summary?.sales_ended_at || ''
  const event_sale_countdown_hrs = saleTimeLeft || ''
  const event_start_date =
    eventUserDetail?.event_summary?.event_started_at || ''
  const event_end_date =
    eventUserDetail?.event_summary?.event_ended_at || ''
  const event_location_city =
    eventUserDetail?.event_summary?.location_city || ''
  const event_location_name =
    eventUserDetail?.event_summary?.location_venue || ''
  const event_gmap_url = eventUserDetail?.event_summary?.map_url || ''
  const event_max_quota = eventUserDetail?.event_summary?.quota || ''
  const event_status = eventUserDetail?.event_summary?.status || ''
  const event_is_multiple_option_selected =
    productEventDetail?.ticket_options?.length > 1

  const baseProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_photo_image_path,
    content_price_initial,
    content_price_final,
    content_type,
    content_items_sold,
    content_created_date,
    content_description,
    content_description_count,
    content_has_teaser,
  }
  const basePropertiesEvent = {
    state_name,
    event_is_promoted,
    event_promoter_commission,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    event_title,
    event_id,
    event_type_id,
    event_type_name,
    event_num_sold,
    event_price_initial,
    event_price_final,
    event_is_seat_selection_set,
    event_is_custom_form_set,
    event_is_first_purchase,
    event_has_teaser,
    event_poster_image_path,
    event_created_date,
    event_sale_end_date,
    event_sale_countdown_hrs,
    event_start_date,
    event_end_date,
    event_location_city,
    event_location_name,
    event_gmap_url,
    event_max_quota,
    event_status,
    event_is_multiple_option_selected,
  }

  const communityProperties = {
    community_id,
    community_name,
    community_is_private,
  }
  const videoProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_duration,
    content_type,
    content_price_initial,
    content_price_final,
    content_description,
  }

  const documentProperties = {
    state_name,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_page_count,
    content_type,
    content_price_initial,
    content_price_final,
    content_description,
  }

  if (content_type === 'document') {
    delete baseProperties['content_items_sold']
    delete baseProperties['content_photo_image_path']

    baseProperties['content_page_count'] = content_page_count

    if (content_price_initial === '') {
      baseProperties['content_price_initial'] = 0
      documentProperties['content_price_initial'] = 0
    }
    if (content_price_final === '') {
      baseProperties['content_price_final'] = 0
      documentProperties['content_price_final'] = 0
    }
  }

  if (content_type === 'video') {
    baseProperties['content_page_count'] = content_page_count

    if (content_price_initial === '') {
      baseProperties['content_price_initial'] = 0
      videoProperties['content_price_initial'] = 0
    }
    if (content_price_final === '') {
      baseProperties['content_price_final'] = 0
      videoProperties['content_price_final'] = 0
    }
    if (content_duration === '') {
      videoProperties['content_duration'] = 0
    }
  }
  const eventProperties = {
    close_voucher_code_failed_page: {
      ...baseProperties,
      ...additionalProperties,
      modal_name: 'PURCHASE_CONTENT',
    },
    click_voucher_code_fill_in:
      itemType === 'event'
        ? {
            ...basePropertiesEvent,
            ...communityProperties,
            ...additionalProperties,
          }
        : {
            ...baseProperties,
            ...additionalProperties,
          },
    click_voucher_code_tnc: {
      ...baseProperties,
      ...additionalProperties,
      modal_name: 'PURCHASE_CONTENT',
    },
    topup_completed: {
      state_name,
      ...additionalProperties,
    },
    cancel_topup_payment: {
      state_name,
      ...additionalProperties,
    },
    click_topup: {
      state_name,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
