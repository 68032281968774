import { StateCreator } from 'zustand'
import {
  ICreatorFollower,
  IFollowStateDefault,
  IFollowStore,
} from './interface'

const resetters: (() => void)[] = []

export const INITIAL_CREATOR_FOLLOWER: ICreatorFollower = {
  creator_id: '',
  creator_user_status: 1,
  creator_username: '',
  follower_number: 0,
  is_following: false,
}

const INITIAL_DATA: IFollowStateDefault = {
  isOpenSuggestFollowModal: false,
  isSuccessFollow: false,
  successMessage: '',
  creatorFollower: INITIAL_CREATOR_FOLLOWER,
}

export const createFollowStore: StateCreator<IFollowStore> = (
  set,
) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setIsOpenSuggestFollowModal(data) {
      set(() => ({ isOpenSuggestFollowModal: data }))
    },
    setIsSuccessFollow(data) {
      set(() => ({ isSuccessFollow: data }))
    },
    setSuccessMessage(data) {
      set(() => ({ successMessage: data }))
    },
    setCreatorFollower(data) {
      set(() => ({ creatorFollower: data }))
    },
  }
}
