import { action, thunk } from 'easy-peasy'
import { IDetailModel } from 'stores/domains/Content/models/Detail/interface'
import { getCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import isEmpty from 'helpers/is-empty'

const DetailModel: IDetailModel = {
  isError: false,
  errorMessage: '',
  errorResponse: null,
  isLoading: false,
  isFetching: false,
  data: null,

  iframeUrl: '',
  isIframeUrlLoading: false,
  isIframeUrlError: false,

  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsFetching: action((state, payload) => {
    state.isFetching = payload
  }),

  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  setErrorResponse: action((state, payload) => {
    state.errorResponse = payload
  }),
  setContentDetail: action((state, payload) => {
    state.data = payload
  }),

  setIframeUrl: action((state, payload) => {
    state.iframeUrl = payload
  }),
  setIsIframeUrlLoading: action((state, payload) => {
    state.isIframeUrlLoading = payload
  }),
  setIsIframeUrlError: action((state, payload) => {
    state.isIframeUrlError = payload
  }),

  resetContentDetail: action((state) => {
    state.isError = false
    state.errorMessage = ''
    state.errorResponse = null
    state.isLoading = false
    state.isFetching = false
    state.data = null
    state.iframeUrl = ''
    state.isIframeUrlLoading = false
    state.isIframeUrlError = false
  }),

  getContentDetail: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsFetching(true)
        actions.setIsLoading(true)
        actions.setIsError(false)
        actions.setErrorResponse(null)

        const promoterToken = !isEmpty(payload.promoterToken)
          ? payload.promoterToken
          : ''

        const { apiClient } = injections
        const res = await apiClient({
          url: getCookie(authConfig.idTokenName)
            ? `/content/v1/product/${payload.content_uid}/detail?pt=${promoterToken}`
            : `/content/guest/v1/product/${payload.content_uid}/detail?pt=${promoterToken}`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setContentDetail(res.data.data)
      } catch (err) {
        actions.setErrorResponse(err.response || null)
        actions.setIsError(true)
      } finally {
        actions.setIsFetching(false)
        actions.setIsLoading(false)
      }
    },
  ),

  getContentIframe: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsIframeUrlLoading(true)
        const { apiClient } = injections
        const url =
          payload.apikey !== undefined
            ? `/content/guest/v1/product/${payload.content_uid}/file`
            : `/content/v1/product/${payload.content_uid}/file`
        const res = await apiClient({
          url,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
          params: payload.apikey !== undefined && {
            apikey: payload.apikey,
          },
        })
        actions.setIframeUrl(res.data.data.file_link)
      } catch (err) {
        actions.setIsIframeUrlError(true)
      } finally {
        actions.setIsIframeUrlLoading(false)
      }
    },
  ),
}

export default DetailModel
