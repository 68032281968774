import { action, thunk } from 'easy-peasy'
import IVoucherModel from 'stores/domains/User/models/Voucher/interface'
import get from 'lodash/get'
import { getIdToken } from 'helpers/auth'

const VoucherModel: IVoucherModel = {
  inputVoucherCode: '',
  isLoading: false,
  isError: false,
  errorMessage: { code: '', message: '', data: {}, errors: [] },
  voucherDetails: null,
  voucherData: null,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setVoucherData: action((state, response) => {
    state.voucherData = get(response, 'data.data', null)
    state.errorMessage = {
      code: '',
      message: '',
      data: {},
      errors: [],
    }
    state.isError = false
  }),
  setVoucherDetails: action((state, payload) => {
    state.voucherDetails = payload
  }),
  setInputVoucherCode: action((state, payload) => {
    state.inputVoucherCode = payload
  }),

  checkVoucherData: thunk(
    async (
      actions,
      {
        products,
        payment_method,
        user_mask_id,
        voucher_code,
        idempotentKey,
        checkout_source,
      },
      { injections },
    ) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/order/v4/voucher/check`,
          method: 'POST',
          token: getIdToken(),
          data: {
            products: products,
            payment_method: payment_method,
            user_mask_id: user_mask_id,
            voucher_code: voucher_code,
            checkout_source: checkout_source,
          },
          headers: { 'Idempotent-Key': idempotentKey },
        })
        actions.setVoucherData(response)
        actions.setLoading(false)
      } catch (error) {
        actions.setVoucherData(null)
        actions.setLoading(false)
        actions.setError(error?.response?.data)
      } finally {
        actions.setLoading(false)
      }
    },
  ),
}

export default VoucherModel
