import * as React from 'react'
import { useRouter } from 'next/router'
import Stack from 'components/commons/Stack'

import CreateMenuList from 'components/commons/Header/CreateMenuList'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import Button from 'components/commons/Button'
import { useTranslation } from 'react-i18next'

function CreateMenuHeader() {
  const { pathname } = useRouter()
  const { t } = useTranslation()
  const [isActive, setIsActive] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isActive) {
      const eventProperties = {
        state_name: getStateFromSource(pathname),
      }
      trackEvent('click_create_product', eventProperties)
    }
    return () => {
      setIsActive(false)
    }
  }, [isActive])

  return (
    <Stack
      position="relative"
      display={{ xs: 'none', md: 'inline-block' }}
      sx={{
        ':hover .button': {
          opacity: '75%',
        },
        ':hover .dropdown-content': {
          visibility: 'visible',
          opacity: 1,
        },
      }}
    >
      <Button
        id="b-common-create-button"
        className="button"
        buttonVariant="filled"
        size="small"
        onMouseOver={() => {
          if (!isActive) setIsActive(true)
        }}
      >
        Create
      </Button>
      <Stack
        className="dropdown-content"
        visibility="hidden"
        position="absolute"
        paddingY={2}
        zIndex={1}
        right={0}
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <Stack
          id="c-common-create-menu"
          sx={(theme) => ({
            padding: 2,
            color: theme.palette.text.primary,
            background: theme.palette.background.primary,
            boxShadow: '0px 8px 8px rgba(161, 160, 166, 0.12)',
            borderRadius: '12px',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
          })}
        >
          <CreateMenuList
            title={t('createContentBottomSheetBuatKonten')}
            width="400px"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CreateMenuHeader
