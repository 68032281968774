import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M2 9.5C1.45 9.5 1 9.95 1 10.5V20.5C1 21.6 1.9 22.5 3 22.5H17C18.11 22.5 19 21.61 19 20.5H4C3.45 20.5 3 20.05 3 19.5V10.5C3 9.95 2.55 9.5 2 9.5Z"
        fill="#CB1C4F"
      />
      <path
        d="M18 5.5V3.5C18 2.4 17.1 1.5 16 1.5H12C10.9 1.5 10 2.4 10 3.5V5.5H7C5.9 5.5 5 6.4 5 7.5V16.5C5 17.6 5.9 18.5 7 18.5H21C22.1 18.5 23 17.6 23 16.5V7.5C23 6.4 22.1 5.5 21 5.5H18ZM12 3.5H16V5.5H12V3.5ZM12 14.52V9.34C12 8.96 12.41 8.72 12.74 8.9L16.81 11.12C17.13 11.3 17.16 11.75 16.86 11.96L12.79 14.92C12.46 15.16 12 14.93 12 14.52Z"
        fill="#F87500"
      />
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
