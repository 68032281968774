import { useInfiniteQuery } from '@tanstack/react-query'
import { useCommunityStore } from 'stores/domains/Social'
import { getErrorMessage } from 'helpers/error'
import get from 'lodash/get'
import { getCommunityMember } from 'services/social'

export const useListCommunityMember = (
  id: string,
  limit: number = 5,
) => {
  const {
    setTotalMember,
    setCommunityMemberLoadMore,
    setTotalPendingRequest,
  } = useCommunityStore((state) => ({
    setTotalMember: state.setTotalMember,
    setCommunityMemberLoadMore: state.setCommunityMemberLoadMore,
    setTotalPendingRequest: state.setTotalPendingRequest,
  }))
  return useInfiniteQuery({
    queryKey: ['community-member', id, limit],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await getCommunityMember(id, {
          page: pageParam,
          limit,
        })
        setTotalMember(get(response, 'data._metadata.total', 0))
        setTotalPendingRequest(
          get(response, 'data._metadata.pending_request', 0),
        )
        setCommunityMemberLoadMore(
          get(response, 'data._metadata.load_more', false),
        )
        return response.data
      } catch (error) {
        throw new Error(getErrorMessage(error))
      }
    },
    enabled: false,
    retry: false,
    getNextPageParam: (lastPage: any) => {
      return lastPage?._metadata.page + 1
    },
  })
}
