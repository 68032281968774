import * as React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m12 16 12.242 48H56L23 12l-11 4Z" fill="url(#a)" />
      <path
        d="M8.528 7.235a5 5 0 1 1 8.946-4.468l3.128 6.262a5 5 0 0 1-8.946 4.469L8.528 7.235Z"
        fill="#312BD5"
      />
      <path
        d="M21.497 8.582c-.74-1.482-3.744-1.483-6.709-.003-2.964 1.481-4.767 3.883-4.027 5.365l1.341 2.684 10.735-5.362-1.34-2.684Z"
        fill="#484FF9"
      />
      <path
        d="M22.837 11.266c.74 1.482-1.062 3.884-4.027 5.365-2.964 1.48-5.968 1.48-6.708-.003-.74-1.482 1.063-3.884 4.027-5.365 2.964-1.48 5.968-1.48 6.708.003Z"
        fill="#CB1C4F"
      />
      <path d="M52 16 39.758 64H8l33-52 11 4Z" fill="url(#b)" />
      <path
        d="M55.472 7.235a5 5 0 1 0-8.946-4.468l-3.128 6.262a5 5 0 0 0 8.946 4.469l3.128-6.263Z"
        fill="#312BD5"
      />
      <path
        d="M42.503 8.582c.74-1.482 3.744-1.483 6.709-.003 2.964 1.481 4.767 3.883 4.027 5.365l-1.341 2.684-10.735-5.362 1.34-2.684Z"
        fill="#484FF9"
      />
      <path
        d="M41.163 11.266c-.74 1.482 1.062 3.884 4.027 5.365 2.964 1.48 5.968 1.48 6.708-.003.74-1.482-1.063-3.884-4.027-5.365-2.964-1.48-5.968-1.48-6.708.003Z"
        fill="#F87500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.414 45.743 32 26.182 19.586 45.743 24.242 64h15.516l4.656-18.257Z"
        fill="#F87500"
      />
      <defs>
        <linearGradient
          id="a"
          x1={34}
          y1={12}
          x2={34}
          y2={64}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D93D68" />
          <stop offset={1} stopColor="#E85E81" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={30}
          y1={12}
          x2={30}
          y2={64}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F89C0B" />
          <stop offset={1} stopColor="#F8C316" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
