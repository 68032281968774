import { useState } from 'react'
import {
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { ICustomField } from '../interface'

// field validation function
function validateField({
  fieldValidation,
  fieldValue,
  fieldLabel,
}): string | null {
  if (fieldValidation.required && !fieldValue.length) {
    return `${fieldLabel} wajib diisi`
  }

  return null
}

// main component function
export default function CustomRadioField({ ...props }: ICustomField) {
  const [isTouched, setIsTouched] = useState(false)

  if (!props.options?.length) {
    return null
  }

  const validationResult = validateField({
    fieldLabel: props.label,
    fieldValidation: props.validation,
    fieldValue: props.value,
  })

  return (
    <>
      <RadioGroup
        row
        value={props.value}
        onBlur={() => {
          setIsTouched(true)
        }}
        onChange={(e) => {
          setIsTouched(true)
          props.onValueChange(
            e.target.value,
            validateField({
              fieldValidation: props.validation,
              fieldValue: e.target.value,
              fieldLabel: props.label,
            }) === null,
          )
        }}
      >
        {props.options.map((item, itemIndex) => {
          return (
            <FormControlLabel
              key={`${props.name}-options-${itemIndex}`}
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                marginRight: '16px',
              })}
              control={<Radio />}
              value={item.value}
              label={item.value}
              disabled={item.disabled || props.disabled}
            />
          )
        })}
      </RadioGroup>
      {isTouched && validationResult && (
        <FormHelperText
          sx={(theme) => ({ color: theme.palette.error.main })}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <ErrorIcon
              sx={{ fontSize: 'inherit', marginRight: '4px' }}
            />
            {validationResult}
          </Stack>
        </FormHelperText>
      )}
    </>
  )
}
