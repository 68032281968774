import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { addDays } from 'helpers/date-time'
import dateFormatter from 'helpers/formats/date'

export default function SubscriptionTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const { pathname, states } = data

  const state_name = getStateFromSource(pathname) || ''
  const state_sub_name = states.stateSubName || ''

  const first_time_subscription_to_creator =
    !states.userSubscriptionData?.is_subscriber
  const creator_category_id = states.creatorProfileData.category.id
  const creator_category = states.creatorProfileData.category.name
  const creator_user_id =
    states.creatorProfileData.creator.creator_user_id
  const creator_name = states.creatorProfileData.creator.name
  const creator_id = states.creatorProfileData.creator.creator_id

  const package_id = states?.packageData?.package_id || ''
  const package_title = states?.packageData?.title || ''
  const package_description = states?.packageData?.description || ''
  const package_selected_perks_id =
    states?.packageData?.selected_perks || ''
  const package_selected_perks_name =
    states?.packageData?.selected_perks.map((perk) => perk.name) || ''

  const package_duration = states?.packageDurationData?.duration || ''
  const package_price_initial =
    states?.packageDurationData?.price || ''
  const package_price_final = states?.packageDurationData?.price || ''

  const package_tax_value = states.cartData?.payment_info?.tax || 0
  const package_admin_fee =
    states.cartData?.payment_info?.admin_fee || ''
  const package_total_price =
    states.cartData?.payment_info?.total || ''

  const subscribed_package =
    states.userSubscriptionData?.list_packages?.find((item) => {
      return item.package_id === states.packageData?.package_id
    })
  const package_is_subscribed = !!subscribed_package

  const package_start_date = dateFormatter({
    date: new Date().toString(),
    format: 'YYYY-MM-DDTHH:mm:ss',
  })
  const package_end_date = dateFormatter({
    date: addDays(
      states?.packageDurationData?.duration,
      package_is_subscribed
        ? new Date(subscribed_package.ended_at)
        : new Date(),
    ).toString(),
    format: 'YYYY-MM-DDTHH:mm:ss',
  })

  const baseProperties = {
    state_name,
    state_sub_name,
    first_time_subscription_to_creator,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
  }

  const packageProperties = {
    package_id,
    package_title,
    package_description,
    package_selected_perks_id,
    package_selected_perks_name,
    package_is_subscribed,
  }

  const eventProperties = {
    click_subscribe: {
      ...baseProperties,
      ...additionalProperties,
    },
    close_creator_subscription_modal: {
      modal_name: 'CREATOR_SUBSCRIPTION',
      ...baseProperties,
      ...additionalProperties,
    },
    close_subscription_duration_modal: {
      modal_name: 'CREATOR_SUBSCRIPTION_DURATION',
      ...baseProperties,
      ...additionalProperties,
    },
    click_subscription_tnc: {
      modal_name: 'CREATOR_SUBSCRIPTION',
      ...baseProperties,
      ...additionalProperties,
    },
    select_subscription_package: {
      modal_name: 'CREATOR_SUBSCRIPTION',
      ...baseProperties,
      ...packageProperties,
      ...additionalProperties,
    },
    change_subscription_package: {
      modal_name: 'CREATOR_SUBSCRIPTION_DURATION',
      ...baseProperties,
      ...packageProperties,
      ...additionalProperties,
    },
    select_subscription_duration: {
      modal_name: 'CREATOR_SUBSCRIPTION_DURATION',
      ...baseProperties,
      ...packageProperties,
      package_duration,
      package_price_initial,
      package_price_final,
      package_start_date,
      package_end_date,
      ...additionalProperties,
    },
    submit_creator_subscription: {
      modal_name: 'CREATOR_SUBSCRIPTION_DURATION',
      ...baseProperties,
      ...packageProperties,
      package_duration,
      package_price_initial,
      package_price_final,
      package_start_date,
      package_end_date,
      ...additionalProperties,
    },
    cancel_creator_subscription: {
      modal_name: 'SUBSCRIBE_PACKAGE_CONFIRMATION',
      ...baseProperties,
      ...packageProperties,
      package_duration,
      package_price_initial,
      package_price_final,
      package_start_date,
      package_end_date,
      package_tax_value,
      package_admin_fee,
      package_total_price,
      ...additionalProperties,
    },
    subscribe_creator_confirm: {
      modal_name: 'SUBSCRIBE_PACKAGE_CONFIRMATION',
      ...baseProperties,
      ...packageProperties,
      package_duration,
      package_price_initial,
      package_price_final,
      package_start_date,
      package_end_date,
      package_tax_value,
      package_admin_fee,
      package_total_price,
      ...additionalProperties,
    },
    click_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    trigger_close_promote_subscription: {
      ...baseProperties,
      modal_name: 'CREATOR_SUBSCRIPTION',
    },
    trigger_dont_show_modal: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_content_hub: {
      ...baseProperties,
      ...additionalProperties,
    },
  }
  return trackEvent(eventName, eventProperties[eventName])
}
