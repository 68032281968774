import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import ISessionModel from 'stores/domains/Session/SessionList/interface'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'

const SessionModel: ISessionModel = {
  isLoading: true,
  isError: false,
  sessionData: {
    sessionList: [],
    loadMore: false,
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setSessionList: action((state, response) => {
    state.sessionData = {
      sessionList: get(response, 'data.data', []),
      loadMore: get(response, 'data._metadata.load_more', false),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  setDataLoadmoreSession: action((state, response) => {
    state.sessionData = {
      sessionList: [
        ...state.sessionData.sessionList,
        ...get(response, 'data.data', []),
      ],
      loadMore: get(response, 'data._metadata.load_more', false),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  getSessionList: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      let idToken = getCookie(authConfig.idTokenName)
      let url = ''
      if (!isEmpty(idToken)) {
        url = '/session/v1/list'
      } else {
        url = '/session/guest/v1/list'
        idToken = ''
      }
      const { apiClient } = injections
      const response = await apiClient({
        url: url,
        method: 'GET',
        token: idToken,
        params: {
          limit: 10,
          page: 1,
          status: payload.id,
        },
      })
      actions.setSessionList(response)
      actions.setLoading(false)
    } catch (error) {
      if (error.response?.data?.code === 'NOT_FOUND') {
        actions.setSessionList([])
        actions.setLoading(false)
      } else {
        actions.setError(error.message)
      }
    }
  }),
  loadMoreSession: thunk(async (actions, payload, { injections }) => {
    try {
      let idToken = getCookie(authConfig.idTokenName)
      let url = ''
      if (!isEmpty(idToken)) {
        url = '/session/v1/list'
      } else {
        url = '/session/guest/v1/list'
        idToken = ''
      }
      const { apiClient } = injections
      const response = await apiClient({
        url: url,
        method: 'GET',
        token: idToken,
        params: payload.params,
      })
      actions.setDataLoadmoreSession(response)
    } catch (error) {
      if (error.response?.data?.code === 'NOT_FOUND') {
        actions.setError(error.response?.message)
      }
    }
  }),
}

export default SessionModel
