import { useFormContext, Controller } from 'react-hook-form'
import { FormControl, SwitchProps } from '@mui/material'
import Switch from 'components/commons/Switch'

type IProps = {
  name: string
  label?: string
}

type Props = IProps & SwitchProps

export default function RHFSwitch({
  name,
  label,
  id,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <FormControl variant="standard">
      <Controller
        name={name}
        control={control as any}
        render={({ field: { onChange, value, ...field } }) => (
          <Switch
            onChange={onChange}
            checked={value}
            {...field}
            id={id}
            {...other}
          />
        )}
      />
    </FormControl>
  )
}
