import { getErrorCode, getErrorMessage } from 'helpers/error'
import { sendLog } from 'helpers/log'
import { StateCreator } from 'zustand'
import { IAddToCartStore } from './interface'

export const createAddToCartStore: StateCreator<IAddToCartStore> = (
  set,
) => ({
  addToCartResponse: {
    code: '',
    message: '',
    data: {},
    errors: [],
  },
  setAddToCart: async (payload) => {
    set({ addToCartResponse: payload })
  },
  setErrorAddToCart: async (error) => {
    const message = getErrorMessage(error)
    const errorCode = getErrorCode(error)
    sendLog(message)
    set({
      addToCartResponse: {
        code: errorCode,
        message: message,
        data: {},
        errors: [],
      },
    })
  },
  resetData: () => {
    set({
      addToCartResponse: {
        code: '',
        message: '',
        data: {},
        errors: [],
      },
    })
  },
})
