import { ISearchParams } from 'stores/commons/models/Search/interface'
import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'

export const search = async (
  params: ISearchParams,
  eventType?: string,
) => {
  let url = '/recommendation/guest/v3/search?'

  if (eventType && eventType !== '') {
    url = url + eventType
  }

  return await apiClient({
    url: url,
    method: 'GET',
    params,
  })
}

export async function getHomeBanner() {
  return await apiClient({
    url: '/content/guest/v1/home/banner',
    method: 'GET',
  })
}

export const getContentDetail = async (
  product_uid: string | string[],
  pt?: string | string[],
) => {
  if (!product_uid) {
    throw 'id not exist'
  }
  return await apiClient({
    url: !isGuestMode()
      ? `/content/v2/product/${product_uid}/detail`
      : `/content/guest/v2/product/${product_uid}/detail`,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      pt: pt,
    },
  })
}

export const getContentUserDetail = async (
  content_uid: string | string[],
  pt?: string | string[],
) => {
  return await apiClient({
    url: !isGuestMode()
      ? `/content/v2/product/${content_uid}/user`
      : `/content/guest/v2/product/${content_uid}/user`,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      pt: pt,
    },
  })
}

export const getContentCommunityInfo = async (
  content_uid: string | string[],
) => {
  return await apiClient({
    url: getIdToken()
      ? `/content/v1/product/${content_uid}/community`
      : `/content/guest/v1/product/${content_uid}/community`,
    method: 'GET',
    token: getIdToken(),
  })
}

export interface ICommunityContentParams {
  pc?: number // page content
  pe?: number // page event
  lc?: number // limit content
  le?: number // limit event
  tab: 'content' | 'event' | 'all'
}
export const getCommunityContent = async (
  id: string,
  params: ICommunityContentParams,
) => {
  return await apiClient({
    url: isGuestMode()
      ? `/content/guest/v1/community/${id}/product/list`
      : `/content/v1/community/${id}/product/list`,
    method: 'GET',
    token: isGuestMode() ? undefined : getIdToken(),
    params,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getMyCommunityContent = async (payload) => {
  const res = await apiClient({
    method: 'GET',
    url: '/content/v1/user/community/product/list',
    token: getIdToken(),
    params: {
      ...payload,
    },
  })

  return res.data
}

interface IProductSearchParams {
  page?: number
  limit?: number
  category_id?: number | string | string[]
  exclude_id?: string | string[]
  order_by?: string
  sort_by?: string
  sort?: string
  is_published?: boolean
  min_total_sales?: number
}

export const getProductSearch = async (
  params: IProductSearchParams,
) => {
  return await apiClient({
    url: `/content/guest/v1/product/search`,
    method: 'GET',
    params,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getPurchasedList = async (payload) => {
  const res = await apiClient({
    method: 'GET',
    url: '/order/v2/purchased',
    token: getIdToken(),
    params: {
      ...payload,
    },
  })
  return res.data
}

export interface IContentRecommendationParams {
  source?: string
  page?: number
  limit?: number
}

export const getContentRecommendation = async (
  params: IContentRecommendationParams,
  category_id?: string,
) => {
  let url = !isGuestMode()
    ? `/recommendation/v1/content`
    : `/recommendation/guest/v1/content`

  if (category_id && category_id !== '') {
    url = `${url}?${category_id}`
  }

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params,
  })
}
