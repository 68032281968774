import { action, thunk } from 'easy-peasy'
import get from 'lodash/get'
import IParticipantListModel from 'stores/domains/Session/ParticipantList/interface'

const SessionBookingModel: IParticipantListModel = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  participants: [],

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setSuccess: action((state, payload) => {
    state.isSuccess = payload
  }),
  setError: action((state, payload) => {
    state.isSuccess = false
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setParticipants: action((state, payload) => {
    state.participants = payload
    state.isError = false
    state.errorMessage = ''
  }),
  getParticipantList: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/session/guest/v1/book/${sessionID}/participant`,
          method: 'GET',
        })
        actions.setLoading(false)
        actions.setSuccess(true)
        actions.setParticipants(get(response, 'data.data', []))
      } catch (error) {
        if (get(error, 'response.data.code') === 'FAIL') {
          actions.setError(
            get(error, 'response.data.errors', [
              'unknown error',
            ]).join(','),
          )
        } else {
          actions.setError(get(error, 'response.data.message'))
        }
      }
    },
  ),
}

export default SessionBookingModel
