import { useFormContext, Controller } from 'react-hook-form'
import {
  Breakpoint,
  FormControl,
  FormHelperText,
  SelectProps,
  SxProps,
  styled,
  useTheme,
} from '@mui/material'
import {
  StyledSelect as NestedSelect,
  StyledInputLabel,
} from '../styled'
import Typography from 'components/commons/Typography'

type IProps = {
  name: string
  children: React.ReactNode
  onChangeValue: (value: string) => void
  mobileIn?: Breakpoint
  dark?: boolean
  sxLabel?: SxProps
}

type Props = IProps & SelectProps

export default function RHFSelect({
  name,
  children,
  id,
  label,
  placeholder,
  onChange,
  onChangeValue,
  required,
  dark = false,
  sx,
  sxLabel,
  ...other
}: Props) {
  const { control } = useFormContext()
  const theme = useTheme()

  const renderErrorText = (message: string | undefined) => {
    if (message) {
      return <FormHelperText>{message}</FormHelperText>
    }
  }

  const renderPlaceholder = () => (
    <Typography color={theme.palette.text.tertiery}>
      {placeholder}
    </Typography>
  )

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <StyledInputLabel
          sx={sxLabel}
          shrink
          htmlFor={id}
          required={required}
        >
          {label}
        </StyledInputLabel>
      )}
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <StyledSelect
                {...field}
                id={id}
                error={!!error}
                renderValue={
                  (!field.value ||
                    (other.multiple && !field.value.length)) &&
                  renderPlaceholder
                }
                label={label}
                required={required}
                MenuProps={
                  dark && {
                    PaperProps: {
                      style: {
                        backgroundColor:
                          theme.palette.background.tertiery,
                        color: theme.palette.text.primary,
                        maxHeight: '238px',
                        width: '486px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        marginTop: '12px',
                        borderRadius: '6px',
                      },
                    },
                  }
                }
                sx={{
                  marginTop: !!label && '20px !important',
                  ...sx,
                }}
                value={field.value || ''}
                {...other}
                onChange={(e, child) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onChangeValue(String(e.target.value))
                  field.onChange(e)
                  if (onChange) onChange(e, child)
                }}
              >
                {children}
              </StyledSelect>
              {renderErrorText(error?.message)}
            </>
          )
        }}
      />
    </FormControl>
  )
}

const StyledSelect = styled(NestedSelect)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.background.tertiery,
  svg: {
    color: theme.palette.icon.tertiery,
    fontSize: '16px',
    marginRight: '6px',
  },
  paddingTop: '16px',
}))
