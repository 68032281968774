import React, { memo, useState } from 'react'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/SearchRounded'
import CancelIcon from '@mui/icons-material/CancelRounded'
import isEmpty from 'helpers/is-empty'
import { useBrowseStore } from 'stores/domains/Browse'
import { useTranslation } from 'react-i18next'

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  background: theme.palette.background.primary,
  border: `1px solid ${theme.palette.background.tertiery}`,
  boxSizing: 'border-box',
  borderRadius: '8px',
  width: '100%',
  svg: {
    color: theme.palette.text.tertiery,
  },
  input: {
    background: theme.palette.background.primary,
    color: theme.palette.text.primary,
    fontFamily: 'revert',
  },
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    width: '100%',
    padding: '2px 0 5px',
  },
  '& .MuiInputBase-root:after': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    opacity: 'unset',
    WebkitTextFillColor: 'unset',
  },
}))

interface ISearch {
  value?: string
  onClickSearch?: Function
  autoFocus?: boolean
  disabled?: boolean
  handleEnterPress?: Function
  handleDeleteKey?: Function
  placeholder?: string
}
function Search({
  value,
  onClickSearch,
  autoFocus = false,
  disabled,
  handleEnterPress,
  handleDeleteKey,
  placeholder,
}: ISearch): JSX.Element {
  const { t } = useTranslation()
  const [showDeleteBtn, setShowDeleteBtn] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  const { setKeyword } = useBrowseStore((state) => ({
    setKeyword: state.setKeyword,
  }))

  const handleUserInput = (e) => {
    setInputValue(e.target.value)

    if (e.target.value.length !== 0) {
      setShowDeleteBtn(true)
    } else {
      setShowDeleteBtn(false)
    }
  }
  const onDeleteInput = (disabled) => {
    if (!disabled) {
      setInputValue('')
      setShowDeleteBtn(false)
      handleDeleteKey()
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setInputValue(e.target.value)
      setKeyword(e.target.value)
      handleEnterPress(e.target.value)
    }
  }

  return (
    <SearchContainer>
      <InputBase
        autoComplete="off"
        autoFocus={autoFocus}
        onChange={handleUserInput}
        onClick={() => (onClickSearch ? onClickSearch() : false)}
        onKeyDown={(e) => onKeyDown(e)}
        value={inputValue}
        disabled={disabled}
        startAdornment={
          <IconButton
            type="submit"
            sx={{ p: '10px' }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        }
        placeholder={
          isEmpty(placeholder) ? t('cariDiTiptip') : placeholder
        }
        endAdornment={
          showDeleteBtn ||
          (inputValue !== '' && inputValue !== undefined) ? (
            <IconButton
              onClick={() => onDeleteInput(disabled)}
              type="submit"
              sx={(theme) => ({
                p: '10px',
                color: theme.palette.text.tertiery,
              })}
              aria-label="search"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          ) : null
        }
      />
    </SearchContainer>
  )
}

export default memo(Search)
