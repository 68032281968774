import { Link, Stack, StackProps } from '@mui/material'
import Typography from 'components/commons/CustomTypography'
import appConfig from 'configs/app'

interface BubbleAlertProps extends StackProps {
  onClickSecurity?: Function
}

export default function BubbleAlert({
  onClickSecurity,
  ...rest
}: BubbleAlertProps) {
  return (
    <Stack
      textAlign="center"
      alignItems="center"
      direction="column"
      {...rest}
    >
      <Typography
        as="p"
        size="xs"
        color="secondary"
        sx={{ display: 'contents' }}
      >
        Demi kenyamanan interaksi dengan pengguna lain, harap mematuhi
        panduan dan peraturan komunitas TipTip.
        <Typography size="xs">
          <Link
            sx={(theme) => ({
              color: theme.palette.blue[500],
              textDecoration: 'none',
            })}
            href={`${appConfig.supportCenterUrl}solutions/folders/72000565536`}
            onClick={() => {
              if (onClickSecurity) onClickSecurity()
            }}
            target="_blank"
          >
            Baca Panduan Keamanan.
          </Link>
        </Typography>
      </Typography>
    </Stack>
  )
}
