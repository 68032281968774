import { Box, SxProps } from '@mui/material'

interface Props {
  sx?: SxProps
  children: React.ReactNode
}

export function MobileView({ sx, children, ...otherProps }: Props) {
  return (
    <Box
      sx={{
        display: {
          xs: 'block',
          md: 'none',
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  )
}

export function DesktopView({ sx, children, ...otherProps }: Props) {
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  )
}
