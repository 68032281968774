import ClearIcon from '@mui/icons-material/Clear'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { SxProps, styled, Theme, useTheme } from '@mui/material'
import Box from '../Box'
import Typography from '../Typography'

const StyledMsgBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '0px 14px',
  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}))

const Container = styled(Box)({
  color: 'white',
  padding: 10,
  margin: '16px 0',
  borderRadius: 6,
  width: '100%',
})

const StyledError = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '>div': {
    display: 'flex',
    svg: {
      marginRight: 10,
    },
  },
  'div:last-child': {
    cursor: 'pointer',
    svg: {
      marginRight: 0,
    },
  },
})

interface IMessage {
  message: string
  isError: Boolean
  open: Boolean
  onClose: any
  sxContainer?: SxProps<Theme>
}

function MessageBox({
  message,
  isError,
  open,
  onClose,
  sxContainer,
}: IMessage): JSX.Element {
  const theme = useTheme()
  return (
    <StyledMsgBox>
      {open && (
        <Container
          sx={{
            border: isError
              ? `1px solid ${theme.palette.error.main}`
              : `1px solid ${theme.palette.success.main}`,
            ...sxContainer,
          }}
        >
          <StyledError>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isError ? (
                <ErrorIcon sx={{ color: theme.palette.error.main }} />
              ) : (
                <CheckCircleIcon
                  sx={{ color: theme.palette.success.main }}
                />
              )}
              <Typography variant="smallRegular">
                {message}
              </Typography>
            </div>
            <div onClick={() => onClose(false)}>
              <ClearIcon sx={{ color: theme.palette.primary.main }} />
            </div>
          </StyledError>
        </Container>
      )}
    </StyledMsgBox>
  )
}

export default MessageBox
