import { destroyCookie } from 'helpers/cookie'
import { NextPageContext } from 'next'
import nookies from 'nookies'

const PREFIX_QUEUE_COOKIES = 'TipTip-Queue_'

const generateQueueTokenHeaderValue = (selectedCookie) => {
  const decodedCookies = decodeURIComponent(selectedCookie)
  const params = new URLSearchParams(decodedCookies)
  return `${params.get('wrid')}=${params.get('queue_token')}`
}

const getQueueToken = (ctx?: NextPageContext) => {
  const queueTokenHeader = []
  if (ctx?.req && ctx?.req.headers) {
    const cookies = nookies.get(ctx)
    for (const property in cookies) {
      if (String(property).includes(PREFIX_QUEUE_COOKIES)) {
        const queueHeader = generateQueueTokenHeaderValue(
          cookies[property],
        )
        queueTokenHeader.push(queueHeader)
      }
    }
  } else {
    var allCookies =
      typeof document !== 'undefined' && document.cookie.split(';')
    for (var i = 0; i < allCookies.length; i++) {
      if (allCookies[i].includes(PREFIX_QUEUE_COOKIES)) {
        const queueHeader = generateQueueTokenHeaderValue(
          allCookies[i].split('=')[1],
        )
        queueTokenHeader.push(queueHeader)
      }
    }
  }

  return queueTokenHeader.join(';')
}

const deleteCookiesQueueToken = () => {
  var allCookies =
    typeof document !== 'undefined' && document.cookie.split(';')

  for (var i = 0; i < allCookies.length; i++) {
    if (allCookies[i].includes(PREFIX_QUEUE_COOKIES)) {
      destroyCookie(allCookies[i].split('=')[0].toString())
    }
  }
  destroyCookie('queue_token')
}

export { getQueueToken, deleteCookiesQueueToken }
