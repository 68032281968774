import { useRouter } from 'next/router'

import ShareMediaSocial from 'components/commons/ShareMediaSocial'
import ModalDrawer from 'components/domains/User/ModalDrawer'

import trackEvent from 'src/trackers'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { useTranslation } from 'react-i18next'

function ReferralCodeShareDrawer() {
  const { t } = useTranslation()
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }

  const {
    showShareReferralCodeDrawer,
    selectedShareReferralCode,
    referralLink,
  } = useStoreState((state) => state.referralCode)

  const { setShowShareReferralCodeDrawer } = useStoreActions(
    (actions) => actions.referralCode,
  )

  return (
    <ModalDrawer
      title={t('key_share_refferal_code')}
      isOpen={showShareReferralCodeDrawer}
      onClose={() => {
        trackEvent.user('cancel_share_referral_code', trackerData, {
          modal_name: 'SHARE_REFERRAL_CODE',
          referral_code: selectedShareReferralCode,
        })
        setShowShareReferralCodeDrawer(false)
      }}
      sxDrawerContainer={{ padding: '16px', width: '100%' }}
    >
      {typeof window !== 'undefined' && (
        <ShareMediaSocial
          isCreator
          customQuote={`Yuk, daftar dan bergabung di TipTip pakai kode ${selectedShareReferralCode} atau click link ini:`}
          customLink={referralLink}
          isShareReferral
          referralCode={selectedShareReferralCode}
          trackerData={trackerData}
          onClose={() => setShowShareReferralCodeDrawer(false)}
        />
      )}
    </ModalDrawer>
  )
}

export default ReferralCodeShareDrawer
