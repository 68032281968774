import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9989 23.817C18.5334 23.817 23.8306 18.5198 23.8306 11.9853C23.8306 5.4508 18.5334 0.153564 11.9989 0.153564C5.46441 0.153564 0.167166 5.4508 0.167166 11.9853C0.167166 18.5198 5.46441 23.817 11.9989 23.817Z"
        fill="url(#paint0_linear_2454_15507)"
      />
      <path
        d="M16.1873 8.78364C16.6949 8.78364 17.1064 8.37211 17.1064 7.86447C17.1064 7.35684 16.6949 6.94531 16.1873 6.94531C15.6796 6.94531 15.2681 7.35684 15.2681 7.86447C15.2681 8.37211 15.6796 8.78364 16.1873 8.78364Z"
        fill="white"
      />
      <path
        d="M12.1009 8.12402C9.97188 8.12402 8.23969 9.85615 8.23969 11.9852C8.23969 14.1142 9.97188 15.8463 12.1009 15.8463C14.2299 15.8463 15.962 14.1142 15.962 11.9852C15.962 9.85615 14.2299 8.12402 12.1009 8.12402ZM12.1009 14.4585C10.7371 14.4585 9.62749 13.349 9.62749 11.9852C9.62749 10.6213 10.7371 9.51185 12.1009 9.51185C13.4647 9.51185 14.5741 10.6213 14.5741 11.9852C14.5741 13.349 13.4647 14.4585 12.1009 14.4585Z"
        fill="white"
      />
      <path
        d="M15.1661 19.8245H8.9066C6.30981 19.8245 4.19725 17.712 4.19725 15.1152V8.85535C4.19725 6.25852 6.3098 4.146 8.9066 4.146H15.1661C17.7629 4.146 19.8758 6.25852 19.8758 8.85535V15.1152C19.8758 17.712 17.7629 19.8245 15.1661 19.8245ZM8.9066 5.621C7.12306 5.621 5.67225 7.07181 5.67225 8.85535V15.1152C5.67225 16.8987 7.12306 18.3495 8.9066 18.3495H15.1661C16.9497 18.3495 18.4008 16.8987 18.4008 15.1152V8.85535C18.4008 7.07181 16.9497 5.621 15.1661 5.621H8.9066V5.621Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2454_15507"
          x1="1.90963"
          y1="22.0745"
          x2="27.4792"
          y2="-3.49499"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0011" stopColor="#FBE18A" />
          <stop offset="0.2094" stopColor="#FCBB45" />
          <stop offset="0.3765" stopColor="#F75274" />
          <stop offset="0.5238" stopColor="#D53692" />
          <stop offset="0.7394" stopColor="#8F39CE" />
          <stop offset="1" stopColor="#5B4FE9" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
