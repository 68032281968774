import { StateCreator } from 'zustand'
import {
  INotificationStore,
  IParamsGetListNotification,
} from './interface'
import * as service from 'services/message'
import { sendLog } from 'helpers/log'
import { isGuestMode } from 'helpers/cookie'

export const createNotificationStore: StateCreator<
  INotificationStore
> = (set) => ({
  //states
  informationNotifications: [],
  transactionNotifications: [],
  isLoadingInformationNotification: false,
  isLoadingTransactionNotification: false,
  totalUnreadInformationNotification: 0,
  totalUnreadTransactionNotification: 0,
  isHoveringNotificationHeader: false,
  transactionLoadMore: false,
  informationLoadMore: false,

  //actions
  getListNotification: async ({
    page = 1,
    type = 1,
    limit = 5,
    isReset = false,
    isActive = false,
  }: IParamsGetListNotification) => {
    if (!isActive) return
    set({
      isLoadingInformationNotification: type === 2,
      isLoadingTransactionNotification: type === 1,
    })
    if (((page === 1 && isReset) || page > 1) && !isGuestMode()) {
      try {
        const response = await service.getListNotification({
          page,
          type,
          limit,
        })
        const { code, data = [], _metadata } = response.data || {}
        if (code === 'SUCCESS') {
          set((state) => {
            if (type === 1) {
              return {
                transactionNotifications: isReset
                  ? data
                  : state.transactionNotifications.concat(data),
                totalUnreadTransactionNotification:
                  _metadata.total_unread,
                transactionLoadMore: _metadata.load_more,
              }
            }
            return {
              informationNotifications: isReset
                ? data
                : state.informationNotifications.concat(data),
              totalUnreadInformationNotification:
                _metadata.total_unread,
              informationLoadMore: _metadata.load_more,
            }
          })
          return Promise.resolve(response.data)
        }
      } catch (error) {
        sendLog(error)
      } finally {
        set(() => ({
          isLoadingInformationNotification: false,
          isLoadingTransactionNotification: false,
        }))
      }
    }
  },
  readNotification: async ({ notification_id, bulk, type }) => {
    try {
      const response = await service.readNotification({
        notification_id,
        bulk,
      })
      const { code } = response.data || {}
      if (code === 'SUCCESS') {
        set((state) => {
          const newTransactionNotifications = [
            ...state.transactionNotifications,
          ]
          const newInformationNotifications = [
            ...state.informationNotifications,
          ]
          if (bulk) {
            return {
              totalUnreadInformationNotification: 0,
              totalUnreadTransactionNotification: 0,
              transactionNotifications:
                newTransactionNotifications.map((item) => {
                  return { ...item, read: true }
                }),
              informationNotifications:
                newInformationNotifications.map((item) => {
                  return { ...item, read: true }
                }),
            }
          } else {
            if (type === 1) {
              const index = state.transactionNotifications.findIndex(
                (item) => item.notification_id === notification_id,
              )
              if (index !== 1)
                newTransactionNotifications[index].read = true
              return {
                totalUnreadTransactionNotification:
                  state.totalUnreadTransactionNotification - 1,
                transactionNotifications: newTransactionNotifications,
              }
            }
            const index = state.informationNotifications.findIndex(
              (item) => item.notification_id === notification_id,
            )
            if (index !== 1)
              newInformationNotifications[index].read = true
            return {
              totalUnreadInformationNotification:
                state.totalUnreadInformationNotification - 1,
              informationNotifications: newInformationNotifications,
            }
          }
        })
        return Promise.resolve('SUCCESS')
      }
    } catch (error) {
      sendLog(error)
      return Promise.reject('ERROR')
    }
  },
  setIsHoveringNotificationHeader: (
    isHoveringNotificationHeader: boolean,
  ) => {
    set({
      isHoveringNotificationHeader,
    })
  },
})
