import { NextPageContext } from 'next'
import Router from 'next/router'

interface RedirectOptions {
  referrer?: string
  ctx?: NextPageContext
  isReplace?: boolean
}

/**
 * Handle redirect on both server-side and client-side
 *
 * @param res response object from NextPageContext
 * @param target
 * @param referrer
 */
const redirect = (
  target: string,
  options: RedirectOptions = {},
): void => {
  const { referrer, ctx, isReplace = false } = options
  const urlDest = referrer ? `${target}?ref=${referrer}` : target
  if (ctx?.res) {
    ctx.res.writeHead(302, {
      Location: urlDest,
    })
    ctx.res.end()
  } else if (isReplace) {
    Router.replace(urlDest)
  } else {
    Router.push(urlDest)
  }
}

export default redirect
