import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import { contentConst } from 'constants/common'
import IContentDeliveryModel from 'stores/domains/Content/models/ContentDelivery/interface'
import redirect from 'helpers/redirect'
import { isGuestMode } from 'helpers/cookie'

const ContentDetailModel: IContentDeliveryModel = {
  isError: false,
  isLoading: false,
  errorResponse: undefined,
  errorMessage: '',
  contentType: null,
  contentVideo: null,
  contentAsset: null,
  contentDocument: null,

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setErrorResponse: action((state, payload) => {
    state.errorResponse = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorResponse = undefined
    state.errorMessage = ''
  }),

  setContentType: action((state, payload) => {
    state.contentType = payload
  }),
  setContentVideo: action((state, payload) => {
    state.contentVideo = payload
  }),
  setContentAsset: action((state, payload) => {
    state.contentAsset = payload
  }),
  setContentDocument: action((state, payload) => {
    state.isError = false
    state.contentDocument = payload
  }),

  getContent: thunk(async (actions, payload, { injections }) => {
    if (isGuestMode()) {
      redirect(`/content/${payload}`)
    } else {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/content/v1/product/${payload}/view`,
          token: getCookie(authConfig.idTokenName),
          method: 'GET',
        })

        actions.setContentType(response.data.data.type)
        if (response.data.data.type.id === contentConst.typeId.video)
          actions.setContentVideo(response.data.data)
        if (response.data.data.type.id === contentConst.typeId.asset)
          actions.setContentAsset(response.data.data)
        if (
          response.data.data.type.id === contentConst.typeId.document
        )
          actions.setContentDocument(response.data.data)
      } catch (error) {
        actions.setError(error.message)
        if (error.response) {
          if (error.response.data.code === 'NOT_PURCHASE_YET')
            redirect(`/content/${payload}`)
          actions.setErrorResponse(error.response)
        }
      } finally {
        actions.setLoading(false)
      }
    }
  }),
}

export default ContentDetailModel
