import { StateCreator } from 'zustand'
import { ICategoryDefault, ICategoryStore } from './interface'

const INITIAL_DATA: ICategoryDefault = {
  categories: [],
  selectedCategories: [],
}

const resetters: (() => void)[] = []

export const createCategoryStore: StateCreator<ICategoryStore> = (
  set,
) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setCategory(data) {
      set({
        categories: data,
      })
    },
    setSelectedCategories: (payload) => {
      set({ selectedCategories: payload })
    },
  }
}
