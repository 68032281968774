import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import HubRoundedIcon from '@mui/icons-material/HubRounded'
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import LanguageIcon from '@mui/icons-material/Language'
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'

import TiptipSmallIcon from 'assets/logos/mini-logo-gray.svg'
import appConfig from 'configs/app'
import { MenuItemProps } from './Menu'
import { IProfileData } from 'stores/domains/User/UserProfile'

export const menuGeneral = (): MenuItemProps[] => {
  return [
    {
      title: 'transactionHistoryScreenAppBarTitle',
      icon: TrendingUpIcon,
      eventName: 'click_transaction_history',
      url: appConfig.transactionHistory,
      id: 'b-users-transaction-history',
    },
  ]
}

export const menuCreatorActivities = (
  profileData?: IProfileData,
  pathname?: string,
): MenuItemProps[] => {
  return [
    {
      title: 'upgradeToBusiness',
      icon: WorkRoundedIcon,
      eventName: '',
      url: `${appConfig.businessRegistrationUrl}?source=${pathname}`,
      id: 'b-users-upgrade-to-business',
      isNewFeature: true,
    },
    {
      title: 'myCommunity',
      icon: GroupsRoundedIcon,
      eventName: 'click_my_community',
      url: `${appConfig.myCommunityUrl}?source=${pathname}&source_action=click_my_community`,
      id: 'b-users-my-community',
      additionalProperties: {
        user_type: profileData?.isCreator ? 'creator' : 'supporter',
      },
    },
    {
      title: 'mission',
      icon: ChecklistRtlIcon,
      eventName: 'click_community_mission',
      url: `${appConfig.communityMissionUrl}?source=${pathname}&source_action=click_mission`,
      id: 'b-users-community-mission',
      isNewFeature: true,
    },
    {
      title: 'contentHub',
      icon: HubRoundedIcon,
      eventName: 'click_content_hub',
      url: appConfig.contentHubMainUrl,
      id: 'b-users-content-hub',
    },
    {
      title: 'dashboard',
      icon: BarChartRoundedIcon,
      eventName: 'click_creator_dashboard',
      url: appConfig.creatorDashboardUrl,
      id: 'b-users-creator-dashboard',
    },
  ]
}

export const menuPersonalActivities = (
  profileData?: IProfileData,
  pathname?: string,
): MenuItemProps[] => [
  {
    title: 'becomeCreatorNow',
    icon: TipsAndUpdatesRoundedIcon,
    eventName: 'click_creator_registration',
    url: appConfig.creatorRegistrationUrl,
    id: 'b-users-become-creator',
  },
  {
    title: 'upgradeToBusiness',
    icon: WorkRoundedIcon,
    eventName: '',
    url: `${appConfig.businessRegistrationUrl}?source=${pathname}`,
    id: 'b-users-upgrade-to-business',
    isNewFeature: true,
  },
  {
    title: 'myCommunity',
    icon: GroupsRoundedIcon,
    eventName: 'click_my_community',
    url: `${appConfig.myCommunityUrl}?source=${pathname}&source_action=click_my_community`,
    id: 'b-users-my-community',
    additionalProperties: {
      user_type: profileData?.isCreator ? 'creator' : 'supporter',
    },
  },
  {
    title: 'mission',
    icon: ChecklistRtlIcon,
    eventName: 'click_community_mission',
    url: `${appConfig.communityMissionUrl}?source=${pathname}&source_action=click_mission`,
    id: 'b-users-community-mission',
    isNewFeature: true,
  },
  {
    title: 'contentHub',
    icon: HubRoundedIcon,
    eventName: 'click_content_hub',
    url: appConfig.contentHubMainUrl,
    id: 'b-users-content-hub',
  },
  {
    title: 'dashboard',
    icon: PaidRoundedIcon,
    eventName: 'click_refund_balance',
    url: appConfig.supporterRefundUrl,
    id: 'b-users-refund-balance',
  },
]

export const menuBusinessActivities = (
  profileData?: IProfileData,
  pathname?: string,
): MenuItemProps[] => [
  {
    title: 'myCommunity',
    icon: GroupsRoundedIcon,
    eventName: 'click_my_community',
    url: `${appConfig.myCommunityUrl}?source=${pathname}&source_action=click_my_community`,
    id: 'b-users-my-community',
    additionalProperties: {
      user_type: profileData?.isCreator ? 'creator' : 'supporter',
    },
  },
  {
    title: 'mission',
    icon: ChecklistRtlIcon,
    eventName: 'click_community_mission',
    url: `${appConfig.communityMissionUrl}?source=${pathname}&source_action=click_mission`,
    id: 'b-users-community-mission',
    isNewFeature: true,
  },
  {
    title: 'contentHub',
    icon: HubRoundedIcon,
    eventName: 'click_content_hub',
    url: appConfig.contentHubMainUrl,
    id: 'b-users-content-hub',
  },
  {
    title: 'dashboard',
    icon: BarChartRoundedIcon,
    eventName: 'click_creator_dashboard',
    url: appConfig.creatorDashboardUrl,
    id: 'b-users-creator-dashboard',
  },
]

export const menuHelpCenter: MenuItemProps[] = [
  {
    title: 'accountSecurity',
    icon: SettingsRoundedIcon,
    eventName: 'click_account_settings',
    url: appConfig.accountSettingUrl,
    id: 'b-users-account-setting',
  },
  {
    title: 'key_language',
    icon: LanguageIcon,
    eventName: 'click_language',
    url: '',
    id: 'b-users-language',
    isBottomDrawer: true,
    isMobileOnly: true,
    isRightSection: true,
  },
  {
    title: 'supportCenter',
    icon: HelpRoundedIcon,
    eventName: 'click_help_center',
    url: `${appConfig.supportCenterUrl}home`,
    id: 'b-users-help-center',
    isNewTab: true,
  },
  {
    title: 'aboutTiptip',
    icon: TiptipSmallIcon,
    eventName: 'click_about_us',
    url: appConfig.aboutTiptipUrl,
    id: 'b-users-about-tiptip',
    isNewTab: true,
  },
]
