import { action } from 'easy-peasy'
import ILocalTrackModel from 'stores/commons/models/LocalTrack/interface'

const LocalTrack: ILocalTrackModel = {
  enableCamera: false,
  muteCamera: true,
  cameraOn: false,
  enableAudio: false,
  loadingCamera: false,
  noPermissionCamera: false,
  noPermissionMicrophone: false,
  setNoPermissionCamera: action((state, payload) => {
    state.noPermissionCamera = payload
  }),
  setNoPermissionMicrophone: action((state, payload) => {
    state.noPermissionMicrophone = payload
  }),
  setLoadingCamera: action((state, payload) => {
    state.loadingCamera = payload
  }),
  setCameraOn: action((state, payload) => {
    state.cameraOn = payload
  }),
  setEnableCamera: action((state, payload) => {
    state.enableCamera = payload
  }),
  setMuteCamera: action((state, payload) => {
    state.muteCamera = payload
  }),
  setEnabelAudio: action((state, payload) => {
    state.enableAudio = payload
  }),
}

export default LocalTrack
