import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISufficientBalanceModel from 'stores/domains/Session/SufficientBalance/interface'

const SufficientBalanceModel: ISufficientBalanceModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  balance: undefined,

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setBalanceData: action((state, payload) => {
    state.balance = payload
  }),
  getSufficientCoin: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/session/v1/book/${sessionID}/sufficient-balance`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setLoading(false)
        actions.setBalanceData(get(response, 'data.data', {}))
      } catch (error) {
        if (get(error, 'response.data.code') === 'FAIL') {
          actions.setError(
            get(error, 'response.data.errors', [
              'unknown error',
            ]).join(','),
          )
        } else {
          actions.setError(get(error, 'response.data.message'))
        }
      }
    },
  ),
}

export default SufficientBalanceModel
