import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import IReferralCodeModel from './interface'
import authConfig from 'configs/auth'

const ReferralCodeModel: IReferralCodeModel = {
  isErrorGenerateLink: false,
  showReferralCodeDrawer: false,
  showReferralCodeListDrawer: false,
  showShareReferralCodeDrawer: false,
  selectedShareReferralCode: '',
  referralLink: '',
  setShowReferralCodeDrawer: action((state, payload) => {
    state.showReferralCodeDrawer = payload
  }),
  setShowReferralCodeListDrawer: action((state, payload) => {
    state.showReferralCodeListDrawer = payload
  }),
  setShowShareReferralCodeDrawer: action((state, payload) => {
    state.showShareReferralCodeDrawer = payload
  }),
  setSelectedShareReferralCode: action((state, payload) => {
    state.selectedShareReferralCode = payload
  }),
  setReferralLink: action((state, payload) => {
    state.referralLink = payload
  }),
  setErrorGenerateLink: action((state, payload) => {
    state.isErrorGenerateLink = payload
  }),
  generateReferralLink: thunk(
    async (actions, referral_code, { injections }) => {
      try {
        actions.setSelectedShareReferralCode(referral_code)
        const { apiClient } = injections
        const response = await apiClient({
          url: `user/v1/referral-code/${referral_code}/link`,
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
          data: {
            branch_key: 'key_test_fk4SRQ3DvH8YN8xpzKx2amggDylMnukO',
            feature: 'share-referral-code',
            data: {
              $canonical_identifier: `ref/${referral_code}`,
              $og_title: 'TipTip: Home For Creators',
              $og_description:
                'Klik di sini untuk menikmati berbagai live sesi dan konten digital!',
              $canonical_url: `https://tipnet.studio/ref/${referral_code}`,
              $desktop_url: `https://tipnet.studio/ref/${referral_code}`,
            },
            alias: `ref/${referral_code}`,
          },
        })
        if (response?.data?.data?.url) {
          actions.setReferralLink(response.data.data.url)
        }
        return Promise.resolve(response)
      } catch (error) {
        if (error?.response?.status === 500) {
          actions.setErrorGenerateLink(true)
        }
        return Promise.reject(error)
      }
    },
  ),
}

export default ReferralCodeModel
