const CONTENT_HUB_URL = process.env.NEXT_PUBLIC_CONTENT_HUB_URL

const config = {
  assetBaseUrl: process.env.NEXT_PUBLIC_ASSET_BASE_URL,
  waCS: process.env.NEXT_PUBLIC_WA_CS,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  threshold_participant_booking:
    process.env.NEXT_PUBLIC_THRESHOLD_PARTICIPANT_BOOKING || 5,
  supportCenterUrl: process.env.NEXT_PUBLIC_SUPPORT_CENTER_URL,
  creatorLandingUrl: process.env.NEXT_PUBLIC_CREATOR_LANDING_URL,
  termsConditionUrl: process.env.NEXT_PUBLIC_TERMS_CONDITION_URL,
  privacyPolicyUrl: process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL,
  communityGuidelineUrl:
    process.env.NEXT_PUBLIC_COMMUNITY_GUIDELINE_URL,
  coinGuidelineUrl: process.env.NEXT_PUBLIC_COIN_GUIDELINE_URL,
  disbursementIncomeCreatorUrl:
    process.env.NEXT_PUBLIC_DISBURSEMENT_INCOME_CREATOR_URL,
  timeoutTippingTooltip:
    process.env.NEXT_PUBLIC_TIMEOUT_MINUTE_TIPPING_TOOLTIP || 5,
  publicVersion: process.env.NEXT_PUBLIC_APP_VERSION || '',
  tiptipBrowserSupport: [
    'chrome',
    'firefox',
    'safari',
    'mobile safari',
    'mobile chrome',
    'mobile firefox',
  ],

  // content hub urls
  contentHubUrl: CONTENT_HUB_URL,
  contentHubMainUrl: `${CONTENT_HUB_URL}app/`,
  promoterUrl: `${CONTENT_HUB_URL}promoter-program/about/`,
  promoteContentUrl: `${CONTENT_HUB_URL}promoter-program/promote-content`,
  balanceUrl: `${CONTENT_HUB_URL}balance/`,
  balanceVerificationUrl: `${CONTENT_HUB_URL}balance/verification/`,
  kycVerificationURL: `${CONTENT_HUB_URL}/verification/`,
  createContentUrl: `${CONTENT_HUB_URL}create-content/`,
  createEventUrl: `${CONTENT_HUB_URL}../event/create/`,
  createSessionUrl: '/creator/session/create/type',
  createSubscriptionUrl: `${CONTENT_HUB_URL}subscription/create/`,
  manageCommunityUrl: `${CONTENT_HUB_URL}my-community/community-setting/`,
  pendingMembersUrl: `${CONTENT_HUB_URL}my-community/community-setting/pending-members/`,
  // end content hub urls
  // redirection url
  creatorDashboardUrl: '/creator/dashboard',
  creatorRegistrationUrl: '/creator/create',
  businessRegistrationUrl: '/business/create',
  accountSettingUrl: '/setting/account',
  supporterRefundUrl: '/refund',
  myCommunityUrl: '/community',
  transactionHistory: '/transaction-history',
  communityRedirectionUrl: '/browse?status=community',
  withdrawRedirectionUrl: '/menu',
  sessionRedirectionUrl: '/menu',
  communityRegistrationUrl: '/community/registration',
  postRegistrationUrl: '/user/post-registration',
  communityMissionUrl: '/community/mission',
  communityProfileAbout: '/community/profile/about',
  communityProfileAboutAI: '/community/profile/about/ai',
  // end of redirection url
  withdrawalMin: parseInt(process.env.NEXT_PUBLIC_WITHDRAWAL_MIN, 10),
  aboutTiptipUrl: process.env.NEXT_PUBLIC_URL_ABOUT_TIPTIP,
  isServerSide: process.env.NEXT_IS_SERVER === 'true',
  staticBaseUrl: process.env.NEXT_PUBLIC_ASSET_PREFIX,
  waitingRoomUrl: process.env.NEXT_PUBLIC_WAITING_ROOM_URL,
  defaultLang: process.env.NEXT_PUBLIC_DEFAULT_LANG,
}

export default config
