import { memo, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'

import redirect from 'helpers/redirect'
import { INotificationProps } from 'constants/notification'

import Attention from 'components/commons/Attention/v2'
import NotificationItem from 'components/commons/Notification/NotificationItem'
import { ButtonAttention } from 'components/commons/Attention/v2/styled'
import { useNotificationStore } from 'stores/commons'
import ActionButtonNotification from '../ActionButtonNotification'
import { Wrapper } from '../styled'

import { LoadingState } from 'components/domains/User/Message'
import { WrapperButtonLoad } from 'containers/domains/Browse/TabPanel/styled'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import { useTranslation } from 'react-i18next'

const TransactionNotification = ({
  positionActionButton,
  active = false,
  onClickEmptyNotification = () => {},
  isHeader: isRenderOnHeader,
}: INotificationProps) => {
  const { isReady } = useRouter()
  const { ref, inView } = useInView({ threshold: 0 })
  const { t } = useTranslation()

  const {
    getListNotification,
    transactionNotifications,
    readNotification,
    isLoading,
    transactionLoadMore,
  } = useNotificationStore((state) => ({
    transactionNotifications: state.transactionNotifications,
    getListNotification: state.getListNotification,
    readNotification: state.readNotification,
    isLoading: state.isLoadingTransactionNotification,
    transactionLoadMore: state.transactionLoadMore,
  }))

  const handleReadNotification = async ({ id, read, url }) => {
    if (!read) {
      readNotification({
        bulk: false,
        notification_id: id,
        type: 1,
      }).then(() => {
        clearNotificationMutation.mutate(null, {
          onSuccess: () => redirect(url),
        })
      })
    } else {
      redirect(url)
    }
  }

  const clearNotificationMutation = useMutation({
    mutationFn: async () => {
      return await getListNotification({
        page: 1,
        isReset: true,
        limit: 5,
        type: 2,
      })
    },
  })

  const { fetchNextPage, refetch, isFetching } = useInfiniteQuery(
    ['transactionNotifications'],
    ({ pageParam = 1 }) => {
      return getListNotification({
        page: pageParam,
        isReset: pageParam === 1,
        limit: 5,
        type: 1,
        isActive: active,
      })
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?._metadata.page + 1
      },
      enabled: false,
    },
  )

  const notifications = useMemo(
    () =>
      isRenderOnHeader
        ? transactionNotifications.slice(0, 5)
        : transactionNotifications,
    [transactionNotifications],
  )

  useEffect(() => {
    if (inView && transactionLoadMore && !isLoading && !isFetching) {
      fetchNextPage()
    }
  }, [inView, isFetching])

  useEffect(() => {
    if (isReady && active) {
      if (isRenderOnHeader && transactionNotifications.length >= 5)
        return

      refetch({ queryKey: ['transactionNotifications'] })
    }
  }, [active, isReady])

  if (isLoading && notifications.length === 0) {
    return (
      <Wrapper py={8}>
        <LoadingState />
      </Wrapper>
    )
  }

  if (!isLoading && notifications.length === 0) {
    return (
      <Wrapper>
        <Attention
          image={<StateImage type="empty-data" />}
          title={t('key_no_trx')}
          subtitle={t(
            'key_notifications_related_to_transaction_will_appear_here',
          )}
          actions={[
            <ButtonAttention
              id="b-notification-transaction-explore"
              key="b-notification-transaction-explore"
              onClick={onClickEmptyNotification}
              size="large"
            >
              {t('notificationEmptyStateButton')}
            </ButtonAttention>,
          ]}
          sxSubtitle={{
            width: '240px',
          }}
          sx={{
            paddingTop: '32px',
          }}
        />
      </Wrapper>
    )
  }

  return (
    <Stack>
      {positionActionButton === 'top' && (
        <ActionButtonNotification
          positionActionButton={positionActionButton}
          type="transaction"
        />
      )}
      <Box>
        {notifications.map((item) => (
          <NotificationItem
            key={item.notification_id}
            onClick={() =>
              handleReadNotification({
                id: item.notification_id,
                read: item.read,
                url: item.redirect_link,
              })
            }
            item={item}
          />
        ))}
        {!isRenderOnHeader &&
          isLoading &&
          notifications.length >= 1 && (
            <Wrapper py={2}>
              <LoadingState />
            </Wrapper>
          )}
        {!isRenderOnHeader && !isLoading && (
          <WrapperButtonLoad ref={ref} />
        )}
      </Box>
      {positionActionButton === 'bottom' && (
        <ActionButtonNotification
          positionActionButton={positionActionButton}
          type="transaction"
        />
      )}
    </Stack>
  )
}

export default memo(TransactionNotification)
