import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'

interface Payload {
  newer_than?: string
  limit?: number
}

interface RoomPayload extends Payload {
  last_message_id?: string
}

const DEFAULT_LIMIT = 255

export const getChats = async (payload?: Payload) => {
  return await apiClient({
    url: '/user/v1/chats',
    method: 'GET',
    params: { limit: DEFAULT_LIMIT, ...payload },
    token: getIdToken(),
  })
}

export const getMessagesInRoom = async (
  userId: string,
  payload?: RoomPayload,
) => {
  return await apiClient({
    url: `/user/v1/chats/${userId}/messages`,
    method: 'GET',
    params: { limit: DEFAULT_LIMIT, ...payload },
    token: getIdToken(),
  })
}

export const sendMessage = async (
  userId: string,
  message: string,
) => {
  return await apiClient({
    url: `/user/v1/chats/${userId}/send`,
    method: 'POST',
    data: { message },
    token: getIdToken(),
  })
}

export const markMessageAsRead = async (ids: string[]) => {
  return await apiClient({
    url: `/user/v1/chats/messages/mark_read`,
    method: 'POST',
    data: { ids },
    token: getIdToken(),
  })
}

export const getCreatorProfile = async (username: string) => {
  const idToken = getIdToken()
  const prefixEndpoint = !idToken ? '/user/guest' : '/user'
  return await apiClient({
    url: `${prefixEndpoint}/v1/creator/${username}/profile`,
    method: 'GET',
    token: idToken,
  })
}

export const getGuestUserProfile = async (username: string) => {
  return await apiClient({
    url: `/user/guest/v1/${username}/profile`,
    method: 'GET',
    withoutHeaders: true,
  })
}

export const isBlocked = async (userId: string) => {
  return await apiClient({
    url: `/user/v1/chats/users/${userId}/blocked`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const blockPeerUser = async (
  userId: string,
  unblock?: boolean,
) => {
  return await apiClient({
    url: `/user/v1/chats/users/${userId}/${
      unblock ? 'unblock' : 'block'
    }`,
    method: 'POST',
    token: getIdToken(),
  })
}
