import * as React from 'react'
import { Auth, Hub } from 'aws-amplify'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

import appConfig from 'configs/app'
import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import {
  destroySessionStorage,
  getSessionStorage,
  setSessionStorage,
} from 'helpers/session-storage'
import {
  destroyLocalStorage,
  getLocalStorage,
} from 'helpers/local-storage'
import { createLastAuthUser } from 'helpers/cognito/token'
import { redirectToReferrer } from 'helpers/redirector'
import { addBreadcrumb, sendLog, setLogUser } from 'helpers/log'
import afterLogin from 'helpers/auth/after-login'
import afterLogout from 'helpers/auth/after-logout'
import { useRouter } from 'next/router'
import { getUsername, postRegistration } from 'services/user'
import { setCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import { addDays } from 'helpers/date-time'
import {
  setPeopleProperties,
  setUserRegister,
} from 'helpers/analytics'
import trackEvent from 'src/trackers'
import * as moe from 'helpers/moengage'
import { setFcmToken } from 'helpers/firebase'
import { getIdToken } from 'helpers/auth'
import { getErrorMessage } from 'helpers/error'
import { useProfileUserStore } from 'stores/domains/User'
import { useProfileUser } from 'hooks/domains/User'

interface AuthContextType {
  errorCode: string
  isLoading: boolean
}

const AuthContext = React.createContext<AuthContextType | null>(null)

interface AuthProps {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProps) => {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const idToken = getIdToken()
  const isNewUser = getSessionStorage('isNewUser')
  const isSso = getLocalStorage('authMethod') === 'google'

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorCode, setErrorCode] = React.useState<string>()
  const [dataUser, setDataUser] = React.useState(null)

  const refetchUsername = async () => {
    try {
      const response = await getUsername(idToken)
      setDataUser(response.data)
      return response?.data
    } catch (error) {
      Promise.reject(error)
    }
  }

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))

  const { refetch: refetchProfileData } = useProfileUser()

  const handleLogout = async () => {
    try {
      await Auth.signOut()
      afterLogout(() => {
        window.location.reload()
      })
    } catch (error) {
      sendLog(error?.message || error)
    }
  }

  const redirectToAboutPromoter = () => {
    destroySessionStorage('source')
    redirect(appConfig.promoterUrl)
  }

  const fetchRefreshToken = async (data?: any) => {
    const source = getSessionStorage('source')

    setIsLoading(true)
    if (!isEmpty(data) && !isEmpty(data?.signInUserSession)) {
      const session = data.signInUserSession
      const token = session.getIdToken().getJwtToken()
      const accessToken = session.getAccessToken().getJwtToken()
      const refreshToken = session.getRefreshToken().getToken()
      createLastAuthUser(data.getUsername())
      afterLogin({ token, accessToken, refreshToken }, () => {
        setIsLoading(false)
        destroyLocalStorage('userEmail')
        destroySessionStorage('userPassword')
        if (source === 'ABOUT_PROMOTER') {
          redirectToAboutPromoter()
          return
        }
      })
      return
    }

    const currentUser = await Auth.currentAuthenticatedUser()
    const currentSession = currentUser.signInUserSession
    const refreshToken = currentSession.getRefreshToken()
    currentUser.refreshSession(
      refreshToken,
      (err: Error, session: CognitoUserSession) => {
        if (!isEmpty(err)) {
          sendLog(`refresh token err: ${err?.message}`)
          return
        }
        const idToken = session.getIdToken()
        if (idToken && session.isValid()) {
          const token = idToken.getJwtToken()
          const accessToken = session.getAccessToken().getJwtToken()

          createLastAuthUser(currentUser.username)
          afterLogin(
            {
              token,
              accessToken,
              refreshToken: refreshToken.getToken(),
            },
            () => {
              setIsLoading(false)
              destroyLocalStorage('userEmail')
              destroySessionStorage('userPassword')
            },
          )
          return
        } else {
          setIsLoading(false)
          // force logout if error
          handleLogout()
          return
        }
      },
    )
  }

  const handleSkipPostProfileRegistration = async () => {
    try {
      const response = await postRegistration({
        username: dataUser.username,
      })
      if (response?.data?.code === 'SUCCESS') {
        setCookie(
          'user_id',
          response.data.data?.user_id,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setCookie(
          'username',
          response.data.data?.username,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setCookie(
          'email',
          response.data.data?.mail,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setUserRegister(response.data.data?.user_id, {
          sign_up_method: 'unknown',
          user_id: response.data.data?.user_id,
          $email: response.data.data?.email,
          $name: response.data.data?.username,
          birth_date: null,
          user_type: 'supporter',
        })
        setLogUser(
          response.data.data?.user_id,
          response.data.data?.usename,
          response.data.data?.email,
        )

        // Condition to detecting user submit from skip button
        if (
          response.data.data?.month === '' &&
          response.data.data?.year === '' &&
          response.data.data?.day === ''
        ) {
          trackEvent.user(
            'skip_post_registration_process',
            trackerData,
            {
              user_id: response.data.data?.user_id,
              $name: response.data.data?.username,
              $email: response.data.data?.email,
              phone_number: null,
              user_type: 'supporter',
              gender: null,
              birth_date: null,
              coin_balance: 0,
              income_balance: 0,
            },
          )
        }

        trackEvent.user('sign_up_completed', trackerData, {
          refer_by_cl: false,
          referrer_username: null,
          referrer_code: null,
          use_referral_code_link: false,
        })
        setPeopleProperties({
          refer_by_cl: false,
          referrer_username: null,
          referrer_code: null,
          use_referral_code_link: false,
        })
        moe.trackEvent('sign_up_completed')
        moe.trackUser(response.data.data)
        setFcmToken('SIGN_IN').then(() => {
          destroySessionStorage('isNewUser')
          redirectToReferrer()
        })
      }
    } catch (error) {
      destroySessionStorage('isNewUser')
      sendLog(getErrorMessage(error))
    }
  }

  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      addBreadcrumb('auth', 'auth listen', 'info', { event, data })
      switch (event) {
        case 'signIn':
          fetchRefreshToken(data)
          break
        case 'signIn_failure':
          addBreadcrumb(
            'auth',
            'auth listen signIn_failure',
            'error',
            {
              event,
              data,
            },
          )
          sendLog(data)
          break
        case 'cognitoHostedUI_failure':
          addBreadcrumb(
            'auth',
            'auth listen cognitoHostedUI_failure',
            'error',
            { event, data },
          )
          sendLog(data)
          setErrorCode(data.code)
          break
        case 'oAuthSignOut':
          addBreadcrumb('auth', 'auth listen oAuthSignOut', 'info', {
            event,
            data,
          })
          sendLog('auth listen oAuthSignOut')
          handleLogout()
          break
        case 'customOAuthState':
          setSessionStorage('oAuthRoute', String(data))
          const target = String(data)?.replace('?', '&')
          redirect(`/user/post-registration?ref=${target}`, {
            isReplace: true,
          })
          break
      }
    })
  }, [])

  React.useEffect(() => {
    if (!profileData.user_id) {
      refetchProfileData()
    }
  }, [])

  React.useEffect(() => {
    if (!isEmpty(idToken) && isNewUser && isSso) refetchUsername()
  }, [idToken])

  React.useEffect(() => {
    if (isNewUser && !isEmpty(dataUser)) {
      handleSkipPostProfileRegistration()
    }
  }, [dataUser])

  return (
    <AuthContext.Provider
      value={{
        errorCode,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
