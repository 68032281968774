import { styled } from '@mui/material'
import CommonTypography from '../Typography'

const ChipNewFeature = styled(CommonTypography)(({ theme }) => ({
  ...theme.typography.smallBold,
  lineHeight: '18px',
  background: theme.palette.tiptip[500],
  borderRadius: '4px',
  color: 'white',
  padding: '3px 6px',
}))

export default ChipNewFeature
