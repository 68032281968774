import { styled } from '@mui/material'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

export const AdditionalFeeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.normalRegular,
}))

export const AdditionalFeeContainer = styled(Stack)({
  width: '100%',
  marginBottom: '8px',
})
export const AdditionalFeeValueContainer = styled(Stack)({
  width: '50%',
})
export const AdditionalFeeLabelContainer = styled(Stack)({
  width: '50%',
  svg: {
    width: '18px',
    height: '18px',
  },
})
