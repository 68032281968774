import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import LoadingButton from '../LoadingButton'
import { styled } from '@mui/material/styles'
import { Form } from './CommonForm'
import Box from '../Box'

export const StyledForm = styled(Form)(({ theme }) => ({
  maxWidth: '800px',
  '& .MuiFormControl-root': {
    marginBottom: 10,
  },
  '& .MuiInputBase-root:before': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-root:after': {
    borderBottom: 'none',
  },
  '& .MuiSelect-select': {
    padding: 0,
  },
  '& .MuiTextField-root': {
    marginBottom: 0,
  },
  '& .MuiTypography-root': {
    ...theme.typography.normalRegular,
    lineHeight: '21px',
  },
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-root': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiery,
    border: `1px solid ${theme.palette.border.minimal}`,
    borderRadius: 6,
  },
  '& .MuiInputAdornment-root': {
    width: 44,
    justifyContent: 'center',
    marginRight: 0,
  },
  input: {
    fontSize: 14,
    padding: '12px',
    backgroundColor: theme.palette.background.tertiery,
    border: `1px solid ${theme.palette.border.minimal}`,
    borderRadius: 6,

    '&[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.error.main,
      borderWidth: 1,
    },
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: `${theme.palette.text.primary} !important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.normalBold,
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.text.secondary,
  },
  transform: 'none',
}))

export const StyledError = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: 12,
    color: theme.palette.error.main,
  },
  display: 'inline-flex',
  color: theme.palette.error.main,
  fontSize: '12px !important',
  margin: '5px 0px',
  alignItems: 'center',
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  width: '100%',
  padding: '12px 14px',
  borderRadius: 6,
  position: 'relative',
  backgroundColor: theme.palette.background.secondary,
  svg: {
    color: theme.palette.text.tertiery,
    width: '16px',
    height: '16px',
  },
}))

export const StyledSubmitButton = styled(LoadingButton)(
  ({ theme }) => ({
    width: '100% !important',
    borderRadius: '8px',
    background: theme.colors.gradient1,
    display: 'block',
    ...theme.typography.normalBold,
    lineHeight: '21px',
    color: theme.palette.text.primary,
    padding: '12px 16px',
  }),
)

export const StyledMenuItem = styled(MenuItem)(
  ({ theme, selected }) => ({
    ...theme.typography.normalRegular,
    lineHeight: '21px',
    backgroundColor: selected
      ? `${theme.palette.background.secondary} !important`
      : 'transparent',
    padding: '12px 16px',
    ':focus': {
      backgroundColor: 'transparent',
    },
  }),
)
