import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import isEmpty from 'helpers/is-empty'
import IShareScreenModel from 'stores/domains/Creator/ShareScreen/interface'

const ShareScreenModel: IShareScreenModel = {
  isLoading: false,
  clientRTCShareScreen: null,
  shareScreenLocalTrack: undefined,
  shareScreenAudioLocalTrack: undefined,
  isShowModalPermissionAccess: false,
  creatorShareScreen: {
    is_share_screen: false,
    share_screen_info: undefined,
  },
  setShowModalPermissionAccess: action((state, payload) => {
    state.isShowModalPermissionAccess = payload
  }),
  setClientRTCShareScreen: action((state, payload) => {
    state.clientRTCShareScreen = payload
  }),
  setShareScreenLocalTrack: action((state, payload) => {
    state.shareScreenLocalTrack = payload
  }),
  setShareScreenAudioLocalTrack: action((state, payload) => {
    state.shareScreenAudioLocalTrack = payload
  }),
  setIsShareScreen: action((state, payload) => {
    state.creatorShareScreen = {
      is_share_screen: payload,
      share_screen_info: state.creatorShareScreen.share_screen_info,
    }
  }),
  setCreatorShareScreen: action((state, payload) => {
    state.creatorShareScreen = {
      is_share_screen: !isEmpty(payload),
      share_screen_info: payload,
    }
  }),
  onUpdateSessionLayout: thunk(
    async (_actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        await apiClient({
          url: `/session/v1/live/${payload.session_id}/update`,
          method: 'PUT',
          token: getCookie(authConfig.idTokenName),
          data: {
            shared_screen: payload.shared_screen,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
  ),
}

export default ShareScreenModel
