import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISessionReviewModel from 'stores/domains/Session/SessionReview/interface'

const SessionReview: ISessionReviewModel = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  isErrorSubmit: false,
  isReviewExist: false,
  errorMessage: '',
  review: undefined,
  setDataReview: action((state, payload) => {
    state.review = payload
    state.isReviewExist = true
    state.isLoading = false
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = false
    state.isReviewExist = false
    state.isErrorSubmit = false
    state.isError = true
    state.errorMessage = payload
  }),
  setErrorSubmit: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = false
    state.isReviewExist = false
    state.isError = false
    state.isErrorSubmit = true
    state.errorMessage = payload
  }),
  setReviewExist: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = false
    state.isErrorSubmit = false
    state.isError = false
    state.isReviewExist = true
    state.errorMessage = payload
  }),
  setSuccess: action((state, payload) => {
    state.isLoading = false
    state.isSuccess = payload
    state.isErrorSubmit = false
    state.isError = false
    state.errorMessage = ''
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
    state.review = undefined
    state.isSuccess = false
    state.isErrorSubmit = false
    state.isError = false
    state.errorMessage = ''
  }),
  onReset: action((state) => {
    state.isLoading = false
    state.isSuccess = false
    state.isErrorSubmit = false
    state.isReviewExist = false
    state.isError = false
    state.errorMessage = ''
  }),
  onSubmitReview: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      await apiClient({
        url: `/rating/v1/session/${payload.session_id}/review`,
        method: 'POST',
        token: getCookie(authConfig.idTokenName),
        data: {
          rating: payload.rating,
          review: payload.review,
        },
      })
      actions.setSuccess(true)
    } catch (error) {
      if (
        get(error, 'response.data.code') === 'REVIEW_ALREADY_EXIST'
      ) {
        actions.setReviewExist(get(error, 'response.data.message'))
      } else {
        actions.setErrorSubmit(get(error, 'response.data.message'))
      }
    }
  }),
  onCheckReview: thunk(
    async (actions, session_id, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/rating/v1/session/${session_id}/review`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setDataReview(get(response, 'data.data'))
      } catch (error) {
        actions.setError(get(error, 'response.data.message'))
      }
    },
  ),
}

export default SessionReview
