import { LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'
import { ButtonVariant } from 'components/commons/Button'
import LoadingButton from 'components/commons/LoadingButton'

const Submit = styled(LoadingButton)({
  marginTop: '16px',
  height: '44px',
})

interface ISubmitButton extends LoadingButtonProps {
  text: string
  buttonVariant?: ButtonVariant
}

export default function SubmitButton(props: ISubmitButton) {
  return (
    <Submit
      id="act-submit-button"
      fullWidth
      disableElevation
      type="submit"
      buttonVariant={props.buttonVariant}
      {...props}
    >
      {props.text}
    </Submit>
  )
}
