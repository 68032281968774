import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'
import IBrowseSessionModel from './interface'

const BrowseSessionModel: IBrowseSessionModel = {
  isLoading: true,
  isError: false,
  sessionData: {
    sessionList: [],
    loadMore: false,
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setBrowseSession: action((state, response) => {
    state.sessionData = {
      sessionList: get(response, 'data.data', []),
      loadMore: get(response, 'data._metadata.load_more', false),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  getBrowseSession: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        let idToken = getCookie(authConfig.idTokenName)
        let url = ''
        if (!isEmpty(idToken)) {
          url =
            '/session/v1/search?' +
            payload.params.category_id +
            payload.params.session_type
        } else {
          url =
            '/session/guest/v1/search?' +
            payload.params.category_id +
            payload.params.session_type
          idToken = ''
        }
        const { apiClient } = injections
        const response = await apiClient({
          url: url,
          method: 'GET',
          token: idToken,
          params: {
            q: payload.params.key,
            page: payload.params.page,
            limit: payload.params.limit,
          },
        })
        actions.setBrowseSession(response)
        actions.setLoading(false)
        return response
      } catch (error) {
        actions.setError(
          error?.response?.data?.message
            ? error.response.data.message
            : 'Ups, terjadi kesalahan',
        )
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setDataLoadmoreSession: action((state, response) => {
    state.sessionData = {
      sessionList: [
        ...state.sessionData.sessionList,
        ...get(response, 'data.data', []),
      ],
      loadMore: get(response, 'data._metadata.load_more', false),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  loadMoreSession: thunk(async (actions, payload, { injections }) => {
    try {
      let idToken = getCookie(authConfig.idTokenName)
      let url = ''
      if (!isEmpty(idToken)) {
        url = `/session/v1/search?${payload.params.category_id}${payload.params.session_type}`
      } else {
        url = `/session/guest/v1/search?${payload.params.category_id}${payload.params.session_type}`
        idToken = ''
      }
      const { apiClient } = injections
      const response = await apiClient({
        url: url,
        method: 'GET',
        token: idToken,
        params: {
          q: payload.params.key,
          page: payload.params.page,
          limit: payload.params.limit,
        },
      })
      actions.setDataLoadmoreSession(response)
    } catch (error) {
      actions.setError(
        error?.response?.data?.message
          ? error.response.data.message
          : 'Ups, terjadi kesalahan',
      )
    }
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
}

export default BrowseSessionModel
