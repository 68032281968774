import { action } from 'easy-peasy'
import ICreatorUserModel from 'stores/commons/models/CreatorUser/interface'
import isEmpty from 'helpers/is-empty'

const CreatorUser: ICreatorUserModel = {
  creator: undefined,
  addOrUpdateCreator: action((state, payload) => {
    if (!isEmpty(state.creator)) {
      state.creator = {
        ...payload,
        rtc_info: state.creator.rtc_info,
      }
    } else {
      state.creator = payload
    }
  }),
  addOrUpdateCreatorRTC: action((state, rtc_info) => {
    state.creator = {
      ...state.creator,
      rtc_info,
    }
  }),
  removeCreator: action((state) => {
    state.creator = undefined
  }),
}

export default CreatorUser
