import { useQuery } from '@tanstack/react-query'

import { getCartTotalItems } from 'services/cart'
import { useCartTotalItemsStore } from 'stores/domains/Cart'
import { isGuestMode } from 'helpers/cookie'

export interface IUseCartTotalItems {
  data
  refetch
  isLoading
  isFetching
  isError
  isSuccess
  remove
}

export const useCartTotalItems = () => {
  const { setTotalItems } = useCartTotalItemsStore((state) => ({
    setTotalItems: state.setTotalItems,
  }))
  return useQuery({
    queryKey: ['totalCartItems'],
    queryFn: async () => {
      if (isGuestMode()) return {}
      try {
        const response = await getCartTotalItems()
        return response?.data?.data
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setTotalItems(data)
    },
  })
}
