import { action } from 'easy-peasy'
import INetworkQualityModel from 'stores/commons/models/NetworkQuality/interface'

const NetworkQuality: INetworkQualityModel = {
  quality: 0,
  setQuality: action((state, payload) => {
    state.quality = payload
  }),
}

export default NetworkQuality
