import * as React from 'react'
import { useRouter } from 'next/router'
import { MenuItem, styled } from '@mui/material'

import { unionListToPeopleProperties } from 'helpers/analytics'
import { getUriParam } from 'helpers/url'
import redirect from 'helpers/redirect'
import trackEvent from 'src/trackers'
import { useCategoryStore } from 'stores/domains/User'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import { useCategory } from 'hooks/domains/User/useCategory'
import { useTranslation } from 'react-i18next'

const StyledCategoryItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.normalRegular,
  padding: '13px 12px',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.tiptip[500],
    background: 'transparent',
  },
}))

const CategoriesHeader = () => {
  const { pathname, query, isReady } = useRouter()
  const { t } = useTranslation('common')

  const { refetch: fetchCategories } = useCategory()

  const trackerData = { pathname, query }

  const { categories, selectedCategories } = useCategoryStore(
    (state) => ({
      categories: state.categories,
      selectedCategories: state.selectedCategories,
    }),
  )

  const [isActive, setIsActive] = React.useState<boolean>(false)

  const getCategoryUrl = (id: number | string) => {
    const params = {
      source: '/',
      source_action: 'trigger_category_card',
    }
    const url = `/browse?status=all&category_id=${id}`
    return getUriParam(url, params)
  }

  const handleClickCategory = (category: any) => {
    const { id, name } = category || {}
    trackEvent.home('select_category', trackerData, {
      creator_category: name,
      creator_category_id: String(id),
      list_selected_category_id: selectedCategories,
      list_selected_category_name: categories
        .filter((cat) => selectedCategories?.includes(cat.id))
        .map((cat) => cat.name),
    })
    unionListToPeopleProperties({
      lifetime_select_category_id_list: String(id),
    })
    redirect(getCategoryUrl(id))
  }

  React.useEffect(() => {
    if (isActive) {
      trackEvent.home('trigger_categories_drop_down', trackerData)
    }
    return () => {
      setIsActive(false)
    }
  }, [isActive])

  React.useEffect(() => {
    if (isReady) {
      fetchCategories()
    }
  }, [isReady])

  return (
    <Stack
      position="relative"
      display={{ xs: 'none', md: 'inline-block' }}
      sx={(theme) => ({
        ':hover .button': {
          color: theme.palette.tiptip[500],
        },
        ':hover .dropdown-content': {
          visibility: 'visible',
          opacity: 1,
        },
      })}
    >
      <Button
        id="b-common-categories-button"
        className="button"
        buttonVariant="nude"
        size="large"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.tiptip[500],
            background: 'transparent',
          },
        })}
        onMouseOver={() => {
          if (!isActive) setIsActive(true)
        }}
      >
        {t('category')}
      </Button>

      <Stack
        className="dropdown-content"
        visibility="hidden"
        position="absolute"
        paddingY={2}
        zIndex={1}
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {categories?.length > 0 && (
          <Stack
            id="c-common-categories-menu"
            sx={(theme) => ({
              padding: 2,
              color: theme.palette.text.primary,
              background: theme.palette.background.primary,
              boxShadow: `0px 8px 8px ${theme.palette.border.bold}`,
              borderRadius: '12px',
              width: '276px',
              height: '664px',
              maxHeight: '80vh',
              overflowY: 'auto',
            })}
          >
            {categories.map((category, index) => (
              <StyledCategoryItem
                key={index}
                onClick={() => handleClickCategory(category)}
              >
                {category.name}
              </StyledCategoryItem>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default CategoriesHeader
