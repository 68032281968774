import { StateCreator } from 'zustand'
import IBrowse, { IBrowseStateDefault } from './interface'

const resetters: (() => void)[] = []

const INITIAL_DATA: IBrowseStateDefault = {
  currentStatusPage: 'all',
  selectedTab: 0,
  priceType: [],
  roleType: [],
  statusType: [],
  checkedFilters: [],
  keyword: '',
}

export const createBrowseStore: StateCreator<IBrowse> = (set) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setCurrentStatusPage: (status) => {
      set({
        currentStatusPage: status,
      })
    },
    setSelectedTab: (selectedTab) => {
      set({
        selectedTab,
      })
    },
    setPriceType: (payload) => {
      set({ priceType: payload })
    },
    setRoleType: (payload) => {
      set({ roleType: payload })
    },
    setCheckedFilters: (payload) => {
      set({ checkedFilters: payload })
    },
    setStatusType: (payload) => {
      set({ statusType: payload })
    },
    setKeyword: (payload) => {
      set({ keyword: payload })
    },
  }
}

export const resetBrowseStore = () =>
  resetters.forEach((resetter) => resetter())
