import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { IProductsProps } from 'services/payment'

interface ICartPayload {
  products: IProductsProps
  idempotentKey: number
}

export async function getCartListItems() {
  return apiClient({
    url: `/order/v1/cart`,
    method: 'GET',
    token: getIdToken(),
  })
}

export async function getCartTotalItems() {
  return apiClient({
    url: `/order/v1/cart/total-item`,
    method: 'GET',
    token: getIdToken(),
  })
}
interface IDeleteCartPayload {
  product_uid: string | string[]
  head_source: string
  idempotentKey: string
}
export async function deleteCartItems({
  product_uid,
  head_source,
  idempotentKey,
}: IDeleteCartPayload) {
  return apiClient({
    url: `/order/v1/cart`,
    method: 'DELETE',
    params: { product_uid: product_uid, head_source: head_source },
    headers: { 'Idempotent-Key': idempotentKey },
    token: getIdToken(),
  })
}

export async function addToCart({
  products,
  idempotentKey,
}: ICartPayload) {
  return apiClient({
    url: `/order/v3/cart`,
    method: 'POST',
    data: {
      products: products,
    },
    headers: { 'Idempotent-Key': idempotentKey },
    token: getIdToken(),
  })
}
