import apiClient from 'helpers/api-client'

const storeConfig = (otherConfig = {}): Object => {
  return {
    version: 0,
    devTools: process.env.NODE_ENV !== 'production',
    injections: {
      apiClient,
    },
    ...otherConfig,
  }
}

export default storeConfig
