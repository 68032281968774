import { action } from 'easy-peasy'
import ITippingAccumulationModel from 'stores/domains/Session/TippingAccumulation/interface'

const TippingAccumulation: ITippingAccumulationModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  participantTipping: [],
  totalTipping: 0,

  setTotalTipping: action((state, payload) => {
    state.totalTipping = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setParticipantTipping: action((state, payload) => {
    state.participantTipping = payload
  }),
}

export default TippingAccumulation
