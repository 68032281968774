export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL
export const STORE_NAME = process.env.NEXT_PUBLIC_STORE_NAME
export const SOFT_LAUNCH = process.env.NEXT_PUBLIC_SOFT_LAUNCH
export const CREATOR_ALTERNATE_LAYOUT =
  process.env.NEXT_PUBLIC_CREATOR_ALTERNATE_LAYOUT === 'true'
    ? true
    : false
export const IS_CREATOR_VERIFICATION =
  process.env.NEXT_PUBLIC_IS_CREATOR_VERIFICATION === 'true'
