import { StateCreator } from 'zustand'
import { IContentDetail } from './interface'

export const createContentDetailSlice: StateCreator<
  IContentDetail
> = (set) => ({
  // states
  contentDetailData: {
    product_id: '',
    product_uid: '',
    title: '',
    description: '',
    cover: '',
    type: {
      id: 0,
      name: '',
      units: '',
    },
    created_at: '',
    content_length: 0,
    teaser: '',
    stats: {
      total_sales: 0,
    },
    is_published: false,
    cover_file: '',
    teaser_file: '',
    currency: '',
    tier_id: 0,
    allowed_payment_method: [],
    tiptip_fee: 0,
    is_tiptip_studio: false,
    category: {
      id: 0,
      name: '',
    },
    file_name: '',
    file_name_original: '',
    promoter_fee_from_creator: 0,
    promoter_fee_from_tiptip: 0,
    is_promoted: false,
    status: {
      id: 0,
      name: '',
    },
    content_info: '',
    preview_link: '',
    submitted_at: '',
    released_at: '',
    cover_thumbnail: '',
  },
  contentUserDetailData: {
    creator: {
      creator_id: '',
      name: '',
      username: '',
      creator_user_id: '',
      verified: false,
      is_community_leader: false,
      creator_group: '',
      creator_user_status: 0,
    },
    currency: '',
    encoding_information: {
      encoding_id: '',
      original_file_path: '',
      encoding_file_path: '',
    },
    file_link: '',
    is_show_success_payment: false,
    is_subscriber: false,
    metadata: null,
    payment: {
      expiry_date: '',
      time_remaining: 0,
      expiry_message: '',
      is_timer_active: false,
    },
    payment_instruction: {
      is_show: false,
      title: '',
      body: '',
      invoice_id: '',
      payment_method_code: '',
    },
    price: 0,
    sale_price: 0,
    price_tier: {
      tier_id: 0,
      web_price: 0,
      web_price_sale: 0,
      app_price: 0,
      app_price_sale: 0,
      ios_price: 0,
      ios_price_sale: 0,
      iap_product_id: '',
    },
    promoter_commission_estimation: 0,
    status_order: '',
    subscribe_package_list: [],
    content_access_source: '',
  },

  // actions
  setContentDetail: async (payload) => {
    set({ contentDetailData: payload })
  },
  setContentUserDetail: async (payload) => {
    set({ contentUserDetailData: payload })
  },
})
