import { useTheme } from '@mui/material'

import transformDateTime from 'helpers/transform/date-time'

import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'
import { Message } from 'stores/domains/User/Message'
import { urlify } from './urlify'
import htmlParse from 'html-react-parser'

interface BubbleChatProps {
  message: Message
  isSelf?: boolean
}

export default function BubbleChat({
  message,
  isSelf,
}: BubbleChatProps) {
  const theme = useTheme()

  return (
    <Stack
      width="100%"
      alignItems={isSelf ? 'flex-end' : 'flex-start'}
    >
      <Stack
        direction="column"
        spacing={1}
        p={1.5}
        bgcolor={
          isSelf
            ? theme.palette.background.tertiery
            : theme.palette.background.primary
        }
        position="relative"
        borderRadius={
          isSelf ? '20px 20px 0px 20px' : '20px 20px 20px 0px'
        }
        width="70%"
        textAlign="left"
        border={!isSelf && `1px solid ${theme.palette.border.bold}`}
      >
        <Stack mb={2}>
          <Typography
            as="p"
            size="xs"
            color={isSelf ? 'tertiery' : 'primary'}
            sx={{
              display: 'inline-block',
              a: {
                textDecoration: 'none',
                color: theme.palette.blue[500],
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            }}
          >
            {htmlParse(urlify(message.message))}
          </Typography>
        </Stack>
        <Stack
          position="absolute"
          right={0}
          bottom={0}
          py={1}
          px={1.5}
        >
          <Typography
            as="span"
            size="xs"
            color={isSelf ? 'tertiery' : 'secondary'}
          >
            {transformDateTime(message.created_at, true)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
