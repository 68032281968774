import { isGuestMode } from 'helpers/cookie'

export const MOENGAGE_APP_ID = process.env.NEXT_PUBLIC_MOENGAGE_KEY

export const isEnabled =
  process.env.NEXT_PUBLIC_MOENGAGE_ENABLED === 'true' &&
  process.env.NEXT_IS_SERVER !== 'true' &&
  !isGuestMode()

export const handleDebug = () => {
  const isDebugEnabled =
    process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_ENABLED === 'true'

  return Number(isDebugEnabled)
}

export const trackEvent = (event_name) => {
  if (isEnabled) {
    const Moengage = window?.Moengage
    Moengage.track_event(event_name)
  }
}

export const trackUser = (userData) => {
  if (isEnabled) {
    const Moengage = window?.Moengage
    Moengage.add_unique_user_id(userData?.user_id)
    Moengage.add_user_name(userData?.username)
    Moengage.add_email(userData?.email)
    Moengage.add_mobile(userData?.phone || '')
    Moengage.add_gender(userData?.gender || '')
  }
}

export const trackLogout = () => {
  if (isEnabled) {
    const Moengage = window?.Moengage
    Moengage.destroy_session()
  }
}
