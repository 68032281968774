import PromoIcon from 'assets/svg/notification/promo.svg'
import InfoIcon from 'assets/svg/notification/general_info.svg'
import FailedIcon from 'assets/svg/notification/failed_transaction.svg'
import SuccessIcon from 'assets/svg/notification/success_purchase.svg'
import {
  StyledContainer,
  StyledDateLabel,
  StyledImageNotification,
  StyledMessageDesc,
  StyledMessageTitle,
  StyledNotificationItemContainer,
  StyledRedDot,
} from './styled'
import dayjs from 'dayjs'
import isYesterday from 'dayjs/plugin/isYesterday'
import isToday from 'dayjs/plugin/isToday'
import 'dayjs/locale/id'
import { MouseEventHandler } from 'react'
import {
  NotificationItem as INotificationItem,
  notificationConst,
} from 'constants/notification'

type Category = 'content' | 'session' | 'event' | 'subscription'
interface NotificationItemProps {
  item: INotificationItem
  onClick: MouseEventHandler<HTMLDivElement>
}

const NotificationItem = ({
  item,
  onClick,
}: NotificationItemProps) => {
  dayjs.extend(isYesterday)
  dayjs.extend(isToday)
  dayjs.locale('id')

  const renderDate = (date: string) => {
    if (dayjs(date).isToday()) {
      return dayjs(date).format('HH:mm')
    } else if (dayjs(date).isYesterday()) {
      return 'Kemarin'
    }
    return dayjs(date).format('DD MMMM')
  }

  const renderIcon = (iconType: number) => {
    switch (iconType) {
      case 1:
        return <SuccessIcon />
      case 2:
        return <FailedIcon />
      case 4:
        return <PromoIcon />
      case 3:
      default:
        return <InfoIcon />
    }
  }

  const handleImageError = (categoryType: Category) => {
    switch (categoryType) {
      default:
        return '/img/placeholder/product_placeholder.jpg'
    }
  }

  return (
    <StyledNotificationItemContainer
      onClick={onClick}
      read={item?.read ? 'true' : 'false'}
    >
      <StyledContainer>
        {renderIcon(item?.icon_type)}
        {!item?.read && <StyledRedDot />}
      </StyledContainer>
      <StyledContainer
        sx={{
          alignItems: 'flex-start',
          marginLeft: '8px',
          width:
            notificationConst.category_type[item.category_type] ===
              'session' ||
            notificationConst.category_type[item.category_type] ===
              'content'
              ? '90%'
              : 'auto',
        }}
      >
        <StyledMessageTitle>{item?.title}</StyledMessageTitle>
        <StyledMessageDesc>{item?.message}</StyledMessageDesc>
        <StyledDateLabel>{renderDate(item?.sent_at)}</StyledDateLabel>
      </StyledContainer>
      {(notificationConst.category_type[item.category_type] ===
        'session' ||
        notificationConst.category_type[item.category_type] ===
          'content') && (
        <StyledContainer>
          <StyledImageNotification
            alt="image-notification"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = handleImageError(
                notificationConst.category_type[item.category_type],
              )
            }}
            sx={{
              width:
                notificationConst.category_type[
                  item.category_type
                ] === 'session'
                  ? '114px'
                  : '64px',
            }}
            src={item.image_url}
          />
        </StyledContainer>
      )}
    </StyledNotificationItemContainer>
  )
}

export default NotificationItem
