import { create } from 'zustand'
import { createCartListItemsStore } from './CartItems'
import { ICartListItemsStore } from './CartItems/interface'
import { createCartTotalItemsStore } from './CartTotalItems'
import { ICartTotalItemsStore } from './CartTotalItems/interface'

export const useCartTotalItemsStore = create<ICartTotalItemsStore>()(
  (...a) => ({
    ...createCartTotalItemsStore(...a),
  }),
)

export const useCartListItemsStore = create<ICartListItemsStore>()(
  (...a) => ({
    ...createCartListItemsStore(...a),
  }),
)
