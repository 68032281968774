import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function BrowseTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const { pathname, query } = data
  const state_name = getStateFromSource(pathname)
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const sourceProperties = {
    source,
    source_action,
  }

  const defaultValue = {
    state_name,
    ...additionalProperties,
  }

  const eventProperties = {
    unfollow_user: {
      ...defaultValue,
    },
    follow_user: {
      ...defaultValue,
    },
    select_category: {
      ...defaultValue,
    },
    visit_browse_search: {
      ...sourceProperties,
      ...defaultValue,
    },
    browse_creator_load_more: {
      ...defaultValue,
    },
    apply_filter_option: {
      ...defaultValue,
    },
    select_browse_and_search_tab: {
      ...defaultValue,
    },
    select_browse_and_search_content_tab: {
      ...defaultValue,
    },
    select_browse_and_search_session_tab: {
      ...defaultValue,
    },
    select_browse_and_search_creator_tab: {
      ...defaultValue,
    },
    select_browse_and_search_community_tab: {
      ...defaultValue,
    },
    select_browse_and_search_event_tab: {
      ...defaultValue,
      ...additionalProperties,
    },
    click_filter: {
      state_name,
    },
    select_content: {
      source_action,
      ...defaultValue,
    },
    select_event: {
      source_action,
      ...defaultValue,
    },
    click_play_teaser: {
      ...defaultValue,
    },
    back_from_browse_search: {
      ...defaultValue,
    },
    select_session: {
      ...defaultValue,
    },
    select_creator: {
      ...defaultValue,
    },
    browse_session_load_more: {
      ...additionalProperties,
    },
    click_see_more: {
      ...defaultValue,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
