import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import ITopUpModel from 'stores/domains/Wallet/models/TopUp/interface'

const TopupModel: ITopUpModel = {
  topUpCoinStatus: false,
  isLoading: false,
  isError: false,
  statusCode: 200,
  showTopUpModal: false,
  showPaymentConfirmationModal: false,
  showExternalPaymentModal: false,
  coinPackages: {
    info: '',
    coins: [],
    max_total_coin_purchase: 0,
  },
  topupInquiry: {
    coins: [],
    transaction_fee: 0,
    purchase_amount: 0,
    sub_total: 0,
    payment_id: '',
    payment_method: [],
  },
  topupRequest: {},
  errorMessage: '',
  totalCoinTopUp: 0,
  totalAmountTopUp: 0,
  setTopUpCoinStatus: action((state, payload) => {
    state.topUpCoinStatus = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  resetTopUp: action((state) => {
    state.isLoading = false
    state.isError = false
    state.showTopUpModal = false
    state.coinPackages.coins = []
    state.coinPackages.info = ''
    state.coinPackages.max_total_coin_purchase = 0
    state.errorMessage = ''
  }),
  setShowTopUpModal: action((state, payload) => {
    state.showTopUpModal = payload
  }),
  setShowPaymentConfirmationModal: action((state, payload) => {
    state.showPaymentConfirmationModal = payload
  }),
  setShowExternalPaymentModal: action((state, payload) => {
    state.showExternalPaymentModal = payload
  }),
  setCoinPackages: action((state, payload) => {
    const payloadData = payload.coins.map((coinPackage) => {
      coinPackage.quantity = 0
      return coinPackage
    })
    state.coinPackages.coins = payloadData
    state.coinPackages.info = payload.info
    state.coinPackages.max_total_coin_purchase =
      payload.max_total_coin_purchase
    state.totalCoinTopUp = 0
    state.totalAmountTopUp = 0
  }),
  addCoinPackages: action((state, package_id) => {
    const index = state.coinPackages.coins.findIndex(
      (coinPackage) => coinPackage.id === package_id,
    )
    state.coinPackages.coins[index].quantity += 1
    let countTotalCoin = 0
    let countTotalAmount = 0
    state.coinPackages.coins.forEach((coinPackage) => {
      countTotalCoin += coinPackage.coin_unit * coinPackage.quantity
      countTotalAmount +=
        coinPackage.purchase_price * coinPackage.quantity
    })
    state.totalCoinTopUp = countTotalCoin
    state.totalAmountTopUp = countTotalAmount
  }),
  reduceCoinPackages: action((state, package_id) => {
    const index = state.coinPackages.coins.findIndex(
      (coinPackage) => coinPackage.id === package_id,
    )
    state.coinPackages.coins[index].quantity -= 1
    let countTotalCoin = 0
    let countTotalAmount = 0
    state.coinPackages.coins.forEach((coinPackage) => {
      countTotalCoin += coinPackage.coin_unit * coinPackage.quantity
      countTotalAmount +=
        coinPackage.purchase_price * coinPackage.quantity
    })
    state.totalCoinTopUp = countTotalCoin
    state.totalAmountTopUp = countTotalAmount
  }),
  getCoinPackages: thunk(
    async (actions, _payload, { injections }) => {
      try {
        actions.setLoading(true)
        actions.clearError()
        const { apiClient } = injections
        const response = getCookie(authConfig.idTokenName)
          ? await apiClient({
              url: '/payment/v2/topup/coins',
              method: 'GET',
              token: getCookie(authConfig.idTokenName),
            })
          : { data: { result: {} } }
        actions.setCoinPackages(response.data?.data)
        actions.setLoading(false)
      } catch (err) {
        actions.error({
          message: err?.response?.data?.message
            ? err.response.data.message
            : 'Ups, terjadi kesalahan, coba beberapa saat lagi',
          code: err?.response?.status,
        })
      }
    },
  ),
  setTopupInquiry: action((state, payload) => {
    state.topupInquiry = payload
  }),
  postTopupInquiry: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        actions.clearError()
        const { apiClient } = injections
        const response = getCookie(authConfig.idTokenName)
          ? await apiClient({
              url: '/payment/v1/topup',
              method: 'POST',
              token: getCookie(authConfig.idTokenName),
              data: {
                coins: payload,
              },
            })
          : { data: { result: {} } }
        actions.setTopupInquiry(response?.data?.data)
        actions.setShowTopUpModal(false)
        actions.setShowPaymentConfirmationModal(true)
        actions.setLoading(false)
      } catch (err) {
        actions.error({
          message: err?.response?.data?.message
            ? err.response.data.message
            : 'Ups, terjadi kesalahan, coba beberapa saat lagi',
          code: err?.response?.status,
        })
      }
    },
  ),
  setTopupRequest: action((state, payload) => {
    state.topupRequest = payload
  }),
  postTopupRequest: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        actions.clearError()
        const { apiClient } = injections
        const response = getCookie(authConfig.idTokenName)
          ? await apiClient({
              url: '/payment/v1/topup/invoice',
              method: 'POST',
              token: getCookie(authConfig.idTokenName),
              data: {
                payment_id: payload,
              },
            })
          : { data: { result: {} } }
        actions.setTopupRequest(response?.data?.data)
        actions.setShowPaymentConfirmationModal(false)
        actions.setShowExternalPaymentModal(true)
        actions.setLoading(false)
      } catch (err) {
        actions.error({
          message: err?.response?.data?.message
            ? err.response.data.message
            : 'Ups, terjadi kesalahan, coba beberapa saat lagi',
          code: err?.response?.status,
        })
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload.message
    state.statusCode = payload.code
  }),
  clearError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
}

export default TopupModel
