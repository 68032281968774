import { useFormContext, Controller } from 'react-hook-form'
import { FormHelperText } from '@mui/material'
import dynamic from 'next/dynamic'

import { Props as EditorProps } from '../RichTextEditor'
import 'helpers/highlight'

const Editor = dynamic(() => import('../RichTextEditor'), {
  ssr: false,
})

interface Props extends EditorProps {
  name: string
  disabled?: boolean
}

export default function RHFEditor({
  name,
  limit,
  disabled = false,
  ...other
}: Props) {
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return <FormHelperText error>{message}</FormHelperText>
  }

  return (
    <Controller
      name={name}
      control={control as any}
      render={({ field, fieldState: { error } }) => {
        return (
          <Editor
            id={name}
            limit={limit}
            error={!!error}
            helperText={renderErrorText(error?.message)}
            readOnly={disabled}
            {...field}
            {...other}
          />
        )
      }}
    />
  )
}
