/**
 * Custom Form Field
 * based from page: https://tiptiptv.atlassian.net/wiki/spaces/ENGG/pages/300646448/RFC+Centralized+form+format
 */

import { SxProps, InputProps } from '@mui/material'

import Stack from '../Stack'
import Typography from '../Typography'

import CustomTextField from './Text'
import CustomDropdownField from './Dropdown'
import CustomRadioField from './Radio'
import CustomDateField from './Date'

import { ICustomField } from './interface'
import { FieldTitle, FieldSubtitle } from './styled'
import CustomFileUpload from './FileUpload'
import { UseFormReturn } from 'react-hook-form'
import { ImageListType } from 'react-images-uploading'
import { useTranslation } from 'react-i18next'

export interface IInputField extends ICustomField, UseFormReturn {
  multiline?: boolean
  sx?: SxProps
  InputProps?: InputProps
  onGetImageList?: Function
  defaultImages?: ImageListType
}

function InputField({ ...props }: IInputField) {
  switch (props.type) {
    case 'TEXT':
    case 'PARAGRAPH':
      return (
        <CustomTextField
          multiline={props.type === 'PARAGRAPH'}
          {...props}
        />
      )
    case 'DROPDOWN':
      return <CustomDropdownField {...props} />
    case 'DATE':
      return <CustomDateField {...props} />
    case 'RADIO_BUTTON':
      return <CustomRadioField {...props} />
    case 'ATTACHMENT':
      return <CustomFileUpload {...props} />
    default:
      return (
        <Typography color={'white'}>
          {JSON.stringify(props)}
        </Typography>
      )
  }
}

// default export function
// will generate another form field based on
export default function CustomField({ ...props }: any) {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{ '.MuiOutlinedInput-root': { marginTop: 0 } }}
      spacing={0.5}
      id={props.component_id || null}
    >
      <FieldTitle
        content={t(props.label)}
        isRequired={props.validation.required}
      />
      <FieldSubtitle content={t(props.description)} />
      <InputField {...props} />
    </Stack>
  )
}
