import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'

import { getCommunityProductList } from 'services/social'
import { getErrorMessage } from 'helpers/error'
import { Event } from 'src/@types/event'

interface Metadata {
  limit_content: number
  limit_event: number
  load_more_content: boolean
  load_more_event: boolean
  page_content: number
  page_event: number
  total: number
}

interface ProductListResponse {
  code: string
  errors: any[]
  message: string
  data: {
    content: any[]
    event: Event[]
  }
  _metadata: Metadata
}

interface Payload {
  started_date: string
  ended_date: string
  limit: number
  page: number
}

export const useCommunityProductList = (
  id: string,
  payload: Payload,
) => {
  const { page } = payload
  return useQuery<ProductListResponse>({
    queryKey: ['community-product-list', id, payload],
    queryFn: async () => {
      const response = await getCommunityProductList(id, {
        lc: 10,
        pc: page,
        pe: page,
        tab: 'event',
        le: payload.limit,
        ...payload,
      })
      return get(response, 'data', {})
    },
    enabled: false,
    retry: false,
    keepPreviousData: true,
    onError: (err) => Error(getErrorMessage(err as any)),
  })
}
