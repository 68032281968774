import appConfig from 'configs/app'

const noBackURLs = ['/creator/session/live/', '/session/live/']
const skipCommonSessionURLs = [
  '/login',
  '/sign-up',
  '/token-expired',
  '/forgot-password',
  '/code-verification/phone',
  '/code-verification/email',
  '/phone-verification',
  '/user/post-registration',
  '/setting/account/information',
  '/setting/code-verification/phone',
  '/setting/code-verification/email',
  '/setting/account/information/change-birthday',
  '/setting/account/information/change-email',
  '/setting/account/information/change-gender',
  '/setting/account/information/change-phonenumber',
  '/setting/account/information/change-username',
  '/setting/account/security',
  '/setting/account/security/change-password',
]
const loggedInSSO = ['google', 'appleid']

const skipPostRegistrationUrls = [
  appConfig.communityRegistrationUrl,
  '/community/verify',
  appConfig.creatorRegistrationUrl,
]

export {
  noBackURLs,
  skipCommonSessionURLs,
  skipPostRegistrationUrls,
  loggedInSSO,
}
