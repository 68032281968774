import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function PaymentCartTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const {
    pathname,
    query,
    preCheckoutData,
    paymentData,
    voucherData,
  } = data
  const state_name = getStateFromSource(pathname)
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const cart_num_item = preCheckoutData?.products?.length || 0
  const cart_total_price =
    voucherData?.total || paymentData?.total || 0
  const cart_admin_fee = paymentData?.transaction_fee || 0
  const cart_tax_value = paymentData?.tax || 0
  const cart_discount = paymentData?.voucher_discount || 0
  const cart_subtotal_price = paymentData?.sub_total || 0
  const voucher_code = paymentData?.voucher_code || ''

  const paymentProperties = {
    cart_num_item,
    cart_total_price,
    cart_admin_fee,
    cart_tax_value,
    cart_discount,
    cart_subtotal_price,
    voucher_code,
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    visit_cart_payment_confirmation: {
      state_name,
      ...sourceProperties,
      ...paymentProperties,
    },
    click_add_voucher: {
      state_name,
      ...sourceProperties,
      ...paymentProperties,
    },
    purchase_cart_item_completed: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    visit_voucher_inbox: {
      state_name,
      source,
      ...additionalProperties,
    },
    click_search_box: {
      state_name,
      ...additionalProperties,
    },
    select_voucher: {
      state_name,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
