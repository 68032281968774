import { useRouter } from 'next/router'

import {
  DesktopView,
  MobileView,
} from 'components/commons/Responsive'
import ErrorPage, {
  ErrorPageProps,
} from 'components/commons/ErrorPage'
import Layout from 'containers/commons/Layout/v2'
import { IllustrationNetworkError } from 'svgs/illustrations'
import { useTranslation } from 'react-i18next'

function NetworkError(): JSX.Element {
  const { t } = useTranslation()
  const router = useRouter()

  const errorParams: ErrorPageProps = {
    title: t('noInternetConnectionTitle'),
    subtitle: t('noInternetConnectionSubtitle'),
    illustration: <IllustrationNetworkError />,
    onRefresh: () => router.reload(),
    hideHomeButton: true,
    height: '100vh',
  }

  return (
    <>
      <DesktopView>
        <ErrorPage {...errorParams} />
      </DesktopView>
      <MobileView>
        <Layout
          withManualWidth
          mainPadding={{ xs: 0, md: 0 }}
          renderFooter={false}
          renderHeader={false}
        >
          <ErrorPage {...errorParams} />
        </Layout>
      </MobileView>
    </>
  )
}

export default NetworkError
