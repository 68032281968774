import { AppBar, Drawer, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from 'components/commons/Box'

export const StyleContainer = styled(Drawer)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  '& .MuiPaper-root': {
    background: theme.palette.background.primary,
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

export const StyledStack = styled(Stack)({
  width: '100vw',
  '@media (min-width:480px)': {
    width: '480px',
  },
  minHeight: '15vh',
  marginTop: '8px',
})

export const DrawerHeader = styled(Stack)({
  width: '100vw',
  '@media (min-width:480px)': {
    width: '480px',
  },
  marginTop: '8px',
})

export const StyleAppBar = styled(AppBar)(({ theme }) => ({
  right: 'unset',
  maxWidth: '100vw',
  background: theme.palette.background.primary,
  boxShadow: 'none',
  position: 'absolute',
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
  },
  '@media (min-width:480px)': {
    maxWidth: '480px',
  },
}))

export const DrawerContentContainer = styled(Box)({
  width: '90vw',
  '@media (min-width:480px)': {
    width: '420px',
  },
})
export const DrawerContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
}))

export const StyledTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const HistoryContent = styled(Box)({
  marginTop: '12px',
})

export const HistoryWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
  cursor: 'pointer',
})
