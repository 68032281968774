import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect x="3" y="3" width="8" height="8" rx="3" fill="#F87500" />
      <rect x="3" y="13" width="8" height="8" rx="3" fill="#CB1C4F" />
      <rect x="13" y="3" width="8" height="8" rx="3" fill="#F87500" />
      <rect
        x="13"
        y="13"
        width="8"
        height="8"
        rx="3"
        fill="#CB1C4F"
      />
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
