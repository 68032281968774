import ModalDialog from 'components/commons/Modal/Dialog/v2'
import { Container, SxProps } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import appConfig from 'configs/app'
import { openInNewTab } from 'helpers/redirector'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import CloseIcon from '@mui/icons-material/Close'
import redirect from 'helpers/redirect'
import StateImage from 'components/commons/StateImage'
import Button from 'components/commons/Button'
import Typography from 'components/commons/Typography'
import { useTranslation } from 'react-i18next'

const StyledDrawerTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.text.primary,
  padding: '0 25px',
  letterSpacing: '0.0015em',
}))

const StyledSubtitle = styled('ol')(({ theme }) => ({
  ...theme.typography.normalRegular,
  color: theme.palette.text.secondary,
  textAlign: 'center',
  margin: 0,
  marginBottom: '3px',
  padding: 0,
}))

interface IModalDialogProps {
  onClose?: any
  open: boolean
  sx?: SxProps
}

export const ModalBecomeCreator = ({
  open,
  onClose,
  sx,
}: IModalDialogProps) => {
  const { t } = useTranslation()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const theme = useTheme()

  const handleClickBecomeCreator = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_creator_registration', trackerData)
    trackEvent.home('click_apply_creator_now', trackerData)

    redirect(
      `${appConfig.creatorRegistrationUrl}?source=${pathname}&source_action=click_creator_registration`,
    )
  }

  return (
    <ModalDialog
      sx={sx}
      open={open}
      onClose={() => {
        trackEvent.home('close_become_a_creator', trackerData)
        onClose()
      }}
      isNoCloseButton={false}
      closeButtonIcon={
        <CloseIcon
          sx={{ color: theme.palette.tiptip[500] }}
          fontSize="large"
        />
      }
    >
      <div style={{ textAlign: 'center' }}>
        <StateImage type="creator-onboarding" />
        <StyledDrawerTitle>
          Jadi Kreator di TipTip bisa dapat berbagai Keuntungan lho!
        </StyledDrawerTitle>

        <StyledSubtitle sx={{ marginTop: '12px' }}>
          1. Raih pendapatan dari sesi live interaktif
        </StyledSubtitle>
        <StyledSubtitle>2. Terima Tip dari penggemar</StyledSubtitle>
        <StyledSubtitle>
          3. Jual konten digital ke penggemar
        </StyledSubtitle>
        <StyledSubtitle>
          4. Lebih dekat dengan sesi 1on1
        </StyledSubtitle>

        <div style={{ marginTop: 12 }}>
          <a
            id="b-home-start-sell-content"
            style={{
              color: theme.palette.blue[500],
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              trackEvent.home('click_learn_more', trackerData)
              openInNewTab(
                `${appConfig.creatorLandingUrl}?source=HOME&source_action=click_learn_more`,
              )
            }}
          >
            {t('key_learn_more')}
          </a>
        </div>
      </div>
      <Container
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '0px !important',
          marginTop: '32px',
        }}
      >
        <Button
          sx={{
            width: '219px',
            borderRadius: '4px',
            height: '45px',
          }}
          onClick={() => {
            trackEvent.home(
              'click_start_selling_content',
              trackerData,
            )
            openInNewTab('https://wa.link/uxhjz0')
          }}
        >
          Mulai Jual Konten
        </Button>
        <Button
          sx={{
            width: '219px',
            borderRadius: '4px',
            height: '45px',
          }}
          buttonVariant="outlined"
          onClick={handleClickBecomeCreator}
        >
          Daftar Jadi Kreator
        </Button>
      </Container>
    </ModalDialog>
  )
}
