import SnackbarNotification from 'components/commons/SnackbarNotification'
import { useNotificationStore } from 'stores/commons'
import { MessagePayload } from 'firebase/messaging'
import { onMessageListener } from 'helpers/firebase'
import isEmpty from 'helpers/is-empty'
import { sendLog } from 'helpers/log'
import { useEffect, useState } from 'react'

const NotificationBar = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState({
    title: '',
    subtitle: '',
  })

  const { getListNotification } = useNotificationStore((state) => ({
    getListNotification: state.getListNotification,
  }))

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setMessage({
      title: '',
      subtitle: '',
    })
  }

  useEffect(() => {
    onMessageListener()
      .then((payload: MessagePayload) => {
        const { notification } = payload || {}
        if (!isEmpty(notification)) {
          getListNotification({
            limit: 5,
            page: 1,
            type: 1,
            isReset: true,
          })
          getListNotification({
            limit: 5,
            page: 1,
            type: 2,
            isReset: true,
          })
          setMessage({
            title: notification.title,
            subtitle: notification.body,
          })
          setOpen(true)
        }
      })
      .catch((error) =>
        sendLog(
          `Error not supported firebase: ${error?.code} - ${error?.message}`,
        ),
      )
  }, [])

  return (
    <SnackbarNotification
      open={open}
      severity={'info'}
      onClose={handleClose}
      variant="filled"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6500}
      sx={{
        position: 'absolute !important',
        top: '10% !important',
        right: '-15% !important',
        bottom: 'unset !important',
        left: 'unset !important',
      }}
      desc={message.title}
    />
  )
}

export default NotificationBar
