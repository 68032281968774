import { styled } from '@mui/material/styles'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

type TabPanelContainerProps = {
  heightadjuster?: number
}

export const TabPanelContainer = styled(Box)<TabPanelContainerProps>(
  ({ theme }) => ({
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    paddingRight: '16px',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '50px',
    },
  }),
)

export const WrapperButtonLoad = styled(Box)({
  textAlign: 'center',
  opacity: 0,
  visibility: 'hidden',
})

export const LoadMoreButton = styled(Button)({
  padding: '10px 25px',
  borderRadius: '50px',
  minWidth: '167px',
  marginBottom: '24px',
})

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: '8px',
  marginTop: '16px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}))

export const StyledLabelEndOfList = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.normalRegular,
    color: theme.palette.text.tertiery,
    textAlign: 'center',
  }),
)
