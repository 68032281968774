import { StateCreator } from 'zustand'
import { IMissionDetail, IMissionDetailStore } from './interface'

const INITIAL_DATA: IMissionDetail = {
  title: 'Mission',
  missions: [],
  isActive: false,
  missionLink: '',
}

/* eslint-disable-next-line no-unused-vars */
const resetters: (() => void)[] = []

export const createMissionDetailStore: StateCreator<
  IMissionDetailStore
> = (set, _get) => {
  resetters.push(() => set(INITIAL_DATA))

  return {
    ...INITIAL_DATA,
    setMissionActive(link) {
      set({
        isActive: true,
        missionLink: link,
      })
    },
    setMissionOff() {
      set({
        isActive: false,
        missionLink: '',
      })
    },
    setMissionDetail(data) {
      set({
        ...data,
      })
    },
  }
}
