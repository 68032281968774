import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Box from 'components/commons/Box'

interface IWrapper {
  supportdesktop: string
}

export const Wrapper = styled(Drawer)<IWrapper>(
  ({ supportdesktop = 'true', theme }) => ({
    '.MuiDrawer-root': {
      width: '480px',
      background: 'red',
    },
    '.MuiDrawer-paper': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '480px',
      },
      margin: '0px auto',
    },
    '.MuiDrawer-paperAnchorBottom': {
      background: theme.palette.background.secondary,
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '& .MuiPaper-root': supportdesktop === 'true' && {
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        transform: 'translate(-50%, -50%) !important',
        left: '50%',
        top: '50%',
        height: 'fit-content',
        margin: '0px 0px',
        borderRadius: '12px',
        width: '500px',
      },
    },
  }),
)

export const CloseButton = styled(IconButton)(({ theme }) => ({
  svg: {
    fill: theme.palette.tiptip[500],
  },
}))

export const HeaderSection = styled(Stack)({
  marginBottom: '16px',
})

export const FooterSection = styled(Box)({
  marginTop: '16px',
})
