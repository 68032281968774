import { MouseEventHandler } from 'react'
import { useTheme } from '@mui/material'
import appConfig from 'configs/app'
import ActionButton from 'components/commons/Button/Action'
import StateImage from 'components/commons/StateImage'
import Typography from 'components/commons/Typography'

interface ILimitExceedBody {
  backButtonAction?: MouseEventHandler<HTMLButtonElement>
  isButtonHide?: boolean
}
function LimitExceedBody({
  backButtonAction,
  isButtonHide,
}: ILimitExceedBody) {
  const theme = useTheme()
  return (
    <div style={{ textAlign: 'center' }}>
      <StateImage
        src={`${appConfig.assetBaseUrl}/webp/state_features.webp`}
        sx={{
          marginBottom: '24px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '35px',
        }}
      />
      <Typography
        variant="h3"
        color={theme.palette.text.primary}
        sx={{ marginBottom: '10px' }}
      >
        Terlalu Banyak Request
      </Typography>
      <Typography
        variant="normalRegular"
        color={theme.palette.text.secondary}
        sx={{ marginBottom: '24px', display: 'block' }}
      >
        Maaf, sistem terlalu sibuk karena kamu mengirim request
        berkali-kali.
      </Typography>
      {!isButtonHide && (
        <ActionButton
          sx={{
            padding: '12px 24px',
            width: '100%',
          }}
          onClick={backButtonAction}
        >
          Kembali
        </ActionButton>
      )}
    </div>
  )
}

export default LimitExceedBody
