import { StateCreator } from 'zustand'
import { IPromoterStore } from './interface'
import * as service from 'services/promoter'
import { sendLog } from 'helpers/log'

export const createPromoterStore: StateCreator<IPromoterStore> = (
  set,
) => ({
  isLoadingGenerateLink: false,
  isSuccessGenerateLink: false,
  isErrorGenerateLink: false,
  errorGenerateLink: {
    data: { code: '', message: '', data: {}, errors: [] },
    status: 0,
    statusText: '',
  },
  link: '',
  generatePromoterLink: async ({
    product_uid,
    product_type,
    creator_id,
  }) => {
    try {
      set({
        isLoadingGenerateLink: true,
      })
      const res = await service.generatePromoterLink({
        product_uid,
        product_type,
        creator_id,
      })
      if (res?.data?.code === 'SUCCESS') {
        set({
          isLoadingGenerateLink: false,
          isSuccessGenerateLink: true,
          isErrorGenerateLink: false,
          link: res?.data.data.link,
        })
      }
    } catch (error) {
      set({
        isLoadingGenerateLink: false,
        isSuccessGenerateLink: false,
        isErrorGenerateLink: true,
        link: '',
        errorGenerateLink: error.response,
      })
      sendLog(error)
    }
  },
  resetGenerateLink: () => {
    set({
      isLoadingGenerateLink: false,
      isSuccessGenerateLink: false,
      isErrorGenerateLink: false,
      link: '',
      errorGenerateLink: {
        data: { code: '', message: '', data: {}, errors: [] },
        status: 0,
        statusText: '',
      },
    })
  },
  setErrorGenerateLink: (error) => {
    set({
      isErrorGenerateLink: true,
      errorGenerateLink: error.response,
    })
  },
})
