import React, { useEffect, useState } from 'react'
import { useSupportedCountry } from 'hooks/commons/useSupportedCountry'
import { useRouter } from 'next/router'
import Flag from 'react-world-flags'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { useAuthStore } from 'stores/domains/User'
import { Control, useController } from 'react-hook-form'
import SearchIcon from '@mui/icons-material/Search'
import {
  debounce,
  InputAdornment,
  styled,
  TextField,
  useMediaQuery,
} from '@mui/material'
import theme from 'src/theme'
import BottomDrawer from 'components/commons/BottomDrawer'
import Grid from 'components/commons/Grid'
import StateImage from 'components/commons/StateImage'
import Attention from 'components/commons/Attention'
import { getName, getNames, getCode } from 'country-list'
import { useSupportedCountryStore } from 'stores/commons'
import { useTranslation } from 'react-i18next'

const StyledStack = styled(Stack)({
  width: '100%',
  minHeight: '180px',
  overflowY: 'scroll',
  gap: '8px',
  '::-webkit-scrollbar': {
    width: 0,
    background: 'transparent' /* make scrollbar transparent */,
  },
  '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
  'scrollbar-width': 'none',
})

const StyledSelectContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  padding: '0px 12px',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.border.minimal}`,
  background: theme.palette.background.tertiery,
  color: 'black',
  maxHeight: '44px',
  lineHeight: '44px',
  justifyContent: 'space-between',
}))

const StyledDesktopContainer = styled('div')(({ theme }) => ({
  width: '300px',
  zIndex: 1,
  backgroundColor: 'white',
  padding: '8px',
  height: 312,
  borderRadius: `12px`,
  background: theme.palette.secondary.main,
  boxShadow: `0px 0px 8px 0px ${theme.palette.grey[300]}`,
}))

const StyledTextField = styled(TextField)({
  width: '100%',
  display: 'flex',
  height: '44px',
  borderRadius: `100px`,
  background: theme.palette.background.tertiery,
  '& .MuiOutlinedInput-root': {
    height: '44px',
    '& fieldset': {
      border: 'unset',
    },
    '&:hover fieldset': {
      border: 'unset',
    },
    '&.Mui-focused fieldset': {
      border: 'unset',
    },
  },
  input: {
    fontSize: '14px',
  },
})

const StyledCountryWrapper = styled(Stack)({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  padding: '13px 12px',
  gap: `8px`,
  borderRadius: `6px`,
  background: theme.palette.background.secondary,
  justifyContent: 'space-between',
})

interface ISelectCountryCodes {
  name?: string
  control: Control<any, any>
  onChanged?: (countryCode: string) => void
  value?: string
  readOnly?: boolean
  isHideFlag?: boolean
  position?: 'absolute' | 'fixed'
}

const CountrySelect = ({
  name = 'areaCode',
  control,
  onChanged,
  value = '+62',
  readOnly = false,
  isHideFlag = false,
  position = 'absolute',
}: ISelectCountryCodes) => {
  const { t } = useTranslation()
  const { isReady } = useRouter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const { field } = useController({
    name,
    defaultValue: value,
    rules: { required: true },
    control,
  })

  const [countriesData, setCountriesData] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { setCountryData, countryData } = useAuthStore((state) => ({
    setCountryData: state.setCountryData,
    countryData: state.countryData,
  }))

  const { isError } = useSupportedCountryStore((state) => ({
    isError: state.isError,
  }))

  const { refetch: getSupportedCountry, data } = useSupportedCountry()

  const handleSelect = (country) => {
    setIsOpen(false)
    setCountryData(country)
    if (onChanged) {
      onChanged(country.dial_code)
    }
  }

  const filteredOptions = countriesData?.filter(
    (country) =>
      country.dial_code.includes(searchTerm.toLowerCase()) ||
      country?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      country.country_code
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  )

  const getNewArrayMapping = () => {
    const names = getNames()
    const countries = names.map((name) => ({
      name,
      country_code: getCode(name),
    }))
    const newArray = data.map((item1) => {
      const item2 = countries.find(
        (item2) => item2.country_code === item1.country_code,
      )
      return { ...item1, ...item2 }
    })
    setCountriesData(newArray)
  }

  const handleCloseAreaCodeDrawer = () => {
    setSearchTerm('')
    setIsOpen(false)
  }

  const renderCountryList = () => {
    return (
      <>
        {filteredOptions.map((item) => (
          <StyledCountryWrapper
            key={`country-code-container-${item.country_code}`}
            onClick={() => handleSelect(item)}
            direction={`row`}
          >
            <Stack direction={'row'}>
              <Flag
                code={item.country_code}
                style={{ width: 24, marginRight: 10 }}
              />
              <Typography>{getName(item.country_code)}</Typography>
            </Stack>
            <Typography>{item.dial_code}</Typography>
          </StyledCountryWrapper>
        ))}
      </>
    )
  }

  const renderEmpty = () => {
    return (
      <Attention
        title={t('countryCodeNotFound')}
        desc={t('tryOtherKeyword')}
        sx={{
          '& p': {
            fontWeight: `800 !important`,
            fontSize: `18px !important`,
          },
        }}
      >
        <StateImage type="error" />
      </Attention>
    )
  }

  const renderError = () => {
    return (
      <Attention
        padding="20px"
        title={t('internalErrorTitle')}
        desc="Silahkan coba kembali"
        textBtnFirst={t('tryAgain')}
        variantBtnFirst={'filled'}
        handleRedirectBtnFirst={() => {
          getSupportedCountry()
        }}
        sx={{
          '& p': {
            fontWeight: `800 !important`,
            fontSize: `18px !important`,
          },
        }}
      >
        <StateImage type="error" />
      </Attention>
    )
  }

  const renderContent = () => {
    return (
      <Grid
        id="c-users-phone-drawer-grid-container"
        container
        spacing={2}
        gap="16px"
        sx={{
          marginLeft: '0px',
          width: `100%`,
          marginTop: `10px`,
        }}
      >
        <StyledTextField
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          placeholder="Ketik nama negara atau kode negara"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ width: 24, height: 24 }}
              >
                <SearchIcon color={`tertiery`} />
              </InputAdornment>
            ),
          }}
        />
        <StyledStack
          spacing={1}
          sx={{
            maxHeight: isDesktop && '220px',
            height: !isDesktop && '78vh',
          }}
        >
          {!isError
            ? filteredOptions.length > 0
              ? renderCountryList()
              : renderEmpty()
            : renderError()}
        </StyledStack>
      </Grid>
    )
  }

  const renderMobileView = () => {
    return (
      <BottomDrawer
        title="Cari Kode Negaramu"
        content={renderContent()}
        showDrawer={isOpen}
        onCloseAction={debounce(handleCloseAreaCodeDrawer, 250)}
        hideButtonRequest
        hideCancelButton
        disablePadding
        sx={{
          top: '56px',
          '>div': {
            gap: '8px',
          },
        }}
      />
    )
  }

  const renderDesktopView = () => {
    return (
      <StyledDesktopContainer sx={{ position: position }}>
        {renderContent()}
      </StyledDesktopContainer>
    )
  }

  useEffect(() => {
    if (isReady) {
      getSupportedCountry()
    }
  }, [isReady])

  useEffect(() => {
    if (isReady && data?.length > 0) {
      getNewArrayMapping()
    }
  }, [isReady, data])

  return (
    <>
      <input type="hidden" {...field} />
      <Stack
        onClick={() => {
          setSearchTerm('')
          !readOnly && setIsOpen(!isOpen)
        }}
      >
        <StyledSelectContainer direction={'row'} gap={0.5}>
          {!isHideFlag && (
            <Flag
              code={countryData.country_code}
              style={{ width: 16 }}
            />
          )}
          {value ? value : countryData.dial_code}
          <ExpandMoreIcon />
        </StyledSelectContainer>
      </Stack>
      {isOpen && (
        <>{isDesktop ? renderDesktopView() : renderMobileView()}</>
      )}
    </>
  )
}

export default CountrySelect
