import { styled } from '@mui/material'
import CommonStack from 'components/commons/Stack'
import CommonTypography from 'components/commons/Typography'

export const Container = styled(CommonStack)<{
  disallowClick?: boolean
  dark?: boolean
}>(({ theme, disallowClick, dark }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '8px',
  padding: '12px',
  backgroundColor: dark
    ? theme.palette.background.primary
    : theme.palette.background.secondary,
  borderRadius: '8px',
  cursor: disallowClick ? 'default' : 'pointer',
}))

export const Title = styled(CommonTypography)(({ theme }) => ({
  ...theme.typography.mediumBold,
  display: 'flex',
  columnGap: '4px',
  color: theme.palette.text.primary,
}))

export const Subtitle = styled(CommonTypography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}))

export const Caption = styled(Title)<{ main?: boolean }>(
  ({ theme, main = false }) => ({
    ...theme.typography.normalRegular,
    color: main
      ? theme.palette.text.primary
      : theme.palette.text.secondary,
    letterSpacing: '-0.07px',
  }),
)

export const StyledSectionTitle = styled(CommonTypography)(
  ({ theme }) => ({
    ...theme.typography.normalBold,
  }),
)
