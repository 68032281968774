import { ITabNotificationProps } from 'constants/notification'
import {
  StyledLabelContainer,
  StyledLabelTitle,
  StyledMarkAllRead,
} from '../styled'
import redirect from 'helpers/redirect'
import { sendLog } from 'helpers/log'
import { useNotificationStore } from 'stores/commons'
import { useTranslation } from 'react-i18next'

interface IActionButtonNotificationProps
  extends ITabNotificationProps {
  type: 'transaction' | 'information'
}

const ActionButtonNotification = ({
  type,
  positionActionButton,
}: IActionButtonNotificationProps) => {
  const { t } = useTranslation()
  const { readNotification } = useNotificationStore((state) => ({
    readNotification: state.readNotification,
  }))

  const handleReadAllNotification = async () => {
    await readNotification({ bulk: true })
  }

  const handleOnClickSeeMoreNotification = async () => {
    try {
      redirect('/notification')
    } catch (error) {
      sendLog(error)
    }
  }

  return (
    <StyledLabelContainer
      sx={(theme) => ({
        background:
          positionActionButton === 'top'
            ? theme.palette.background.primary
            : theme.palette.background.tertiery,
      })}
    >
      {positionActionButton === 'top' && (
        <StyledLabelTitle>
          {type === 'transaction' ? 'Transaksi' : 'Informasi'} Terbaru
        </StyledLabelTitle>
      )}
      <StyledMarkAllRead
        id={`b-mark-all-as-read-${type}-notification`}
        onClick={handleReadAllNotification}
        buttonVariant="nude"
      >
        Tandai semua dibaca
      </StyledMarkAllRead>
      {positionActionButton === 'bottom' && (
        <StyledMarkAllRead
          onClick={handleOnClickSeeMoreNotification}
          id={`b-see-more-notification`}
          buttonVariant="nude"
        >
          {t('filterSearchBottomSheetShowMore')}
        </StyledMarkAllRead>
      )}
    </StyledLabelContainer>
  )
}

export default ActionButtonNotification
