import { action, thunk } from 'easy-peasy'
import ICreatorRatingModel from 'stores/domains/Creator/Rating/interface'

const CreatorRatingModel: ICreatorRatingModel = {
  isLoadingRating: true,
  isError: false,
  creatorRatingData: {
    rating_flag: false,
    rating_percentage: 0,
    rating_threshold: 0,
  },
  errorMessage: '',

  setLoading: action((state, payload) => {
    state.isLoadingRating = payload
  }),

  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoadingRating = false
  }),

  resetCreatorRating: action((state) => {
    state.isLoadingRating = true
    state.isError = false
    state.creatorRatingData = {
      rating_flag: false,
      rating_percentage: 0,
      rating_threshold: 0,
    }
    state.errorMessage = ''
  }),

  setCreatorRating: action((state, payload) => {
    state.creatorRatingData = payload
  }),

  getCreatorRating: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `rating/guest/v1/creator/${payload.username}/overall`,
          method: 'GET',
        })
        actions.setLoading(false)
        actions.setCreatorRating(response.data?.data)
      } catch (error) {
        actions.setError(error.message)
      }
    },
  ),

  error: action((state, payload) => {
    state.isLoadingRating = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CreatorRatingModel
