import { trackEvent, getStateFromSource } from 'helpers/analytics'

export default function EventTracker(
  eventName: string,
  data?,
  additionalProperties?: {},
) {
  const {
    pathname,
    states,
    eventDetail,
    eventUserDetail,
    contentCommunityInfo,
    saleTimeLeft,
    productEventDetail,
  } = data
  const state_name =
    (eventName === 'purchase_eticket_confirm' ||
    eventName === 'purchase_eticket_completed'
      ? 'ETICKET_ADDITIONAL_FORM'
      : getStateFromSource(pathname)) || ''
  const creator_category_id =
    states.creatorProfile?.creatorProfileData?.category?.id || ''
  const creator_category =
    states.creatorProfile?.creatorProfileData?.category?.name || ''
  const creator_user_id =
    states.creatorProfile?.creatorProfileData?.creator
      ?.creator_user_id || ''
  const creator_name =
    states.creatorProfile?.creatorProfileData?.creator?.name || ''
  const creator_id =
    states.creatorProfile?.creatorProfileData?.creator?.creator_id ||
    ''
  const event_id = eventDetail?.product_uid || ''
  const event_title = eventDetail?.title || ''
  const event_type_id = eventDetail?.type?.id || ''
  const event_type_name = eventDetail?.type?.name.toLowerCase() || ''
  const event_num_sold = eventDetail?.stats?.total_sales || ''
  const event_promoter_commission = eventUserDetail?.pro_com_est || ''
  const event_price_initial = eventUserDetail?.price || ''
  const event_price_final = eventUserDetail?.sale_price || ''
  const event_is_promoted = eventUserDetail?.pro_com_est > 0 || false
  const community_id = contentCommunityInfo?.mask_id || ''
  const community_name = contentCommunityInfo?.name || ''
  const community_is_private = contentCommunityInfo?.is_private || ''

  const event_is_seat_selection_set =
    eventUserDetail?.event_summary?.is_seating_required || false
  const event_is_custom_form_set =
    eventUserDetail?.event_summary?.additional_form?.id !== '' ||
    false
  const event_is_first_purchase =
    eventUserDetail?.event_summary?.status_order === '' || false
  const event_has_teaser = eventDetail?.teaser !== '' || ''
  const event_poster_image_path = eventDetail?.cover || ''
  const event_created_date = eventDetail?.created_at || ''
  const event_sale_end_date =
    eventUserDetail?.event_summary?.sales_ended_at || ''
  const event_sale_countdown_hrs = saleTimeLeft || ''
  const event_start_date =
    eventUserDetail?.event_summary?.event_started_at || ''
  const event_end_date =
    eventUserDetail?.event_summary?.event_ended_at || ''
  const event_location_city =
    eventUserDetail?.event_summary?.location_city || ''
  const event_location_name =
    eventUserDetail?.event_summary?.location_venue || ''
  const event_gmap_url = eventUserDetail?.event_summary?.map_url || ''
  const event_max_quota = eventUserDetail?.event_summary?.quota || ''
  const event_status = eventUserDetail?.event_summary?.status || ''
  const event_is_multiple_option_selected =
    productEventDetail?.ticket_options?.length > 1

  const baseEventProperties = {
    state_name,
    event_is_promoted,
    event_promoter_commission,
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    event_title,
    event_id,
    event_type_id,
    event_type_name,
    event_num_sold,
    event_price_initial,
    event_price_final,
    event_is_seat_selection_set,
    event_is_custom_form_set,
    event_is_first_purchase,
    event_has_teaser,
    event_poster_image_path,
    event_created_date,
    event_sale_end_date,
    event_sale_countdown_hrs,
    event_start_date,
    event_end_date,
    event_location_city,
    event_location_name,
    event_gmap_url,
    event_max_quota,
    event_status,
    event_is_multiple_option_selected,
  }

  const communityProperties = {
    community_id,
    community_name,
    community_is_private,
  }
  const eventProperties = {
    // Promoter
    click_promote_event: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    trigger_close_promote_event: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    click_content_hub: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    trigger_dont_show_modal: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    cancel_promote_event: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    submit_promote_event: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    click_community_profile: {
      ...baseEventProperties,
      ...additionalProperties,
    },
    purchase_eticket_confirm: {
      ...baseEventProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    purchase_eticket_completed: {
      ...baseEventProperties,
      ...communityProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
