import { Box, BoxProps, useTheme } from '@mui/material'

export function Circle({ children, sx, ...rest }: BoxProps) {
  const theme = useTheme()
  return (
    <Box
      component="span"
      color="white"
      sx={{
        padding: '2px',
        background: `linear-gradient(180deg, ${theme.palette.purple[100]} 0%, #787DFC 100%)`,
        borderRadius: '50%',
        fontSize: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        svg: {
          width: '10px',
          height: '10px',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
