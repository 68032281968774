import { StateCreator } from 'zustand'
import IMultipleLanguageModel from './interface'
import appConfig from 'configs/app'

export const createMultipleLanguageStore: StateCreator<
  IMultipleLanguageModel
> = (set) => ({
  currentLanguage: {
    id: appConfig.defaultLang,
    label: 'Indonesia',
    flag: `https://flagcdn.com/w20/id.png`,
  },
  currentLanguageCode: appConfig.defaultLang,
  showMultipleLanguageDrawer: false,
  setLanguage: async (val) => {
    set({ currentLanguage: val, currentLanguageCode: val.id })
  },
  setShowMultipleLanguageDrawer: async (status) => {
    set({ showMultipleLanguageDrawer: status })
  },
})
