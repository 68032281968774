import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'
import ICreatorFeedsModel from 'stores/domains/Creator/Feeds/interface'

const CreatorFeedsModel: ICreatorFeedsModel = {
  isLoadingFeeds: true,
  isError: false,
  creatorFeedsData: [],
  errorMessage: '',
  setLoadingFeeds: action((state, payload) => {
    state.isLoadingFeeds = payload
  }),
  setCreatorFeeds: action((state, payload) => {
    state.creatorFeedsData = payload
  }),
  getCreatorFeeds: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoadingFeeds(true)
      let idToken = getCookie(authConfig.idTokenName)
      let url = ''
      if (!isEmpty(idToken)) {
        url = '/social/v1/feeds/' + payload.username
      } else {
        url = '/social/guest/v1/feeds/' + payload.username
        idToken = ''
      }
      const { apiClient } = injections
      const response = await apiClient({
        url: url,
        method: 'GET',
        token: idToken,
      })
      if (response?.data?.data.length) {
        actions.setCreatorFeeds(response.data?.data)
        actions.setLoadingFeeds(false)
      }
    } catch (error) {
      actions.error(error.response)
    }
  }),
  error: action((state, payload) => {
    state.isLoadingFeeds = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CreatorFeedsModel
