import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import appConfig from './src/configs/app'
import { getLocalStorage } from 'helpers/local-storage'

const supportedLanguages = ['id', 'en'] // Supported languages

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    fallbackLng: appConfig.defaultLang, // Default language
    lng: getLocalStorage('i18nextLng') || appConfig.defaultLang, // Language used on initialization
    supportedLngs: supportedLanguages, // Supported languages
    detection: {
      order: ['localStorage', 'navigator'], // Prioritize localStorage
      caches: ['localStorage'], // Save the language in localStorage
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    resources: {
      id: {
        common: require('public/locales/id/common.json'),
      },
      en: {
        common: require('public/locales/en/common.json'),
      },
    },
    ns: ['common'], // Namespace(s)
    defaultNS: 'common', // Default namespace
    react: {
      useSuspense: false,
    },
    interpolation: {
      prefix: '{', // Custom prefix for interpolation
      suffix: '}', // Custom suffix for interpolation
      escapeValue: false, // React already does escaping
    },
  })

export { supportedLanguages }
export default i18n
