export function transformYotubeEmbedURL(link: string) {
  const youtubeTransformerRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|v\/|e\/|embed\/|shorts\/|live\/|oembed\?url=.+v%3D|attribution_link\?.+v%3D)|youtu\.be\/)([\w-]{11})/i

  return link.replace(
    youtubeTransformerRegex,
    'https://www.youtube.com/embed/$1',
  )
}

export function urlify(link: string) {
  const patternTiptipUrls =
    /(https?:\/\/([\w\d]+\.)?([go\.]+)?tiptip\.id.*)|(www\.([go\.]+)?tiptip\.id)|(([go\.]+)?tiptip\.id)/g
  const urlMediaRegex =
    /^(https?:\/\/|www:\/\/)[^\s/$.?#].[^\s]*(\.(jpe?g|png|mp4|mov)$|\/embed\/[\w-]+)/i

  const url = transformYotubeEmbedURL(link)
  const isMediaUrl = urlMediaRegex.test(url)

  if (!isMediaUrl) {
    const isInternal = patternTiptipUrls.test(url)

    return { url, internal: isInternal, media: false }
  }

  return { url, internal: false, media: true }
}
