import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { isGuestMode } from 'helpers/cookie'
import { getPromoterToken } from 'helpers/promoter'
import { libraryConst } from 'constants/common'
import { sendLog } from 'helpers/log'
import { setCanonicalUrl } from 'helpers/url'

export const getCreatorSubscriptionList = async (creatorId, pt) => {
  const url = isGuestMode()
    ? `/subscription/guest/v3/package/${creatorId}/list`
    : `/subscription/v3/package/${creatorId}/list`

  const response = await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      pt: pt,
    },
  })
  return response?.data
}

export const getIsUserSubscribedToCreator = async (
  username: string,
  token: string,
) => {
  const response = await apiClient({
    url: `/subscription/v1/subscriber/${username}`,
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getSubscriptionHistory = async (payload: {
  limit: number
  page: number
}) => {
  try {
    const response = await apiClient({
      url: `/subscription/v1/subscribed`,
      method: 'GET',
      token: getIdToken(),
      params: payload,
    })
    return {
      data: response.data.data,
      metadata: response.data._metadata,
    }
  } catch (error) {
    throw error
  }
}

export const fetchSubscriptionHistory = async ({
  pageParam,
  ...payload
}) => {
  try {
    const res = await apiClient({
      url: `/subscription/v2/subscribed`,
      method: 'GET',
      token: getIdToken(),
      params: {
        limit: libraryConst.defaultLimit,
        cursor: pageParam,
        ...payload,
      },
    })

    return res.data
  } catch (error) {
    sendLog(error)
  }
}

export const subscriptionPayment = async (payload) => {
  try {
    const response = await apiClient({
      url: `/subscription/v1/payment/${payload.subscriptionDurationId}`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': payload.idempotentKey },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const subscriptionPaymentCheckout = async (payload) => {
  try {
    const response = await apiClient({
      url: '/subscription/v1/payment/checkout',
      method: 'POST',
      data: {
        invoice_id: payload.invoice_id,
        promoter_token: getPromoterToken(payload.username),
        redirection_link: getIdToken() && setCanonicalUrl(),
      },
      headers: { 'Idempotent-Key': payload.idempotentKey },
      token: getIdToken(),
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}
