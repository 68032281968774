import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'
import { getListScreeningQuestion } from 'services/social'

export const useScreeningQuestionCommunity = (id: string) => {
  return useQuery({
    queryKey: ['screening-question-community', id],
    queryFn: async () => {
      try {
        const response = await getListScreeningQuestion(id)
        return get(response, 'data.data', {})
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
