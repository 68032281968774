import { action, thunk } from 'easy-peasy'
import IBrowseCreatorModel from 'stores/domains/Browse/Creator/interface'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'
import { getIdToken } from 'helpers/auth'

const BrowseCreatorModel: IBrowseCreatorModel = {
  isLoading: true,
  isError: false,
  creatorData: {
    creatorList: [],
    loadMore: false,
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
    state.isLoading = false
  }),
  setBrowseCreator: action((state, response) => {
    state.creatorData = {
      creatorList: get(response, 'data.data', []),
      loadMore: get(response, 'data._metadata.load_more', false),
      currentPage: get(response, 'data._metadata.page', 1),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  getBrowseCreator: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        let idToken = getIdToken()
        let url = ''
        if (!isEmpty(idToken)) {
          url = `/user/v1/creator/search?${payload.params.category_id}`
        } else {
          url = `/user/guest/v1/creator/search?${payload.params.category_id}`
          idToken = ''
        }
        const { apiClient } = injections
        const response = await apiClient({
          url: url,
          method: 'GET',
          token: idToken,
          params: {
            q: payload.params.key,
            page: payload.params.page,
            limit: payload.params.limit,
          },
        })
        if (payload.params.page > 1) {
          actions.setDataLoadmoreCreator(response)
        } else {
          actions.setBrowseCreator(response)
        }
        actions.setLoading(false)
        return response
      } catch (error) {
        if (error.response?.data?.code === 'NOT_FOUND') {
          actions.setBrowseCreator([])
          actions.setLoading(false)
        } else {
          actions.error(error.message)
        }
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setDataLoadmoreCreator: action((state, response) => {
    state.creatorData = {
      creatorList: [
        ...state.creatorData.creatorList,
        ...get(response, 'data.data', []),
      ],
      loadMore: get(response, 'data._metadata.load_more', false),
    }
    state.errorMessage = ''
    state.isError = false
  }),
  loadMoreCreator: thunk(async (actions, payload, { injections }) => {
    try {
      let idToken = getIdToken()
      let url = ''

      if (!isEmpty(idToken)) {
        url = `/user/v1/creator/search?${payload.params.category_id}`
      } else {
        url = `/user/guest/v1/creator/search?${payload.params.category_id}`
        idToken = ''
      }
      const { apiClient } = injections
      const response = await apiClient({
        url: url,
        method: 'GET',
        token: idToken,
        params: {
          q: payload.params.key,
          page: payload.params.page,
          limit: payload.params.limit,
        },
      })
      actions.setDataLoadmoreCreator(response)
    } catch (error) {
      if (error.response?.data?.code === 'NOT_FOUND') {
        actions.setError(error.response?.message)
      }
    }
  }),
  editFollowing: action((state, payload) => {
    const index = state.creatorData.creatorList.findIndex(
      (creator) => creator.username === payload.creator_username,
    )
    state.creatorData.creatorList[index].follower.is_following =
      payload.is_following
    state.creatorData.creatorList[index].follower.total =
      payload.follower_number
  }),
}

export default BrowseCreatorModel
