import { action } from 'easy-peasy'
import { original } from 'immer'
import filter from 'lodash/filter'
import map from 'lodash/map'
import some from 'lodash/some'
import orderBy from 'lodash/orderBy'
import IRemoteUserModel from 'stores/commons/models/RemoteUsers/interface'

const RemoteUser: IRemoteUserModel = {
  users: [],
  addUser: action((state, payload) => {
    if (!some(original(state.users), ['uid', payload.uid])) {
      state.users = [
        ...state.users,
        {
          uid: payload.uid,
          rtc_info: payload.rtc_info,
        },
      ]
    }
  }),
  clearUsers: action((state) => {
    state.users = []
  }),
  removeUserByUsername: action((state, username) => {
    const newUsers = filter(
      state.users,
      (user) => user.username !== username,
    )

    state.users = newUsers
  }),
  removeUserByUID: action((state, uid) => {
    const newUsers = filter(state.users, (user) => user.uid !== uid)

    state.users = newUsers
  }),
  updateUser: action((state, payload) => {
    const currentUser = filter(
      original(state.users),
      (user) => user.uid === payload.uid,
    )
    state.users = [
      ...filter(
        original(state.users),
        (user) => user.uid !== payload.uid,
      ),
      {
        ...payload,
        rtc_info: currentUser.rtc_info,
      },
    ]
  }),
  updateUserRTC: action((state, payload) => {
    const newUsers = map(original(state.users), (user) => {
      if (user.uid === payload.uid) {
        return {
          ...user,
          rtc_info:
            payload.audioTrack || payload.videoTrack
              ? payload
              : undefined,
        }
      } else {
        return user
      }
    })
    state.users = newUsers
  }),
  orderByTippingCoin: action((state) => {
    const newUsers = orderBy(state.users, ['tipping_coin'], ['desc'])
    state.users = newUsers
  }),
}

export default RemoteUser
