import { action } from 'easy-peasy'
import IPreviewSessionModel from 'stores/commons/models/PreviewSession/interface'

const PreviewSessionModel: IPreviewSessionModel = {
  inputMicList: [],
  inputCamList: [],
  selectedInputMic: '',
  selectedInputCam: '',
  sourceLocaltrackCam: undefined,
  sourceLocaltrackMic: undefined,
  setSourceLocaltrackCam: action((state, payload) => {
    state.sourceLocaltrackCam = payload
  }),
  setSourceLocaltrackMic: action((state, payload) => {
    state.sourceLocaltrackMic = payload
  }),
  setInputMicList: action((state, payload) => {
    state.inputMicList = payload
  }),
  setInputCamList: action((state, payload) => {
    state.inputCamList = payload
  }),
  setSelectedInputMic: action((state, payload) => {
    state.selectedInputMic = payload
  }),
  setSelectedInputCam: action((state, payload) => {
    state.selectedInputCam = payload
  }),
}

export default PreviewSessionModel
