import { action, thunk } from 'easy-peasy'
import { isGuestMode } from 'helpers/cookie'
import ICoinBalanceModel from 'stores/domains/Wallet/models/CoinBalance/interface'
import { setDefaultProperties } from 'helpers/analytics'
import { getIdToken } from 'helpers/auth'

const CoinBalanceModel: ICoinBalanceModel = {
  isLoading: false,
  isError: false,
  CoinBalance: 0,
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  setCoinBalance: action((state, payload) => {
    state.CoinBalance = payload
    setDefaultProperties({ coin_balance: payload })
  }),

  getCoinBalance: thunk(async (actions, _payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = !isGuestMode()
        ? await apiClient({
            url: '/wallet/v1/coin/balance',
            method: 'GET',
            token: getIdToken(),
          })
        : { data: { data: { balance: 0 } } }
      actions.setCoinBalance(response.data?.data?.balance)
      actions.setLoading(false)
    } catch (err) {
      actions.error(err.message)
    }
  }),

  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CoinBalanceModel
