import { Menu, useTheme } from '@mui/material'

import { MoreMenuItem } from './MoreMenuItem'

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded'

interface MoreMenuProps {
  anchorEl?: HTMLElement
  open?: boolean
  isBlocked?: boolean
  onClose?: Function
  onClickProfile?: Function
  onClickReport?: Function
  onClickBlockUser?: Function
}

export default function MoreMenu({
  anchorEl,
  isBlocked,
  onClickBlockUser,
  onClickProfile,
  onClickReport,
  onClose,
  open,
}: MoreMenuProps) {
  const theme = useTheme()

  const handleClose = () => {
    if (onClose) onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="more-menu"
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'b-users-message-header-more',
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        style: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.primary,
          boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
          borderRadius: '8px',
        },
      }}
    >
      {onClickProfile && (
        <MoreMenuItem
          title="View User Profile"
          onClick={() => onClickProfile()}
          icon={AccountCircleRoundedIcon}
        />
      )}
      {onClickBlockUser && (
        <MoreMenuItem
          icon={isBlocked ? LockOpenRoundedIcon : BlockRoundedIcon}
          onClick={() => onClickBlockUser()}
          title={`${isBlocked ? 'Unblock' : 'Block'} User`}
        />
      )}
      {onClickReport && (
        <MoreMenuItem
          title="Report User"
          onClick={() => onClickReport()}
          icon={ReportGmailerrorredRoundedIcon}
        />
      )}
    </Menu>
  )
}
