import { StatusJoin } from 'src/@types/social'
import { SocialType } from 'src/@types/user'

export const socialMediaRegex: Record<SocialType, RegExp> = {
  instagram:
    /^((http(s)?:\/\/)?(www\.)?(m\.)?)+(instagram\.com\/)\S.+\S$/,
  facebook:
    /^((http(s)?:\/\/)?(www\.)?(m\.)?)+(facebook\.com\/)\S.+\S$/,
  linkedin:
    /^((http(s)?:\/\/)?(www\.)?(m\.)?)+(linkedin\.com\/)\S.+\S$/,
  tiktok: /^((http(s)?:\/\/)?(www\.)?(m\.)?)+(tiktok\.com\/)\S.+\S$/,
  twitter:
    /^((http(s)?:\/\/)?(www\.)?(mobile\.)?)+(twitter\.com\/)\S.+\S$/,
  youtube:
    /^((http(s)?:\/\/)?(www\.)?(m\.)?)+(youtube\.com\/)\S.+\S$/,
}

export const STATUS_JOIN: Record<number, StatusJoin> = {
  1: 'not_join',
  2: 'in_progress',
  3: 'deny',
  4: 'accept',
}
