import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'

const StyledContainer = styled('div')({
  width: '90vw',
  marginLeft: '5vw',
  '@media (min-width:480px)': {
    width: '440px',
    marginLeft: '20px',
  },
  marginTop: '32px',
  marginBottom: '24px',
  height: '60px',
  background: 'rgba(31, 29, 43, 0.75)',
  borderRadius: '8px',
})

const StyledSkeleton = styled(Skeleton)({
  borderRadius: '8px',
  backgroundColor: 'lightgrey',
  marginTop: '8px',
  width: '100%',
  height: '100%',
})

function CoinPackageSkeleton() {
  return (
    <StyledContainer>
      <StyledSkeleton variant="rectangular" />
    </StyledContainer>
  )
}

export default CoinPackageSkeleton
