import ShortUniqueId from 'short-unique-id'

function generateShortUuid(length: number = 10): string {
  const options = {
    length: length,
    debug: false,
  }
  const uid = new ShortUniqueId(options)
  return uid()
}

export { generateShortUuid }
