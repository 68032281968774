import { useState } from 'react'
import { FormHelperText, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import Stack from 'components/commons/Stack'

import ErrorIcon from '@mui/icons-material/Error'
import { ICustomField } from '../interface'

// field validation function
function validateField({
  fieldValidation,
  fieldValue,
  fieldLabel,
}): string | null {
  const currentSelectedDate = dayjs(fieldValue)
  if (
    fieldValidation.required &&
    (!fieldValue.length || !currentSelectedDate)
  ) {
    return `${fieldLabel} wajib diisi`
  }

  if (
    currentSelectedDate &&
    dayjs(currentSelectedDate).isBefore(
      dayjs(
        getMinDate({
          daysBefore: fieldValidation.days_before,
          absoluteDate: fieldValidation.min_date,
        }),
      ),
      'date',
    )
  ) {
    const isMinusDaysBefore = fieldValidation.days_before < 0
    const daysBefore = isMinusDaysBefore
      ? fieldValidation.days_before * -1
      : fieldValidation.days_before
    if (daysBefore > 365)
      return `Pilih ${fieldLabel} ${
        isMinusDaysBefore ? 'maksimum' : 'minimum'
      } ${Math.floor(daysBefore / 365)} tahun ke ${
        isMinusDaysBefore ? 'belakang' : 'depan'
      }`
    else if (daysBefore > 30)
      return `Pilih ${fieldLabel} ${
        isMinusDaysBefore ? 'maksimum' : 'minimum'
      } ${Math.floor(daysBefore / 30)} bulan ke ${
        isMinusDaysBefore ? 'belakang' : 'depan'
      }`
    else
      return `Pilih ${fieldLabel} ${
        isMinusDaysBefore ? 'maksimum' : 'minimum'
      } ${daysBefore} hari ke ${
        isMinusDaysBefore ? 'belakang' : 'depan'
      }`
  }

  if (
    currentSelectedDate &&
    dayjs(currentSelectedDate).isAfter(
      dayjs(
        getMaxDate({
          daysAfter: fieldValidation.days_after,
          absoluteDate: fieldValidation.max_date,
        }),
      ),
      'date',
    )
  ) {
    const isMinusDaysAfter = fieldValidation.days_after < 0
    const daysAfter = isMinusDaysAfter
      ? fieldValidation.days_after * -1
      : fieldValidation.days_after
    if (daysAfter > 365)
      return `Pilih ${fieldLabel} ${
        isMinusDaysAfter ? 'minimum' : 'maksimum'
      } ${Math.floor(daysAfter / 365)} tahun ke ${
        isMinusDaysAfter ? 'belakang' : 'depan'
      }`
    else if (daysAfter > 30)
      return `Pilih ${fieldLabel} ${
        isMinusDaysAfter ? 'minimum' : 'maksimum'
      } ${Math.floor(daysAfter / 30)} bulan ke ${
        isMinusDaysAfter ? 'belakang' : 'depan'
      }`
    else
      return `Pilih ${fieldLabel} ${
        isMinusDaysAfter ? 'minimum' : 'maksimum'
      } ${daysAfter} hari ke ${
        isMinusDaysAfter ? 'belakang' : 'depan'
      }`
  }
  return null
}

function getMinDate({ daysBefore, absoluteDate }) {
  if (absoluteDate) {
    return dayjs(absoluteDate).valueOf()
  }

  if (daysBefore !== null || daysBefore !== undefined) {
    return dayjs().subtract(-daysBefore, 'day').valueOf()
  }

  return null
}

function getMaxDate({ daysAfter, absoluteDate }) {
  if (absoluteDate) {
    return dayjs(absoluteDate).valueOf()
  }

  if (daysAfter !== null || daysAfter !== undefined) {
    return dayjs().add(daysAfter, 'day').valueOf()
  }

  return null
}

function getSelectedDate({ selectedDate }) {
  if (!selectedDate) {
    return null
  }

  let currentDate = dayjs(selectedDate)

  return currentDate.format('YYYY-MM-DD')
}

// main component function
export default function CustomDateField({ ...props }: ICustomField) {
  const { t } = useTranslation()

  const [isTouched, setIsTouched] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const theme = useTheme()

  const validationResult = validateField({
    fieldLabel: props.label,
    fieldValidation: props.validation,
    fieldValue: props.value,
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={props.disabled}
        minDate={getMinDate({
          daysBefore: props.validation?.days_before,
          absoluteDate: props.validation?.min_date,
        })}
        open={isOpen}
        maxDate={getMaxDate({
          daysAfter: props.validation?.days_after,
          absoluteDate: props.validation?.max_date,
        })}
        onClose={() => {
          setIsOpen(false)
        }}
        slotProps={{
          textField: {
            placeholder: t(props.placeholder),
            onBlur: () => {
              setIsTouched(true)
            },
            onClick: (e) => {
              setIsOpen(true)
              e.preventDefault()
            },
            sx: {
              color: theme.palette.text.primary,
              borderRadius: '6px',
              backgroundColor: theme.palette.background.tertiery,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                ':hover': {
                  border: 'none',
                },
              },
              '& .MuiOutlinedInput-input': {
                ...theme.typography.normalRegular,
              },
              svg: {
                color: theme.palette.text.secondary,
                fontSize: '16px',
              },
            },
          },
        }}
        value={props.value === '' ? null : props.value}
        onChange={(e) => {
          setIsTouched(true)
          try {
            const selectedDate = getSelectedDate({
              selectedDate: e,
            })

            if (!selectedDate) throw 'no date selected'

            props.onValueChange(
              selectedDate,
              validateField({
                fieldValidation: props.validation,
                fieldValue: new Date(e).toISOString(),
                fieldLabel: props.label,
              }) === null,
            )
          } catch (err) {
            props.onValueChange('', false)
          }
        }}
      />
      {isTouched && validationResult && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          <Stack direction={'row'} alignItems={'center'}>
            <ErrorIcon
              sx={{ fontSize: 'inherit', marginRight: '4px' }}
            />
            {validationResult}
          </Stack>
        </FormHelperText>
      )}
    </LocalizationProvider>
  )
}
