const config = {
  aws_project_region:
    process.env.NEXT_PUBLIC_AWS_COGNITO_PROJECT_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
  aws_user_pools_id:
    process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.NEXT_PUBLIC_AWS_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'openid', 'phone', 'profile'],
    redirectSignIn:
      process.env.NEXT_PUBLIC_AWS_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut:
      process.env.NEXT_PUBLIC_AWS_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    responseType:
      process.env.NEXT_PUBLIC_AWS_COGNITO_OAUTH_RESPONSE_TYPE,
  },
  federationTarget:
    process.env.NEXT_PUBLIC_AWS_COGNITO_FEDERATION_TARGET,
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration:
    process.env.NEXT_PUBLIC_AWS_COGNITO_MFA_CONFIGURATION,
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength:
      process.env.NEXT_PUBLIC_AWS_COGNITO_PASSWORD_MIN_LENGTH,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  Auth: {
    production: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production',
    region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOLS_ID,
    userPoolWebClientId:
      process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
  },
}

export default config
