import { MouseEventHandler } from 'react'

export interface ITabNotificationProps {
  positionActionButton: 'top' | 'bottom'
  active?: boolean
  isHeader?: boolean
}

export interface INotificationProps extends ITabNotificationProps {
  onClickEmptyNotification: MouseEventHandler<HTMLButtonElement>
}

export interface IReadNotificationParams {
  notification_id?: number
  bulk: boolean
}

export interface IResponseNotification {
  code: string
  _metadata: IMetadataNotification
  data: Array<NotificationItem>
}

interface IMetadataNotification {
  limit: number
  load_more: boolean
  page: number
  total_unread: number
}

export interface NotificationItem {
  notification_id: number
  title: string
  message: string
  image_url: string
  redirect_link: string
  category_type: number
  icon_type: number
  read: boolean
  sent_at: string
}

export const notificationConst = {
  category_type: {
    1: 'content',
    2: 'session',
    3: 'event',
    4: 'subscription',
  },
}
