import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function CartTracker(
  eventName: string,
  data?,
  additionalProperties?,
) {
  const {
    pathname,
    states,
    query,
    contentDetail,
    contentUserDetail,
    dataCart,
  } = data

  const state_name = getStateFromSource(pathname)
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  let product = dataCart?.products?.find(
    (o) => o?.product_uid === additionalProperties?.product_uid,
  )

  const creator_category_id =
    states?.creatorProfile?.creatorProfileData?.category?.id ||
    product?.creator?.category_id
  const creator_category =
    states?.creatorProfile?.creatorProfileData?.category?.name ||
    product?.creator?.category_name
  const creator_user_id =
    states?.creatorProfile?.creatorProfileData?.creator
      ?.creator_user_id || product?.creator?.user_id
  const creator_name =
    states?.creatorProfile?.creatorProfileData?.creator?.name ||
    product?.creator?.name
  const creator_id =
    states?.creatorProfile?.creatorProfileData?.creator?.creator_id ||
    product?.creator?.user_id
  const content_title = contentDetail?.title || product?.title
  const content_id =
    contentDetail?.product_uid || product?.product_uid
  const content_price_initial =
    contentUserDetail?.price || product?.price
  const content_price_final =
    contentUserDetail?.sale_price || product?.sale_price
  const content_type =
    contentDetail?.type?.name?.toLowerCase() ||
    product?.metadata?.type?.name?.toLowerCase()

  const total_item = dataCart?.total_item || 0

  const productProperties = {
    creator_category_id,
    creator_category,
    creator_user_id,
    creator_name,
    creator_id,
    content_title,
    content_id,
    content_price_initial,
    content_price_final,
    content_type,
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    click_cart: {
      state_name,
      ...additionalProperties,
    },
    click_add_content_to_cart: {
      state_name,
      ...productProperties,
      ...additionalProperties,
    },
    visit_cart_detail: {
      state_name,
      ...sourceProperties,
      ...additionalProperties,
    },
    back_form_cart: {
      state_name,
      total_item,
    },
    delete_item_from_cart: {
      state_name,
      ...productProperties,
      ...additionalProperties,
    },
    click_purchase_cart_item: {
      ...additionalProperties,
    },
    click_browse_search: {
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
