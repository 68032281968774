import { AlertColor } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { SxProps } from '@mui/system'
import { styled } from '@mui/material/styles'

import { Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { useTheme } from '@mui/material/styles'
import Button from '../Button'
import Stack from '../Stack'
import Typography from '../Typography'

export interface SnackbarOrigin {
  vertical: 'top' | 'bottom'
  horizontal: 'left' | 'center' | 'right'
}

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-root': {
    position: 'fixed',
    width: '88%',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '55px',
  },
  '@media (min-width:480px)': {
    '&.MuiSnackbar-root': {
      position: 'fixed',
      bottom: '55px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '432px',
    },
  },
})

interface IAlertProps {
  open: boolean
  autoHideDuration?: number
  severity: AlertColor
  title?: string
  desc?: string
  onClose?: any
  sx?: SxProps
  handleAction?: Function
  actionBtn?: string
  closeBtn?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  anchorOrigin?: SnackbarOrigin
  addtionalComponent?: JSX.Element | any
}

function SnackbarNotification({
  autoHideDuration = 2000,
  open = false,
  onClose = () => {},
  sx,
  severity = 'success',
  title,
  desc,
  handleAction,
  actionBtn = '',
  closeBtn = true,
  variant = 'outlined',
  anchorOrigin,
  addtionalComponent,
}: IAlertProps) {
  const theme = useTheme()

  const action = (
    <Stack direction={'row'}>
      {actionBtn !== '' && (
        <Button
          buttonVariant="outlined"
          onClick={() => handleAction()}
        >
          {actionBtn}
        </Button>
      )}
      {closeBtn && (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  )

  const iconMapping = {
    success: (
      <CheckCircleIcon
        sx={{
          color:
            variant === 'filled'
              ? 'white'
              : theme.palette.success.main,
        }}
      />
    ),
    info: (
      <InfoIcon
        sx={{
          color:
            variant === 'filled' ? 'white' : theme.palette.info.main,
        }}
      />
    ),
    error: (
      <ErrorIcon
        sx={{
          color:
            variant === 'filled' ? 'white' : theme.palette.error.main,
        }}
      />
    ),
    warning: (
      <WarningIcon
        sx={{
          fontSize: '20px',
          color:
            variant === 'filled'
              ? 'white'
              : theme.palette.orange[500],
        }}
      />
    ),
  }

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={sx}
      anchorOrigin={anchorOrigin}
    >
      <Alert
        severity={severity}
        variant={variant}
        iconMapping={iconMapping}
        sx={{ alignItems: 'center', background: 'white' }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction={'column'}>
            {title !== '' && <AlertTitle>{title}</AlertTitle>}
            <Typography>
              {desc}
              {` `}
              {addtionalComponent}
            </Typography>
          </Stack>
          {action}
        </Stack>
      </Alert>
    </StyledSnackbar>
  )
}

export default SnackbarNotification
