import { useFormContext, Controller } from 'react-hook-form'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
} from '@mui/material'

type IProps = {
  name: string
  options: {
    label: string
    value: any
  }[]
  maxLength?: number
}

type Props = IProps & Omit<FormControlLabelProps, 'control' | 'label'>

export default function RHFCheckbox({
  name,
  options,
  maxLength,
  ...other
}: Props) {
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message) return <FormHelperText>{message}</FormHelperText>
  }

  return (
    <FormControl variant="standard" fullWidth>
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) => {
          const onSelected = (option: string) =>
            field.value.includes(option)
              ? field.value.filter(
                  (value: string) => value !== option,
                )
              : [...field.value, option]

          const isChecked = (value: string) =>
            field.value.includes(value)

          const disabled = maxLength
            ? field.value?.length >= maxLength
            : false

          return (
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={isChecked(option.value)}
                      disabled={!isChecked(option.value) && disabled}
                      onChange={() =>
                        field.onChange(onSelected(option.value))
                      }
                      sx={(theme) => ({
                        color: theme.palette.text.tertiery,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          opacity: '75%',
                        },
                      })}
                    />
                  }
                  label={option.label}
                  sx={(theme) => ({
                    '.MuiFormControlLabel-label': {
                      color: theme.palette.text.primary,
                      '&.Mui-disabled': {
                        color: theme.palette.text.tertiery,
                      },
                    },
                  })}
                  {...other}
                />
              ))}
              {renderErrorText(error?.message)}
            </FormGroup>
          )
        }}
      />
    </FormControl>
  )
}
