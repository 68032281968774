import { useEffect, useState } from 'react'
import { styled, SxProps } from '@mui/material'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

const StyledCountDown = styled(Stack)({
  gap: 3,
})

interface ICountdownTimerProps {
  expiryDate: string
  sx?: SxProps
  withText?: boolean
  withHours?: boolean
  onTimerFinished?: VoidFunction
}
function CountdownTimer({
  expiryDate,
  withText,
  withHours = true,
  onTimerFinished,
  sx,
}: ICountdownTimerProps) {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(
    Math.floor(
      (new Date(expiryDate).getTime() - new Date().getTime()) / 1000,
    ),
  )
  const [isFinished, setFinished] = useState(false)

  const hours = Math.floor(timeLeft / 3600)
  const minutes = withHours
    ? Math.floor((timeLeft % 3600) / 60)
    : Math.floor(timeLeft / 60)
  const seconds = timeLeft % 60

  const hDisplay = hours >= 10 ? hours : '0' + hours
  const mDisplay = minutes >= 10 ? minutes : '0' + minutes
  const sDisplay = seconds >= 10 ? seconds : '0' + seconds

  const updateTimeLeftOnVisibilityChange = () => {
    setTimeLeft(
      Math.floor(
        (new Date(expiryDate).getTime() - new Date().getTime()) /
          1000,
      ),
    )
  }

  useEffect(() => {
    if (timeLeft <= 0) {
      setFinished(true)
      if (onTimerFinished) onTimerFinished()

      return
    }
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  useEffect(() => {
    window.addEventListener(
      'visibilitychange',
      updateTimeLeftOnVisibilityChange,
      true,
    )
    return () => {
      window.removeEventListener(
        'visibilitychange',
        updateTimeLeftOnVisibilityChange,
        true,
      )
    }
  }, [])

  if (!isFinished) {
    if (withText) {
      if (withHours)
        return (
          <StyledCountDown direction={'row'} sx={sx}>
            <Typography>
              {hDisplay} {t('hours')} : {mDisplay} {t('minutes')} :{' '}
              {sDisplay} {t('seconds')}
            </Typography>
          </StyledCountDown>
        )
      else
        return (
          <StyledCountDown direction={'row'} sx={sx}>
            <Typography>
              {mDisplay} {t('minutes')} : {sDisplay} {t('seconds')}
            </Typography>
          </StyledCountDown>
        )
    }
    return (
      <StyledCountDown direction={'row'} sx={sx}>
        {withHours && <Typography>{hDisplay} :</Typography>}
        <Typography>{mDisplay} : </Typography>
        <Typography>{sDisplay}</Typography>
      </StyledCountDown>
    )
  }

  return (
    <StyledCountDown direction={'row'} sx={sx}>
      {withHours ? (
        <Typography>00 : 00 : 00</Typography>
      ) : (
        <Typography>00 : 00</Typography>
      )}
    </StyledCountDown>
  )
}

export default CountdownTimer
