import { FormControl, useTheme } from '@mui/material'

import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import BottomDrawer from 'components/commons/BottomDrawer/v2'
import CustomField from 'components/commons/CustomFormField'
import Button from 'components/commons/Button'
import { ICustomField } from 'components/commons/CustomFormField/interface'
import MessageBox from 'components/commons/MessageBox'

import { useScreeningQuestionCommunity } from 'hooks/domains/Community'
import { useCommunityStore } from 'stores/domains/Social'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import isEmpty from 'helpers/is-empty'
import { useProfileUserStore } from 'stores/domains/User'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'

interface IScreeningQuestionDrawerProps {
  onSubmit: Function
  communityId: string
  isLoading: boolean
  isError: boolean
  onCloseError: Function
  creatorProfile?: any
}

export default function ScreeningQuestionDrawer({
  onSubmit,
  communityId,
  isLoading,
  isError,
  onCloseError,
  creatorProfile = {},
}: IScreeningQuestionDrawerProps) {
  const theme = useTheme()
  const formMethod = useForm({
    mode: 'onChange',
  })
  const { query, pathname } = useRouter()
  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    reset,
    formState: { isValid, isDirty },
  } = formMethod

  const {
    community,
    setIsOpenScreeningQuestionDrawer,
    isOpenScreeningQuestionDrawer,
    setScreeningAnswerInput,
    screeningAnswerInput,
    resetScreeningAnswer,
  } = useCommunityStore((state) => ({
    community: state.community,
    setIsOpenScreeningQuestionDrawer:
      state.setIsOpenScreeningQuestionDrawer,
    isOpenScreeningQuestionDrawer:
      state.isOpenScreeningQuestionDrawer,
    setScreeningAnswerInput: state.setScreeningAnswerInput,
    screeningAnswerInput: state.screeningAnswerInput,
    resetScreeningAnswer: state.resetScreeningAnswer,
  }))

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))

  const trackerData = {
    query,
    pathname,
    community,
    profileData,
    creatorProfile,
  }

  const { data, refetch: fetchListScreeningQuestion } =
    useScreeningQuestionCommunity(communityId)

  const handleCloseModal = () => {
    trackEvent.community(
      'cancel_community_member_request',
      trackerData,
      { modal_name: 'COMMUNITY_MEMBERSHIP_QUESTION' },
    )
    setIsOpenScreeningQuestionDrawer(false)
    reset()
    resetScreeningAnswer()
  }

  const saveDataToLocal = (key, value) => {
    const prevAnswer =
      profileData.user_id === screeningAnswerInput.userId &&
      communityId === screeningAnswerInput.communityId
        ? { ...screeningAnswerInput.answer }
        : {}
    setScreeningAnswerInput({
      userId: profileData.user_id,
      communityId: communityId,
      answer: {
        ...prevAnswer,
        [`${key}`]: value,
      },
    })
  }

  const onSubmitForm = (formValue) => {
    const payload = {
      form_answer: formValue,
      form_question: data,
    }
    onSubmit(payload)
  }

  const initDefaultValue = () => {
    if (
      profileData.user_id === screeningAnswerInput.userId &&
      communityId === screeningAnswerInput.communityId
    ) {
      if (!isEmpty(screeningAnswerInput.answer)) {
        for (const formName in screeningAnswerInput.answer) {
          const value =
            typeof screeningAnswerInput.answer[formName] === 'string' // check that value is object file or string
              ? screeningAnswerInput.answer[formName]
              : screeningAnswerInput.answer[formName]?.map(
                  (item) => item.file.value,
                )
          setValue(formName, value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (isOpenScreeningQuestionDrawer) {
      fetchListScreeningQuestion()
      if (
        profileData.user_id !== screeningAnswerInput.userId ||
        communityId !== screeningAnswerInput.communityId
      ) {
        resetScreeningAnswer()
      } else {
        initDefaultValue()
      }
    }
  }, [isOpenScreeningQuestionDrawer])

  return (
    <BottomDrawer
      sx={{
        [theme.breakpoints.up('sm')]: {
          '& .MuiPaper-root': {
            position: 'absolute',
            transform: 'translate(-50%, -50%) !important',
            left: '50%',
            top: '50%',
            height: 'fit-content',
            margin: '0px 0px',
            borderRadius: '12px',
            width: '500px',
          },
          boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
        },
      }}
      title="Kirim permintaan bergabung"
      open={isOpenScreeningQuestionDrawer}
      boxPadding={'0px'}
      onCloseIcon={handleCloseModal}
      sxHeader={{ padding: '18px 16px 0px' }}
    >
      <Stack>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Stack
            sx={{
              padding: '0px 16px 16px',
            }}
          >
            <Typography
              color={theme.palette.text.secondary}
              fontSize={14}
            >
              Jawab pertanyaan khusus di bawah ini untuk bergabung di
              komunitas
            </Typography>
            <Typography variant="normalBold">
              {' '}
              {community.name}
            </Typography>
          </Stack>
          <Stack
            height={{ md: '360px' }}
            sx={{ overflow: 'auto', padding: '16px' }}
            spacing={2}
          >
            {data?.fields?.map((item: ICustomField, index) => {
              register(item.name, {
                required: true,
                maxLength: item.validation.max_length,
              })
              return (
                <FormControl
                  key={`form_answer-${index}-${item.name}`}
                  variant="standard"
                  fullWidth
                  onKeyDown={(event) => {
                    if (
                      event.code === 'Enter' &&
                      item.type !== 'PARAGRAPH'
                    )
                      event.preventDefault()
                  }}
                >
                  <CustomField
                    {...item}
                    {...formMethod}
                    defaultValue={
                      screeningAnswerInput.answer?.[`${item.name}`]
                    }
                    value={getValues(item.name) || ''}
                    onValueChange={(value) => {
                      if (item.type !== 'ATTACHMENT') {
                        saveDataToLocal(item.name, value)
                      }
                      setValue(item.name, value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: `${theme.palette.background.tertiery} !important`,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    onGetImageList={(images) => {
                      if (item.type === 'ATTACHMENT') {
                        saveDataToLocal(item.name, images)
                      }
                    }}
                    defaultImages={
                      screeningAnswerInput.answer?.[`${item.name}`] ||
                      null
                    }
                  />
                </FormControl>
              )
            })}
          </Stack>

          <MessageBox
            open={isError}
            onClose={onCloseError}
            isError={true}
            message={
              'Maaf, ada sedikit gangguan. Boleh coba gabung lagi, ya.'
            }
          />

          <Stack
            padding={2}
            spacing={2}
            direction={'row'}
            width="100%"
            sx={{
              borderTop: `1px solid ${theme.palette.border.bold}`,
            }}
          >
            <Button
              sx={{
                borderRadius: '8px',
                width: '100%',
              }}
              buttonVariant="outlined"
              id="b-social-close-drawer-screening-question"
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              Batal
            </Button>
            <Button
              type="submit"
              id="b-social-send-request-join-drawer-screening-question"
              sx={{
                borderRadius: '8px',
                width: '100%',
              }}
              disabled={!isValid || !isDirty || isLoading}
            >
              Kirim Permintaan
            </Button>
          </Stack>
        </form>
      </Stack>
    </BottomDrawer>
  )
}
