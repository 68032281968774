import { styled, useTheme } from '@mui/material/styles'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import ClearIcon from '@mui/icons-material/Clear'
import { useEffect, useRef } from 'react'
import { useStoreActions } from 'stores/hooks'

import Stack from '../Stack'
import Typography from '../Typography'
import Box from '../Box'

interface ISearchHistory {
  data: any
  handleRemoveItem?: Function
  handleRemoveAll?: Function
  handleRedirectPage?: Function
}

const StyledTitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HistoryContent = styled(Box)({
  marginTop: '12px',
})

const HistoryWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
  cursor: 'pointer',
})

const SearchHistory = ({
  data,
  handleRemoveItem,
  handleRemoveAll,
  handleRedirectPage,
}: ISearchHistory): JSX.Element => {
  const theme = useTheme()
  const wrapperRef = useRef(null)
  const { setHistoryOpen, setIsSearchBarActive } = useStoreActions(
    (action) => action.search,
  )

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setHistoryOpen(false)
        setIsSearchBarActive(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef}>
      {data && data.length !== 0 && (
        <StyledTitleContainer>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              ...theme.typography.h4Desk,
              fontFamily: 'Open Sans',
            }}
          >
            Riwayat Pencarian
          </Typography>
          <Typography
            id="b-search-delete-all"
            sx={{
              color: theme.palette.tiptip[500],
              ...theme.typography.normalBold,
              cursor: 'pointer',
            }}
            onClick={() => handleRemoveAll()}
          >
            Hapus Semua
          </Typography>
        </StyledTitleContainer>
      )}
      <HistoryContent>
        {data &&
          data.map((item, index) => {
            return (
              <HistoryWrapper
                key={index}
                onClick={(e) => handleRedirectPage(e, item)}
                id={`b-search-delete-${index}`}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1.5}
                >
                  <RotateLeftIcon color="info" />
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      ...theme.typography.normalRegular,
                    }}
                  >
                    {item}
                  </Typography>
                </Stack>
                <ClearIcon
                  sx={{
                    color: theme.palette.text.tertiery,
                  }}
                  onClick={(e) => handleRemoveItem(e, item)}
                />
              </HistoryWrapper>
            )
          })}
      </HistoryContent>
    </div>
  )
}

export default SearchHistory
