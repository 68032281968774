import { SxProps } from '@mui/material'
import { StyledHexagonContainer } from './styled'

interface ICommunityImageProps {
  sxContainer?: SxProps
  src?: string
  isLoading?: boolean
}
export const CommunityImage = ({
  sxContainer,
  src,
  isLoading,
}: ICommunityImageProps) => {
  return (
    <>
      <StyledHexagonContainer
        sx={{
          filter: 'url(#round)',
          WebkitFilter: 'url(#round)',
          ...sxContainer,
        }}
        src={src}
        loading={isLoading ? 'true' : 'false'}
      />
      <svg
        style={{ visibility: 'hidden', position: 'absolute' }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="5"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite
              in="SourceGraphic"
              in2="goo"
              operator="atop"
            />
          </filter>
        </defs>
      </svg>
    </>
  )
}
