import {
  ICreateCognitoAccountPayload,
  ISignInPayload,
  ISignUpPayload,
} from 'stores/domains/User/Auth/interface'

import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { getLocalStorage, getWithExpiry } from 'helpers/local-storage'

import authConfig from 'configs/auth'
import ampConfig from 'configs/amplify'

export const createCognitoAccount = async (
  payload: ICreateCognitoAccountPayload,
) => {
  return await apiClient({
    url: `/user/guest/v1/auth/account`,
    method: 'POST',
    data: payload,
  })
}

export const signIn = async (payload: ISignInPayload) => {
  return await apiClient({
    url: `/user/guest/v1/auth/sign-in`,
    method: 'POST',
    data: payload,
  })
}

export const signUp = async (payload: ISignUpPayload) => {
  return await apiClient({
    url: `/user/guest/v1/auth/sign-up`,
    method: 'POST',
    data: {
      ...payload,
      client_id: ampConfig.aws_user_pools_web_client_id,
    },
  })
}

export const postRefreshToken = async () => {
  const isEmailMethod = getLocalStorage('authMethod') === 'email'
  const refreshToken = getWithExpiry(authConfig.refreshTokenName)
  const username = isEmailMethod
    ? getLocalStorage('userEmail')
    : getLocalStorage('userPhoneNumber')

  return await apiClient({
    url: 'user/guest/v1/auth/refresh-token',
    method: 'POST',
    data: {
      username,
      refresh_token: refreshToken,
      client_id: ampConfig.aws_user_pools_web_client_id,
    },
    token: getIdToken(),
  })
}
