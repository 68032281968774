import { RegisterOptions } from 'react-hook-form'

export const phoneNumberOptions: RegisterOptions = {
  required: true,
  minLength: 6,
  maxLength: 13,
  pattern: /^0?8\d+$/,
}

export const phoneNumberOptionsForOther: RegisterOptions = {
  required: true,
  minLength: 6,
  maxLength: 15,
  pattern: /^\d+$/,
}
