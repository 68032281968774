import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  SxProps,
} from '@mui/material'

import {
  Props as TextFieldProps,
  StyledTextField,
} from './RHFTextField'

import Grid from 'components/commons/Grid'
import CountrySelect from 'components/domains/User/SelectCountryCodes'

type IProps = {
  isLoading?: boolean
  sxCountryCodeContainer?: SxProps
  sxCountryCodeLabel?: SxProps
  position?: 'absolute' | 'fixed'
}

type Props = IProps & Omit<TextFieldProps, 'action'>

export default function RHFPhoneField({
  name,
  label,
  id,
  isLoading,
  readOnly = false,
  sxCountryCodeContainer,
  sxCountryCodeLabel,
  position = 'absolute',
  ...other
}: Props) {
  const { control, setValue, watch } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return (
        <Grid id="c-users-handphone-error-grid-item" item xs={12}>
          <FormHelperText>{message}</FormHelperText>
        </Grid>
      )
  }

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <InputLabel sx={sxCountryCodeLabel} shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control as any}
        render={({ field, fieldState: { error } }) => (
          <Grid
            id="c-users-phone-grid-container"
            container
            spacing={1}
            sx={{
              marginTop: '15px',
            }}
          >
            <Grid item xs={2} md={2.5} lg={2.5}>
              <CountrySelect
                control={control as any}
                name={`${field.name}_code`}
                value={watch(`${field.name}_code`)}
                onChanged={(code) => {
                  setValue(`${field.name}_code`, code)
                }}
                readOnly={readOnly}
                isHideFlag
                position={position}
              />
            </Grid>
            <Grid item xs={10} md={9.5} lg={9.5}>
              <StyledTextField
                {...field}
                variant="outlined"
                type="number"
                id={id}
                fullWidth
                error={!!error && error?.type !== 'info'}
                noLabel={!Boolean(label)}
                InputProps={{
                  readOnly,
                }}
                {...other}
              />
            </Grid>
            {renderErrorText(error?.message)}
          </Grid>
        )}
      />
    </FormControl>
  )
}
