import { action, computed, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import IBankAccountModel from 'stores/domains/Creator/BankAccount/interface'

const BankAccountModel: IBankAccountModel = {
  isLoadingBankAccount: true,
  isErrorBankAccount: false,
  errorMessage: '',
  bankAccount: {
    bank: {
      bank_id: 0,
      bank_code: '',
      bank_name: '',
      max_withdraw: 0,
      min_withdraw: 0,
    },
    account_no: '',
    account_name: '',
    bank_account_status: '',
  },
  setLoading: action((state, payload) => {
    state.isLoadingBankAccount = payload
  }),
  setBankAccount: action((state, payload) => {
    state.bankAccount = payload
  }),
  getBankAccount: thunk(async (actions, _payload, { injections }) => {
    try {
      actions.resetError()
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/user/v1/bank-account`,
        method: 'GET',
        token: getCookie(authConfig.idTokenName),
      })

      if (response?.data?.code === 'SUCCESS') {
        actions.setBankAccount(response.data?.data)
      }
      actions.setLoading(false)
    } catch (error) {
      actions.error(error.message)
    }
  }),
  registerBankAccount: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.resetError()
        actions.setLoading(true)
        const { apiClient } = injections

        const res = await apiClient({
          url: '/user/v1/bank-account',
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
          data: payload,
        })

        if (res?.data?.code === 'SUCCESS') {
          actions.setBankAccount(res.data.data)
        }
      } catch (error) {
        const hasErrorDetails =
          (error?.response?.data?.errors || false) && // If field is there
          Array.isArray(error.response.data.errors) && // If it's an array
          error.response.data.errors.length >= 1 // If it has at least 1 error

        if (hasErrorDetails)
          actions.error(error?.response?.data?.errors)
        else actions.error(error.message)
      } finally {
        actions.setLoading(false)
      }
    },
  ),
  isBankAccountNotFound: computed((state) => {
    return (
      state.isErrorBankAccount &&
      state.errorMessage === 'Request failed with status code 404'
    )
  }),
  error: action((state, payload) => {
    state.isLoadingBankAccount = false
    state.isErrorBankAccount = true
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isErrorBankAccount = false
    state.errorMessage = ''
  }),
}

export default BankAccountModel
