import { Divider, styled } from '@mui/material'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import { Work, Email, WhatsApp } from '@mui/icons-material'

export const LinkContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '8px',
  marginBottom: '8px',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}))

export const ContactAddress = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.normalRegular,
}))

export const SocialMediaLabel = styled(ContactAddress)(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    marginBottom: '4px',
  }),
)

export const ContactContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '18px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))
export const ContactItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '8px',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    marginLeft: '12px',
  },
}))

export const StyledWhatsAppIcon = styled(WhatsApp)(({ theme }) => ({
  color: theme.palette.tiptip[500],
  fontSize: '20px',
}))

export const StyledEmailIcon = styled(Email)(({ theme }) => ({
  color: theme.palette.tiptip[500],
  fontSize: '20px',
}))

export const StyledWorkIcon = styled(Work)(({ theme }) => ({
  color: theme.palette.tiptip[500],
  fontSize: '20px',
}))

export const LinkLabel = styled(ContactAddress)(({ theme }) => ({
  cursor: 'pointer',
  marginBottom: '12px',
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export const StyledSectionName = styled(ContactAddress)(
  ({ theme }) => ({
    ...theme.typography.mediumBold,
  }),
)

export const ContactFooter = styled(Box)(({ theme }) => ({
  background: 'white',
  ...theme.typography.normalRegular,
  color: theme.palette.text.secondary,
  textAlign: 'center',
  width: '100%',
  height: '45px',
  padding: '15px 0',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette.border.minimal,
  marginTop: '0px !important',
}))

export const StyledImage = styled('img')(({ theme }) => ({
  height: '48px',
  width: '165px',
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    marginBottom: '16px',
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '0 16px',
  [theme.breakpoints.up('md')]: {
    padding: '32px 24px 0px 24px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '1440px',
    margin: '32px auto 0px auto',
  },
}))
