import { StateCreator } from 'zustand'
import { IBrowseEvent, IBrowseEventStateDefault } from './interface'
import get from 'lodash/get'

const INITIAL_DATA: IBrowseEventStateDefault = {
  eventData: {
    eventList: [],
    loadMore: false,
    currentPage: 1,
  },
  isError: false,
  errorMessage: '',
  isLoading: false,
  eventStatusFilter: [],
  eventOtherData: {
    eventList: [],
    loadMore: false,
    currentPage: 1,
  },
}

export const createBrowseEventStore: StateCreator<IBrowseEvent> = (
  set,
) => ({
  ...INITIAL_DATA,
  setEventData: (response) => {
    set((state) => ({
      eventData: {
        eventList:
          get(response, 'data._metadata.page', 1) > 1
            ? [
                ...state.eventData.eventList,
                ...get(response, 'data.data', []),
              ]
            : get(response, 'data.data', []),
        loadMore: get(response, 'data._metadata.load_more', false),
        currentPage: get(response, 'data._metadata.page', 1),
      },
    }))
  },
  setError: (message) => {
    set({
      isError: true,
      errorMessage: message,
    })
  },
  clearError: () => {
    set({
      isError: false,
      errorMessage: '',
    })
  },
  setIsLoading: (isLoading) => {
    set({
      isLoading,
    })
  },
  setEventStatusFilter: (payload) => {
    set({ eventStatusFilter: payload })
  },
  setEventOtherData: (response) => {
    set((state) => ({
      eventOtherData: {
        eventList:
          get(response, 'data._metadata.page', 1) > 1
            ? [
                ...state.eventOtherData.eventList,
                ...get(response, 'data.data', []),
              ]
            : get(response, 'data.data', []),
        loadMore: get(response, 'data._metadata.load_more', false),
        currentPage: get(response, 'data._metadata.page', 1),
      },
    }))
  },
})
