import { StateCreator } from 'zustand'
import _get from 'lodash/get'
import last from 'lodash/last'
import pick from 'lodash/pick'
import reverse from 'lodash/reverse'
import union from 'lodash/union'
import unionBy from 'lodash/unionBy'
import formatDate from 'date-fns/formatRFC3339'
import parseDate from 'date-fns/parse'

import * as service from 'services/user/message'
import isEmpty from 'helpers/is-empty'
import { sendLog } from 'helpers/log'

import {
  Chat,
  ChatPeer,
  Creator,
  IMessageDefault,
  IMessageStore,
  LastDate,
  Message,
  RoomMessage,
} from './interface'
import { IProfileData } from '../UserProfile'

export * from './interface'

const INITIAL_DATA: IMessageDefault = {
  chats: [],
  roomMessages: [],
  activeMessages: [],
  currentUserId: undefined,
  errorInChat: undefined,
  errorInMessage: undefined,
  errorInMessageDelivery: undefined,
  errorInBlock: undefined,
  isDelivered: false,
  isEmptyChat: false,
  isLoadingChat: false,
  isLoadingMessage: false,
  isPeerChanging: false,
  isSendingMessage: false,
  isInitiateChat: false,
  isUnreadMessage: false,
  currentPeer: undefined,
  lastDateInChat: undefined,
  lastDatesInMessage: [],
  userBlockList: [],
  draftMessageList: [],
}

const getErrorResponse = (error: Error) => {
  return _get(
    error,
    'response.data.message',
    error?.message || 'Ups, terjadi kesalahan',
  )
}

const getErrorSendMessage = (error: Error) => {
  const codeMessage = {
    TOO_MANY_REQUESTS:
      'Kamu telah kirim lebih dari 5 pesan dalam satu menit. Silakan tunggu beberapa saat lagi.',
  }
  const code = _get(error, 'response.data.code')
  const message = codeMessage[code]
  if (!message) return 'Gagal mengirim pesan'
  return message
}

/* eslint-disable-next-line no-unused-vars */
const resetters: (() => void)[] = []

const messageByCreatedAt = (a: Message, b: Message) => {
  const firstCreatedAt = a.created_at
  const secondCreatedAt = b.created_at
  const firstDate = parseDate(
    firstCreatedAt,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(),
  )
  const secondDate = parseDate(
    secondCreatedAt,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(),
  )
  if (firstDate < secondDate) {
    return -1
  }
  if (firstDate > secondDate) {
    return 1
  }
  return 0
}

const chatByCreatedAt = (a: Chat, b: Chat) => {
  const firstCreatedAt = last(
    a.messages.sort(messageByCreatedAt),
  ).created_at
  const secondCreatedAt = last(
    b.messages.sort(messageByCreatedAt),
  ).created_at
  const firstDate = parseDate(
    firstCreatedAt,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(),
  )
  const secondDate = parseDate(
    secondCreatedAt,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    new Date(),
  )
  if (firstDate < secondDate) {
    return -1
  }
  if (firstDate > secondDate) {
    return 1
  }
  return 0
}

export const createMessageStore: StateCreator<IMessageStore> = (
  set,
  get,
) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    async getChats() {
      const excludeTempChats = get().chats.filter(
        (chat) => !chat.isTemporary,
      )
      set((state) => ({
        isLoadingChat: state.chats.length <= 0, // loading only when chats is empty
        errorInChat: undefined,
        isEmptyChat: false,
        chats: excludeTempChats,
      }))

      const newer_than = get().lastDateInChat
      try {
        const response = await service.getChats({ newer_than })
        const { code, data: chats } = response.data || {}
        if (code === 'SUCCESS') {
          set((state) => {
            if (state.chats.length <= 0 && chats.length <= 0) {
              return { chats: [], isEmptyChat: true }
            }

            const prepareNewChats: Chat[] = (chats as Chat[]).map(
              (chat) => {
                const previousChat = state.chats.find(
                  (c) => c.peer.id === chat.peer.id,
                )
                return {
                  ...chat,
                  messages: reverse(
                    unionBy(
                      chat.messages,
                      previousChat ? previousChat.messages : [],
                      'id',
                    ),
                  ),
                }
              },
            )
            const allChats: Chat[] = unionBy(
              reverse(prepareNewChats),
              state.chats,
              'peer.id',
            )
            const isUnreadMessage = allChats.some((chat) =>
              chat.messages.some(
                (message) =>
                  !message.read &&
                  message.from_user_id !== state.currentUserId,
              ),
            )
            const newChats: Chat[] = allChats.map((chat) => ({
              ...chat,
              peer: {
                ...chat.peer,
                isCreator: !isEmpty(chat.peer.creator),
                profile_image_thumbnail:
                  chat.peer.creator?.profile_image_thumbnail,
                callName:
                  chat.peer.name ||
                  chat.peer.creator?.name ||
                  chat.peer.username ||
                  '-',
              },
            }))
            const newRoomMessages: RoomMessage[] = allChats.map(
              (chat) => ({
                userId: chat.peer.id,
                messages: chat.messages,
              }),
            )
            const newLastDatesInMessage: LastDate[] = allChats.map(
              (chat) => {
                const sortingMessages = chat.messages.sort(
                  messageByCreatedAt,
                )
                return {
                  userId: chat.peer.id,
                  createdAt: last(sortingMessages).created_at,
                }
              },
            )
            const sortingNewChats = newChats.sort(chatByCreatedAt)
            const sortingChatMessages = last(
              sortingNewChats,
            ).messages.sort(messageByCreatedAt)
            return {
              isUnreadMessage,
              chats: newChats,
              roomMessages: newRoomMessages,
              isEmptyChat: false,
              lastDateInChat: last(sortingChatMessages).created_at,
              lastDatesInMessage: newLastDatesInMessage,
            }
          })
        }
      } catch (error) {
        sendLog(error?.message)
        set({ errorInChat: getErrorResponse(error) })
      } finally {
        set({ isLoadingChat: false })
      }
    },
    async getMessagesInRoom(userId, payload) {
      set({
        isLoadingMessage: true,
        errorInChat: undefined,
      })
      try {
        const lastDate = get().lastDatesInMessage.find(
          (date) => date.userId === userId,
        )
        const newer_than = lastDate ? lastDate.createdAt : undefined
        const response = await service.getMessagesInRoom(userId, {
          newer_than,
          ...payload,
        })
        const { code, data: newMessages } = response.data || {}
        if (code === 'SUCCESS' && newMessages.length > 0) {
          const lastNewMessage = last(newMessages)
          const lastDate: LastDate = {
            userId,
            createdAt:
              newMessages.length > 0 && lastNewMessage
                ? lastNewMessage.created_at
                : undefined,
          }

          set((state) => {
            const userMessage: RoomMessage = state.roomMessages.find(
              (room) => room.userId === userId,
            )
            const newUserRoomMessages = [
              {
                userId,
                messages: unionBy(
                  userMessage?.messages?.filter(
                    (msg) => !msg.isTemporary,
                  ),
                  newMessages,
                  'id',
                ),
              },
            ]
            const existingUserChats: Chat[] = state.chats.filter(
              (chat) => chat.peer.id === userId,
            )
            const roomMessages: RoomMessage[] = unionBy(
              newUserRoomMessages,
              state.roomMessages,
              'userId',
            )
            const isUnreadMessage = roomMessages.some((chat) =>
              chat.messages.some(
                (message) =>
                  !message.read &&
                  message.from_user_id !== state.currentUserId,
              ),
            )

            return {
              roomMessages,
              isUnreadMessage,
              lastDatesInMessage: unionBy(
                [lastDate],
                state.lastDatesInMessage,
                'userId',
              ),
              chats: unionBy(
                existingUserChats.map((chat) => ({
                  ...chat,
                  isTemporary: false,
                  messages: [...chat.messages, ...newMessages],
                })),
                state.chats,
                'peer.id',
              ),
            }
          })
        }
      } catch (error) {
        sendLog(error?.message)
        set({ errorInChat: getErrorResponse(error) })
      } finally {
        set({
          isLoadingMessage: false,
          isDelivered: false,
        })
      }
    },
    async sendMessage(userId, text) {
      set({
        isSendingMessage: true,
        errorInMessageDelivery: undefined,
      })
      try {
        const response = await service.sendMessage(userId, text)
        const { code, message, data } = response.data || {}
        if (code === 'SUCCESS') {
          const lastDate: LastDate = {
            userId,
            createdAt: data.created_at,
          }
          set((state) => {
            const userMessage: RoomMessage = state.roomMessages.find(
              (room) => room.userId === userId,
            )
            const newUserRoomMessages = [
              {
                userId,
                messages: unionBy(
                  userMessage?.messages?.filter(
                    (msg) => !msg.isTemporary,
                  ),
                  [data],
                  'id',
                ),
              },
            ]
            const existingUserChats: Chat[] = state.chats.filter(
              (chat) => chat.peer.id === userId,
            )

            return {
              isDelivered: true,
              roomMessages: unionBy(
                newUserRoomMessages,
                state.roomMessages,
                'userId',
              ),
              lastDatesInMessage: unionBy(
                [lastDate],
                state.lastDatesInMessage,
                'userId',
              ),
              chats: unionBy(
                existingUserChats.map((chat) => ({
                  ...chat,
                  isTemporary: false,
                  messages: [...chat.messages, data],
                })),
                state.chats,
                'peer.id',
              ),
              draftMessageList: state.draftMessageList.filter(
                (msg) => msg.userId !== userId,
              ),
            }
          })
          return Promise.resolve('SUCCESS')
        } else {
          set({
            errorInMessageDelivery: {
              userId,
              message,
              type: 'warning',
            },
          })
          return Promise.reject('FAILED')
        }
      } catch (error) {
        sendLog(error?.message)
        const code = _get(error, 'response.data.code')
        set({
          errorInMessageDelivery: {
            userId,
            message: getErrorSendMessage(error),
            type: code === 'TOO_MANY_REQUESTS' ? 'warning' : 'error',
          },
        })
        return Promise.reject('FAILED')
      } finally {
        set({ isSendingMessage: false })
      }
    },
    async markAsRead(userId, ids) {
      try {
        const response = await service.markMessageAsRead(ids)
        const { code, data } = response.data || {}
        if (code === 'SUCCESS') {
          set((state) => {
            // update messages of room
            const targetRoomMessage = state.roomMessages.find(
              (msg) => msg.userId === userId,
            )
            const updatedMessages: Message[] =
              targetRoomMessage?.messages?.map((msg) => ({
                ...msg,
                read: data?.ids?.includes(msg.id) ? true : msg.read,
              }))
            const updatedRoomMessages: RoomMessage = {
              userId,
              messages: updatedMessages,
            }
            // update messages of chat
            const targetChat = state.chats.find(
              (msg) => msg.peer.id === userId,
            )
            const updatedChatMessages: Message[] =
              targetChat?.messages?.map((msg) => ({
                ...msg,
                read: data?.ids?.includes(msg.id) ? true : msg.read,
              }))
            const updatedChat: Chat = {
              peer: targetChat.peer,
              messages: updatedChatMessages,
            }
            const roomMessages = unionBy(
              [updatedRoomMessages],
              state.roomMessages,
              'userId',
            )
            const isUnreadMessage = roomMessages.some((chat) =>
              chat.messages.some(
                (message) =>
                  !message.read &&
                  message.from_user_id !== state.currentUserId,
              ),
            )

            return {
              roomMessages,
              isUnreadMessage,
              chats: unionBy([updatedChat], state.chats, 'peer.id'),
            }
          })
        }
      } catch (error) {
        sendLog(error?.message)
        set({ errorInMessage: getErrorResponse(error) })
      } finally {
        set({ isSendingMessage: false })
      }
    },
    selectPeer(peer) {
      set({
        isPeerChanging: true,
        currentPeer: {
          ...peer,
          isCreator: !isEmpty(peer.creator),
          callName:
            peer.name || peer.creator?.name || peer.username || '-',
        },
      })
      setTimeout(() => {
        set({
          isPeerChanging: false,
        })
      }, 500)
    },
    clearPeer() {
      set({ currentPeer: undefined })
    },
    clearData() {
      set({ ...INITIAL_DATA })
    },
    setErrorMessage(payload) {
      set(payload)
    },
    async initiateChat(username) {
      const chats = get().chats
      if (!username) return
      const isExistingUsername =
        chats.findIndex(
          (chat) =>
            chat.peer.username.toLowerCase() ===
            username.toLowerCase(),
        ) > -1
      if (isExistingUsername) return

      if (get().isInitiateChat) return
      set({ isInitiateChat: true })

      try {
        // when username is not empty, that mean user need to chat with peer
        const response = await service.getGuestUserProfile(username)
        const { code, data: userData } = response.data || {}
        const data = userData as IProfileData
        if (code === 'SUCCESS') {
          // after got data, then merge peer data to tempChats
          const creatorId = data?.creator?.creator_id || ''
          const creatorUserId =
            data?.creator?.creator_user_id || data?.user_id
          const creator: Creator = {
            id: creatorId,
            ...pick(data?.creator, [
              'name',
              'profile_image_thumbnail',
              'verified',
            ]),
          }
          const peer: ChatPeer = {
            isCreator: true,
            creator:
              data?.creator?.creator_class === 'CREATOR'
                ? creator
                : undefined,
            id: creatorUserId,
            name: data.name || data.creator?.name,
            username: data.username,
            profile_image_thumbnail:
              data?.creator?.profile_image_thumbnail ||
              data?.profile_image_thumbnail,
            callName:
              data.name ||
              data?.creator?.name ||
              data.username ||
              '-',
          }
          set((state) => ({
            isInitiateChat: false,
            currentPeer: peer,
            isEmptyChat: false,
            chats: [
              {
                peer,
                messages: [
                  {
                    created_at: formatDate(new Date()),
                    from_user_id: creatorUserId,
                    id: creatorId,
                    message: 'Mulai percakapan',
                    read: true,
                    isTemporary: true,
                  },
                ],
                isTemporary: true,
              },
              ...state.chats.filter(
                (chat) =>
                  chat.peer.username.toLowerCase() !==
                  username.toLowerCase(),
              ),
            ],
          }))
        }
      } catch (error) {
        sendLog(error?.message)
      }
    },
    setCurrentUser(userId) {
      set({ currentUserId: userId })
    },
    async blockPeerUser(userId, unblock) {
      try {
        const response = await service.blockPeerUser(userId, unblock)
        const { code, message } = response.data || {}
        if (code === 'SUCCESS') {
          set((state) => {
            let userBlockList = []
            if (!unblock) {
              userBlockList = union([userId], state.userBlockList)
            } else {
              userBlockList = state.userBlockList.filter(
                (user) => user !== userId,
              )
            }
            return {
              userBlockList,
            }
          })
        } else {
          set({ errorInBlock: message })
        }
      } catch (error) {
        sendLog(error?.message)
        set({ errorInBlock: getErrorResponse(error) })
      }
    },
    async checkBlockUser(userId) {
      try {
        const response = await service.isBlocked(userId)
        const { code, data } = response.data || {}
        if (code === 'SUCCESS') {
          set((state) => {
            let userBlockList = []
            if (Boolean(data.is_blocked)) {
              userBlockList = union([userId], state.userBlockList)
            } else {
              userBlockList = state.userBlockList.filter(
                (user) => user !== userId,
              )
            }
            return { userBlockList }
          })
        }
      } catch (error) {
        sendLog(error?.message)
        set({ errorInBlock: getErrorResponse(error) })
      }
    },
    saveDraftMessage(userId, message) {
      set((state) => {
        if (message.length > 0) {
          const draftMessageList = unionBy(
            [{ userId, message }],
            state.draftMessageList,
            'userId',
          )

          return { draftMessageList }
        }

        if (
          state.draftMessageList.some((msg) => msg.userId === userId)
        ) {
          return {
            draftMessageList: state.draftMessageList.filter(
              (msg) => msg.userId !== userId,
            ),
          }
        }
      })
    },
  }
}

export const resetMessageStore = () =>
  resetters.forEach((resetter) => resetter())
