import { StateCreator } from 'zustand'
import { ICartTotalItemsStore } from './interface'

export const createCartTotalItemsStore: StateCreator<
  ICartTotalItemsStore
> = (set) => ({
  // states
  totalItems: 0,
  isBadgeCart: false,
  // actions
  setTotalItems: async (data) => {
    set({
      totalItems: data?.total_item || 0,
      isBadgeCart: data?.total_item > 0 ? true : false,
    })
  },
})
