import {
  Avatar,
  Box,
  styled,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material'

const AvatarContainer = styled(Box)({
  width: '40px',
  height: '40px',
})

type IProfileAvatarProps = {
  onClick?: Function | any
  src: string
  alt: string
  sx?: SxProps<Theme>
  sxContainer?: SxProps<Theme>
}

const ProfileAvatar = ({
  onClick = () => {},
  src,
  alt = 'profile-avatar',
  sx = {},
  sxContainer,
}: IProfileAvatarProps) => {
  const theme = useTheme()
  return (
    <AvatarContainer sx={sxContainer} onClick={onClick}>
      <Avatar
        alt={alt}
        src={src}
        sx={{
          background: theme.palette.info.main,
          width: 40,
          height: 40,
          ...sx,
        }}
      />
    </AvatarContainer>
  )
}

export default ProfileAvatar
