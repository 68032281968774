import {
  Badge,
  BadgeProps,
  IconButton as MaterialIconButton,
  IconButtonProps,
  SvgIcon,
} from '@mui/material'

interface IButtonIcon extends IconButtonProps {
  icon: typeof SvgIcon
  badge?: BadgeProps
  colorBtn?: string
}

export default function ButtonIcon({
  icon: Icon,
  badge,
  colorBtn,
  ...rest
}: IButtonIcon) {
  return (
    <MaterialIconButton size="small" {...rest}>
      {badge ? (
        <Badge color="primary" variant="dot" {...badge}>
          <Icon
            sx={(theme) => ({
              color: colorBtn || theme.palette.text.secondary,
            })}
          />
        </Badge>
      ) : (
        <Icon />
      )}
    </MaterialIconButton>
  )
}
