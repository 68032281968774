import { ReportType } from 'components/commons/ReportDrawer/v2'
import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'

export const getReportOptionList = async (reportType: ReportType) => {
  return await apiClient({
    url: `/rating/v1/report/category`,
    method: 'GET',
    params: {
      type: reportType === 'event' ? 'product' : reportType,
      lang: 'id',
    },
    token: getIdToken(),
  }).catch((error) => {
    return Promise.reject(error)
  })
}
