import Head from 'next/head'

interface IMetaTags {
  property: string
  content: string
  key?: string
}

export default function HtmlHead({
  metatags = [],
  title,
  description,
  keywords,
  link,
  image,
  robots = 'Allow',
}: {
  metatags?: IMetaTags[]
  title?: string
  description?: string
  keywords?: string
  link?: string
  image?: string
  robots?: string
}) {
  if (
    (!title || !description || !keywords || !link || !image) &&
    !metatags
  ) {
    return null
  }

  return (
    <Head>
      {title && (
        <>
          <title>{title}</title>
          <meta
            property={'og:title'}
            content={title}
            key={'og-title'}
          />
          <meta
            name={'twitter:title'}
            content={title}
            key={'twitter-title'}
          />
        </>
      )}
      {description && (
        <>
          <meta
            name="description"
            content={description}
            key="description"
          />
          <meta
            property={'og:description'}
            content={description}
            key={'og-description'}
          />
          <meta
            name={'twitter:description'}
            content={description}
            key={'twitter-description'}
          />
        </>
      )}
      {keywords && (
        <meta name="keywords" content={keywords} key="keywords" />
      )}
      {link && (
        <>
          <link rel="canonical" href={link} key="canonical" />
          <meta property={'og:url'} content={link} key={'og-url'} />
        </>
      )}
      {image && (
        <>
          <meta
            property={'og:image'}
            content={image}
            key={'og-image'}
          />
          <meta
            name={'twitter:image'}
            content={image}
            key={'twitter-image'}
          />
        </>
      )}
      <meta name="robots" content={robots} />
      {metatags.length &&
        metatags.map((tag, tagIndex) => {
          return (
            <meta
              property={tag.property}
              content={tag.content}
              key={tag.key || `tags-${tagIndex}`}
            />
          )
        })}
    </Head>
  )
}
