import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useStoreState } from 'stores/hooks'
import ExternalPaymentSkeletonContent from 'components/domains/Wallet/ExternalPaymentDrawerContent/Skeleton'

const StyledContainer = styled('div')({
  width: '100%',
  marginBottom: '24px',
  height: '80vh',
})

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
})

function PaymentConfirmationDrawerContent() {
  const { isLoading, topupRequest } = useStoreState(
    (actions) => actions.topUp,
  )

  return (
    <>
      {isLoading ? (
        <ExternalPaymentSkeletonContent />
      ) : (
        <StyledContainer>
          <StyledIframe
            id="iframe-external-payment"
            allow="clipboard-read; clipboard-write"
            title="Invoice"
            src={topupRequest.invoice_url}
          ></StyledIframe>
        </StyledContainer>
      )}
    </>
  )
}

export default PaymentConfirmationDrawerContent
