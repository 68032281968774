import { StackProps } from '@mui/material'
import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { IconCoin } from 'svgs/icons'
import { Caption, Container } from './styled'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

interface MenuCoinProps extends StackProps {
  balance: number
  narrow?: boolean
  dark?: boolean
}

export function MenuCoin({
  balance,
  narrow = false,
  ...rest
}: MenuCoinProps) {
  const { t } = useTranslation()

  return (
    <Container flex={1} {...rest}>
      <Stack justifyContent="center" alignItems="center" width="24px">
        <IconCoin />
      </Stack>
      <Stack direction="column" flex={1}>
        {!narrow && <Caption>{t('coinBalance')}</Caption>}
        <Caption
          sx={(theme) => ({ ...theme.typography.normalBold })}
          id="c-wallet-coin-balance-label"
          main
        >
          {balance.toLocaleString('id-ID')}
        </Caption>
      </Stack>
      <IconKeyboardArrowRight fontSize="small" color="tertiery" />
    </Container>
  )
}
