import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'

const StyledContainer = styled('div')({
  width: '100%',
  marginBottom: '24px',
  minHeight: '200px',
})

const StyledSkeleton = styled(Skeleton)({
  borderRadius: '8px',
  backgroundColor: 'lightgrey',
  marginTop: '8px',
  width: '100%',
  height: '100%',
})

function ExternalPaymentDrawerContent() {
  return (
    <StyledContainer>
      <StyledSkeleton variant="rectangular" />
    </StyledContainer>
  )
}

export default ExternalPaymentDrawerContent
