import { MenuContentItemProps } from 'components/commons/MenuContentItem'

import appConfig from 'configs/app'

import {
  IconDigital,
  IconEvent,
  IconLiveSession,
  IconSubscription,
} from 'svgs/icons'

export interface MenuProps extends MenuContentItemProps {
  url: string
  eventName: string
}

export const menuItems: MenuProps[] = [
  {
    title: 'createContentBottomSheetJadwalkanSesiLive',
    description: 'createContentBottomSheetLiveDescription',
    icon: <IconLiveSession />,
    url: appConfig.createSessionUrl,
    eventName: 'click_create_session',
  },
  {
    title: 'createContentBottomSheetUploadKontenDigital',
    description: 'createContentBottomSheetDCDescription',
    icon: <IconDigital />,
    url: appConfig.createContentUrl,
    eventName: 'click_create_digital_content',
  },
  {
    title: 'createEventBottomSheetTitle',
    description: 'createEventBottomSheetDesc',
    icon: <IconEvent />,
    url: appConfig.createEventUrl,
    eventName: 'click_create_event',
  },
  {
    title: 'createSubscriptionBottomSheetTitle',
    description: 'createSubscriptionBottomSheetDesc',
    icon: <IconSubscription />,
    url: appConfig.createSubscriptionUrl,
    eventName: 'click_create_subscription',
  },
]
