import { ElementType } from 'react'
import { styled, Typography, TypographyProps } from '@mui/material'
import theme from 'src/theme'

type TypographySizes = 'xs' | 'sm' | 'md' | 'lg'
type TypographyColors = keyof typeof theme.palette.text

export interface ITypographyComponent extends TypographyProps {
  size?: TypographySizes
  bold?: boolean
  truncate?: boolean
  as?: ElementType
  color?: TypographyColors
}

export default function TypographyComponent({
  size = 'md',
  bold = false,
  truncate = false,
  color = 'primary',
  as: component,
  children,
  ...rest
}: ITypographyComponent) {
  const heading = {
    lg: bold ? HeadingH2Bold : HeadingH2,
    md: bold ? HeadingH3Bold : HeadingH3,
    sm: bold ? HeadingH4Bold : HeadingH4,
    xs: bold ? HeadingH5Bold : HeadingH5,
  }

  const headingTypes: Record<TypographySizes, ElementType> = {
    lg: 'h1',
    md: 'h1',
    sm: 'p',
    xs: 'span',
  }

  const style = heading[size]
  const Caption = style

  return (
    <Caption
      as={component || headingTypes[size]}
      isTruncate={truncate}
      color={color}
      {...rest}
    >
      {children}
    </Caption>
  )
}

const Heading = styled(Typography)<{
  hasChild?: boolean
  isTruncate?: boolean
  color?: TypographyColors
}>(({ isTruncate = false, theme, color }) => {
  const truncateStyle = isTruncate && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
  }

  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: 0,
    color: theme.colors[color],
    ...truncateStyle,
  }
})

const HeadingH2 = styled(Heading)(({ theme }) => ({
  columnGap: '8px',
  ...theme.typography.h2,
  letterSpacing: '0.01em',
  textAlign: 'center',
  textTransform: 'uppercase',
  marginBottom: '6px',
  justifyContent: 'center',
}))

const HeadingH3 = styled(Heading)(({ theme }) => ({
  ...theme.typography.mediumRegular,
}))

const HeadingH4 = styled(Heading)(({ theme }) => ({
  ...theme.typography.normalRegular,
}))

const HeadingH5 = styled(Heading)(({ theme }) => ({
  ...theme.typography.smallRegular,
}))

const HeadingH2Bold = styled(HeadingH2)(({ theme }) => ({
  ...theme.typography.h2Desk,
}))

const HeadingH3Bold = styled(HeadingH3)(({ theme }) => ({
  ...theme.typography.mediumBold,
}))

const HeadingH4Bold = styled(HeadingH4)(({ theme }) => ({
  ...theme.typography.normalBold,
}))

const HeadingH5Bold = styled(HeadingH5)(({ theme }) => ({
  ...theme.typography.smallBold,
}))
