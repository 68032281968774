import { useQuery } from '@tanstack/react-query'
import get from 'lodash/get'

import { useCommunityStore } from 'stores/domains/Social'
import { getErrorCode, getErrorMessage } from 'helpers/error'
import { sendLog } from 'helpers/log'
import { getContentCommunityInfo } from 'services/content'

export const useContentCommunityInfo = (
  content_uid: string | string[],
) => {
  const { setCommunityProduct } = useCommunityStore((state) => ({
    setCommunityProduct: state.setCommunityProduct,
  }))

  return useQuery({
    queryKey: ['content-community-info', content_uid],
    queryFn: async () => {
      try {
        const response = await getContentCommunityInfo(content_uid)
        const product = get(response, 'data.data', {
          mask_id: '',
          name: '',
          thumbnail_image: '',
          description: '',
          category: {
            id: 0,
            name: '',
          },
          total_member: 0,
        })
        setCommunityProduct(product)
        return product
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    onError: (error: Error) =>
      sendLog(`${getErrorCode(error)}: ${getErrorMessage(error)}`),
    refetchOnWindowFocus: false,
  })
}
