import {
  AREAS,
  AudioEncoderConfigurationPreset,
  ScreenEncoderConfigurationPreset,
  ScreenVideoTrackInitConfig,
  VideoEncoderConfigurationPreset,
} from 'agora-rtc-sdk-ng'
import { RtmStatusCode } from 'agora-rtm-sdk'

interface IAgoraConfig {
  appID: string
  areaCodeRTC: AREAS
  areaCodeRTM: RtmStatusCode.AreaCode
  userIDCreator: number
  poorConnection: number
  durationSessionEnd: number
  percentageEnableLeave: number
  enableButtonCreatorTimer: number
  adminRTM: number
  supporterUidStart: number
  creatorEncoder: VideoEncoderConfigurationPreset
  supporterEncoder: VideoEncoderConfigurationPreset
  shareScreenEncoder: ScreenEncoderConfigurationPreset
  userCreatorShareScreen: number
  permissionDeniedBySystemMsg: string
  optimizationMode: ScreenVideoTrackInitConfig['optimizationMode']
  limitUserRTC: number
  audioEncoder: AudioEncoderConfigurationPreset
}

export default <IAgoraConfig>{
  appID: process.env.NEXT_PUBLIC_AGORA_APP_ID,
  areaCodeRTC: process.env.NEXT_PUBLIC_AGORA_AREA_CODE || 'GLOBAL',
  areaCodeRTM: process.env.NEXT_PUBLIC_AGORA_AREA_CODE || 'GLOBAL',
  userIDCreator: 999,
  userCreatorShareScreen: 998,
  adminRTM: 888,
  supporterUidStart: 1000,
  poorConnection: 6,
  durationSessionEnd:
    process.env.NEXT_PUBLIC_DURATION_SESSION_END || 5,
  percentageEnableLeave:
    process.env.NEXT_PUBLIC_PERCENTAGE_ENABLE_LEAVE_CREATOR || 90,
  enableButtonCreatorTimer:
    process.env.NEXT_PUBLIC_ENABLE_BUTTON_CREATOR_TIMER || 600,
  creatorEncoder:
    process.env.NEXT_PUBLIC_AGORA_CREATOR_ENCODER || '360p',
  supporterEncoder:
    process.env.NEXT_PUBLIC_AGORA_PARTICIPANT_ENCODER || '180p',
  shareScreenEncoder:
    process.env.NEXT_PUBLIC_AGORA_SHARE_SCREEN_ENCODER || '360p',
  permissionDeniedBySystemMsg: 'Permission denied by system', // please don't change this message
  optimizationMode:
    process.env.NEXT_PUBLIC_AGORA_SHARE_SCREEN_OPTIMIZATION_MODE ||
    'motion',
  limitUserRTC: process.env.NEXT_PUBLIC_AGORA_LIMIT_USER_RTC || 50,
  audioEncoder:
    process.env.NEXT_PUBLIC_AGORA_AUDIO_ENCODER || 'standard_stereo',
}
