import { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  FormHelperText,
  Stack,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { ICustomField } from '../interface'

// field validation function
function validateField({
  fieldValidation,
  fieldValue,
  fieldLabel,
}): string | null {
  if (fieldValidation.required && !fieldValue.length) {
    return `${fieldLabel} wajib diisi`
  }

  return null
}

// main component function
export default function CustomDropdownField({
  ...props
}: ICustomField) {
  const [isTouched, setIsTouched] = useState(false)

  useEffect(() => {
    if (props.value && !isTouched) {
      setIsTouched(true)
    }
  }, [isTouched, props.value])

  if (!props.options?.length) {
    return null
  }

  const validationResult = validateField({
    fieldLabel: props.label,
    fieldValidation: props.validation,
    fieldValue: props.value,
  })

  return (
    <>
      <Select
        fullWidth
        className={`i-custom-field-dropdown ${
          isTouched && validationResult && 'Mui-error'
        }`}
        id={props.component_id}
        value={props.value}
        disabled={props.disabled}
        onBlur={() => {
          setIsTouched(true)
        }}
        onChange={(e) => {
          setIsTouched(true)
          props.onValueChange(
            e.target.value,
            validateField({
              fieldValidation: props.validation,
              fieldValue: e.target.value,
              fieldLabel: props.label,
            }) === null,
          )
        }}
      >
        {props.options.map((item) => {
          return (
            <MenuItem key={item.key} value={item.value}>
              {item.value}
            </MenuItem>
          )
        })}
      </Select>
      {isTouched && validationResult && (
        <FormHelperText
          sx={(theme) => ({ color: theme.palette.error.main })}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <ErrorIcon
              sx={{ fontSize: 'inherit', marginRight: '4px' }}
            />
            {validationResult}
          </Stack>
        </FormHelperText>
      )}
    </>
  )
}
