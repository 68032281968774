import { ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  CssBaseline,
  Container,
  styled,
  SxProps,
} from '@mui/material'

import Header from 'containers/commons/Header'
import Footer from 'containers/domains/Home/Footer'
import PhoneSubmitDrawer from 'containers/domains/User/PhoneSubmitDrawer'

import Box from 'components/commons/Box'
import Loader from 'components/commons/Layout/Loader'
import BottomBar from 'components/commons/Header/BottomBar'
import CommunityMissionCompleteDialog from 'screens/domains/Community/Mission/CompleteDialog'

import appConfig from 'configs/app'
import redirect from 'helpers/redirect'
import useReady from 'hooks/commons/useReady'
import { getPreviousURL } from 'helpers/routing'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { useMissionCategoryStore } from 'stores/domains/Social'
import { Theme } from '@mui/material/styles'

const StyledContainer = styled(Container)(({ theme }) => ({
  background: theme.palette.background.primary,
}))

type IStyledMainProps = {
  withHeader?: boolean
  withAppBanner?: boolean
  isHome?: boolean
}

const StyledMain = styled('main')<IStyledMainProps>(
  ({ theme, withHeader, withAppBanner, isHome }) => {
    let marginTop = 0
    if (withHeader) {
      if (withAppBanner) {
        if (isHome) marginTop = 68
        else marginTop = 140
      } else {
        if (isHome) marginTop = 0
        else marginTop = 68
      }
    }
    return {
      paddingBottom: '0px',
      marginTop: withHeader ? 40 : 0,
      [theme.breakpoints.up('md')]: {
        marginTop: withHeader ? 80 : 0,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '96px auto 0px auto',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: marginTop,
      },
    }
  },
)

const StyledBackgroundLogo = styled(Box)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${appConfig.assetBaseUrl}/webp/background_logo_white_mobile.webp)`,
  width: '100%',
  margin: '0px !important',
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${appConfig.assetBaseUrl}/webp/background_logo_white.webp)`,
  },
  height: '200px',
}))

interface FooterProps {
  isSticky?: boolean
  component: ReactNode
}

type HeaderType = 'base' | 'search'

interface LayoutProps {
  footer?: FooterProps
  children: ReactNode
  renderFooter?: boolean
  renderHeader?: boolean
  headerType?: HeaderType
  headerTitle?: string
  renderBackButton?: boolean
  renderBackgroundLogo?: boolean
  withManualWidth?: boolean
  mainPadding?: object
  setBackgroundColor?: string
  trackerData?: object
  bgColor?: string
  renderAppBanner?: boolean
  renderCart?: boolean
  renderBottomBar?: boolean
  isShowLogoOnly?: boolean
  bgImage?: string
  sxMainContainer?: SxProps<Theme>
  isHome?: boolean
  fullWidth?: boolean
}

function Layout({
  footer,
  children,
  renderFooter = true,
  renderHeader = true,
  headerType = 'base',
  headerTitle,
  renderBackButton = false,
  renderBackgroundLogo = false,
  renderBottomBar = true,
  withManualWidth = false,
  mainPadding = {},
  renderAppBanner = false,
  bgColor,
  renderCart = true,
  isShowLogoOnly = false,
  bgImage,
  sxMainContainer,
  isHome,
  fullWidth = false,
}: LayoutProps): JSX.Element {
  // Begin of Sign Up Bonus
  const { pathname } = useRouter()

  const { mounted } = useReady()
  const { signUpBonusBanner, screenLayout } = useStoreState(
    (state) => ({
      signUpBonusBanner: state.signUpBonusBanner,
      screenLayout: state.screenLayout,
    }),
  )
  const { isCompleted, isDialog, hideDialog } =
    useMissionCategoryStore()

  const bgImageStyle: any = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  const {
    setHeaderType,
    setHeaderTitle,
    setRenderBackButton,
    setOnClickBack,
  } = useStoreActions((action) => action.screenLayout)
  const [showAppBanner, setShowAppBanner] = useState(renderAppBanner)

  const setMaxWidth = () => {
    if (withManualWidth) return 'none'
    if (isHome || fullWidth) return '1440px'

    return '1152px'
  }

  useEffect(() => {
    setHeaderTitle(headerTitle)
    setHeaderType(headerType)
    setRenderBackButton(renderBackButton || pathname === '/browse')
    setOnClickBack(() => {
      redirect(getPreviousURL(pathname, 'trigger_back'))
    })
  }, [])

  if (!mounted) return null

  return (
    <>
      <CssBaseline />

      <StyledContainer maxWidth={false} disableGutters={true}>
        <PhoneSubmitDrawer />
        {renderHeader && (
          <Header
            isShowLogoOnly={isShowLogoOnly}
            showAppBanner={showAppBanner}
            setShowAppBanner={setShowAppBanner}
            renderCart={renderCart}
            isHome={isHome}
          />
        )}

        {screenLayout.isLoading ? (
          <Loader />
        ) : (
          <StyledMain
            withAppBanner={showAppBanner}
            sx={{
              flexGrow: 1,
              height: 'auto',
              paddingTop: {
                xs:
                  signUpBonusBanner.elementHeight >= 1
                    ? '60px !important'
                    : '0px',
                md:
                  signUpBonusBanner.elementHeight >= 1
                    ? '40px !important'
                    : '0px',
              },
              padding:
                withManualWidth && mainPadding
                  ? mainPadding
                  : {
                      xs: withManualWidth ? '0' : '16px',
                      md: withManualWidth ? '0' : '24px',
                    },
              maxWidth: {
                lg: setMaxWidth(),
              },
              backgroundColor: bgColor,
              ...(bgImage && bgImageStyle),
              ...sxMainContainer,
            }}
            withHeader={renderHeader}
            isHome={isHome}
          >
            {children}
          </StyledMain>
        )}
        {renderBackgroundLogo && <StyledBackgroundLogo />}
        {renderFooter && footer?.component ? (
          footer.component
        ) : (
          <>
            {renderFooter ? (
              <Footer
                sx={{
                  display: headerType === 'search' && {
                    xs: 'none',
                    md: 'flex',
                  },
                }}
              />
            ) : null}
          </>
        )}
        {renderBottomBar && <BottomBar />}
        <CommunityMissionCompleteDialog
          open={isCompleted && isDialog}
          onClose={hideDialog}
        />
      </StyledContainer>
    </>
  )
}

export default Layout
