import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import IAgreementModel from 'stores/domains/Creator/AgreementPromoter/interface'

const AgreementModel: IAgreementModel = {
  isLoadingAgreement: false,
  agreementData: {
    is_agreement_accepted: false,
  },
  setLoading: action((state, payload) => {
    state.isLoadingAgreement = payload
  }),
  setAgreement: action((state, payload) => {
    state.agreementData = payload
  }),
  updateAgreement: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/user/v1/agreement`,
        method: 'PUT',
        token: getCookie(authConfig.idTokenName),
        data: {
          is_agreement_accepted: payload,
        },
      })
      actions.setLoading(false)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }),
}

export default AgreementModel
