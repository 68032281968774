interface Props {
  contentType: string
  url: string
  method?: string
  blob: Blob
  handleUploadProgress?: Function
  setRequest?: Function
  handleError?: Function
}

export function makeXMLRequest({
  contentType,
  url,
  method = 'PUT',
  blob,
  handleUploadProgress = undefined,
  setRequest = undefined,
  handleError = undefined,
}: Props) {
  return new Promise(function (resolve) {
    let oReq = new XMLHttpRequest()
    if (setRequest) setRequest(oReq)

    oReq.open(method, url, true)
    oReq.setRequestHeader('Content-Type', contentType)
    oReq.setRequestHeader('Cache-Control', 'max-age=31536000')
    oReq.setRequestHeader(
      'Access-Control-Allow-Origin',
      process.env.NEXT_PUBLIC_ALLOW_ORIGIN_URL,
    )
    oReq.upload.addEventListener('progress', function (event) {
      if (event.lengthComputable && handleUploadProgress) {
        const progress = (event.loaded / event.total) * 100
        handleUploadProgress(progress | 0)
      }
    })
    oReq.onload = function () {
      resolve(oReq.response)
    }
    oReq.onerror = function () {
      if (handleError) handleError()
    }
    oReq.send(blob)
  })
}
