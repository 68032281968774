import { styled } from '@mui/material'
import appConfig from 'configs/app'
import Button from '../Button'
import Box from '../Box'
import Typography from '../Typography'

export const BannerContainer = styled(Box)({
  position: 'fixed',
  display: 'grid',
  gridTemplateColumns: 'auto 20px 20px',
  top: '0',
  left: '0',
  right: '0',
  width: '100%',
  height: '72px',
  color: ' #000',
  boxShadow:
    '0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3)',
  padding: '10px',
  borderRadius: 0,
  zIndex: 10,
  backgroundImage: `url(${appConfig.assetBaseUrl}/app-banner.png)`,
  backgroundSize: 'cover',
})

export const CloseButtonContainer = styled(Box)({
  justifySelf: 'end',
  alignSelf: 'center',
})
export const CloseButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  margin: 'auto',
  border: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  borderRadius: '14px',
  minWidth: 0,
  minHeight: 0,
  color: theme.palette.background.primary,
  backgroundColor: '#00000029',
  cursor: 'pointer',
  padding: '2px 2px',
}))

export const AppLinkContainer = styled(Box)({
  justifySelf: 'end',
  textAlign: 'right',
  marginRight: '10px',
  height: '100%',
  display: 'flex',
})

export const AppLinkButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  minWidth: '80px',
  color: 'white',
  fontColor: 'white',
  padding: '2px 0px',
  backgroundColor: '#00000029',
  borderRadius: 18,
  border: `1px solid ${theme.palette.info.main}`,
  cursor: 'pointer',
  textDecoration: 'none',
}))
export const LabelContainer = styled(Box)({
  justifySelf: 'center',
  alignSelf: 'center',
  marginRight: 10,
  textShadow: 'inherit',
})
export const StyledLabelMain = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumBold,
  lineHeight: '20px',
  color: ' #FFFFFF',
}))
export const StyledLabelSub = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallBold,
  lineHeight: '20px',
  color: ' #FFFFFF',
  textShadow: '0px 1px 2px 0px #0000004D',
}))
