import { StackProps } from '@mui/material'
import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { Caption, Container } from './styled'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

interface MenuReferralProps extends StackProps {
  referral: string
  isLoading?: boolean
  narrow?: boolean
  dark?: boolean
}

export function MenuReferral({
  referral,
  narrow = false,
  ...rest
}: MenuReferralProps) {
  const { t } = useTranslation()

  return (
    <Container flex={1} {...rest}>
      <Stack justifyContent="center" alignItems="center" width="24px">
        <PeopleRoundedIcon color="primary" />
      </Stack>
      <Stack direction="column" flex={1}>
        {!narrow && <Caption>{t('referralCode')}</Caption>}
        <Caption
          sx={(theme) => ({ ...theme.typography.normalBold })}
          id="c-wallet-referral-code-label"
          main
        >
          {referral}
        </Caption>
      </Stack>
      <IconKeyboardArrowRight fontSize="small" color="tertiery" />
    </Container>
  )
}
