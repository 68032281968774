/* eslint-disable @next/next/no-img-element */
import { Stack, Slide } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import { redirectWithReferrer } from 'helpers/redirector'
import authConfig from 'configs/auth'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import get from 'lodash/get'
import htmlParse from 'html-react-parser'
import { useProfileUserStore } from 'stores/domains/User'
import Button from 'components/commons/Button'

function BannerBonus() {
  const bannerEl = useRef<HTMLDivElement>(null)
  const { isReady, pathname, query } = useRouter()
  const [clickableBanner, setClickableBanner] = useState(false)
  const [shouldBeSticky, setShouldBeSticky] = useState(false)

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const signUpBonusBanner = useStoreState(
    (state) => state.signUpBonusBanner,
  )
  const signUpBonusAction = useStoreActions(
    (actions) => actions.signUpBonusBanner,
  )

  const { creatorProfileData } = useStoreState(
    (state) => state.creatorProfile,
  )

  const states = useStoreState((state) => ({
    sessionDetail: state.sessionDetail,
    sessionBooking: state.sessionBooking,
    participantList: state.participantList,
  }))

  const pathNamesWithStickyHeaders = useMemo(
    () => [
      '/browse/[id]',
      '/session/[session_id]',
      '/p/[username]',
      '/sign-up',
      '/user/post-registration',
      '/login',
    ],
    [],
  )

  const pathNamesWithoutClickableBanner = useMemo(
    () => ['/sign-up', '/login', '/user/post-registration'],
    [],
  )

  const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.25,
    padding: 8,
  }))

  const WrapperIcon = styled('div')({
    width: 24,
    height: 24,
  })

  const WrapperContent = styled('div')({})

  const WrapperCta = styled('div')({
    marginLeft: '8px !important',
    marginRight: '8px !important',
    width: '16px',
  })

  const CloseBannerButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    padding: 0,
    minWidth: 0,
    width: '16px',
    marginLeft: 0,
    marginRight: 8,
  }))

  const handleCloseBanner = () => {
    if (
      pathname === '/' ||
      pathname === '/browse' ||
      pathname === '/session/[session_id]'
    ) {
      trackEvent('trigger_close_free_coin_on_sign_up_flying_banner', {
        state_name: getStateFromSource(pathname),
      })
    } else if (pathname === '/p/[username]') {
      trackEvent('trigger_close_free_coin_on_sign_up_flying_banner', {
        state_name: getStateFromSource(pathname),
        sub_state_name:
          query && query.tab === '1on1'
            ? '1_on_1_session'
            : 'group_session',
      })
    }
    signUpBonusAction.setOpened(false) // Close app wide state
  }

  const handleClickBanner = () => {
    if (pathname === '/' || pathname === '/browse') {
      trackEvent('click_free_coin_on_sign_up_flying_banner', {
        state_name: getStateFromSource(pathname),
      })
    } else if (pathname === '/p/[username]') {
      trackEvent('click_free_coin_on_sign_up_flying_banner', {
        state_name: getStateFromSource(pathname),
        sub_state_name:
          query && query.tab === '1on1'
            ? '1_on_1_session'
            : 'group_session',
        creator_category_id: creatorProfileData.category.id,
        creator_category: creatorProfileData.category.name,
        creator_user_id: creatorProfileData.creator.creator_user_id,
        creator_name: creatorProfileData.creator.name,
        creator_id: creatorProfileData.creator.creator_id,
      })
    } else if (pathname === '/session/[session_id]') {
      trackEvent('click_free_coin_on_sign_up_flying_banner', {
        state_name: getStateFromSource(pathname),
        creator_id: get(
          states.sessionDetail,
          'sessionData.creator.creator_id',
          '',
        ),
        creator_name: get(
          states.sessionDetail,
          'sessionData.creator.name',
          '',
        ),
        creator_user_id: get(
          states.sessionDetail,
          'sessionData.creator.creator_user_id',
          '',
        ),
        creator_category_id: get(
          states.sessionDetail,
          'sessionData.creator.category.id',
          '',
        ),
        creator_category: get(
          states.sessionDetail,
          'sessionData.creator.category.name',
          '',
        ),
        session_id: get(
          states.sessionDetail,
          'sessionData.session_id',
          '',
        ),
        num_user_booked: states.sessionBooking.totalBooking,
        list_user_booked: states.participantList.participants.map(
          (participant) => participant.user_id,
        ),
        session_status: get(
          states.sessionDetail,
          'sessionData.session_status',
          '',
        ).toLowerCase(),
        session_type: get(
          states.sessionDetail,
          'sessionData.session_type',
        ),
        session_price_per_participant: get(
          states.sessionDetail,
          'sessionData.price',
          0,
        ),
        max_num_participant: get(
          states.sessionDetail,
          'sessionData.slots',
          0,
        ),
        session_title: get(states.sessionDetail, 'sessionData.title'),
        session_description: get(
          states.sessionDetail,
          'sessionData.description',
        ),
        estimate_session_start_datetime: get(
          states.sessionDetail,
          'sessionData.start_time',
        ),
        estimate_session_duration:
          get(states.sessionDetail, 'sessionData.duration', 0) * 60, // seconds
        session_photo_image_path: get(
          states.sessionDetail,
          'sessionData.cover',
        ),
      })
    }
    redirectWithReferrer(authConfig.loginPath)
  }

  useEffect(() => {
    if (!!isReady) {
      if (pathname === '/user/post-registration') {
        signUpBonusAction.getBannerSignup({ params: { id: 3 } })
      } else {
        signUpBonusAction.getBannerSignup({ params: { id: 1 } })
      }
    }
  }, [isReady, pathname, signUpBonusAction])

  useEffect(() => {
    if (!!signUpBonusBanner.entitled && !!isReady) {
      if (!pathNamesWithoutClickableBanner.includes(pathname)) {
        setClickableBanner(true)
      }
      // Set setShouldBeSticky true only if the page has sticky header, after the API request finish
      if (pathNamesWithStickyHeaders.includes(pathname)) {
        setShouldBeSticky(true)
      }
      signUpBonusAction.setElementHeight(
        bannerEl?.current?.clientHeight ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReady,
    pathname,
    signUpBonusBanner,
    pathNamesWithStickyHeaders,
  ])
  if (
    signUpBonusBanner?.opened &&
    profileData.phone === '' &&
    signUpBonusBanner?.bannerSignUpMessage
  ) {
    return (
      <Slide
        in={signUpBonusBanner?.opened}
        {...(signUpBonusBanner?.opened ? { timeout: 500 } : {})}
        unmountOnExit
        style={shouldBeSticky ? { position: 'sticky', top: 0 } : {}}
      >
        <Wrapper ref={bannerEl} id="component-signupbonus-banner">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            style={{
              cursor: clickableBanner ? 'pointer' : 'default',
            }}
          >
            <Stack
              onClick={clickableBanner ? handleClickBanner : () => {}}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{ paddingLeft: 8 }}
            >
              <WrapperIcon>
                <img
                  src={signUpBonusBanner?.bannerSignUp?.image_url}
                  alt="Gift"
                  style={{ width: 24, height: 24 }}
                />
              </WrapperIcon>

              <WrapperContent>
                {htmlParse(signUpBonusBanner?.bannerSignUpMessage)}
              </WrapperContent>
            </Stack>

            <WrapperCta>
              <CloseBannerButton onClick={handleCloseBanner}>
                <CloseIcon sx={{ fontSize: 16 }} />
              </CloseBannerButton>
            </WrapperCta>
          </Stack>
        </Wrapper>
      </Slide>
    )
  }

  return null
}

export default BannerBonus
