import { styled, useTheme } from '@mui/material/styles'
import CoinIcon from 'assets/svg/session/coin.svg'
import Divider from '@mui/material/Divider'
import InfoIcon from '@mui/icons-material/Info'

import ErrorIcon from '@mui/icons-material/Error'
import CurrencyFormatter from 'helpers/formats/currency'
import { useStoreState } from 'stores/hooks'
import PaymentConfirmationSkeleton from 'components/domains/Wallet/PaymentConfirmationDrawerContent/Skeleton'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(Box)({
  width: '100%',
  minHeight: '200px',
})

const CoinIconContainer = styled(Stack)({
  width: '50%',
  svg: {
    width: '18px',
    height: '18px',
  },
})
const CoinInfoContainer = styled(Stack)({
  width: '100%',
})
const CoinPriceContainer = styled(Stack)({
  width: '50%',
})

const CoinPriceLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 'bold',
  marginLeft: '18px',
}))
const CoinQuantityLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '16px',
  marginLeft: '18px',
}))

const CoinValueLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '24px',
}))

const StyledStack = styled(Stack)({
  width: '100%',
})

const PriceCurrency = styled('span')(({ theme }) => ({
  color: theme.palette.tiptip[500],
}))
const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette.border.bold,
}))
const StyledError = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  color: theme.palette.text.primary,
  fontSize: 14,
  margin: '5px 0px',
  alignItems: 'center',
  svg: {
    marginRight: 5,
    color: theme.palette.error.main,
    fontSize: '22px',
  },
}))
const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  borderRadius: '8px',
  padding: '12px 26px',
  [theme.breakpoints.up('sm')]: {
    padding: '14px 56px',
  },
}))

interface IPaymentConfirmationDrawer {
  onShowPaymentDrawer?: Function
}

function PaymentConfirmationDrawerContent(
  props: IPaymentConfirmationDrawer,
) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isLoading, topupInquiry, isError, errorMessage } =
    useStoreState((actions) => actions.topUp)

  return (
    <>
      {isLoading ? (
        <PaymentConfirmationSkeleton />
      ) : (
        <StyledContainer>
          {isError && (
            <StyledError>
              <ErrorIcon />
              <Typography>{errorMessage}</Typography>
            </StyledError>
          )}

          <StyledStack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            {topupInquiry.coins.map((item) => (
              <CoinInfoContainer
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                key={'coin-package-inquiry-' + item.coin_id}
              >
                <CoinIconContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <CoinIcon />
                  <CoinValueLabel className="c-wallet-coin-value-confirmation">
                    {item.coin_unit.toLocaleString()}
                  </CoinValueLabel>
                  <CoinQuantityLabel className="c-wallet-coin-quantity-confirmation">
                    x {item.qty.toLocaleString()}
                  </CoinQuantityLabel>
                </CoinIconContainer>
                <CoinPriceContainer
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <CoinPriceLabel className="c-wallet-coin-price-confirmation">
                    <PriceCurrency>Rp</PriceCurrency>
                    {item.amount.toLocaleString()}
                  </CoinPriceLabel>
                </CoinPriceContainer>
              </CoinInfoContainer>
            ))}
            <Stack
              direction={'row'}
              gap={2}
              sx={{ alignItems: 'center', justifyContent: 'start' }}
            >
              <InfoIcon
                sx={{
                  fontSize: '18px',
                  color: theme.palette.info.main,
                }}
              />
              <Typography fontSize={12}>
                {t('topUpPaymentConfirmationDescription')}
              </Typography>
            </Stack>
            <StyledDivider />
            <Stack
              direction={'row'}
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction={'column'}>
                <Typography fontSize={12}>
                  {t('successPurchaseWithCoinBottomSheetWidgetTotal')}
                </Typography>
                <Typography fontSize={20} fontWeight={700}>
                  {CurrencyFormatter(topupInquiry.sub_total)}
                </Typography>
              </Stack>
              <StyledButton
                buttonVariant="filled"
                onClick={() => props.onShowPaymentDrawer()}
                id="b-wallet-submit-top-up-confirmation"
              >
                {t('paymentConfirmationBottomSheetButton')}
              </StyledButton>
            </Stack>
          </StyledStack>
        </StyledContainer>
      )}
    </>
  )
}

export default PaymentConfirmationDrawerContent
