import { useTheme } from '@mui/material'
import redirect from 'helpers/redirect'
import Typography from 'components/commons/Typography'
import StateImage from 'components/commons/StateImage'
import appConfig from 'configs/app'
import ActionButton from 'components/commons/Button/Action'

interface IMaintenanceBody {
  isButtonHide?: boolean
}
function MaintenanceBody({
  isButtonHide,
}: IMaintenanceBody): JSX.Element {
  const theme = useTheme()

  return (
    <div>
      <StateImage
        src={`${appConfig.assetBaseUrl}/webp/state_system_maintenance.webp`}
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '35px',
        }}
      />
      <Typography
        variant="h3"
        color={theme.palette.text.primary}
        sx={{ marginBottom: '10px' }}
      >
        Pemeliharaan Sistem
      </Typography>
      <Typography
        color={theme.palette.text.secondary}
        variant="normalRegular"
        sx={{ marginBottom: '24px', display: 'block' }}
      >
        Mohon maaf kami sedang dalam pemeliharaan sistem, mohon
        kembali lagi nanti
      </Typography>
      {!isButtonHide && (
        <ActionButton
          buttonVariant="outlined"
          sx={{
            borderRadius: '28px',
            padding: '12px 24px',
            width: 159,
            marginTop: '24px',
          }}
          onClick={() => redirect('/')}
        >
          Kembali ke Home
        </ActionButton>
      )}
    </div>
  )
}

export default MaintenanceBody
