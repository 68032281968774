import { action, thunk } from 'easy-peasy'
import { getCookie } from 'helpers/cookie'
import { generateShortUuid } from 'helpers/generate/uuid'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import ISendTippingCoinModel from 'stores/domains/Wallet/models/SendTippingCoin/interface'

const SendTippingCoin: ISendTippingCoinModel = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  tipCoin: 0,
  tipAccumulation: 0,

  clear: action((state) => {
    state.isError = false
    state.isSuccess = false
    state.errorMessage = ''
  }),
  setTipCoin: action((state, payload) => {
    state.tipCoin = payload
    state.isError = false
    state.isSuccess = false
    state.errorMessage = ''
  }),
  setTipAccumulation: action((state, payload) => {
    state.tipAccumulation = payload
    state.isError = false
    state.isSuccess = false
    state.errorMessage = ''
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
    state.isError = false
    state.isSuccess = false
    state.errorMessage = ''
  }),
  setSuccess: action((state, payload) => {
    state.isSuccess = payload
    state.isError = false
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.isSuccess = false
    state.errorMessage = payload
  }),
  send: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const resp = await apiClient({
        url: `/session/v2/tip/${payload.session_id}/deduct`,
        method: 'POST',
        data: {
          coin_id: payload.coin_id,
        },
        headers: { 'Idempotent-Key': generateShortUuid() },
        token: getCookie(authConfig.idTokenName),
      })
      actions.setTipAccumulation(
        get(resp, 'data.data.tip_accumulation', 0),
      )
      actions.setTipCoin(get(resp, 'data.data.tip_coin', 0))

      actions.setLoading(false)
      actions.setSuccess(true)
    } catch (error) {
      if (get(error, 'response.data.code') === 'FAIL') {
        actions.setError(
          get(error, 'response.data.errors', ['unknown error']).join(
            ',',
          ),
        )
      } else {
        actions.setError(get(error, 'response.data.message'))
      }
    }
  }),
}

export default SendTippingCoin
