import { useRouter } from 'next/router'
import { Avatar, IconButton, Link, useTheme } from '@mui/material'

import { useMessageStore } from 'stores/domains/User'
import redirect from 'helpers/redirect'
import trackEvent from 'src/trackers'

import Stack from 'components/commons/Stack'
import ButtonIcon from 'components/commons/ButtonIcon'
import CreatorCaption from 'components/commons/CreatorCaption'
import BlockDialog from './BlockDialog'
import MoreMenu from './MoreMenu'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import ReportDrawer from 'components/commons/ReportDrawer/v2'
import { isGuestMode } from 'helpers/cookie'
import {
  DEFAULT_PROFILE_DATA,
  IProfileData,
} from 'stores/domains/User/UserProfile'
import { MouseEvent, useState } from 'react'

interface MessageHeaderProps {
  isBlocked?: boolean
  userProfile?: IProfileData
}

export default function MessageHeader({
  isBlocked,
  userProfile = DEFAULT_PROFILE_DATA,
}: MessageHeaderProps) {
  const theme = useTheme()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }

  const { blockPeerUser, peer, roomMessages } = useMessageStore(
    (state) => ({
      blockPeerUser: state.blockPeerUser,
      peer: state.currentPeer,
      roomMessages: state.roomMessages,
    }),
  )

  const [isBlockDialog, setIsBlockDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [reportDrawerShown, setReportDrawerShown] = useState(false)
  const [yesterdayChats, setYesterdayChats] = useState('[]')

  const open = Boolean(anchorEl)
  const isSenderCreator = userProfile?.creator?.status === 'verified'
  const targetUserData = {
    to_user_id: peer?.id || 'none',
    to_username: peer?.username || 'none',
    to_user_type: peer?.isCreator ? 'creator' : 'supporter',
    to_creator_id: peer?.creator?.id || 'none',
    to_creator_name: peer?.creator?.name || 'none',
  }
  const userData = {
    from_user_id: userProfile?.user_id,
    from_username: userProfile?.username,
    from_user_type: isSenderCreator ? 'creator' : 'supporter',
    from_creator_id: isSenderCreator
      ? userProfile?.creator?.creator_id
      : 'none',
    from_creator_name: isSenderCreator
      ? userProfile?.creator?.name
      : 'none',
    ...targetUserData,
  }
  const eventName = peer?.isCreator
    ? 'click_creator_profile'
    : 'click_supporter_profile'

  const handleClickMore = (event: MouseEvent<HTMLElement>) => {
    trackEvent.user('click_more_options', trackerData, userData)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
  }

  const handleBlockUser = () => {
    if (!isBlocked) {
      trackEvent.user('click_block_user', trackerData, {
        ...userData,
        modal_name: 'OTHER_OPTIONS',
      })
    }
    setIsBlockDialog(true)
    handleCloseMore()
  }

  const handleBlockUserConfirm = () => {
    trackEvent.user(
      isBlocked ? 'unblock_user' : 'block_user',
      trackerData,
      {
        ...userData,
        modal_name: isBlocked ? 'UNBLOCK_USER' : 'BLOCK_USER',
      },
    )

    blockPeerUser(peer?.id, isBlocked)
    handleCloseMore()
    handleCloseBlock()
  }

  const handleCloseBlock = () => {
    setIsBlockDialog(false)
  }

  const trackerClickProfile = () => {
    const additionalData = peer?.isCreator
      ? { creator_user_id: peer?.id }
      : {}
    trackEvent.user(eventName, trackerData, {
      ...targetUserData,
      ...additionalData,
    })
  }

  const handleClickProfile = () => {
    trackerClickProfile()
    handleCloseMore()
    redirect(
      `/p/${peer?.username}?source=${pathname}&source_action=${eventName}`,
    )
  }

  const handleClickReport = () => {
    const yesterdayFormat = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000,
    ).toISOString()
    const peerMessage =
      roomMessages.filter(
        (message) => message.userId === peer.id,
      )?.[0].messages || []
    const lastMessageSinceYesterday = peerMessage.filter(
      (message) => message.created_at > yesterdayFormat,
    )
    setYesterdayChats(JSON.stringify(lastMessageSinceYesterday))
    handleCloseMore()
    setReportDrawerShown(true)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      padding={{
        xs: 0,
        md: 2,
      }}
      bgcolor={{
        xs: theme.palette.background.primary,
      }}
      spacing={1}
      sx={{
        borderTopRightRadius: { md: '12px' },
        borderBottom: `1px solid ${theme.palette.border.bold}`,
      }}
      height={{ xs: '60px', md: '72px' }}
      paddingX={{ xs: 2 }}
      width="100%"
      id="c-users-chat-header"
      position={{ xs: 'fixed', md: 'relative' }}
      top={0}
      zIndex={1}
    >
      <Link
        display={{ md: 'none' }}
        href={`/message?source=${pathname}&source_action=trigger_back`}
      >
        <IconButton
          aria-label="back"
          edge="start"
          color="secondary"
          sx={{ margin: 0 }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="start"
        flex={1}
        onClick={trackerClickProfile}
      >
        <Link
          href={`/p/${peer?.username}?source=${pathname}&source_action=${eventName}`}
        >
          <Avatar
            sx={{ backgroundColor: theme.palette.purple[500] }}
            src={peer?.profile_image_thumbnail}
            sizes="40px"
          />
        </Link>
        <CreatorCaption
          title={peer?.callName}
          isVerified={peer?.creator?.verified}
          isSubscriber={peer?.subscriber}
          isSubscribing={peer?.creator?.subscribing}
          username={peer?.username}
          isSupporter={!peer?.isCreator}
          size="sm"
        />
      </Stack>
      <Stack>
        <ButtonIcon
          id="b-users-message-header-more"
          icon={MoreVertRoundedIcon}
          onClick={handleClickMore}
          aria-controls={open ? 'more-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{ color: theme.palette.text.primary }}
        />
        <MoreMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMore}
          onClickBlockUser={handleBlockUser}
          onClickProfile={handleClickProfile}
          isBlocked={isBlocked}
          onClickReport={handleClickReport}
        />
      </Stack>
      <BlockDialog
        open={isBlockDialog}
        onClose={handleCloseBlock}
        onConfirm={handleBlockUserConfirm}
        peerName={peer?.callName}
        unblock={isBlocked}
      />

      {!isGuestMode() && (
        <ReportDrawer
          onClose={() => {
            setReportDrawerShown(false)
          }}
          showDrawer={reportDrawerShown}
          reportType="direct-message"
          profileData={userProfile}
          peerId={peer?.id}
          message={yesterdayChats}
        />
      )}
    </Stack>
  )
}
