import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <SVGUniqueID>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="url(#paint0_linear_622_10669)"
      />
      <ellipse
        opacity="0.25"
        cx="12"
        cy="11.9998"
        rx="8.7"
        ry="8.7"
        fill="white"
      />
      <path
        d="M17.2865 13.7652L10.5577 17.7205C10.3448 17.8457 10.1185 17.9274 9.88917 17.9685L9.90335 13.363L10.1671 13.2079L7.77729 9.08012C7.21163 8.1031 7.53897 6.8491 8.50841 6.27925C9.47785 5.7094 10.7223 6.03947 11.288 7.01649L13.6777 11.1443L15.2381 10.2271C16.2075 9.65726 17.452 9.98734 18.0176 10.9644C18.5833 11.9414 18.2559 13.1954 17.2865 13.7652Z"
        fill="url(#paint1_linear_622_10669)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5545 6.27825L17.2891 10.2237C18.2593 10.7922 18.5885 12.0457 18.0243 13.0235C17.4601 14.0014 16.2162 14.3333 15.2459 13.7648L13.6842 12.8499L11.3005 16.9812C10.7363 17.959 9.49232 18.2909 8.52204 17.7225C7.55176 17.154 7.22257 15.9005 7.78679 14.9227L10.1705 10.7914L8.51127 9.81934C7.54098 9.2509 7.2118 7.99739 7.77601 7.01954C8.34023 6.0417 9.58418 5.70981 10.5545 6.27825Z"
        fill="url(#paint2_linear_622_10669)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_622_10669"
          x1="12"
          y1="0"
          x2="12"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC75A" />
          <stop offset="1" stopColor="#FFA800" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_622_10669"
          x1="12.8974"
          y1="6"
          x2="12.8974"
          y2="17.9685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC75A" />
          <stop offset="1" stopColor="#FFA800" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_622_10669"
          x1="12.9002"
          y1="6.00049"
          x2="12.9002"
          y2="18.0003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE0A4" />
          <stop offset="1" stopColor="#FFC95F" />
        </linearGradient>
      </defs>
    </svg>
  </SVGUniqueID>
)

export default SvgComponent
