import { useQuery } from '@tanstack/react-query'
import { useReferralStore } from 'stores/domains/User'
import { getErrorMessage } from 'helpers/error'
import { destroyLocalStorage } from 'helpers/local-storage'
import { getReferralCodeInfo } from 'services/user'

const useReferralInfo = (code) => {
  const { setReferralCodeInfo, setErrorMessage } = useReferralStore(
    (state) => ({
      setReferralCodeInfo: state.setReferralCodeInfo,
      setErrorMessage: state.setErrorMessage,
    }),
  )
  return useQuery({
    queryKey: ['referral-code-info'],
    queryFn: async () => {
      try {
        setErrorMessage('')
        const response = await getReferralCodeInfo(code)
        const result = response?.data?.data
        setReferralCodeInfo(result)
        return result
      } catch (error) {
        destroyLocalStorage('referralCode')
        setErrorMessage(getErrorMessage(error))
        return Promise.reject(error?.response?.data?.code)
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export { useReferralInfo }
