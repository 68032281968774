const objectToQueryString = (obj): any => {
  let str = ''
  if (obj !== null && typeof obj === 'object') {
    str = Object.entries(obj)
      .map(([key, val]) =>
        Array.isArray(val)
          ? val.map((value) => `${key}=${value}`).join('&')
          : `${key}=${val}`,
      )
      .join('&')
  }
  return str
}

export default objectToQueryString
